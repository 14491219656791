<div class="pt-28 mx-1 sm:mx-12">

  <!-- <div class="container"> -->
  <div class="flex sticky w-full z-1000 bg-white">

    <!-- <div class="w-full"  > -->
    <!-- <button type="submit" class="inline-block relative drop-shadow-lg bg-purple-500 border-purple-300 py-[8px] px-[20px] rounded-lg text-2xl text-center font-bold uppercase before:content-[' '] before:absolute before:z-0 before:bg-purple-500 before:border-purple-300 before:w-[38px] before:h-[38px] before:transform before:rotate-45 before:top-[5px] before:-right-[13px] before:origin-[50%] before:rounded-lg">
        <span>Next</span>
      </button> -->

    <div class="grid grid-cols-1 sticky w-full sm:grid-cols-4">
      <button *ngFor="let tab of tabs; let i = index" (click)="setActiveTab(i)" [ngClass]="getTabClass(i)"
        [disabled]="activeTab !== i"
        class="flex items-center text-center   justify-center mt-2 h-16 w-full rounded-tr-full rounded-bl-lg  p-0.5 "
        [class.text-green-700]="activeTab === i"
        [class.bg-green-200]="activeTab === i" [class.bg-gray-200]="activeTab <i"
        [class.border-transparent]="activeTab !== i">
        {{ tab.title }}
        <!-- font-bold -->
        <!-- [disabled]="activeTab !== i"     -->
        <!-- [class.animate-pulse]="activeTab === i" -->
        @if(activeTab === i){&nbsp;&nbsp;
        <span class="relative flex justify-end h-12 w-12 mb-16">
          @if(activeTab !== i){
          <svg class="h-28 w-14 text-blue-900" viewBox="0 0 23 24" fill="none" stroke="currentColor" stroke-width="2"
            stroke-linecap="round" stroke-linejoin="round">
            <polyline points="20 6 9 17 4 12" />
          </svg>

          }
          <!-- <div id="arrowAnim" class="col-span-1">
          <div class="arrowSliding">
            <div class="arrow"></div>
          </div>
          <div class="arrowSliding delay1">
            <div class="arrow"></div>
          </div>
          <div class="arrowSliding delay2">
            <div class="arrow"></div>
          </div>
          <div class="arrowSliding delay3">
            <div class="arrow"></div>
          </div>
        </div> -->
          <!-- <img
          class="h-20 w-20 mb-15"
          src="./../../assets/img/image.gif"
        /> -->

          <!-- <span
          class="animate-ping absolute inline-flex h-8 w-8 mt-9 rounded-full bg-green-400 opacity-75"
        ></span> -->
          <!-- <span class="relative inline-flex rounded-full h-4 w-4 bg-green-700"></span> -->
        </span>
        }
      </button>
      <button class="flex items-center justify-center h-16 w-full mt-[-5px]">
        <div class="h-16 w-16">
          <img src="../../assets/img/cart.png" alt="" />
        </div>
        <p class="text-small leading-6">
          {{ lang.Monthly_premium }} <br />{{ lang.Taxes_included }}<br>

          @if(planAmount && planAmount.length>0){
            <strong>${{ planAmount | number: "1.2-2" }}</strong>
            }
        </p>
      </button>
    </div>
    <!-- </div> -->
  </div>

  <div class="linescrollbar" style="height: calc(100vh - 240px); overflow-y: scroll">
    @if(activeTab === 0)
    {

    <form autocomplete="off" [formGroup]="userForm" (ngSubmit)="onSubmit(userForm.value, 0)">
      <div class="grid grid-cols-2 md:grid-cols-4 gap-2">

        <div class="md:col-span-4 w-full lg:w-1/2">
          <label for="planEnrollDate">
            {{ lang.Plan_Enrollment_Date }}<span style="color: red">*</span></label>
          <select class="form-control" id="planEnrollmentDate" placeholder="Select"
            formControlName="planEnrollmentDate">
            <option value="" label="">{{ lang.Select }}</option>
            <option *ngFor="let name of configplanEnrollmentDateFull" [value]="name" [label]="name">
              {{ name }}
            </option>
          </select>
          @if (f['planEnrollmentDate'].errors &&
          f['planEnrollmentDate'].touched) {
          <div class="invalid text-red-500">
            @if (f['planEnrollmentDate'].errors['required']) {
            <div>{{ lang.Please_choose_plan_enrollment_date }}</div>
            }
          </div>
          }
        </div>
        <div class="col-span-2">
          <label for="firstname">{{ lang.First_Name}}<span style="color: red">*</span></label>
          <input type="text" class="form-control checking-field" id="firstName" placeholder={{lang.First_Name}}
            formControlName="firstName" (keypress)="alphabatesOnly($event)" maxlength="40" minlength="2" />
          @if (f['firstName'].errors && f['firstName'].touched) {
          <div class="invalid text-red-500">
            @if (f['firstName'].errors['required']) {
            <div>{{ lang.Please_enter_first_name}}</div>
            } @if (f['firstName'].errors['minlength']) {
            <div>{{ lang.First_Name_minimum_of_2_Characters_and_without_numbers}}</div>
            }
          </div>
          }
        </div>
        <div class="col-span-2">
          <label for="lastName">{{ lang.Last_Name }}<span style="color: red">*</span></label>
          <input type="text" class="form-control checking-field" id="lastName" placeholder={{lang.Last_Name}}
            (keypress)="alphabatesOnly($event)" formControlName="lastName" maxlength="40" minlength="2" />
          @if (f['lastName'].errors && f['lastName'].touched) {
          <div class="invalid text-red-500">
            @if (f['lastName'].errors['required']) {
            <div>{{ lang.Please_enter_last_name}}</div>
            } @if (f['lastName'].errors['minlength']) {
            <div>{{ lang.Last_Name_minimum_of_2_Characters_and_without_numbers}}</div>
            }
          </div>
          }
        </div>
        <div class="col-span-2">
          <label for="maidenname">{{ lang.Email }}<span style="color: red">*</span></label>
          <input type="email" class="form-control checking-field" id="email" placeholder={{lang.Email}}
            formControlName="email" />
          @if (f['email'].errors && f['email'].touched) {
          <div class="invalid text-red-500">
            @if (f['email'].errors['required']) {
            <div>{{ lang.Please_enter_email_Id}}</div>
            } @if (f['email'].errors['pattern']) {
            <div>{{ lang.Please_enter_valid_email}}</div>
            }
          </div>
          }
        </div>

        <div class="col-span-2">
          <label for="phone_number">{{ lang.Phone_Number}}<span style="color: red">*</span></label>
          <input type="tel" class="form-control checking-field" id="phone_number" (change)="phoneFormat($event)"
            placeholder="+1 (123) 456-7890" formControlName="phone_number" />
          @if (f['phone_number'].errors && f['phone_number'].touched) {
          <div class="invalid text-red-500">
            @if (f['phone_number'].errors['required']) {
            <div>{{ lang.Please_enter_Phone_Number}}</div>
            } @if (f['phone_number'].errors['minlength']) {
            <div>{{ lang.Phone_number_minimum_of_10_digits}}</div>
            }
            @if (f['phone_number'].errors['maxlength']) {
            <div>{{ lang.Phone_number_minimum_of_10_digits}}</div>
            }
          </div>
          }
        </div>
        <div class="md:col-span-4 w-full lg:w-1/2 underline decoration-indigo-700 font-bold">
        {{ lang.Home_Mailing_Address}}
        </div>

        <div class="col-span-2">
          <label for="street_address_line1">{{ lang.Street_Address
            }}<span style="color: red">*</span></label>
          <input type="text" class="form-control checking-field" id="street_address_line1"
            placeholder={{lang.Street_Address}} formControlName="street_address_line1" />
          @if (f['street_address_line1'].errors &&
          f['street_address_line1'].touched) {
          <div class="invalid text-red-500">
            @if (f['street_address_line1'].errors['required']) {
            <div>{{ lang.Please_enter_Street_Address}}</div>
            }
          </div>
          }
        </div>
        <div class="col-span-2">
          <label for="street_address_line2">{{ lang.Street_Address_Line_2 }}<span></span></label>
          <input type="text" class="form-control" id="street_address_line2" placeholder={{lang.Street_Address_Line_2}}
            formControlName="street_address_line2" />
        </div>
        <div class="md:col-span-4 w-full lg:w-1/2">
          <label for="apt">{{ lang.Unit_Apt_Suite }}</label>
          <input type="text" class="form-control" id="apt" placeholder={{lang.Unit_Apt_Suite}}
            formControlName="apt" />
        </div>

        <div class="col-span-1">
          <label for="city">{{ lang.City }}<span style="color: red">*</span></label>
          <input type="text" class="form-control checking-field" id="city" placeholder={{lang.City}}
            formControlName="city" />
          @if (f['city'].errors && f['city'].touched) {
          <div class="invalid text-red-500">
            @if (f['city'].errors['required']) {
            <div>{{ lang.Please_enter_City }}</div>
            }
          </div>
          }
        </div>
        <div class="col-span-1">
          <label for="Province">{{ lang.Province }}<span style="color: red">*</span></label>

          <select class="form-control" id="Province" placeholder="" formControlName="province"
            (change)="provincelist($event)">
            <option value="" disabled>{{ lang.Select }}</option>

            <option *ngFor="let name of configprovinceres" [value]="name.shortName">
              {{ name.shortName }}
            </option>
          </select>

          @if (f['province'].errors && f['province'].touched) {

          <div class="invalid text-red-500">
            @if (f['province'].errors['required']) {
            <div>{{ lang.Please_select_Province }}</div>
            }
          </div>
          }
        </div>
        <div class="col-span-1">
          <label for="postal_code">{{ lang.Postal_Code }}<span style="color: red">*</span></label>
          <input type="text" class="form-control checking-field" id="postal_code" placeholder={{lang.Postal_Code}}
            formControlName="postal_code" (input)="changeTextToUppercase('postal_code', $event)" />

          @if (f['postal_code'].errors && f['postal_code'].touched) {
          <div class="invalid text-red-500">
            @if (f['postal_code'].errors['required']) {
            <div>{{ lang.Please_enter_postal_code }}</div>
            }
          </div>
          } @if (invalidPostalCodeForProvince==true) {
          <div class="invalid text-red-500">
            {{ lang.Please_enter_valid_postal_code }}
          </div>
          }
        </div>
        <div class="col-span-1">
          <label for="date_of_birth">{{ lang.Date_of_Birth }}<span style="color: red">*</span></label>

          <input type="text" (paste)="(false)" class="form-control" formControlName="date_of_birth" [minDate]="minDate"
            [maxDate]="maxDate" #dp [bsConfig]="{dateInputFormat: 'MM-DD-YYYY',showWeekNumbers: false}" placeholder="MM-DD-YYYY" bsDatepicker
            (blur)="checkDateOfBirth($event)" (input)="checkDateOfBirth($event)" (keypress)="numbersOnly($event)" />

          <!-- <input id="datepicker-autohide"  formControlName="date_of_birth" datepicker datepicker-autohide type="text" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"  placeholder="MM-DD-YYYY"> -->

          @if (f['date_of_birth'].errors && f['date_of_birth'].touched) {
          <div class="invalid text-red-500">
            @if (f['date_of_birth'].errors['required']) {
            <div>{{ lang.Please_Choose_Date_of_Birth }}</div>
            }
          </div>
          }
        </div>

        <div class="col-span-1">
          <label for="gender">{{ lang.Gender }}<span style="color: red">*</span></label>
          <!--  -->
          <select class="form-control" id="gender" placeholder="" formControlName="gender"
            (change)="genderCheck($event)">
            <option value="">{{ lang.Select }}</option>
            <option value="Male">{{ lang.Male }}</option>
            <option value="Female">{{ lang.Female }}</option>
            <option value="Non-Binary">{{ lang.Non_Binary }}</option>
            <option value="Undisclosed">{{ lang.undisclosed }}</option>
          </select>

          @if (f['gender'].errors && f['gender'].touched) {
          <div class="invalid text-red-500">
            @if (f['gender'].errors['required']) {
            <div>{{ lang.Please_select_gender }}</div>
            }
          </div>
          }
        </div>
        <div class="col-span-1">
          <label for="date_of_hiring">{{ lang.Date_of_Hire }}<span style="color: red">*</span></label>
          <!-- [minDate]="minDateHire"
          [maxDate]="maxDateHire" -->


          <input type="text" (paste)="(false)" class="form-control" formControlName="date_of_hiring" #dp
          [bsConfig]="{dateInputFormat: 'MM-DD-YYYY',showWeekNumbers: false}" placeholder="MM-DD-YYYY" bsDatepicker
            (blur)="checkDateOfHire($event)" (input)="checkDateOfHire($event)" (keypress)="numbersOnly($event)" />
          @if (f['date_of_hiring'].errors && f['date_of_hiring'].touched) {
          <div class="invalid text-red-500">
            @if (f['date_of_hiring'].errors['required']) {
            <div>{{ lang.Please_choose_date_of_hire }}</div>
            }
          </div>
          }
        </div>

        <div class="col-span-1">
          <label for="company_name">{{ lang.Company_Name }}<span style="color: red">*</span></label>
          <input type="text" class="form-control checking-field" id="company_name" placeholder={{lang.Company_Name}}
            formControlName="company_name" />
          @if (f['company_name'].errors && f['company_name'].touched) {
          <div class="invalid text-red-500">
            @if (f['company_name'].errors['required']) {
            <div>{{ lang.Please_enter_company_name }}</div>
            }
          </div>
          }
        </div>

        <div class="col-span-1">
          <label for="job_title">{{ lang.Job_Title }}<span style="color: red">*</span></label>
          <input type="text" class="form-control checking-field" id="job_title" placeholder={{lang.Job_Title}}
            formControlName="job_title" />
          @if (f['job_title'].errors && f['job_title'].touched) {
          <div class="invalid text-red-500">
            @if (f['job_title'].errors['required']) {
            <div>{{ lang.Enter_Job_Title }}</div>
            }
          </div>
          }
        </div>

        <div class="col-span-2 md:col-span-4 w-full lg:w-full my-2">
          <hr class="h-px bg-indigo-700 border-y-2 dark:bg-gray-700" />
        </div>
        <div class="col-span-2">
          <label for="working_20hours">
            {{ lang.I_confirm_that_I_am_actively_working_20_or_more_hours_per_week_to_meet_plan_eligibility }}
          </label>
          <div class="flex items-center space-x-2">
            <input type="radio" id="true" formControlName="working_20hours" value="true"
              class="form-radio text-blue-600" (change)="workingHoursCheck($event)" />
            <label for="true" class="text-gray-700">{{lang.Yes}}</label>
          </div>

          <div class="flex items-center space-x-2">
            <input type="radio" id="false" formControlName="working_20hours" value="false"
              class="form-radio text-blue-600" (change)="workingHoursCheck($event)" />
            <label for="false" class="text-gray-700">{{lang.No}}</label>
          </div>
          @if (f['working_20hours'].errors && f['working_20hours'].touched) {
          <div class="invalid text-red-500">
            @if (f['working_20hours'].errors['required']) {
            <div>{{ lang.To_enroll_in_the_plan_you_need_to_be_working_20_or_more_hours_per_week }}</div>
            } @if (f['working_20hours'].errors['invalidhoursselection']) {
            <div>{{ lang.To_enroll_in_the_plan_you_need_to_be_working_20_or_more_hours_per_week }}</div>
            }
          </div>
          } @if(userForm.get('working_20hours')?.value === 'true'){
          <div
            class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1 gap-4">
            <div class="col-span-1 w-1/2">
              <label for="hours_per_week">{{ lang.Hours_Per_week
                }}<span style="color: red">*</span></label>
              <input class="form-control checking field" F type="number" name="hours_per_week" id="hours_per_week"
                placeholder={{lang.Hours_Per_week}} value="40" name="hours_per_week" formControlName="hours_per_week"
                maxlength="2" min="20" max="80" />
              @if (f['hours_per_week'].errors && f['hours_per_week'].touched) {
              <div class="invalid text-red-500">
                @if (f['hours_per_week'].errors['required']) {
                <div>{{ lang.Please_select_working_hours_per_week }}</div>
                }
                @if (f['hours_per_week'].errors['mininvalidhours']) {
                <div>{{ lang.Hours_Per_week_should_be_more_than_20 }}</div>
                }
                @if (f['hours_per_week'].errors['maxinvalidhours']) {
                <div>{{ lang.Hours_Per_week_should_be_less_than_80 }}</div>
                }
              </div>
              }
            </div>
          </div>
          }
        </div>

        <div class="col-span-2" id="box">
          <label class="lineheight">
            {{ lang.I_confirm_that_I_have_provincial_health_coverage_in_my_province_of_residence }}
          </label>
          <div class="flex items-center space-x-2">
            <input type="radio" id="true" formControlName="provincial_health_coverage" value="true"
              class="form-radio text-blue-600" (change)="provincialCoverageHealth($event)" />
            <label for="true" class="text-gray-700">{{lang.Yes}}</label>
          </div>

          <div class="flex items-center space-x-2">
            <input type="radio" id="false" formControlName="provincial_health_coverage" value="false"
              class="form-radio text-blue-600" (change)="provincialCoverageHealth($event)" />
            <label for="false" class="text-gray-700">{{lang.No}}</label>
          </div>
          @if (f['provincial_health_coverage'].errors &&
          f['provincial_health_coverage'].touched) {
          <div class="invalid text-red-500">
            @if (f['provincial_health_coverage'].errors['required']) {
            <div>{{ lang.You_are_not_eligible_to_apply_without_a_health_card_from_your_province }}</div>
            }
          </div>
          }
          @if (userForm.get('provincial_health_coverage')?.value === 'false'){
          <div class="col-span-1">
            <label>{{lang.Date_of_Arrival_in_Canada}}<span style="color: red">*</span></label>


            <input type="text" class="form-control" formControlName="dateOfArrival" #dp
            [bsConfig]="{dateInputFormat: 'MM-DD-YYYY',showWeekNumbers: false}" placeholder="MM-DD-YYYY" bsDatepicker
              (blur)="checkDateOfArrival($event)" (input)="checkDateOfArrival($event)"
              (keypress)="numbersOnly($event)" />

            @if (f['dateOfArrival'].errors && f['dateOfArrival'].touched) {
            <div class="invalid text-red-500">
              @if (f['dateOfArrival'].errors['required']) {
              <div>{{lang.Please_select_date_of_arrival_in_Canada}}</div>
              }
            </div>
            }
          </div>
          <div class="col-span-1">
            <label>{{lang.Is_the_employee_a_landed_immigrant}}<span style="color: red">*</span></label>

            <div class="flex items-center space-x-2">
              <input type="radio" id="true" formControlName="isEmployeeImigrant" value="true"
                class="form-radio text-blue-600" (change)="landedimmigrantStatus($event)" />
              <label for="true" class="text-gray-700">{{lang.Yes}}</label>
            </div>

            <div class="flex items-center space-x-2">
              <input type="radio" id="false" formControlName="isEmployeeImigrant" value="false"
                class="form-radio text-blue-600" (change)="landedimmigrantStatus($event)" />
              <label for="false" class="text-gray-700">{{lang.No}}</label>
            </div>

            @if (f['isEmployeeImigrant'].errors &&
            f['isEmployeeImigrant'].touched) {
            <div class="invalid text-red-500">
              @if (f['isEmployeeImigrant'].errors['required']) {
              <div>{{lang.Please_choose_employee_landed_immigrant}}</div>
              }
            </div>
            }
          </div>

          @if(userForm.get('isEmployeeImigrant')?.value === 'false'){
          <div class="col-span-1">
            <label>{{lang.Will_he_she_be_applying_for_landed_status}}<span style="color: red">*</span></label>

            <div class="flex items-center space-x-2">
              <input type="radio" id="true" formControlName="applylandedstaus" value="true"
                class="form-radio text-blue-600" />
              <label for="true" class="text-gray-700">{{lang.Yes}}</label>
            </div>

            <div class="flex items-center space-x-2">
              <input type="radio" id="false" formControlName="applylandedstaus" value="false"
                class="form-radio text-blue-600" />
              <label for="false" class="text-gray-700">{{lang.No}}</label>
            </div>

            @if (f['applylandedstaus'].errors && f['applylandedstaus'].touched)
            {
            <div class="invalid text-red-500">
              @if (f['applylandedstaus'].errors['required']) {
              <div>{{lang.Please_choose_landed_status}}</div>
              }
            </div>
            }
          </div>
          }

          <div class="col-span-1">
            <label>{{lang.Country_of_Origin}}<span style="color: red">*</span></label>

            <select class="form-control" id="countryOrigin" placeholder="Select" formControlName="countryOrigin">
              <option value="" label="">{{ lang.Select }}</option>
              <!-- <option value="08-11-2022" label="">08-11-2022</option> -->
              <option value="Afghanistan">Afghanistan</option>
              <option value="Åland Islands">Åland Islands</option>
              <option value="Albania">Albania</option>
              <option value="Algeria">Algeria</option>
              <option value="American Samoa">American Samoa</option>
              <option value="Andorra">Andorra</option>
              <option value="Angola">Angola</option>
              <option value="Anguilla">Anguilla</option>
              <option value="Antarctica">Antarctica</option>
              <option value="Antigua and Barbuda">Antigua and Barbuda</option>
              <option value="Argentina">Argentina</option>
              <option value="Armenia">Armenia</option>
              <option value="Aruba">Aruba</option>
              <option value="Australia">Australia</option>
              <option value="Austria">Austria</option>
              <option value="Azerbaijan">Azerbaijan</option>
              <option value="Bahamas">Bahamas</option>
              <option value="Bahrain">Bahrain</option>
              <option value="Bangladesh">Bangladesh</option>
              <option value="Barbados">Barbados</option>
              <option value="Belarus">Belarus</option>
              <option value="Belgium">Belgium</option>
              <option value="Belize">Belize</option>
              <option value="Benin">Benin</option>
              <option value="Bermuda">Bermuda</option>
              <option value="Bhutan">Bhutan</option>
              <option value="Bolivia">Bolivia</option>
              <option value="Bosnia and Herzegovina">
                Bosnia and Herzegovina
              </option>
              <option value="Botswana">Botswana</option>
              <option value="Bouvet Island">Bouvet Island</option>
              <option value="Brazil">Brazil</option>
              <option value="British Indian Ocean Territory">
                British Indian Ocean Territory
              </option>
              <option value="Brunei Darussalam">Brunei Darussalam</option>
              <option value="Bulgaria">Bulgaria</option>
              <option value="Burkina Faso">Burkina Faso</option>
              <option value="Burundi">Burundi</option>
              <option value="Cambodia">Cambodia</option>
              <option value="Cameroon">Cameroon</option>
              <option value="Canada">Canada</option>
              <option value="Cape Verde">Cape Verde</option>
              <option value="Cayman Islands">Cayman Islands</option>
              <option value="Central African Republic">
                Central African Republic
              </option>
              <option value="Chad">Chad</option>
              <option value="Chile">Chile</option>
              <option value="China">China</option>
              <option value="Christmas Island">Christmas Island</option>
              <option value="Cocos (Keeling) Islands">
                Cocos (Keeling) Islands
              </option>
              <option value="Colombia">Colombia</option>
              <option value="Comoros">Comoros</option>
              <option value="Congo">Congo</option>
              <option value="Congo, The Democratic Republic of The">
                Congo, The Democratic Republic of The
              </option>
              <option value="Cook Islands">Cook Islands</option>
              <option value="Costa Rica">Costa Rica</option>
              <option value="Cote D'ivoire">Cote D'ivoire</option>
              <option value="Croatia">Croatia</option>
              <option value="Cuba">Cuba</option>
              <option value="Cyprus">Cyprus</option>
              <option value="Czech Republic">Czech Republic</option>
              <option value="Denmark">Denmark</option>
              <option value="Djibouti">Djibouti</option>
              <option value="Dominica">Dominica</option>
              <option value="Dominican Republic">Dominican Republic</option>
              <option value="Ecuador">Ecuador</option>
              <option value="Egypt">Egypt</option>
              <option value="El Salvador">El Salvador</option>
              <option value="Equatorial Guinea">Equatorial Guinea</option>
              <option value="Eritrea">Eritrea</option>
              <option value="Estonia">Estonia</option>
              <option value="Ethiopia">Ethiopia</option>
              <option value="Falkland Islands (Malvinas)">
                Falkland Islands (Malvinas)
              </option>
              <option value="Faroe Islands">Faroe Islands</option>
              <option value="Fiji">Fiji</option>
              <option value="Finland">Finland</option>
              <option value="France">France</option>
              <option value="French Guiana">French Guiana</option>
              <option value="French Polynesia">French Polynesia</option>
              <option value="French Southern Territories">
                French Southern Territories
              </option>
              <option value="Gabon">Gabon</option>
              <option value="Gambia">Gambia</option>
              <option value="Georgia">Georgia</option>
              <option value="Germany">Germany</option>
              <option value="Ghana">Ghana</option>
              <option value="Gibraltar">Gibraltar</option>
              <option value="Greece">Greece</option>
              <option value="Greenland">Greenland</option>
              <option value="Grenada">Grenada</option>
              <option value="Guadeloupe">Guadeloupe</option>
              <option value="Guam">Guam</option>
              <option value="Guatemala">Guatemala</option>
              <option value="Guernsey">Guernsey</option>
              <option value="Guinea">Guinea</option>
              <option value="Guinea-bissau">Guinea-bissau</option>
              <option value="Guyana">Guyana</option>
              <option value="Haiti">Haiti</option>
              <option value="Heard Island and Mcdonald Islands">
                Heard Island and Mcdonald Islands
              </option>
              <option value="Holy See (Vatican City State)">
                Holy See (Vatican City State)
              </option>
              <option value="Honduras">Honduras</option>
              <option value="Hong Kong">Hong Kong</option>
              <option value="Hungary">Hungary</option>
              <option value="Iceland">Iceland</option>
              <option value="India">India</option>
              <option value="Indonesia">Indonesia</option>
              <option value="Iran, Islamic Republic of">
                Iran, Islamic Republic of
              </option>
              <option value="Iraq">Iraq</option>
              <option value="Ireland">Ireland</option>
              <option value="Isle of Man">Isle of Man</option>
              <option value="Israel">Israel</option>
              <option value="Italy">Italy</option>
              <option value="Jamaica">Jamaica</option>
              <option value="Japan">Japan</option>
              <option value="Jersey">Jersey</option>
              <option value="Jordan">Jordan</option>
              <option value="Kazakhstan">Kazakhstan</option>
              <option value="Kenya">Kenya</option>
              <option value="Kiribati">Kiribati</option>
              <option value="Korea, Democratic People's Republic of">
                Korea, Democratic People's Republic of
              </option>
              <option value="Korea, Republic of">Korea, Republic of</option>
              <option value="Kuwait">Kuwait</option>
              <option value="Kyrgyzstan">Kyrgyzstan</option>
              <option value="Lao People's Democratic Republic">
                Lao People's Democratic Republic
              </option>
              <option value="Latvia">Latvia</option>
              <option value="Lebanon">Lebanon</option>
              <option value="Lesotho">Lesotho</option>
              <option value="Liberia">Liberia</option>
              <option value="Libyan Arab Jamahiriya">
                Libyan Arab Jamahiriya
              </option>
              <option value="Liechtenstein">Liechtenstein</option>
              <option value="Lithuania">Lithuania</option>
              <option value="Luxembourg">Luxembourg</option>
              <option value="Macao">Macao</option>
              <option value="Macedonia, The Former Yugoslav Republic of">
                Macedonia, The Former Yugoslav Republic of
              </option>
              <option value="Madagascar">Madagascar</option>
              <option value="Malawi">Malawi</option>
              <option value="Malaysia">Malaysia</option>
              <option value="Maldives">Maldives</option>
              <option value="Mali">Mali</option>
              <option value="Malta">Malta</option>
              <option value="Marshall Islands">Marshall Islands</option>
              <option value="Martinique">Martinique</option>
              <option value="Mauritania">Mauritania</option>
              <option value="Mauritius">Mauritius</option>
              <option value="Mayotte">Mayotte</option>
              <option value="Mexico">Mexico</option>
              <option value="Micronesia, Federated States of">
                Micronesia, Federated States of
              </option>
              <option value="Moldova, Republic of">Moldova, Republic of</option>
              <option value="Monaco">Monaco</option>
              <option value="Mongolia">Mongolia</option>
              <option value="Montenegro">Montenegro</option>
              <option value="Montserrat">Montserrat</option>
              <option value="Morocco">Morocco</option>
              <option value="Mozambique">Mozambique</option>
              <option value="Myanmar">Myanmar</option>
              <option value="Namibia">Namibia</option>
              <option value="Nauru">Nauru</option>
              <option value="Nepal">Nepal</option>
              <option value="Netherlands">Netherlands</option>
              <option value="Netherlands Antilles">Netherlands Antilles</option>
              <option value="New Caledonia">New Caledonia</option>
              <option value="New Zealand">New Zealand</option>
              <option value="Nicaragua">Nicaragua</option>
              <option value="Niger">Niger</option>
              <option value="Nigeria">Nigeria</option>
              <option value="Niue">Niue</option>
              <option value="Norfolk Island">Norfolk Island</option>
              <option value="Northern Mariana Islands">
                Northern Mariana Islands
              </option>
              <option value="Norway">Norway</option>
              <option value="Oman">Oman</option>
              <option value="Pakistan">Pakistan</option>
              <option value="Palau">Palau</option>
              <option value="Palestinian Territory, Occupied">
                Palestinian Territory, Occupied
              </option>
              <option value="Panama">Panama</option>
              <option value="Papua New Guinea">Papua New Guinea</option>
              <option value="Paraguay">Paraguay</option>
              <option value="Peru">Peru</option>
              <option value="Philippines">Philippines</option>
              <option value="Pitcairn">Pitcairn</option>
              <option value="Poland">Poland</option>
              <option value="Portugal">Portugal</option>
              <option value="Puerto Rico">Puerto Rico</option>
              <option value="Qatar">Qatar</option>
              <option value="Reunion">Reunion</option>
              <option value="Romania">Romania</option>
              <option value="Russian Federation">Russian Federation</option>
              <option value="Rwanda">Rwanda</option>
              <option value="Saint Helena">Saint Helena</option>
              <option value="Saint Kitts and Nevis">
                Saint Kitts and Nevis
              </option>
              <option value="Saint Lucia">Saint Lucia</option>
              <option value="Saint Pierre and Miquelon">
                Saint Pierre and Miquelon
              </option>
              <option value="Saint Vincent and The Grenadines">
                Saint Vincent and The Grenadines
              </option>
              <option value="Samoa">Samoa</option>
              <option value="San Marino">San Marino</option>
              <option value="Sao Tome and Principe">
                Sao Tome and Principe
              </option>
              <option value="Saudi Arabia">Saudi Arabia</option>
              <option value="Senegal">Senegal</option>
              <option value="Serbia">Serbia</option>
              <option value="Seychelles">Seychelles</option>
              <option value="Sierra Leone">Sierra Leone</option>
              <option value="Singapore">Singapore</option>
              <option value="Slovakia">Slovakia</option>
              <option value="Slovenia">Slovenia</option>
              <option value="Solomon Islands">Solomon Islands</option>
              <option value="Somalia">Somalia</option>
              <option value="South Africa">South Africa</option>
              <option value="South Georgia and The South Sandwich Islands">
                South Georgia and The South Sandwich Islands
              </option>
              <option value="Spain">Spain</option>
              <option value="Sri Lanka">Sri Lanka</option>
              <option value="Sudan">Sudan</option>
              <option value="Suriname">Suriname</option>
              <option value="Svalbard and Jan Mayen">
                Svalbard and Jan Mayen
              </option>
              <option value="Swaziland">Swaziland</option>
              <option value="Sweden">Sweden</option>
              <option value="Switzerland">Switzerland</option>
              <option value="Syrian Arab Republic">Syrian Arab Republic</option>
              <option value="Taiwan">Taiwan</option>
              <option value="Tajikistan">Tajikistan</option>
              <option value="Tanzania, United Republic of">
                Tanzania, United Republic of
              </option>
              <option value="Thailand">Thailand</option>
              <option value="Timor-leste">Timor-leste</option>
              <option value="Togo">Togo</option>
              <option value="Tokelau">Tokelau</option>
              <option value="Tonga">Tonga</option>
              <option value="Trinidad and Tobago">Trinidad and Tobago</option>
              <option value="Tunisia">Tunisia</option>
              <option value="Turkey">Turkey</option>
              <option value="Turkmenistan">Turkmenistan</option>
              <option value="Turks and Caicos Islands">
                Turks and Caicos Islands
              </option>
              <option value="Tuvalu">Tuvalu</option>
              <option value="Uganda">Uganda</option>
              <option value="Ukraine">Ukraine</option>
              <option value="United Arab Emirates">United Arab Emirates</option>
              <option value="United Kingdom">United Kingdom</option>
              <option value="United States">United States</option>
              <option value="United States Minor Outlying Islands">
                United States Minor Outlying Islands
              </option>
              <option value="Uruguay">Uruguay</option>
              <option value="Uzbekistan">Uzbekistan</option>
              <option value="Vanuatu">Vanuatu</option>
              <option value="Venezuela">Venezuela</option>
              <option value="Viet Nam">Viet Nam</option>
              <option value="Virgin Islands, British">
                Virgin Islands, British
              </option>
              <option value="Virgin Islands, U.S.">Virgin Islands, U.S.</option>
              <option value="Wallis and Futuna">Wallis and Futuna</option>
              <option value="Western Sahara">Western Sahara</option>
              <option value="Yemen">Yemen</option>
              <option value="Zambia">Zambia</option>
              <option value="Zimbabwe">Zimbabwe</option>
            </select>
            @if (f['countryOrigin'].errors && f['countryOrigin'].touched) {
            <div class="invalid text-red-500">
              @if (f['countryOrigin'].errors['required']) {
              <div>{{lang.Please_select_country_of_origin}}</div>
              }
            </div>
            }
          </div>

          <div class="col-span-1">
            <label>{{lang.Is_coverage_required_for_the_employee}}<span style="color: red">*</span></label>

            <div class="flex items-center space-x-2">
              <input type="radio" id="true" formControlName="coverageRequiredOfEmployee" value="true"
                class="form-radio text-blue-600" />
              <label for="true" class="text-gray-700">{{lang.Yes}}</label>
            </div>

            <div class="flex items-center space-x-2">
              <input type="radio" id="false" formControlName="coverageRequiredOfEmployee" value="false"
                class="form-radio text-blue-600" />
              <label for="false" class="text-gray-700">{{lang.No}}</label>
            </div>
            @if (f['coverageRequiredOfEmployee'].errors &&
            f['coverageRequiredOfEmployee'].touched) {
            <div class="invalid text-red-500">
              @if (f['coverageRequiredOfEmployee'].errors['required']) {
              <div>{{lang.Please_select_coverage_required_for_employee}}</div>
              }
            </div>
            }
          </div>

          }
        </div>
        <div class="col-span-2 md:col-span-4 w-full lg:w-full">
          <hr class="h-px bg-indigo-700 border-y-2 dark:bg-gray-700" />
        </div>
        <div class="col-span-2" id="box">
          <!-- <h4 class="spouseinfo">{{lang.spouseinfo}}</h4> -->
          <div class="col-span-2 w-full underline decoration-indigo-700 font-bold">
            {{ lang.Spouse_Information }}
          </div>
          <label class="lineheight">
            {{ lang.Do_you_have_a_spouse_married_or_living_common_law_for_at_least_one_year }}
          </label>
          <div class="flex items-center space-x-2">
            <input type="radio" id="true" formControlName="having_spouse" value="true" class="form-radio text-blue-600"
              (change)="spouseInfoCheck($event)" />
            <label for="true" class="text-gray-700">{{lang.Yes}}</label>
          </div>

          <div class="flex items-center space-x-2">
            <input type="radio" id="false" formControlName="having_spouse" value="false"
              class="form-radio text-blue-600" (change)="spouseInfoCheck($event)" />
            <label for="false" class="text-gray-700">{{lang.No}}</label>
          </div>
          @if (f['having_spouse'].errors && f['having_spouse'].touched) {
          <div class="invalid text-red-500">
            @if (f['having_spouse'].errors['required']) {
            <div>{{ lang.Please_choose_spouse_status }}</div>
            }
          </div>
          } @if(userForm.get('having_spouse')?.value === 'true'){
          <div
            class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1 gap-4">
            <div class="col-span-1">
              <label for="planEnrollDate">
                {{ lang.First_Name}}<span style="color: red">*</span></label>
              <input class="form-control" id="firstname" placeholder="{{spouseplaceholdername }}"
                (keypress)="alphabatesOnly($event)" formControlName="spousefirstname" />
              @if (f['spousefirstname'].errors && f['spousefirstname'].touched)
              {
              <div class="invalid text-red-500">
                @if (f['spousefirstname'].errors['required']) {
                <div>{{ lang.please_enter_spouse_first_name }}</div>
                } @if (f['spousefirstname'].errors['minlength']) {
                <div>{{ lang.firstName_minm_error }}</div>
                }
              </div>
              }
            </div>
            <div class="col-span-1">
              <label for="planEnrollDate">
                {{ lang.Last_Name }}<span style="color: red">*</span></label>
              <input class="form-control" id="firstname" placeholder={{lang.Last_Name}}
                (keypress)="alphabatesOnly($event)" formControlName="spouselastname" />
              @if (f['spouselastname'].errors && f['spouselastname'].touched) {
              <div class="invalid text-red-500">
                @if (f['spouselastname'].errors['required']) {
                <div>{{ lang.please_enter_spouse_last_name }}</div>
                } @if (f['spouselastname'].errors['minlength']) {
                <div>{{ lang.lastName_minm_error }}</div>
                }
              </div>
              }
            </div>
            <div class="col-span-1">
              <label for="planEnrollDate">
                {{ lang.Gender }}<span style="color: red">*</span></label>
              <select class="form-control" id="spousegender" placeholder="" formControlName="spousegender">
                <option value="" disabled>{{ lang.Select }}</option>
                <option value="Male">{{ lang.Male }}</option>
                <option value="Female">{{ lang.Female }}</option>
                <option value="Non-Binary">{{ lang.Non_Binary }}</option>
                <option value="Undisclosed">{{ lang.undisclosed }}</option>
              </select>
              @if (f['spousegender'].errors && f['spousegender'].touched) {
              <div class="invalid text-red-500">
                @if (f['spousegender'].errors['required']) {
                <div>{{ lang.Please_select_gender }}</div>
                }
              </div>
              }
            </div>
            <div class="col-span-1">
              <label for="planEnrollDate">
                {{ lang.Date_of_Birth }}<span style="color: red">*</span></label>

              <input type="text" (paste)="(false)" class="form-control" formControlName="spousedateofbirth"
                [minDate]="minDate" [maxDate]="maxDate" #dp [bsConfig]="{dateInputFormat: 'MM-DD-YYYY',showWeekNumbers: false}"
                placeholder="MM-DD-YYYY" bsDatepicker (blur)="checkSpouseDateofBirth($event)"
                (input)="checkSpouseDateofBirth($event)" (keypress)="numbersOnly($event)" />

              @if (f['spousedateofbirth'].errors &&
              f['spousedateofbirth'].touched) {
              <div class="invalid text-red-500">
                @if (f['spousedateofbirth'].errors['required']) {
                <div>{{ lang.Please_Choose_Date_of_Birth }}</div>
                }
              </div>
              }
            </div>
            <div class="col-span-1">
              <label class="lineheight">
                {{ lang.Is_your_spouse_covered_by_another_Health_and_or_Dental_insurance_plan_through_their_employer }}
              </label>
              <div class="flex items-center space-x-2">
                <input type="radio" id="true" formControlName="spousehealthcard" value="true"
                  class="form-radio text-blue-600" (click)="spousehealthcard($event)" />
                <label for="true" class="text-gray-700">{{lang.Yes}}</label>
              </div>

              <div class="flex items-center space-x-2">
                <input type="radio" id="false" formControlName="spousehealthcard" value="false"
                  class="form-radio text-blue-600" (click)="spousehealthcard($event)" />
                <label for="false" class="text-gray-700">{{lang.No}}</label>
              </div>
              @if (f['spousehealthcard'].errors &&
              f['spousehealthcard'].touched) {
              <div class="invalid text-red-500">
                @if (f['spousehealthcard'].errors['required']) {
                <div>{{ lang.Please_choose_spouse_insurance_status }}</div>
                }
              </div>
              }
              @if(userForm.get('spousehealthcard')?.value === 'true'){
              <div class="col-span-1">
                <label for="carrierName">{{ lang.Name_Of_The_Carrier
                  }}<span style="color: red">*</span></label>
                <input type="text" class="form-control checking-field" id="spousenameofthecarrier"
                  placeholder={{lang.Name_Of_The_Carrier}} formControlName="Spousenameofthecarrier" />

                @if (f['Spousenameofthecarrier'].errors &&
                f['Spousenameofthecarrier'].touched) {
                <div class="invalid text-red-500">
                  @if (f['Spousenameofthecarrier'].errors['required']) {
                  <div>{{ lang.Please_enter_name_of_the_carrier }}</div>
                  }
                </div>
                }



              </div>
              <div class="col-span-1">
                <label for="coverage">{{lang.Coordination_of_Benefits_COB_Coverage }}<span style="color: red">*</span></label>

                <select class="form-control" id="spousecob" placeholder="Select" formControlName="spousecob">
                  <option value="">{{lang.Select}}</option>
                  <option value="SINGLE">{{lang.Single}}</option>
                  <option value="COUPLE">{{lang.Couple}}</option>
                  <option value="FAMILY">{{lang.Family}}</option>

                  <!-- <option
                      *ngFor="let name of configMatrialStatus"
                      [value]="name"
                    >
                      {{ name }}
                    </option> -->
                </select>
                @if (f['spousecob'].errors &&
                f['spousecob'].touched) {
                <div class="invalid text-red-500">
                  @if (f['spousecob'].errors['required']) {
                  <div>{{ lang.Please_select_name_of_COB_Coverage }}</div>
                  }
                </div>
                }


              </div>




              }
            </div>
          </div>
          }
        </div>
        <div class="col-span-2" id="box">
          <!-- <h4 class="spouseinfo">{{lang.spouseinfo}}</h4> -->
          <div class="col-span-2 w-full underline decoration-indigo-700 font-bold">
            {{ lang.Dependent_Children_Information }}
          </div>
          <label class="lineheight">
            Do you have dependent children?
          </label>
            <!-- {{ lang.Do_you_have_dependent_children }} -->
          <div class="flex items-center space-x-2">
            <input type="radio" id="true" formControlName="having_dependent_children" value="true"
              class="form-radio text-blue-600" (click)="havingchildren($event)" />
            <label for="true" class="text-gray-700">{{lang.Yes}}</label>
          </div>

          <div class="flex items-center space-x-2">
            <input type="radio" id="false" formControlName="having_dependent_children" value="false"
              class="form-radio text-blue-600" (click)="havingchildren($event)" />
            <label for="false" class="text-gray-700">{{lang.No}}</label>
          </div>
          @if (f['having_dependent_children'].errors &&
          f['having_dependent_children'].touched) {
          <div class="invalid text-red-500">
            @if (f['having_dependent_children'].errors['required']) {
            <div>{{ lang.Please_choose_children_status }}</div>
            }
          </div>
          }
          @if(userForm.get('having_dependent_children')?.value === 'true'){
          <div
            class="grid grid-cols-2 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 gap-4">
            <div class="col-span-1 w-1/2 sm:grid-cols-2">
              <label for="no_of_children">{{lang.No_of_Children }}<span style="color: red">*</span></label>

              <input class="form-control checking field" type="number" name="no_of_children" id="no_of_children"
                value="1" name="childrenvalue" formControlName="no_of_children"
                onkeypress="return /^[0-9]+$/.test(event.key)" min="1" />

            </div>
            <div class="col-span-1">

              <button type="button"
                class="w-28 h-12 mt-8 rounded-full border-2 border-indigo-200 bg-indigo-200 p-0.5  transition duration-700 ease-in-out hover:bg-white"
                (click)="childAddFun()">
                {{lang.Add}}
              </button>
            </div>
          </div>


          @if (userForm.get('having_dependent_children')?.value === 'true' && childTableRowsLength==0) {
          <div class="invalid text-red-500">{{lang.Please_add_at_least_one_child}}</div>
          }
          @if(childTableRowsLength>0){
          <div class="col-span-1 table-responsive">
            <table class="table" style="font-size:14px">
              <thead>
                <tr class="trbottomsolid">
                  <th class="bgColor-tr" scope="col">{{lang.S_no}}</th>
                  <th class="bgColor-tr" scope="col">{{lang.First_Name}}</th>
                  <th class="bgColor-tr" scope="col">{{lang.last_name}}</th>
                  <th class="bgColor-tr" scope="col">{{lang.Gender}}</th>
                  <th class="bgColor-tr" scope="col">{{lang.Date_of_Birth}}</th>
                  <th class="bgColor-tr" scope="col">{{lang.Action}}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let childData of childInfoAddDataArray; let i = index">
                  <td class="thBottomNone"> {{i+1}}</td>
                  <td class="thBottomNone text-capitalize"> {{childData.firstName}}</td>
                  <td class="thBottomNone text-capitalize"> {{childData.lastName}}</td>
                  <td class="thBottomNone"> {{childData.gender}}</td>
                  <td class="thBottomNone"> {{childData.date_of_birth | date:'MM-dd-yyyy'}}</td>
                  <td class="thBottomNone text-center inline-flex items-center">
                    <svg class="h-10 w-10 text-green-600" (click)="EditChild(i)" viewBox="0 0 24 24" stroke-width="2"
                      stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" />
                      <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />
                      <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
                      <line x1="16" y1="5" x2="19" y2="8" />
                    </svg>
                    <svg class="h-9 w-9 text-red-600" (click)="DeleteChild(i)" width="24" height="24"
                      viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
                      stroke-linejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" />
                      <rect x="3" y="4" width="18" height="4" rx="2" />
                      <path d="M5 8v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-10" />
                      <line x1="10" y1="12" x2="14" y2="12" />
                    </svg>
                    <!-- <button type="button" class="text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2" (click)="EditChild(i)">Edit</button>
<button type="button" class="text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2" (click)="DeleteChild(i)">Delete</button> -->
                    <!-- <i class="fa fa-edit childEditSymbleColor" style="font-size:18px" (click)="childInforTableEdit(i)"></i>&nbsp;
                          <i class="fa fa-trash-o childDeleteSymbleColor" style="font-size:18px" (click)="deleteChildrenSwal(i)"></i> -->
                    <!-- <i class="bi bi-pencil-square childEditSymbleColor" (click)="childInforTableEdit(i)"></i>
                          <i class="bi bi-trash-fill ms-2 childDeleteSymbleColor" (click)="deleteChildrenSwal(i)"></i> -->


                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          }

          }
        </div>
        <div class="col-span-2 md:col-span-4 w-full lg:w-full">
          <hr class="h-px bg-indigo-700 border-y-2 dark:bg-gray-700" />
        </div>

        @if((userForm.get('provincial_health_coverage')?.value === 'false' && userForm.get('having_spouse')?.value ===
        'true') || (userForm.get('provincial_health_coverage')?.value === 'false' &&
        userForm.get('having_dependent_children')?.value === 'true'))
        {

        <div class="col-span-2">
          <div class="col-span-2 w-full underline decoration-indigo-700 font-bold">
            {{lang.Dependent_s_Information}}
          </div>
          <label class="lineheight">
            {{lang.Is_Coverage_required_for_Dependent_s}}
          </label>
          <div class="flex items-center space-x-2">
            <input type="radio" id="true" value="true" class="form-radio text-blue-600"
              formControlName="coverageRequiredOfDependents" (change)="dependentCoverage($event)" />
            <label for="true" class="text-gray-700">{{lang.Yes}}</label>
          </div>

          <div class="flex items-center space-x-2">
            <input type="radio" id="false" value="false" class="form-radio text-blue-600"
              formControlName="coverageRequiredOfDependents" (change)="dependentCoverage($event)" />
            <label for="false" class="text-gray-700">{{lang.No}}</label>
          </div>
          @if (f['coverageRequiredOfDependents'].errors && f['coverageRequiredOfDependents'].touched) {
          <div class="invalid text-red-500">
            @if (f['coverageRequiredOfDependents'].errors['required']) {
            <div>{{lang.Please_select_coverage_required_for_dependents_s}}</div>
            }
          </div>
          }

          @if(userForm.get('coverageRequiredOfDependents')?.value === 'true'){

          <div class="col-span-2">
            <label>{{lang.Dependent_s_Date_of_Arrival_in_Canada}}<span style="color: red">*</span></label>

            <input type="text" class="form-control" formControlName="dependentdateOfArrival" #dp
            [bsConfig]="{dateInputFormat: 'MM-DD-YYYY',showWeekNumbers: false}" placeholder="MM-DD-YYYY" bsDatepicker
              (blur)="checkDateOfADependentArrival($event)" (input)="checkDateOfADependentArrival($event)"
              (keypress)="numbersOnly($event)" />

            @if (f['dependentdateOfArrival'].errors && f['dependentdateOfArrival'].touched) {
            <div class="invalid text-red-500">
              @if (f['dependentdateOfArrival'].errors['required']) {
              <div>{{lang.Please_Select_date_of_arrival_in_Canada}}</div>
              }
            </div>
            }


            <div>
              <label>{{lang.Country_of_Origin}}<span style="color: red">*</span></label>
            </div>

            <select class="form-control" id="countryOrigin" placeholder="Select"
              formControlName="dependentcountryOrigin">
              <option value="" label="">{{lang.select}}</option>
              <!-- <option value="08-11-2022" label="">08-11-2022</option> -->
              <option value="Afghanistan">Afghanistan</option>
              <option value="Åland Islands">Åland Islands</option>
              <option value="Albania">Albania</option>
              <option value="Algeria">Algeria</option>
              <option value="American Samoa">American Samoa</option>
              <option value="Andorra">Andorra</option>
              <option value="Angola">Angola</option>
              <option value="Anguilla">Anguilla</option>
              <option value="Antarctica">Antarctica</option>
              <option value="Antigua and Barbuda">Antigua and Barbuda</option>
              <option value="Argentina">Argentina</option>
              <option value="Armenia">Armenia</option>
              <option value="Aruba">Aruba</option>
              <option value="Australia">Australia</option>
              <option value="Austria">Austria</option>
              <option value="Azerbaijan">Azerbaijan</option>
              <option value="Bahamas">Bahamas</option>
              <option value="Bahrain">Bahrain</option>
              <option value="Bangladesh">Bangladesh</option>
              <option value="Barbados">Barbados</option>
              <option value="Belarus">Belarus</option>
              <option value="Belgium">Belgium</option>
              <option value="Belize">Belize</option>
              <option value="Benin">Benin</option>
              <option value="Bermuda">Bermuda</option>
              <option value="Bhutan">Bhutan</option>
              <option value="Bolivia">Bolivia</option>
              <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
              <option value="Botswana">Botswana</option>
              <option value="Bouvet Island">Bouvet Island</option>
              <option value="Brazil">Brazil</option>
              <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
              <option value="Brunei Darussalam">Brunei Darussalam</option>
              <option value="Bulgaria">Bulgaria</option>
              <option value="Burkina Faso">Burkina Faso</option>
              <option value="Burundi">Burundi</option>
              <option value="Cambodia">Cambodia</option>
              <option value="Cameroon">Cameroon</option>
              <option value="Canada">Canada</option>
              <option value="Cape Verde">Cape Verde</option>
              <option value="Cayman Islands">Cayman Islands</option>
              <option value="Central African Republic">Central African Republic</option>
              <option value="Chad">Chad</option>
              <option value="Chile">Chile</option>
              <option value="China">China</option>
              <option value="Christmas Island">Christmas Island</option>
              <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
              <option value="Colombia">Colombia</option>
              <option value="Comoros">Comoros</option>
              <option value="Congo">Congo</option>
              <option value="Congo, The Democratic Republic of The">Congo, The Democratic Republic of The</option>
              <option value="Cook Islands">Cook Islands</option>
              <option value="Costa Rica">Costa Rica</option>
              <option value="Cote D'ivoire">Cote D'ivoire</option>
              <option value="Croatia">Croatia</option>
              <option value="Cuba">Cuba</option>
              <option value="Cyprus">Cyprus</option>
              <option value="Czech Republic">Czech Republic</option>
              <option value="Denmark">Denmark</option>
              <option value="Djibouti">Djibouti</option>
              <option value="Dominica">Dominica</option>
              <option value="Dominican Republic">Dominican Republic</option>
              <option value="Ecuador">Ecuador</option>
              <option value="Egypt">Egypt</option>
              <option value="El Salvador">El Salvador</option>
              <option value="Equatorial Guinea">Equatorial Guinea</option>
              <option value="Eritrea">Eritrea</option>
              <option value="Estonia">Estonia</option>
              <option value="Ethiopia">Ethiopia</option>
              <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
              <option value="Faroe Islands">Faroe Islands</option>
              <option value="Fiji">Fiji</option>
              <option value="Finland">Finland</option>
              <option value="France">France</option>
              <option value="French Guiana">French Guiana</option>
              <option value="French Polynesia">French Polynesia</option>
              <option value="French Southern Territories">French Southern Territories</option>
              <option value="Gabon">Gabon</option>
              <option value="Gambia">Gambia</option>
              <option value="Georgia">Georgia</option>
              <option value="Germany">Germany</option>
              <option value="Ghana">Ghana</option>
              <option value="Gibraltar">Gibraltar</option>
              <option value="Greece">Greece</option>
              <option value="Greenland">Greenland</option>
              <option value="Grenada">Grenada</option>
              <option value="Guadeloupe">Guadeloupe</option>
              <option value="Guam">Guam</option>
              <option value="Guatemala">Guatemala</option>
              <option value="Guernsey">Guernsey</option>
              <option value="Guinea">Guinea</option>
              <option value="Guinea-bissau">Guinea-bissau</option>
              <option value="Guyana">Guyana</option>
              <option value="Haiti">Haiti</option>
              <option value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands</option>
              <option value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
              <option value="Honduras">Honduras</option>
              <option value="Hong Kong">Hong Kong</option>
              <option value="Hungary">Hungary</option>
              <option value="Iceland">Iceland</option>
              <option value="India">India</option>
              <option value="Indonesia">Indonesia</option>
              <option value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
              <option value="Iraq">Iraq</option>
              <option value="Ireland">Ireland</option>
              <option value="Isle of Man">Isle of Man</option>
              <option value="Israel">Israel</option>
              <option value="Italy">Italy</option>
              <option value="Jamaica">Jamaica</option>
              <option value="Japan">Japan</option>
              <option value="Jersey">Jersey</option>
              <option value="Jordan">Jordan</option>
              <option value="Kazakhstan">Kazakhstan</option>
              <option value="Kenya">Kenya</option>
              <option value="Kiribati">Kiribati</option>
              <option value="Korea, Democratic People's Republic of">Korea, Democratic People's Republic of</option>
              <option value="Korea, Republic of">Korea, Republic of</option>
              <option value="Kuwait">Kuwait</option>
              <option value="Kyrgyzstan">Kyrgyzstan</option>
              <option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
              <option value="Latvia">Latvia</option>
              <option value="Lebanon">Lebanon</option>
              <option value="Lesotho">Lesotho</option>
              <option value="Liberia">Liberia</option>
              <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
              <option value="Liechtenstein">Liechtenstein</option>
              <option value="Lithuania">Lithuania</option>
              <option value="Luxembourg">Luxembourg</option>
              <option value="Macao">Macao</option>
              <option value="Macedonia, The Former Yugoslav Republic of">Macedonia, The Former Yugoslav Republic of
              </option>
              <option value="Madagascar">Madagascar</option>
              <option value="Malawi">Malawi</option>
              <option value="Malaysia">Malaysia</option>
              <option value="Maldives">Maldives</option>
              <option value="Mali">Mali</option>
              <option value="Malta">Malta</option>
              <option value="Marshall Islands">Marshall Islands</option>
              <option value="Martinique">Martinique</option>
              <option value="Mauritania">Mauritania</option>
              <option value="Mauritius">Mauritius</option>
              <option value="Mayotte">Mayotte</option>
              <option value="Mexico">Mexico</option>
              <option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
              <option value="Moldova, Republic of">Moldova, Republic of</option>
              <option value="Monaco">Monaco</option>
              <option value="Mongolia">Mongolia</option>
              <option value="Montenegro">Montenegro</option>
              <option value="Montserrat">Montserrat</option>
              <option value="Morocco">Morocco</option>
              <option value="Mozambique">Mozambique</option>
              <option value="Myanmar">Myanmar</option>
              <option value="Namibia">Namibia</option>
              <option value="Nauru">Nauru</option>
              <option value="Nepal">Nepal</option>
              <option value="Netherlands">Netherlands</option>
              <option value="Netherlands Antilles">Netherlands Antilles</option>
              <option value="New Caledonia">New Caledonia</option>
              <option value="New Zealand">New Zealand</option>
              <option value="Nicaragua">Nicaragua</option>
              <option value="Niger">Niger</option>
              <option value="Nigeria">Nigeria</option>
              <option value="Niue">Niue</option>
              <option value="Norfolk Island">Norfolk Island</option>
              <option value="Northern Mariana Islands">Northern Mariana Islands</option>
              <option value="Norway">Norway</option>
              <option value="Oman">Oman</option>
              <option value="Pakistan">Pakistan</option>
              <option value="Palau">Palau</option>
              <option value="Palestinian Territory, Occupied">Palestinian Territory, Occupied</option>
              <option value="Panama">Panama</option>
              <option value="Papua New Guinea">Papua New Guinea</option>
              <option value="Paraguay">Paraguay</option>
              <option value="Peru">Peru</option>
              <option value="Philippines">Philippines</option>
              <option value="Pitcairn">Pitcairn</option>
              <option value="Poland">Poland</option>
              <option value="Portugal">Portugal</option>
              <option value="Puerto Rico">Puerto Rico</option>
              <option value="Qatar">Qatar</option>
              <option value="Reunion">Reunion</option>
              <option value="Romania">Romania</option>
              <option value="Russian Federation">Russian Federation</option>
              <option value="Rwanda">Rwanda</option>
              <option value="Saint Helena">Saint Helena</option>
              <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
              <option value="Saint Lucia">Saint Lucia</option>
              <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
              <option value="Saint Vincent and The Grenadines">Saint Vincent and The Grenadines</option>
              <option value="Samoa">Samoa</option>
              <option value="San Marino">San Marino</option>
              <option value="Sao Tome and Principe">Sao Tome and Principe</option>
              <option value="Saudi Arabia">Saudi Arabia</option>
              <option value="Senegal">Senegal</option>
              <option value="Serbia">Serbia</option>
              <option value="Seychelles">Seychelles</option>
              <option value="Sierra Leone">Sierra Leone</option>
              <option value="Singapore">Singapore</option>
              <option value="Slovakia">Slovakia</option>
              <option value="Slovenia">Slovenia</option>
              <option value="Solomon Islands">Solomon Islands</option>
              <option value="Somalia">Somalia</option>
              <option value="South Africa">South Africa</option>
              <option value="South Georgia and The South Sandwich Islands">South Georgia and The South Sandwich Islands
              </option>
              <option value="Spain">Spain</option>
              <option value="Sri Lanka">Sri Lanka</option>
              <option value="Sudan">Sudan</option>
              <option value="Suriname">Suriname</option>
              <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
              <option value="Swaziland">Swaziland</option>
              <option value="Sweden">Sweden</option>
              <option value="Switzerland">Switzerland</option>
              <option value="Syrian Arab Republic">Syrian Arab Republic</option>
              <option value="Taiwan">Taiwan</option>
              <option value="Tajikistan">Tajikistan</option>
              <option value="Tanzania, United Republic of">Tanzania, United Republic of</option>
              <option value="Thailand">Thailand</option>
              <option value="Timor-leste">Timor-leste</option>
              <option value="Togo">Togo</option>
              <option value="Tokelau">Tokelau</option>
              <option value="Tonga">Tonga</option>
              <option value="Trinidad and Tobago">Trinidad and Tobago</option>
              <option value="Tunisia">Tunisia</option>
              <option value="Turkey">Turkey</option>
              <option value="Turkmenistan">Turkmenistan</option>
              <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
              <option value="Tuvalu">Tuvalu</option>
              <option value="Uganda">Uganda</option>
              <option value="Ukraine">Ukraine</option>
              <option value="United Arab Emirates">United Arab Emirates</option>
              <option value="United Kingdom">United Kingdom</option>
              <option value="United States">United States</option>
              <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
              <option value="Uruguay">Uruguay</option>
              <option value="Uzbekistan">Uzbekistan</option>
              <option value="Vanuatu">Vanuatu</option>
              <option value="Venezuela">Venezuela</option>
              <option value="Viet Nam">Viet Nam</option>
              <option value="Virgin Islands, British">Virgin Islands, British</option>
              <option value="Virgin Islands, U.S.">Virgin Islands, U.S.</option>
              <option value="Wallis and Futuna">Wallis and Futuna</option>
              <option value="Western Sahara">Western Sahara</option>
              <option value="Yemen">Yemen</option>
              <option value="Zambia">Zambia</option>
              <option value="Zimbabwe">Zimbabwe</option>


            </select>



            @if (f['dependentcountryOrigin'].errors && f['dependentcountryOrigin'].touched) {
            <div class="invalid text-red-500">
              @if (f['dependentcountryOrigin'].errors['required']) {
              <div>{{lang.Please_select_dependent_s_country_of_origin}}</div>
              }
            </div>
            }
            <div>
              <label>{{lang.Are_the_dependents_landed_immigrants}}<span style="color: red">*</span></label>
            </div>

            <div class="flex items-center space-x-2">
              <input type="radio" id="true" value="true" class="form-radio text-blue-600"
                formControlName="dependentLandedImigrant" (change)="dependentLandedImigrant($event)" />
              <label for="true" class="text-gray-700">{{lang.Yes}}</label>
            </div>

            <div class="flex items-center space-x-2">
              <input type="radio" id="false" value="false" class="form-radio text-blue-600"
                formControlName="dependentLandedImigrant" (change)="dependentLandedImigrant($event)" />
              <label for="false" class="text-gray-700">{{lang.No}}</label>
            </div>



            @if (f['dependentLandedImigrant'].errors && f['dependentLandedImigrant'].touched) {
            <div class="invalid text-red-500">
              @if (f['dependentLandedImigrant'].errors['required']) {
              <div>{{lang.Please_Select_dependent_s_landed_immigrant}}</div>
              }
            </div>
            }

            @if(userForm.get('dependentLandedImigrant')?.value === 'false'){

            <div class="col-span-2">
              <label>{{lang.Will_they_be_applying_for_landed_immigrant_status}}<span style="color: red">*</span></label>
            </div>




            <div class="flex items-center space-x-2">
              <input type="radio" id="true" value="true" class="form-radio text-blue-600"
                formControlName="dependentapplylandedstaus" />
              <label for="true" class="text-gray-700">{{lang.Yes}}</label>
            </div>

            <div class="flex items-center space-x-2">
              <input type="radio" id="false" value="false" class="form-radio text-blue-600"
                formControlName="dependentapplylandedstaus" />
              <label for="false" class="text-gray-700">{{lang.No}}</label>
            </div>
            @if (f['dependentapplylandedstaus'].errors && f['dependentapplylandedstaus'].touched) {
            <div class="invalid text-red-500">
              @if (f['dependentapplylandedstaus'].errors['required']) {
              <div>{{lang.Please_Select_dependents_s_landed_status}}</div>
              }
            </div>
            }

            }



            <div class="col-span-2">
              <label>{{lang.Will_the_dependent_s_be_in_Canada_for_more_than_3_months}} <span
                  style="color: red">*</span></label>
            </div>




            <div class="flex items-center space-x-2">
              <input type="radio" id="true" value="true" class="form-radio text-blue-600"
                formControlName="dependentMorethan3years" />
              <label for="true" class="text-gray-700">{{lang.Yes}}</label>
            </div>

            <div class="flex items-center space-x-2">
              <input type="radio" id="false" value="false" class="form-radio text-blue-600"
                formControlName="dependentMorethan3years" />
              <label for="false" class="text-gray-700">{{lang.No}}</label>
            </div>
            @if (f['dependentMorethan3years'].errors && f['dependentMorethan3years'].touched) {
            <div class="invalid text-red-500">
              @if (f['dependentMorethan3years'].errors['required']) {
              <div>{{lang.Please_Select_status_of_dependent_s_in_Canada}}</div>
              }
            </div>
            }


          </div>
          }




        </div>

        }
        <!-- <div class="col-span-4">
        <hr class="border-t-1 border-black w-full ">
      </div> -->
        @if((userForm.get('provincial_health_coverage')?.value === 'false' && userForm.get('having_spouse')?.value ===
        'true') || (userForm.get('provincial_health_coverage')?.value === 'false' &&
        userForm.get('having_dependent_children')?.value === 'true'))
        {

        <div class="col-span-2 md:col-span-4 w-full lg:w-full">
          <hr class="h-px bg-indigo-700 border-y-2 dark:bg-gray-700" />
        </div>
        }
        <div class="col-span-2">
          <input type="checkbox" formControlName="termsandconditions" />&nbsp;<span
            class="termsandconditions cursor-pointer">{{ lang.I_have_read_and_agree_to_the }}
            <span class="termsandconditionspara" (click)="termandconditions()">
              <span class="termsandconditionslink" style="color: #007bff">{{
                lang.Terms_Conditions
                }}</span>
            </span>
          </span>
          @if (f['termsandconditions'].errors &&
          f['termsandconditions'].touched) {
          <div class="invalid text-red-500">
            @if (f['termsandconditions'].errors['required']) {
            <div>{{ lang.Please_read_and_agree_to_the_terms_conditions }}</div>
            }
          </div>
          }
        </div>

        <div class="col-span-2">
          <input type="checkbox" [value]="true" formControlName="disclouseradvisor" />&nbsp;<span
            class="Advisor cursor-pointer">{{ lang.I_have_read_the }}
            <span (click)="advisorDisclousre()">
              <span class="advisordisclosure" style="color: #007bff">{{
                lang.Advisor_Disclosure
                }}</span>
            </span>
          </span>
          @if (f['disclouseradvisor'].errors && f['disclouseradvisor'].touched)
          {
          <div class="invalid text-red-500">
            @if (f['disclouseradvisor'].errors['required']) {
            <div>{{ lang.Please_read_the_advisor_disclosure }}</div>
            }
          </div>
          }
        </div>
      </div>

      <div class="relative w-full">
        <div class="text-end fixed bottom-10 right-0 w-full mx-12">

          <button type="submit"
            class="w-36 h-14 mb-1 rounded-full border-2 text-center inline-flex items-center border-indigo-200 bg-indigo-200 p-0.5  transition duration-700 ease-in-out hover:bg-white px-5 py-2.5 me-2">{{
            lang.Next }}&nbsp;&nbsp;
            <svg class="h-10 w-10 me-2 text-indigo-900" viewBox="0 0 24 24" fill="none" stroke="currentColor"
              stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
              <line x1="5" y1="12" x2="19" y2="12" />
              <polyline points="12 5 19 12 12 19" />
            </svg>


          </button>
        </div>
      </div>
    </form>

  }
    @if(activeTab === 1){
    <!-- <div class="container mx-auto py-20 linescrollbar" style="height: calc(100vh - 200px);overflow-y: scroll;"> -->
    <div class="firstname">{{lang.Hi}} &nbsp;{{ applicantfirstname }},</div>

    <div class="firstnameinfo">{{ completeapplicantinfo }} </div>
    <div class="plansscreen">
      @for (package of plansData; track package) {
      @if(package.groups &&
      Object.keys(package.groups).length > 0){
      <div class="text-black text-center text-[18px] font-bold mt-4 mb-4">
        {{ package.name }}
      </div>
      }
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 border rounded-md w-full gap-4">
        @for (groups of Object.keys(package.groups); track groups;let i = $index)
        { @for (group of package.groups[groups]; track group) { @for (plan of
        group.plans; track plan;let i=$index) {
        @for (product of plan.productAddonssNames;
        track product;let j=$index) {

        <div class="shadow-lg px-3 rounded-lg w-82" [style.background-color]="
          plan.isBundle == false
            ? plan.productAddonss[product][0].planlevel.backgroundColor
            : group.backgroundColor
        ">
          <div class="text-center text-white text-[18px] font-bold h-16 mt-5">
            {{ product }}
          </div>

          <div class="p-4">
            @if(plan.isBundle==true){
            <div class="h-96 overflow-y-scroll scrollbar-thin text-center bg-white">
              @if(group.planLevelFeatures){
              @for(features of
              group.planLevelFeatures; track features){

              @if(features.productType){

              @if(plan.jsonData.product_type.includes(features.productType)){
              <strong>{{ features.feature.name }}</strong> <br />
              <p [innerHTML]="features.description | safeHtml"></p>
              }

              }
              @else{
              <strong>{{ features.feature.name }}</strong> <br />
              <p [innerHTML]="features.description | safeHtml"></p>
              }


              }

              } @else {
              <p [innerHTML]="plan.productAddonss[product][0].description2 | safeHtml"></p>
              }
            </div>

            }
            @else{
            <div class="h-96 overflow-y-scroll scrollbar-thin text-center bg-white">
              @if(plan.productAddonss[product][0].planlevel.planLevelFeatures){
              @for (features of
              plan.productAddonss[product][0].planlevel.planLevelFeatures; track
              features){
              @if(features.productType){

              @if(plan.jsonData.product_type.includes(features.productType)){
              <strong>{{ features.feature.name }}</strong> <br />
              <p [innerHTML]="features.description | safeHtml"></p>
              }

              }
              @else{
              <strong>{{ features.feature.name }}</strong> <br />
              <p [innerHTML]="features.description | safeHtml"></p>
              }
              <!-- <strong>{{ features.feature.name }}</strong> <br />
            <p [innerHTML]="features.description"></p> -->
              } }


              @else {
              <p [innerHTML]="plan.productAddonss[product][0].description2 | safeHtml"></p>
              }
            </div>

            } @for(coverage of plan.productAddonss[product]; track coverage;let k=$index){
            <div class="h-34 text-white text-[16px]">
              @if(plan.insuranceType!="LIFE"){
              <span class="overflow-ellipsis2" style="float: right; color: white">
                ${{ coverage.price | number : "1.2-2" }}<small>*</small></span>
              }

              <!-- [checked]="checked[plan.productAddonss[product][k].id]"
              [ngModel]="checked[plan.productAddonss[product][k].id]" -->
              @if(plan.insuranceType!="LIFE"){
              <input type="checkbox" #planCheckdetails
                id="plancheck{{plan.isBundle?plan.id:plan.productAddonss[product][k].planlevel.id }}"
                class="plancheckbox{{ package.id }} plancv  newPlan{{plan.id}}  coverage{{coverage.planCoverageUI | uppercase}}"
                attr.data="{{ package.name }}##{{ group.name }}##{{
                plan.planCoverage
              }}##{{
                plan.productAddonss[product][k].calculatedTax.price
              }}##0##0##0##{{ plan.id }}##{{ plan.fusebillId }}##{{
                plan.frqMonthly
              }}##{{ package.optIn }}##{{ group.disallowedPlanLevels }}##{{
                group.parentId
              }}##{{ group.id }}##{{ plan.name }}##{{ plan.planLevel }}##{{
                plan.packageId
              }}##0##{{ plan.planOptions }}##{{
                plan.productAddonss[product][k].id
              }}##{{plan.productAddonss[product][k].plan_level.id}}##{{plan.isBundle}}##{{ group.disallowedPlanLevels }}"
                (change)="
              onCheckboxChange(
                $event,
                package.allowMultiple,
                plan.productAddonss[product][k].plan_level.disallowedPlanLevels,
                plan.planOptions,
                group.parentId,
                package.name,
                group.name,
                plan.planCoverage,
                0,
                0,
                0,
                0,
                $event,
                i,
                j,
                k,
                plan,
                plan.productAddonss[product][k],package,group,coverage.planCoverageUI)">
              {{ coverage.planCoverageUI }}
              }
              @if(plan.insuranceType=="LIFE"){


              <!-- Member -->


              <div class="flex flex-col gap-2 text-xs mx-2 my-3">
                <div class="flex px-1.5 items-center justify-between font-semibold">
                  <div class="font-semibold text-lg md:text-xl">
                    {{lang.Total}}
                  </div>
                  <div class="text-end text-lg md:text-xl">
                    <p class="text-end w-full outline-none">
                      ${{
                      allFormData[plan.planCoverage]?.total || 0
                      | number : "1.2-2"
                      }}
                    </p>
                  </div>
                </div>
                <div class="grid grid-cols-4 gap-2 lg:gap-4 items-center justify-between">

                  <div class="col-span-3 flex gap-2 items-center">
                    <input id="member" type="checkbox" attr.data="{{ package.name }}##{{ group.name }}##{{
                                    plan.planCoverage
                                  }}##{{
                                    plan.productAddonss[product][k].calculatedTax.price
                                  }}##0##0##0##{{ plan.id }}##{{ plan.fusebillId }}##{{
                                    plan.frqMonthly
                                  }}##{{ package.optIn }}##{{ group.disallowedPlanLevels }}##{{
                                    group.parentId
                                  }}##{{ group.id }}##{{ plan.name }}##{{ plan.planLevel }}##{{
                                    plan.packageId
                                  }}##0##{{ plan.planOptions }}##{{
                                    plan.productAddonss[product][k].id
                                  }}##{{plan.productAddonss[product][k].plan_level.id}}##{{plan.isBundle}}"
                      name="{{ plan.planCoverage }}-member" id="planCheckLife{{ plan.planCoverage }}-MEMBER"
                      class="w-6 h-6 my-auto flex-shrink-0 rounded cursor-pointer" (change)="
                                                    onCheckboxChangeP(
                                                      $event,
                                                      plan.planCoverage,
                                                      'member',
                                                      'coverage',
                                                      plan.productAddonss[product][k]
                                                    )" />
                    <label htmlFor="member"
                      class="mr-2 lg:mr-2 capitalize text-white w-full h-full label-text mt-3 text-[12px]">
                      {{lang.MEMBER}}
                    </label>
                  </div>
                  <div class="col-span-1 text-white text-right font-semibold">
                    <p class="bg-transparent text-end w-full h-full my-auto text-lg outline-none">
                      ${{
                      allFormData[ plan.planCoverage]?.member?.premium ||
                      0 | number : "1.2-2"
                      }}
                    </p>
                  </div>
                </div>
                <div class="grid grid-cols-1 gap-2">
                  <div class="col-span-1 text-base md:text-xl mt-3 text-[12px] text-white">
                    <p>
                      In the last 12 months, have you used, in any
                      form whatsoever, tobacco, nicotine or
                      cannabis mixed with tobacco?
                    </p>

                    <div class="grid grid-cols-3 mt-2">
                      <div class="flex justify-center items-center gap-2">
                        <input id="yes" type="radio" class="member-{{ plan.planCoverage }}-yes"
                          name="member-{{ plan.planCoverage }}" attr.data="{{ package.name }}##{{ group.name }}##{{
                                        plan.planCoverage
                                      }}##{{
                                        plan.productAddonss[product][k].calculatedTax.price
                                      }}##0##0##0##{{ plan.id }}##{{ plan.fusebillId }}##{{
                                        plan.frqMonthly
                                      }}##{{ package.optIn }}##{{ group.disallowedPlanLevels }}##{{
                                        group.parentId
                                      }}##{{ group.id }}##{{ plan.name }}##{{ plan.planLevel }}##{{
                                        plan.packageId
                                      }}##0##{{ plan.planOptions }}##{{
                                        plan.productAddonss[product][k].id
                                      }}##{{plan.productAddonss[product][k].plan_level.id}}##{{plan.isBundle}}"
                          [disabled]="
                                                        isDataAvailable(plan.planCoverage, 'member')
                                                      " class="w-6 h-6 my-auto outline-none cursor-pointer" [checked]="
                                                        allFormData[plan.planCoverage] &&
                                                        allFormData[plan.planCoverage]['member'] &&
                                                        allFormData[plan.planCoverage]['member'][
                                                          'isSmoker'
                                                        ] === 'yes'
                                                      " value="yes" (change)="
                                                        handleRadioChange(
                                                          $event,
                                                          plan.planCoverage,
                                                          'member',plan.planOptions,plan,
                                                          plan.productAddonss[product][k],package,group,plan.productAddonss[product][k].member
                                                        )
                                                      " />
                        <label htmlFor="yes" class="w-full mr-2 text-white text-[12px]">
                          {{lang.Yes}}
                        </label>
                      </div>
                      <div class="flex justify-center items-center gap-2">
                        <input id="no" type="radio" value="no" name="member-{{ plan.planCoverage }}"
                          class="member-{{ plan.planCoverage }}-no" attr.data="{{ package.name }}##{{ group.name }}##{{
                plan.planCoverage
              }}##{{
                plan.productAddonss[product][k].calculatedTax.price
              }}##0##0##0##{{ plan.id }}##{{ plan.fusebillId }}##{{
                plan.frqMonthly
              }}##{{ package.optIn }}##{{ group.disallowedPlanLevels }}##{{
                group.parentId
              }}##{{ group.id }}##{{ plan.name }}##{{ plan.planLevel }}##{{
                plan.packageId
              }}##0##{{ plan.planOptions }}##{{
                plan.productAddonss[product][k].id
              }}##{{plan.productAddonss[product][k].plan_level.id}}##{{plan.isBundle}}" [disabled]="
                                                        isDataAvailable(plan.planCoverage, 'member')
                                                      " class="w-6 h-6 my-auto outline-none cursor-pointer" [checked]="
                                                        allFormData[plan.planCoverage] &&
                                                        allFormData[plan.planCoverage]['member'] &&
                                                        allFormData[plan.planCoverage]['member'][
                                                          'isSmoker'
                                                        ] === 'no'
                                                      " (change)="
                                                      handleRadioChange(
                                                          $event,
                                                          plan.planCoverage,
                                                          'member',plan.planOptions,plan,
                                                          plan.productAddonss[product][k],package,group,plan.productAddonss[product][k].member
                                                        )
                                                      " />
                        <label htmlFor="no" class="w-full mr-2 text-white text-[12px]">
                          {{lang.No}}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="">
                    <div className="my-5 ">
                      <p htmlFor="rangeSlider" class="block my-3 text-center font-semibold mb-2 text-[12px]">
                        <!-- ${{ plan.productAddonss[product][k].member.maximumCoverageWithoutEvidence | number }} -->
                        ${{ plan.productAddonss[product][k].member.maximumCoverageWithoutEvidence/1000 | number }}k


                      </p>
                      <div className="relative">
                        <div *ngIf="
                                                        plan.productAddonss[product][k].member.maximumCoverageWithEvidence &&
                                                        plan.productAddonss[product][k].member.minimumCoverage
                                                      " class="range-ticks flex justify-between mt-2">
                          <span *ngFor="
                                                          let tick of generateTicks(
                                                            plan.productAddonss[product][k].member.maximumCoverageWithEvidence,
                                                            plan.productAddonss[product][k].member.minimumCoverage
                                                          );
                                                          let i = index
                                                        " class="tick" [ngStyle]="{
                                                          position: 'absolute',
                                                          left:
                                                            i === 0
                                                              ? '0.4%'
                                                              : i ===
                                                              plan.productAddonss[product][k].member.maximumCoverageWithEvidence /
                                                              plan.productAddonss[product][k].member.minimumCoverage
                                                              ? '99.6%'
                                                              : (((tick /
                                                              plan.productAddonss[product][k].member.maximumCoverageWithEvidence) *
                                                                  100)+0.5) +
                                                                '%',
                                                          top: '9px',
                                                          width: '2px',
                                                          height: '6px',
                                                          backgroundColor: 'white'
                                                        }"></span>
                        </div>
                        <div class="range-slider-container">
                          <div class="range-slider-wrapper">
                            <input type="range" attr.data="{{ package.name }}##{{ group.name }}##{{
                                        plan.planCoverage
                                      }}##{{
                                        plan.productAddonss[product][k].calculatedTax.price
                                      }}##0##0##0##{{ plan.id }}##{{ plan.fusebillId }}##{{
                                        plan.frqMonthly
                                      }}##{{ package.optIn }}##{{ group.disallowedPlanLevels }}##{{
                                        group.parentId
                                      }}##{{ group.id }}##{{ plan.name }}##{{ plan.planLevel }}##{{
                                        plan.packageId
                                      }}##0##{{ plan.planOptions }}##{{
                                        plan.productAddonss[product][k].id
                                      }}##{{plan.productAddonss[product][k].plan_level.id}}##{{plan.isBundle}}"
                              [disabled]="
                                                        isDataAvailable(plan.planCoverage, 'member','isSmoker')
                                                      " id="{{ plan.planCoverage }}-member-rangeSlider" name="{{
                                                        plan.planCoverage
                                                      }}-member-rangeSlider"
                              [(ngModel)]="plan.productAddonss[product][k].member.maximumCoverageWithoutEvidence"
                              [ngModelOptions]="{standalone: true}" (change)="
                                                        handleRangeChange(
                                                          $event,
                                                          plan.planCoverage,
                                                          'member',plan.planOptions,plan,
                                                          plan.productAddonss[product][k],package,group,$event,plan.productAddonss[product][k].member
                                                        )
                                                      " [min]="0"
                              [max]="plan.productAddonss[product][k].member.maximumCoverageWithEvidence"
                              [step]="plan.productAddonss[product][k].member.minimumCoverage" [ngStyle]="{
                                                        background: getSliderBackground(
                                                          plan.productAddonss[product][k].member.maximumCoverageWithoutEvidence,
                                                          plan.productAddonss[product][k].member.maximumCoverageWithEvidence,
                                                          plan.productAddonss[product][k].member.minimumCoverage,plan.productAddonss[product][k].member.defaultRange
                                                        )
                                                      }"
                              class="my-2 border appearance-none w-full h-4 rounded-md focus:outline-none" />
                            <p class="name-label leading-4 text-[10px]" [ngStyle]="{'width.%': greenValue}">{{lang.Guaranteed_Approval}}</p>

                            @if(showyellowbar){
                            <p class="name-label leading-4  text-[10px]" [ngStyle]="{'right.%': 0}" style="top:-10px">
                              {{lang.Pending_Approval}}</p>
                            }
                            <!-- <div class="range-names">
                                          <span class="name-label text-white" [ngStyle]="{'width.%': greenValue,'left.%':4}" style="color:white;font-size:10px">Guaranteed</span>

                                          @if(showyellowbar){
                                            <span class="name-label text-white" [ngStyle]="{'right.%': 0}" style="color:white;font-size:10px;color: black" >Pending Approval</span>
                                            }

                                        </div> -->
                            <div class="range-ticks1">
                              <span *ngFor="let val of rangeValues" class="tick1"></span>
                            </div>

                          </div>
                          <div class="range-labels">
                            <span *ngFor="let val of rangeValues" class="range-label">${{ val/1000 }}k</span>
                          </div>

                        </div>

                        <!-- <div class="range-container">
                                          <input
                                            type="range"

                                            attr.data="{{ package.name }}##{{ group.name }}##{{ plan.planCoverage }}##{{ plan.productAddonss[product][k].calculatedTax.price }}##0##0##0##{{ plan.id }}##{{ plan.fusebillId }}##{{ plan.frqMonthly }}##{{ package.optIn }}##{{ group.disallowedPlanLevels }}##{{ group.parentId }}##{{ group.id }}##{{ plan.name }}##{{ plan.planLevel }}##{{ plan.packageId }}##0##{{ plan.planOptions }}##{{ plan.productAddonss[product][k].id }}##{{ plan.productAddonss[product][k].plan_level.id }}##{{ plan.isBundle }}"
                                                 [min]="minValue"
                                                 [max]="plan.productAddonss[product][k].member.maximumCoverageWithEvidence"
                                                 [step]="plan.productAddonss[product][k].member.minimumCoverage"
                                                 [(ngModel)]="plan.productAddonss[product][k].member.maximumCoverageWithoutEvidence"
                                                 [disabled]="isDataAvailable(plan.planCoverage, 'member','isSmoker')"
                                                 id="{{ plan.planCoverage }}-member-rangeSlider"
                                                 name="{{ plan.planCoverage }}-member-rangeSlider"
                                            [(ngModel)]="coverageValue"
                                            class="range-input"
                                            (change)="handleRangeChange($event, plan.planCoverage, 'member', plan.planOptions, plan, plan.productAddonss[product][k], package, group, $event, plan.productAddonss[product][k].member)"

                                          />
                                          <div class="range-background">
                                            <div
                                              class="range-indicator minimum-coverage"
                                              [style.width.%]="minimumCoverage"
                                            ></div>
                                            <div
                                              class="range-indicator extra-coverage"
                                              [style.width.%]="extraCoverage"
                                            >

                                          </div>
                                          </div>
                                        </div> -->

                        <!-- <div class="range-container">
                                          <input type="range"
                                                 attr.data="{{ package.name }}##{{ group.name }}##{{ plan.planCoverage }}##{{ plan.productAddonss[product][k].calculatedTax.price }}##0##0##0##{{ plan.id }}##{{ plan.fusebillId }}##{{ plan.frqMonthly }}##{{ package.optIn }}##{{ group.disallowedPlanLevels }}##{{ group.parentId }}##{{ group.id }}##{{ plan.name }}##{{ plan.planLevel }}##{{ plan.packageId }}##0##{{ plan.planOptions }}##{{ plan.productAddonss[product][k].id }}##{{ plan.productAddonss[product][k].plan_level.id }}##{{ plan.isBundle }}"
                                                 [min]="minValue"
                                                 [max]="plan.productAddonss[product][k].member.maximumCoverageWithEvidence"
                                                 [step]="plan.productAddonss[product][k].member.minimumCoverage"
                                                 [(ngModel)]="plan.productAddonss[product][k].member.maximumCoverageWithoutEvidence"
                                                 [disabled]="isDataAvailable(plan.planCoverage, 'member','isSmoker')"
                                                 id="{{ plan.planCoverage }}-member-rangeSlider"
                                                 name="{{ plan.planCoverage }}-member-rangeSlider"
                                                 (change)="handleRangeChange($event, plan.planCoverage, 'member', plan.planOptions, plan, plan.productAddonss[product][k], package, group, $event, plan.productAddonss[product][k].member)">
                                          <div class="slider-track">
                                            <div class="slider-range" [ngStyle]="{'width': greenWidth + '%', 'background-color': 'green'}">
                                              <span class="label">Guaranteed</span>
                                            </div>
                                            <div class="slider-range" [ngStyle]="{'width': greenWidth + '%', 'background-color': 'orange'}">
                                              <span class="label">Pending</span>
                                            </div>
                                            <div class="cutoff-indicator" [ngStyle]="{'left': cutoffWidth + '%'}"></div>
                                            <div class="value-indicator" [ngStyle]="{'left': filledWidth + '%'}">{{ plan.productAddonss[product][k].member.maximumCoverageWithoutEvidence }}</div>
                                          </div>
                                          <div class="units">
                                            <span *ngFor="let unit of units">{{ unit }}</span>
                                          </div>
                                        </div> -->


                      </div>

                    </div>


                    <!-- @if(allFormData[plan.planCoverage]?.member && allFormData[plan.planCoverage]?.member.length>0){ -->
                    <!-- <p *ngIf="
                                                    allFormData[plan.planCoverage]?.member?.coverage.maximumCoverageWithoutEvidence >
                                                    plan.productAddonss[product][k].member.defaultRange
                                                  " class="text-right leading-3 text-[12px]">
                                    Acceptance pending approval
                                  </p>
                                  <p *ngIf="
                                                    allFormData[plan.planCoverage]?.member?.coverage.maximumCoverageWithoutEvidence <=
                                                    plan.productAddonss[product][k].member.defaultRange
                                                  " class="text-left leading-3 text-[12px]">
                                    Guaranteed approval
                                  </p> -->
                    <br>
                    <!-- } -->


                  </div>
                </div>
              </div>


              <!-- Spouse -->


              <div class="flex flex-col gap-2 text-xs mx-2 my-3"
                *ngIf="userForm.get('having_spouse')?.value === 'true'">
                <div class="grid grid-cols-4 gap-2 lg:gap-4 items-center justify-between">
                  <div class="col-span-3 flex gap-2 items-center">
                    <input id="spouse" type="checkbox" attr.data="{{ package.name }}##{{ group.name }}##{{
                                    plan.planCoverage
                                  }}##{{
                                    plan.productAddonss[product][k].calculatedTax.price
                                  }}##0##0##0##{{ plan.id }}##{{ plan.fusebillId }}##{{
                                    plan.frqMonthly
                                  }}##{{ package.optIn }}##{{ group.disallowedPlanLevels }}##{{
                                    group.parentId
                                  }}##{{ group.id }}##{{ plan.name }}##{{ plan.planLevel }}##{{
                                    plan.packageId
                                  }}##0##{{ plan.planOptions }}##{{
                                    plan.productAddonss[product][k].id
                                  }}##{{plan.productAddonss[product][k].plan_level.id}}##{{plan.isBundle}}"
                      id="planCheckLife{{ plan.planCoverage }}-SPOUSE" name="{{ plan.planCoverage }}-spouse"
                      class="w-6 h-6 my-auto flex-shrink-0 rounded cursor-pointer" [disabled]="
                                                        isDataAvailableSpouse(plan.planCoverage, 'member')" (change)="
                                                    onCheckboxChangeP(
                                                      $event,
                                                      plan.planCoverage,
                                                      'spouse',
                                                      'coverage',
                                                      plan.productAddonss[product][k]
                                                    )
                                                  " />
                    <label htmlFor="spouse"
                      class="mr-2 lg:mr-2 font-bold capitalize text-white w-full h-full label-text text-[12px] mt-3">
                      {{lang.SPOUSE}}
                    </label>
                  </div>



                  <div class="col-span-1 text-white text-right font-semibold">
                    <p class="bg-transparent text-end w-full h-full my-auto text-lg outline-none">
                      ${{
                      allFormData[plan.planCoverage]?.spouse?.premium ||
                      0 | number : "1.2-2"
                      }}
                    </p>
                  </div>
                </div>
                <div class="grid grid-cols-1 gap-2">
                  <div class="col-span-1 text-base md:text-xl mt-3 text-[12px] text-white">
                    <p>
                      In the last 12 months, have you used, in any
                      form whatsoever, tobacco, nicotine or
                      cannabis mixed with tobacco?
                    </p>

                    <div class="grid grid-cols-3 mt-2">
                      <div class="flex justify-center items-center gap-2">
                        <input id="yes" type="radio" class="spouse-{{ plan.planCoverage }}-yes" attr.data="{{ package.name }}##{{ group.name }}##{{
                                        plan.planCoverage
                                      }}##{{
                                        plan.productAddonss[product][k].calculatedTax.price
                                      }}##0##0##0##{{ plan.id }}##{{ plan.fusebillId }}##{{
                                        plan.frqMonthly
                                      }}##{{ package.optIn }}##{{ group.disallowedPlanLevels }}##{{
                                        group.parentId
                                      }}##{{ group.id }}##{{ plan.name }}##{{ plan.planLevel }}##{{
                                        plan.packageId
                                      }}##0##{{ plan.planOptions }}##{{
                                        plan.productAddonss[product][k].id
                                      }}##{{plan.productAddonss[product][k].plan_level.id}}##{{plan.isBundle}}"
                          [disabled]="
                                                        isDataAvailableSpouseDataa(plan.planCoverage, 'spouse')
                                                      " value="yes" name="spouse-{{ plan.planCoverage}}"
                          class="w-6 h-6 my-auto outline-none cursor-pointer" [checked]="
                                                        allFormData[plan.planCoverage] &&
                                                        allFormData[plan.planCoverage]['spouse'] &&
                                                        allFormData[plan.planCoverage]['spouse'][
                                                          'isSmoker'
                                                        ] === 'yes'
                                                      " (change)="
                                                      handleRadioChange(
                                                          $event,
                                                          plan.planCoverage,
                                                          'spouse',plan.planOptions,plan,
                                                          plan.productAddonss[product][k],package,group,plan.productAddonss[product][k].spouse
                                                        )
                                                      " />
                        <label htmlFor="yes" class="w-full mr-2 text-white text-[12px]">
                          {{lang.Yes}}
                        </label>
                      </div>
                      <div class="flex justify-center items-center gap-2">

                        <input id="no" type="radio" value="no" class="spouse-{{ plan.planCoverage }}-no" attr.data="{{ package.name }}##{{ group.name }}##{{
                                        plan.planCoverage
                                      }}##{{
                                        plan.productAddonss[product][k].calculatedTax.price
                                      }}##0##0##0##{{ plan.id }}##{{ plan.fusebillId }}##{{
                                        plan.frqMonthly
                                      }}##{{ package.optIn }}##{{ group.disallowedPlanLevels }}##{{
                                        group.parentId
                                      }}##{{ group.id }}##{{ plan.name }}##{{ plan.planLevel }}##{{
                                        plan.packageId
                                      }}##0##{{ plan.planOptions }}##{{
                                        plan.productAddonss[product][k].id
                                      }}##{{plan.productAddonss[product][k].plan_level.id}}##{{plan.isBundle}}"
                          [disabled]="
                                                        isDataAvailableSpouseDataa(plan.planCoverage, 'spouse')
                                                      " name="spouse-{{ plan.planCoverage }}"
                          class="w-6 h-6 my-auto outline-none cursor-pointer" [checked]="
                                                        allFormData[plan.planCoverage] &&
                                                        allFormData[plan.planCoverage]['spouse'] &&
                                                        allFormData[plan.planCoverage]['spouse'][
                                                          'isSmoker'
                                                        ] === 'no'
                                                      " (change)="
                                                      handleRadioChange(
                                                          $event,
                                                          plan.planCoverage,
                                                          'spouse',plan.planOptions,plan,
                                                          plan.productAddonss[product][k],package,group,plan.productAddonss[product][k].spouse
                                                        )
                                                      " />
                        <label htmlFor="no" class="w-full mr-2 text-white text-[12px]">
                          {{lang.No}}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="">
                    <div className="my-5">
                      <p class="block my-3 text-center mb-2 font-semibold text-[12px]">
                        ${{ plan.productAddonss[product][k].spouse.maximumCoverageWithoutEvidence/1000 | number }}k
                      </p>
                      <div className="relative">
                        <div *ngIf="
                                                        plan.productAddonss[product][k].spouse.maximumCoverageWithEvidence &&
                                                        plan.productAddonss[product][k].spouse.minimumCoverage
                                                      " class="range-ticks flex justify-between mt-2">
                          <span *ngFor="
                                                          let tick of generateTicks(
                                                            plan.productAddonss[product][k].spouse.maximumCoverageWithEvidence,
                                                            plan.productAddonss[product][k].spouse.minimumCoverage
                                                          );
                                                          let i = index
                                                        " class="tick" [ngStyle]="{
                                                          position: 'absolute',
                                                          left:
                                                            i === 0
                                                              ? '0.4%'
                                                              : i ===
                                                              plan.productAddonss[product][k].spouse.maximumCoverageWithEvidence /
                                                              plan.productAddonss[product][k].spouse.minimumCoverage
                                                              ? '99.5%'
                                                              : (((tick /
                                                              plan.productAddonss[product][k].spouse.maximumCoverageWithEvidence) *
                                                                  100)+0.3)+
                                                                '%',
                                                          top: '9px',
                                                          width: '2px',
                                                          height: '6px',
                                                          backgroundColor: 'white'
                                                        }"></span>
                        </div>
                        <div class="range-slider-container">
                          <div class="range-slider-wrapper">
                            <input type="range" class="spouse-{{ plan.planCoverage }}-no" attr.data="{{ package.name }}##{{ group.name }}##{{
                                        plan.planCoverage
                                      }}##{{
                                        plan.productAddonss[product][k].calculatedTax.price
                                      }}##0##0##0##{{ plan.id }}##{{ plan.fusebillId }}##{{
                                        plan.frqMonthly
                                      }}##{{ package.optIn }}##{{ group.disallowedPlanLevels }}##{{
                                        group.parentId
                                      }}##{{ group.id }}##{{ plan.name }}##{{ plan.planLevel }}##{{
                                        plan.packageId
                                      }}##0##{{ plan.planOptions }}##{{
                                        plan.productAddonss[product][k].id
                                      }}##{{plan.productAddonss[product][k].plan_level.id}}##{{plan.isBundle}}"
                              [disabled]="
                                                        isDataAvailable(plan.planCoverage, 'spouse','isSmoker')
                                                      " id="{{ plan.planCoverage }}-spouse-rangeSlider" name="{{
                                                        plan.planCoverage
                                                      }}-spouse-rangeSlider"
                              [(ngModel)]="plan.productAddonss[product][k].spouse.maximumCoverageWithoutEvidence"
                              (change)="
                                                      handleRangeChange(
                                                          $event,
                                                          plan.planCoverage,
                                                          'spouse',plan.planOptions,plan,
                                                          plan.productAddonss[product][k],package,group,$event,plan.productAddonss[product][k].spouse
                                                        )
                                                      " [min]="0"
                              [max]="plan.productAddonss[product][k].spouse.maximumCoverageWithEvidence"
                              [step]="plan.productAddonss[product][k].spouse.minimumCoverage" [ngStyle]="{
                                                        background: getSliderBackground(
                                                          plan.productAddonss[product][k].spouse.maximumCoverageWithoutEvidence,
                                                          plan.productAddonss[product][k].spouse.maximumCoverageWithEvidence,
                                                          plan.productAddonss[product][k].spouse.minimumCoverage,plan.productAddonss[product][k].member.defaultRange
                                                        )
                                                      }"
                              class="my-2 border appearance-none w-full h-4 rounded-md focus:outline-none"
                              style="background: #78aaef" />
                            <p class="name-label leading-4 text-[10px]" [ngStyle]="{'width.%': greenValue}">{{lang.Guaranteed_Approval}}</p>

                            @if(showyellowbar){
                            <p class="name-label leading-4  text-[10px]" [ngStyle]="{'right.%': 0}" style="top:-10px">
                              {{lang.Pending_Approval}}</p>
                            }
                            <div class="range-ticks1">
                              <span *ngFor="let val of rangeValues" class="tick1"></span>
                            </div>

                          </div>
                          <div class="range-labels">
                            <span *ngFor="let val of rangeValues" class="range-label">${{ val/1000 }}k</span>
                          </div>

                        </div>
                      </div>
                    </div>

                    <!-- <p *ngIf="
                                  allFormData[plan.planCoverage]?.spouse?.coverage.maximumCoverageWithoutEvidence >
                                  plan.productAddonss[product][k].spouse.defaultRange
                                " class="text-right leading-3 text-[12px]">
                  Acceptance pending approval
                </p>
                <p *ngIf="
                                  allFormData[plan.planCoverage]?.spouse?.coverage.maximumCoverageWithoutEvidence <=
                                  plan.productAddonss[product][k].spouse.defaultRange
                                " class="text-left leading-3 text-[12px]">
                  Guaranteed approval
                </p> -->
                  </div>

                </div>
              </div>



              <!-- Dependent -->


              <!-- <div class="flex flex-col gap-2 text-xs mx-2 my-3" *ngIf="userForm.get('having_dependent_children')?.value === 'true'">
                              <div class="grid grid-cols-4 gap-2 lg:gap-4 items-center justify-evenly">
                                <div class="col-span-2 flex gap-2 items-center">
                                  <input id="dependent" type="checkbox" name="{{ plan.planCoverage }}-dependent"
                                    class="w-6 h-6 my-auto flex-shrink-0 rounded cursor-pointer" (change)="
                                                    onCheckboxChangeP(
                                                      $event,
                                                      plan.planCoverage,
                                                      'dependent',
                                                      'coverage',
                                                      plan.productAddonss[product][k].dependent
                                                    )
                                                  " />
                                  <label htmlFor="dependent"
                                    class="mr-2 lg:mr-2 font-bold text-white w-full h-full label-text">
                                    DEPENDENT
                                  </label>
                                </div>

                                <div class="col-span-2 text-white text-right font-semibold">
                                  <p class="bg-transparent text-end w-full h-full my-auto text-lg outline-none">
                                    ${{
                                    allFormData[plan.planCoverage]?.dependent
                                    ?.premium || 0 | number : "1.2-2"
                                    }}
                                  </p>
                                </div>
                                <div *ngFor="
                                                  let c of signUpData.childrenDetails;
                                                  let index = index
                                                "
                                  class="col-span-4 grid grid-cols-4 gap-2 lg:gap-4 items-center justify-evenly">
                                  <p htmlFor="dependent"
                                    class="mr-2 lg:mr-2 col-span-2 capitalize text-lg text-start justify-center font-bold text-white w-full h-full">
                                    {{index+1}})&nbsp;{{ c.childFirstName }}&nbsp;{{ c.childLastName }}
                                  </p>


                                </div>
                              </div>
                              <div class="grid grid-cols-1 gap-2">
                                <div className="mt-5">
                                  <p htmlFor="rangeSlider" style="font-size:12px"
                                    class="block my-3 text-center text-white font-semibold mb-2">
                                    ${{ plan.productAddonss[product][k].dependent.maximumCoverageWithoutEvidence | number }}
                                  </p>
                                  <div className="relative mt-10">
                                    <div *ngIf="
                                                        plan.productAddonss[product][k].dependent.maximumCoverageWithEvidence &&
                                                        plan.productAddonss[product][k].dependent.minimumCoverage
                                                      " class="range-ticks flex justify-between mt-2">
                                      <span *ngFor="
                                                          let tick of generateTicks(
                                                            plan.productAddonss[product][k].dependent.maximumCoverageWithEvidence,
                                                            plan.productAddonss[product][k].dependent.minimumCoverage
                                                          );
                                                          let i = index
                                                        " class="tick" [ngStyle]="{
                                                          position: 'absolute',
                                                          left:
                                                            i === 0
                                                              ? '0.5%'
                                                              : i ===
                                                              plan.productAddonss[product][k].dependent.maximumCoverageWithEvidence /
                                                              plan.productAddonss[product][k].dependent.minimumCoverage
                                                              ? '99.5%'
                                                              : (tick /
                                                              plan.productAddonss[product][k].dependent.maximumCoverageWithEvidence) *
                                                                  100 +
                                                                '%',
                                                          top: '9px',
                                                          width: '2px',
                                                          height: '6px',
                                                          backgroundColor: 'white'
                                                        }"></span>
                                    </div>
                                    <input type="range" [disabled]="
                                                      isDataAvailable(plan.planCoverage, 'dependent')
                                                    " id="{{plan.planCoverage }}-dependent-rangeSlider" name="{{
                                                      plan.planCoverage
                                                    }}-dependent-rangeSlider" [(ngModel)]="plan.productAddonss[product][k].dependent.maximumCoverageWithoutEvidence" [ngModelOptions]="{standalone: true}"
                                      (ngModelChange)="
                                                      handleRangeChangeForDependent(
                                                        $event,
                                                        plan.planCoverage,
                                                        'dependent'
                                                      )
                                                    " [min]="0" [max]="plan.productAddonss[product][k].dependent.maximumCoverageWithEvidence"
                                      [step]="plan.productAddonss[product][k].dependent.minimumCoverage"
                                      class="my-2 border appearance-none w-full h-4 rounded-md focus:outline-none"
                                      style="background: #78aaef" />
                                  </div>
                                </div>
                              </div>
                            </div> -->





              <!-- <div className="" style="padding-top: 10px">
                        *Monthly, applicable taxes extra
                      </div> -->



              }
            </div>
            }

            <!-- <h3 class="font-bold text-xl mb-2">Card Title 1</h3> -->
            <!-- <p class="text-white text-base">
            *{{ lang.monthly_plus_any_applicable_taxes }}
          </p> -->
          </div>
        </div>
        } }
        <!-- <img src="https://via.placeholder.com/400" alt="Card Image" class="w-full h-48 object-cover"> -->
        } }
      </div>
      }
    </div>

    <!-- iafg -->
    <!-- <div>
      <h4 style="color: black">
        Term Life & Critical Illness Insurance
      </h4>
      <div class="wraptext">
        <div
          class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 xl:mx-auto my-4 px-1 py-2 lg:mt-3 lg:px-4 lg:py-4">
          <div *ngFor="let data of staticData; let index = index" class="col-span-1 mx-2 xl:mx-8">
            <div [ngStyle]="{
                              background: cardColors[index]
                            }" class="h-fit p-2.5 md:p-3.5 text-white rounded-xl">
              <div>
                <p class="text-center pb-2 font-semibold">
                  {{ data.mainheading }}
                </p>
                <div
                  class="text-black bg-white max-h-52 overflow-y-auto py-2 md:py-4 md:px-1 lg:py-5 lg:px-10 text-center text-base md:text-lg xl:text-xl flex flex-col gap-8">
                  <div>
                    <p class="font-bold">{{ data.h1 }}</p>
                    <p>{{ data.d1 }}</p>
                  </div>
                  <div>
                    <p class="font-bold">{{ data.h2 }}</p>
                    <p>{{ data.d2 }}</p>
                  </div>
                </div>
              </div>
              <form class="text-base md:text-xl overflow-y-auto" style="max-height: 500px">
                <div class="flex flex-col gap-6 py-3 overflow-y-auto">
                  <div class="flex px-1.5 items-center justify-between font-semibold">
                    <div class="font-semibold text-lg md:text-xl">
                      {{ data.mainheading }} Total
                    </div>
                    <div class="text-end text-lg md:text-xl">
                      <p class="text-end w-full outline-none">
                        ${{
                        allFormData[data.type]?.total || 0
                        | number : "1.2-2"
                        }}
                      </p>
                    </div>
                  </div>


                  Member

                  <div class="rounded-lg p-1" [ngStyle]="{
                                    background: cardBackground[index]
                                  }">
                    <div class="flex flex-col gap-2 text-xs mx-2 my-3">
                      <div class="grid grid-cols-4 gap-2 lg:gap-4 items-center justify-between">
                        <div class="col-span-3 flex gap-2 items-center">
                          <input id="member" type="checkbox" name="{{ data.type }}-member"
                            class="w-6 h-6 my-auto flex-shrink-0 rounded cursor-pointer" [checked]="true"
                            (change)="
                                            onCheckboxChangeP(
                                              $event,
                                              data.type,
                                              'member',
                                              'coverage',
                                              data.member.defaultRange
                                            )
                                          " />
                          <label htmlFor="member"
                            class="mr-2 lg:mr-2 capitalize text-white w-full h-full label-text">
                            MEMBER-{{signUpData.givenname}}&nbsp;{{signUpData.lastname}}
                          </label>
                        </div>
                        <div class="col-span-1 text-white text-right font-semibold">
                          <p class="bg-transparent text-end w-full h-full my-auto text-lg outline-none">
                            ${{
                            allFormData[data.type]?.member?.premium ||
                            0 | number : "1.2-2"
                            }}
                          </p>
                        </div>
                      </div>
                      <div class="grid grid-cols-1 gap-2">
                        <div class="col-span-1 text-base md:text-xl mt-3">
                          <p>
                            In the last 12 months, have you used, in any
                            form whatsoever, tobacco, nicotine or
                            cannabis mixed with tobacco?
                          </p>

                          <div class="grid grid-cols-3 mt-2">
                            <div class="flex justify-center items-center gap-2">
                              <input id="yes" type="radio" name="member-{{ data.type }}" [disabled]="
                                                isDataAvailable(data.type, 'member')
                                              " class="w-6 h-6 my-auto outline-none cursor-pointer" [checked]="
                                                allFormData[data.type] &&
                                                allFormData[data.type]['member'] &&
                                                allFormData[data.type]['member'][
                                                  'isSmoker'
                                                ] === 'yes'
                                              " value="yes" (change)="
                                                handleRadioChange1(
                                                  $event,
                                                  data.type,
                                                  'member'
                                                )
                                              " />
                              <label htmlFor="yes" class="w-full mr-2 text-white">
                                Yes
                              </label>
                            </div>
                            <div class="flex justify-center items-center gap-2">
                              <input id="no" type="radio" value="no" name="member-{{ data.type }}" [disabled]="
                                                isDataAvailable(data.type, 'member')
                                              " class="w-6 h-6 my-auto outline-none cursor-pointer" [checked]="
                                                allFormData[data.type] &&
                                                allFormData[data.type]['member'] &&
                                                allFormData[data.type]['member'][
                                                  'isSmoker'
                                                ] === 'no'
                                              " (change)="
                                                handleRadioChange1(
                                                  $event,
                                                  data.type,
                                                  'member'
                                                )
                                              " />
                              <label htmlFor="no" class="w-full mr-2 text-white">
                                No
                              </label>
                            </div>
                          </div>
                        </div>
                        <div class="">
                          <div className="my-5 ">
                            <p htmlFor="rangeSlider" class="block my-3 text-center font-semibold mb-2" [ngStyle]="{
                                              'color':(allFormData[data.type]?.member?.coverage >
                                            data.member?.maximumGuaranteedCoverage ) ?'#FF4500':'white' ,
                                           'font-size':(allFormData[data.type]?.member?.coverage >
                                            data.member?.maximumGuaranteedCoverage ) ? '14px' :'12px'
                                            }">
                              ${{ data.member.defaultRange | number }}
                            </p>
                            <div className="relative">
                              <div *ngIf="
                                                data.member.maxRange &&
                                                data.member.stepCount
                                              " class="range-ticks flex justify-between mt-2">
                                <span *ngFor="
                                                  let tick of generateTicks(
                                                    data.member.maxRange,
                                                    data.member.stepCount
                                                  );
                                                  let i = index
                                                " class="tick" [ngStyle]="{
                                                  position: 'absolute',
                                                  left:
                                                    i === 0
                                                      ? '0.4%'
                                                      : i ===
                                                        data.member.maxRange /
                                                          data.member.stepCount
                                                      ? '99.6%'
                                                      : (((tick /
                                                          data.member.maxRange) *
                                                          100)+0.5) +
                                                        '%',
                                                  top: '9px',
                                                  width: '2px',
                                                  height: '6px',
                                                  backgroundColor: 'white'
                                                }"></span>
                              </div>
                              <input type="range" [disabled]="
                                                isDataAvailable(data.type, 'member','isSmoker')
                                              " id="{{ data.type }}-member-rangeSlider" name="{{
                                                data.type
                                              }}-member-rangeSlider" [(ngModel)]="data.member.defaultRange"
                                (ngModelChange)="
                                                handleRangeChange(
                                                  $event,
                                                  data.type,
                                                  'member'
                                                )
                                              " [min]="0" [max]="data.member.maxRange"
                                [step]="data.member.stepCount" [ngStyle]="{
                                                background: getSliderBackground(
                                                  data.member.defaultRange,
                                                  data.member.maxRange,
                                                  data.member.stepCount
                                                )
                                              }"
                                class="my-2 border appearance-none w-full h-4 rounded-md focus:outline-none" />


                            </div>
                          </div>

                          <p *ngIf="
                                            allFormData[data.type]?.member?.coverage >
                                            data.member?.maximumGuaranteedCoverage
                                          " class="text-base text-right leading-3">
                            Acceptance pending approval
                          </p>
                          <p *ngIf="
                                            allFormData[data.type]?.member?.coverage <=
                                            data.member?.maximumGuaranteedCoverage
                                          " class="text-base text-right leading-3">
                            Guaranteed approval
                          </p>
                        </div>
                      </div>
                    </div>


                  </div>

                  Spouse

                  <div class="rounded-lg p-1" [ngStyle]="{
                                    background: cardBackground[index]
                                  }" *ngIf="userForm.get('having_spouse')?.value === 'true'">
                    <div class="flex flex-col gap-2 text-xs mx-2 my-3">
                      <div class="grid grid-cols-4 gap-2 lg:gap-4 items-center justify-between">
                        <div class="col-span-3 flex gap-2 items-center">
                          <input id="spouse" type="checkbox" name="{{ data.type }}-spouse"
                            class="w-6 h-6 my-auto flex-shrink-0 rounded cursor-pointer" [checked]="true"
                            (change)="
                                            onCheckboxChangeP(
                                              $event,
                                              data.type,
                                              'spouse',
                                              'coverage',
                                              data.spouse.defaultRange
                                            )
                                          " />
                          <label htmlFor="spouse"
                            class="mr-2 lg:mr-2 font-bold capitalize text-white w-full h-full label-text">
                            SPOUSE-{{signUpData.spousefirstname}}&nbsp;{{signUpData.spouselastname}}
                          </label>
                        </div>



                        <div class="col-span-1 text-white text-right font-semibold">
                          <p class="bg-transparent text-end w-full h-full my-auto text-lg outline-none">
                            ${{
                            allFormData[data.type]?.spouse?.premium ||
                            0 | number : "1.2-2"
                            }}
                          </p>
                        </div>
                      </div>
                      <div class="grid grid-cols-1 gap-2">
                        <div class="col-span-1 text-base md:text-xl mt-3">
                          <p>
                            In the last 12 months, have you used, in any
                            form whatsoever, tobacco, nicotine or
                            cannabis mixed with tobacco?
                          </p>

                          <div class="grid grid-cols-3 mt-2">
                            <div class="flex justify-center items-center gap-2">
                              <input id="yes" type="radio" [disabled]="
                                                isDataAvailable(data.type, 'spouse')
                                              " value="yes" name="spouse-{{ data.type }}"
                                class="w-6 h-6 my-auto outline-none cursor-pointer" [checked]="
                                                allFormData[data.type] &&
                                                allFormData[data.type]['spouse'] &&
                                                allFormData[data.type]['spouse'][
                                                  'isSmoker'
                                                ] === 'yes'
                                              " (change)="
                                                handleRadioChange1(
                                                  $event,
                                                  data.type,
                                                  'spouse'
                                                )
                                              " />
                              <label htmlFor="yes" class="w-full mr-2 text-white">
                                Yes
                              </label>
                            </div>
                            <div class="flex justify-center items-center gap-2">

                              <input id="no" type="radio" value="no" [disabled]="
                                                isDataAvailable(data.type, 'spouse')
                                              " name="spouse-{{ data.type }}"
                                class="w-6 h-6 my-auto outline-none cursor-pointer" [checked]="
                                                allFormData[data.type] &&
                                                allFormData[data.type]['spouse'] &&
                                                allFormData[data.type]['spouse'][
                                                  'isSmoker'
                                                ] === 'no'
                                              " (change)="
                                                handleRadioChange1(
                                                  $event,
                                                  data.type,
                                                  'spouse'
                                                )
                                              " />
                              <label htmlFor="no" class="w-full mr-2 text-white">
                                No
                              </label>
                            </div>
                          </div>
                        </div>
                        <div class="">
                          <div className="my-5">
                            <p class="block my-3 text-center mb-2 font-semibold" [ngStyle]="{
                                              'color':(allFormData[data.type]?.spouse?.coverage >
                                            data.spouse?.maximumGuaranteedCoverage ) ?'#FF4500':'white' ,
                                           'font-size':(allFormData[data.type]?.spouse?.coverage >
                                            data.spouse?.maximumGuaranteedCoverage ) ? '14px' :'12px'

                                            }">
                              ${{ data.spouse.defaultRange | number }}
                            </p>
                            <div className="relative">
                              <div *ngIf="
                                                data.spouse.maxRange &&
                                                data.spouse.stepCount
                                              " class="range-ticks flex justify-between mt-2">
                                <span *ngFor="
                                                  let tick of generateTicks(
                                                    data.spouse.maxRange,
                                                    data.spouse.stepCount
                                                  );
                                                  let i = index
                                                " class="tick" [ngStyle]="{
                                                  position: 'absolute',
                                                  left:
                                                    i === 0
                                                      ? '0.4%'
                                                      : i ===
                                                        data.spouse.maxRange /
                                                          data.spouse.stepCount
                                                      ? '99.5%'
                                                      : (((tick /
                                                          data.spouse.maxRange) *
                                                          100)+0.3)+
                                                        '%',
                                                  top: '9px',
                                                  width: '2px',
                                                  height: '6px',
                                                  backgroundColor: 'white'
                                                }"></span>
                              </div>
                              <input type="range" [disabled]="
                                                isDataAvailable(data.type, 'spouse','isSmoker')
                                              " id="{{ data.type }}-spouse-rangeSlider" name="{{
                                                data.type
                                              }}-spouse-rangeSlider" [(ngModel)]="data.spouse.defaultRange"
                                (ngModelChange)="
                                                handleRangeChange(
                                                  $event,
                                                  data.type,
                                                  'spouse'
                                                )
                                              " [min]="0" [max]="data.spouse.maxRange"
                                [step]="data.spouse.stepCount" [ngStyle]="{
                                                background: getSliderBackground(
                                                  data.spouse.defaultRange,
                                                  data.spouse.maxRange,
                                                  data.spouse.stepCount
                                                )
                                              }"
                                class="my-2 border appearance-none w-full h-4 rounded-md focus:outline-none"
                                style="background: #78aaef" />
                            </div>
                          </div>

                          <p *ngIf="
                                            allFormData[data.type]?.spouse?.coverage >
                                            data.spouse?.maximumGuaranteedCoverage
                                          " class="text-base text-right leading-3">
                            Acceptance pending approval
                          </p>
                          <p *ngIf="
                                            allFormData[data.type]?.spouse?.coverage <=
                                            data.spouse?.maximumGuaranteedCoverage
                                          " class="text-base text-right leading-3">
                            Guaranteed approval
                          </p>
                        </div>

                      </div>
                    </div>


                  </div>

                  Dependent

                  <div class="rounded-lg p-1" [ngStyle]="{
                                    background: cardBackground[index]
                                  }" *ngIf="userForm.get('having_dependent_children')?.value === 'true'">
                    <div class="flex flex-col gap-2 text-xs mx-2 my-3">
                      <div class="grid grid-cols-4 gap-2 lg:gap-4 items-center justify-evenly">
                        <div class="col-span-2 flex gap-2 items-center">
                          <input id="dependent" type="checkbox" name="{{ data.type }}-dependent"
                            class="w-6 h-6 my-auto flex-shrink-0 rounded cursor-pointer" (change)="
                                            onCheckboxChangeP(
                                              $event,
                                              data.type,
                                              'dependent',
                                              'coverage',
                                              data.dependent.defaultRange
                                            )
                                          " />
                          <label htmlFor="dependent"
                            class="mr-2 lg:mr-2 font-bold text-white w-full h-full label-text">
                            DEPENDENT
                          </label>
                        </div>

                        <div class="col-span-2 text-white text-right font-semibold">
                          <p class="bg-transparent text-end w-full h-full my-auto text-lg outline-none">
                            ${{
                            allFormData[data.type]?.dependent
                            ?.premium || 0 | number : "1.2-2"
                            }}
                          </p>
                        </div>
                        <div *ngFor="
                                          let c of signUpData.childrenDetails;
                                          let index = index
                                        "
                          class="col-span-4 grid grid-cols-4 gap-2 lg:gap-4 items-center justify-evenly">
                          <p htmlFor="dependent"
                            class="mr-2 lg:mr-2 col-span-2 capitalize text-lg text-start justify-center font-bold text-white w-full h-full">
                            {{index+1}})&nbsp;{{ c.childFirstName }}&nbsp;{{ c.childLastName }}
                          </p>


                        </div>
                      </div>
                      <div class="grid grid-cols-1 gap-2">
                        <div className="mt-5">
                          <p htmlFor="rangeSlider" style="font-size:12px"
                            class="block my-3 text-center text-white font-semibold mb-2">
                            ${{ data.dependent.defaultRange | number }}
                          </p>
                          <div className="relative mt-10">
                            <div *ngIf="
                                                data.dependent.maxRange &&
                                                data.dependent.stepCount
                                              " class="range-ticks flex justify-between mt-2">
                              <span *ngFor="
                                                  let tick of generateTicks(
                                                    data.dependent.maxRange,
                                                    data.dependent.stepCount
                                                  );
                                                  let i = index
                                                " class="tick" [ngStyle]="{
                                                  position: 'absolute',
                                                  left:
                                                    i === 0
                                                      ? '0.5%'
                                                      : i ===
                                                        data.dependent.maxRange /
                                                          data.dependent.stepCount
                                                      ? '99.5%'
                                                      : (tick /
                                                          data.dependent.maxRange) *
                                                          100 +
                                                        '%',
                                                  top: '9px',
                                                  width: '2px',
                                                  height: '6px',
                                                  backgroundColor: 'white'
                                                }"></span>
                            </div>
                            <input type="range" [disabled]="
                                              isDataAvailable(data.type, 'dependent')
                                            " id="{{ data.type }}-dependent-rangeSlider" name="{{
                                              data.type
                                            }}-dependent-rangeSlider" [(ngModel)]="data.dependent.defaultRange"
                              (ngModelChange)="
                                              handleRangeChangeForDependent(
                                                $event,
                                                data.type,
                                                'dependent'
                                              )
                                            " [min]="0" [max]="data.dependent.maxRange"
                              [step]="data.dependent.stepCount"
                              class="my-2 border appearance-none w-full h-4 rounded-md focus:outline-none"
                              style="background: #78aaef" />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div></div>
                  </div>

                </div>
              </form>
              <div className="" style="padding-top: 10px">
                *Monthly, applicable taxes extra
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- iafg -->
    <!-- <div class="relative w-full">

      <div class="text-start fixed bottom-16 left-0 w-full mx-12">
        <button
          type="button" id="previous" name="previous"
          class="w-36 h-16 mb-1 rounded-full border-2 border-indigo-700 bg-gray-200 p-0.5 font-bold"
          (click)="gotoPersonalInfoPage()"
        >
          {{ lang.previous }}
        </button>
      </div>
      <div class="text-end fixed bottom-16 right-0 w-full mx-12">
        <button
          type="submit" id="next" name="next"
          class="w-36 h-16 mb-1 rounded-full border-2 border-indigo-700 bg-gray-200 p-0.5 font-bold"
          (click)="paymentpage(1)"
        >
          {{ lang.next }}
        </button>
      </div>

    </div> -->



    <div class="relative">

      <!-- Left Button -->
      <button
        class="fixed text-center inline-flex items-center left-0 bottom-10  w-40 h-14 mb-1 rounded-full border-2 border-indigo-700 bg-indigo-200 p-0.5  mx-2 sm:mx-14 hover:bg-white"
        (click)="gotoPersonalInfoPage()">

        <svg class="h-10 w-10 text-sky-900" width="24" height="24" viewBox="0 0 24 24" stroke-width="2"
          stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" />
          <line x1="5" y1="12" x2="19" y2="12" />
          <line x1="5" y1="12" x2="11" y2="18" />
          <line x1="5" y1="12" x2="11" y2="6" />
        </svg>
        &nbsp;{{ lang.Previous }}
      </button>
      <!-- [disabled]="plansnexttab || cartcheckvalue" -->
      <!-- Right Button -->
      <button [disabled]="plansnexttab || cartcheckvalue"
        class="fixed text-center inline-flex items-center right-0 bottom-10  h-14 mb-1 rounded-full border-2 border-indigo-200 bg-indigo-200 p-4   mx-12 hover:bg-white"
        (click)="paymentpage(1)">
        {{ lang.Next }}
        &nbsp;
        <svg class="h-9 w-9 me-2 text-indigo-900" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
          stroke-linecap="round" stroke-linejoin="round">
          <line x1="5" y1="12" x2="19" y2="12" />
          <polyline points="12 5 19 12 12 19" />
        </svg>
      </button>


      <!-- <button
            type="submit"
            class="w-36 h-14 mb-1 rounded-full border-2 text-center inline-flex items-center border-indigo-700 bg-gray-200 p-0.5 font-bold transition duration-700 ease-in-out hover:bg-white px-5 py-2.5 me-2"
          >{{ lang.next }}&nbsp;&nbsp;
          <svg class="h-10 w-10 me-2 text-sky-900"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">
            <line x1="5" y1="12" x2="19" y2="12" />
            <polyline points="12 5 19 12 12 19" />
          </svg>


          </button> -->
    </div>
    <!-- </div> -->

    } @if(activeTab === 2){


    <div class="grid grid-cols-1 sm:grid-cols-2 gap-2 mt-3 mx-2">
      <!-- First column -->

      <div class="col-span-1">
        <form [formGroup]="paymentForm" #paymentform2
          action="https://payments.subscriptionplatform.com/v2/payments/create" method="post">
          <!-- <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-4 gap-4 p-4 "  > -->

          <div class="col-span-1">
            <input class="form-control" autofocus="autofocus" id="CustomerId" formcontrolname="cutomerId"
              name="CustomerID" type="hidden" value="{{ customerid }}" />
          </div>
          <div class="col-span-1">
            <input class="form-control" id="PublicApiKey" formcontrolname="PublicApiKey" name="PublicApiKey"
              type="hidden" value="{{ publicapikey }}" />
          </div>

          <div class="col-span-1">
            <input class="form-control" id="SuccessUri" formcontrolname="SuccessUri" name="SuccessUri" type="hidden"
              value="{{ paymentsuccessuri }}" />
          </div>

          <div class="col-span-1">
            <input class="form-control" id="FailUri" formcontrolname="FailUri" name="FailUri" type="hidden"
              value="{{ paymentUri }}" />
          </div>

          <div class="col-span-1">
            <input class="form-control" id="MakeDefault" placeholder="Name on the card" value="true" type="hidden"
              name="MakeDefault" />
          </div>
          <div class="grid grid-cols-1 sm:grid-cols-3 gap-2">

            <div class="col-span-3">
              <label for="eamil">{{ lang.Email }}<span style="color: red">*</span></label>

              <input class="form-control" id="Email" placeholder={{lang.Email}} type="hidden" name="email"
                [(ngModel)]="paymentemail" [value]="paymentemail" [ngModelOptions]="{standalone: true}"/>
              <input class="form-control" id="Email" placeholder={{lang.Email}} type="email" readonly
                [(ngModel)]="paymentemail" formControlName="email" [value]="paymentemail" />


            </div>

            <div class="col-span-3">
              <div>
                <label for="cardnumnber">{{ lang.Payment_Method }}</label>
              </div>
              <div class="flex items-center space-x-2">
                <input type="radio" id="true" formControlName="paymentRadioCheck" value="PAD"
                  class="form-radio text-blue-600" (click)="paymentmethod($event)" />
                <!-- <label for="true" class="text-gray-700">{{
                  lang.Pre_authorized_Debit
                  }}</label> -->
                  <label for="true" class="text-gray-700">Pre Authorized Debit</label>
              </div>

              <div class="flex items-center space-x-2">
                <input type="radio" id="false" formControlName="paymentRadioCheck" value="CC"
                  class="form-radio text-blue-600" (click)="paymentmethod($event)" />
                <label for="false" class="text-gray-700">{{
                  lang.Credit_Card
                  }}</label>
              </div>
            </div>
          </div>

          @if(paymentForm.get('paymentRadioCheck')?.value === 'CC')
          {
          <div
            class="grid grid-cols-1 sm:grid-cols-3 gap-1 border-[1px] border-indigo-700 backdrop-blur-sm rounded-xl px-2 py-2">

            <!-- <div class="grid grid-cols-1 sm:grid-cols-3 gap-1 bg-gradient-to-bl from-blue-400 via-blue-200 to-blue-400 backdrop-blur-sm rounded-xl px-2 py-2"> -->
            <!-- <span class="bg-gradient-to-r from-[#d5dfe1] to-[#616874] rounded-xl px-4 py-8 w-3/4"> -->
            <div class="col-span-3 sm:col-span-1">
              <label for="firstname"> {{ lang.First_Name
                }}<span style="color: red">*</span></label>
              <input class="form-control" id="firstname" placeholder={{lang.First_Name}} name="FirstName"
                type="hidden"  [value]="paymentFirstName" [(ngModel)]="paymentFirstName" [ngModelOptions]="{standalone: true}"/>
              <input class="form-control" id="firstname" placeholder={{lang.First_Name}} formControlName="FirstName"
                type="text" [(ngModel)]="paymentFirstName" (keypress)="alphabatesOnly($event)" />
              @if (payment['FirstName'].errors &&
              payment['FirstName'].touched) {
              <div class="invalid text-red-500">
                @if (payment['FirstName'].errors['required']) {
                <div>{{ lang.Please_enter_first_name}}</div>
                }
              </div>
              }
            </div>
            <div class="col-span-3 sm:col-span-1">
              <label for="lastname">{{ lang.Last_Name
                }}<span style="color: red">*</span></label>
              <input class="form-control" id="LastName" placeholder={{lang.last_Name}} name="LastName" type="hidden"
              [value]="paymentLastName" [(ngModel)]="paymentLastName" [ngModelOptions]="{standalone: true}"/>
              <input class="form-control" id="LastName" placeholder={{lang.last_Name}} formControlName="LastName"
                type="text" [(ngModel)]="paymentLastName" (keypress)="alphabatesOnly($event)" />
              @if (payment['LastName'].errors &&
              payment['LastName'].touched) {
              <div class="invalid text-red-500">
                @if (payment['LastName'].errors['required']) {
                <div>{{ lang.Please_enter_last_name}}</div>
                }
              </div>
              }
            </div>
            <div class="col-span-3 sm:col-span-1 animate-pulse content-end">
              @if(cardType){
              <img class="w-20 h-14" src={{cardType}} />
              }

            </div>

            <div class="col-span-2">
              <label for="cardnumnber">{{ lang.Card_Number
                }}<span style="color: red">*</span></label>
              <!-- (input)="credit_card_number($event)" -->
              <!-- <img id="card-image" src="" alt="Card Image"> -->
              <input class="form-control" id="CardNumber" placeholder={{lang.Card_Number}} name="CardNumber"
                formControlName="CardNumber" (input)="creditcardlblurmethod($event)" (keypress)="
                        ($event.charCode >= 48 && $event.charCode < 58)
                      " />

              @if (payment['CardNumber'].errors &&
              payment['CardNumber'].touched) {
              <div class="invalid text-red-500">
                @if (payment['CardNumber'].errors['required']) {
                <div>{{ lang.Please_enter_Card_number }}</div>
                } @if
                (payment['CardNumber'].errors['invalidcreditcarddetails'])
                {
                <div>{{ lang.Please_enter_valid_Card_number }}</div>
                }
                @else{
                @if(invalidcreditcard==true){

                <div class="invalid text-red-500">
                  {{ lang.Please_enter_valid_Card_number }}
                </div>

                }
                }
              </div>
              }



            </div>
            <div class="col-span-1"></div>
            <div class="col-span-3 sm:col-span-1">
              <label for="expirymonth">{{ lang.Expiry_Month
                }}<span style="color: red">*</span></label>

              <select class="form-control" id="ExpirationMonth" name="ExpirationMonth"
                (change)="filterExpiryDate($event)" formControlName="ExpirationMonth" placeholder="MM">
                <option value="" disabled selected>
                  {{ lang.Select }}
                </option>
                <option value="01">Jan</option>
                <option value="02">Feb</option>
                <option value="03">Mar</option>
                <option value="04">April</option>
                <option value="05">May</option>
                <option value="06">June</option>
                <option value="07">July</option>
                <option value="08">Aug</option>
                <option value="09">Sept</option>
                <option value="10">Oct</option>
                <option value="11">Nov</option>
                <option value="12">Dec</option>
              </select>
              @if (payment['ExpirationMonth'].errors &&
              payment['ExpirationMonth'].touched) {
              <div class="invalid text-red-500">
                @if (payment['ExpirationMonth'].errors['required']) {
                <div>{{ lang.Please_select_expiry_month }}</div>
                } @if (payment['ExpirationMonth'].errors['min']) {
                <div>minim</div>
                } @if (payment['ExpirationMonth'].errors['max']) {
                <div>maxmim</div>
                }
              </div>
              }
              <!-- @if (payment['ExpirationMonth'].errors['invalidcreditcarddetails']) {
                                        <div>{{ lang.please_enter_valid_credit_card }}</div>
                                        } -->
              <!-- <span
                                      class="help-block text-danger"
                                      *ngIf="
                                        paymentForm.get('ExpirationMonth').touched &&
                                        paymentForm
                                          .get('ExpirationMonth')
                                          .hasError('required')
                                      "
                                      >{{lang.please_select_expiry_month}}</span
                                    >

                                    <span
                                      class="help-block text-danger"
                                      *ngIf="showexpirymontherror"
                                      >{{lang.please_enter_valid_expiry_month}}</span
                                    > -->
            </div>
            <div class="col-span-3 sm:col-span-1">
              <label for="expiryyear">{{ lang.Expiry_Year
                }}<span style="color: red">*</span></label>

              <select class="form-control" id="ExpirationYear" placeholder="YY" name="ExpirationYear"
                (change)="filterExpiryDate($event)" formControlName="ExporationYear">
                <option value="" disabled selected>
                  {{ lang.Select }}
                </option>
                <option *ngFor="let item of [].constructor(40); let i = index" value="{{ i + 24 }}">
                  {{ i + 2024 }}
                </option>
              </select>
              @if (payment['ExporationYear'].errors &&
              payment['ExporationYear'].touched) {
              <div class="invalid text-red-500">
                @if (payment['ExporationYear'].errors['required']) {
                <div>{{ lang.Please_select_expiry_year }}</div>
                } @if (payment['ExporationYear'].errors['expiryDate']) {
                <div>cant minimminim</div>
                }
              </div>
              }
              <!-- <span
                                      class="help-block text-danger"
                                      *ngIf="
                                        paymentForm.get('ExporationYear').touched &&
                                        paymentForm.get('ExporationYear').hasError('required')
                                      "
                                      >{{lang.please_select_expiry_year}}</span
                                    >

                                    <span
                                      class="help-block text-danger"
                                      *ngIf="showexpiryyearerror"
                                      >{{lang.please_enter_valid_expiry_year}}</span
                                    > -->
            </div>

            <div class="col-span-3 sm:col-span-1">
              <label for="cvv">{{ lang.CVV }}<span style="color: red">*</span></label>

              <input class="form-control" id="Cvv" placeholder={{lang.CVV}} name="Cvv" formControlName="cvv" maxlength="4"
                (keypress)="
                        ($event.charCode >= 48 && $event.charCode < 58)
                      " />
              @if (payment['cvv'].errors && payment['cvv'].touched) {
              <div class="invalid text-red-500">
                @if (payment['cvv'].errors['required']) {
                <div>{{ lang.Please_enter_cvv }}</div>
                } @if (payment['cvv'].errors['minlength']) {
                <div>{{ lang.CVV_must_be_3_or_4_digits }}</div>
                }
              </div>
              }
            </div>


            @if(invalidCardDetails){
            <div class="col-span-3">
              <span class="invalid text-red-500">{{lang.Please_enter_the_correct_Month_and_Year}}</span>
            </div>
            }
          </div>

          <!-- </span> -->
          <div class="grid grid-cols-1 sm:grid-cols-3 gap-2">
            <div class="col-span-3">
              <input type="checkbox" (click)="homeaddresscheckCC($event)" />&nbsp;<span class="paymentaddress">{{
                lang.Home_Address_same_as_billing_Address }}
              </span>
            </div>

            <div class="col-span-3 sm:col-span-1">
              <label for="maidenname">{{ lang.Street_Address
                }}<span style="color: red">*</span></label>
              <input type="text" class="form-control checking-field" id="Address1"
                placeholder={{lang.Street_Address}} formControlName="streetaddress" autocomplete="off" />
              <input type="hidden" name="Address1" />
              @if (payment['streetaddress'].errors &&
              payment['streetaddress'].touched) {
              <div class="invalid text-red-500">
                @if (payment['streetaddress'].errors['required']) {
                <div>{{ lang.Please_enter_Street_Address}}</div>
                }
              </div>
              }
            </div>
            <div class="col-span-3 sm:col-span-1">
              <label for="maidenname">{{ lang.Street_Address_Line_2 }}<span></span></label>
              <input type="text" class="form-control" id="Address2" formControlName="streetaddressline2"
                autocomplete="off" placeholder={{lang.Street_Address_Line_2}} />
              <input type="hidden" name="Address2" />
            </div>
            <div class="col-span-3 sm:col-span-1"></div>

            <div class="col-span-3 sm:col-span-1">
              <label for="maidenname">{{ lang.City }}<span style="color: red">*</span></label>
              <input type="text" class="form-control checking-field" id="City" placeholder={{lang.City}}
                formControlName="city" />
              <input type="hidden" name="City" />
              @if (payment['city'].errors && payment['city'].touched) {
              <div class="invalid text-red-500">
                @if (payment['city'].errors['required']) {
                <div>{{ lang.Please_enter_City }}</div>
                }
              </div>
              }
            </div>
            <div class="col-span-3 sm:col-span-1">
              <label for="Province">{{ lang.Province }}<span style="color: red">*</span></label>

              <select class="form-control" id="StateId" placeholder="select" formControlName="province"
                (change)="provincelistCreditPayment($event)" autocomplete="off">
                <option value="">{{ lang.Select }}</option>
                <option *ngFor="let name of configprovinceres" [value]="name.shortName">
                  {{ name.shortName }}
                </option>
              </select>

              <input type="hidden" name="StateId" />

              @if (payment['province'].errors && payment['province'].touched) {
              <div class="invalid text-red-500">
                @if (payment['province'].errors['required']) {
                <div>{{ lang.Please_select_Province }}</div>
                }
              </div>
              }
            </div>
            <div class="col-span-3 sm:col-span-1">
              <label for="maidenname">{{ lang.Postal_Code }}<span style="color: red">*</span></label>
              <input type="text" class="form-control checking-field" id="PostalZip" formControlName="postalcode"
                autocomplete="off" placeholder={{lang.Postal_Code}}
                (input)="changeTextToUppercaseCreditPayment('postalcode',$event)" />
              <input type="hidden" name="PostalZip" />

              @if (payment['postalcode'].errors && payment['postalcode'].touched) {
              <div class="invalid text-red-500">
                @if (payment['postalcode'].errors['required']) {
                <div>{{ lang.Please_enter_postal_code }}</div>
                }
              </div>
              } @if (creditCradInvalidProvince==true) {
              <div class="invalid text-red-500">
                {{ lang.Please_enter_valid_Postal_Code }}
              </div>
              }
            </div>
            <div class="col-span-3">
              <label for="signature">{{ lang.Signature }}<span style="color: red">*</span></label>

              <div class="signature-container sm:w-1/2" style="border:1px solid #ccc;border-radius: 4px;">

                <!-- <button class="btn btn-primary btn-inline" (click)="clearPadcc()" [disabled]="signaturecheckvalue1cc">Clear</button> -->

                <!-- <canvas
                #canvascc
                (touchstart)="startDrawingcc($event)"
                (touchmove)="movedcc($event)"
                (click)="signaturestartcc($event)" required
              ></canvas> -->

                <signature-pad [options]="signaturePadOptions" (touchmove)="onMouseMove($event)"
                  (mousemove)="onMouseMove($event)" (onEndEvent)="drawComplete()"
                  (onBeginEvent)="drawStart()"></signature-pad>
                @if(signaturecheckvalue1cc){
                <div style="
              left: 10px;
              color: red;
              text-decoration: underline;cursor:pointer">
                  <p (click)="clearSignature()">x {{lang.Clear}}</p>
                </div>
                }

                <!-- <canvas
                #sigPad
                (mousedown)="onMouseDown($event)" (touchstart)="onMouseDown($event)" (touchmove)="onMouseMove($event)"
    (mousemove)="onMouseMove($event)" required  style="border: 1px solid #ccc;border-radius: 4px;"
              ></canvas> -->

                <!-- @if(signaturecheckvalue1cc){
              <div style="
              left: 10px;
              color: red;
              text-decoration: underline;cursor:pointer">
                  <p (click)="clearCreditCardSignature()">x {{lang.clear}}</p>
              </div>
            } -->


              </div>
              @if(signaturemessagecc){
              <span class="invalid text-red-500">{{lang.Please_Sign_the_Signature}}</span>
              }
            </div>
            <div class="col-span-3">
              <re-captcha (resolved)="resolved($event)" formControlName="recaptchaReactive"
                siteKey="{{ fusbillinfocaptchavalue }}">
              </re-captcha>

              <input id="recaptcha_token" type="hidden" name="recaptcha_token" [value]="recaptchaResponse" />
              @if (payment['recaptchaReactive'].errors &&
              payment['recaptchaReactive'].touched) {
              <div class="invalid text-red-500">
                @if (payment['recaptchaReactive'].errors['required']) {
                <div>{{ lang.Please_Check_re_Captcha_Validation }}</div>
                }
              </div>
              }
            </div>
          </div>

          }


          @if(paymentForm.get('paymentRadioCheck')?.value === 'PAD')
          {
          <form [formGroup]="bankpayForm" #bankForm="ngForm">
            <!-- <div class="grid grid-cols-1 sm:grid-cols-6 gap-2"> -->
            <!-- style="max-width:90px;margin-left:5px" -->

            <div class="grid grid-cols-1 sm:grid-cols-3 gap-2">
              <div class="col-span-3 sm:col-span-1">
                <label for="cardnumnber">Bank Code<span style="color: red">*</span></label>
                <input class="form-control checking-field" id="banknumber" placeholder="{{ lang.bank_number }}"
                  name="banknumber" autocomplete="off" formControlName="banknumber" maxlength="3" (input)="touchedmsg()"
                  (keypress)="($event.charCode >= 48 && $event.charCode < 58)" />

                @if (bankpay['banknumber'].errors &&
                bankpay['banknumber'].touched) {
                <div class="invalid text-red-500">
                  @if (bankpay['banknumber'].errors['required']) {
                  <div>{{ lang.Please_enter_bank_code }}</div>
                  }
                  @if (bankpay['banknumber'].errors['checkbanknumbervalidator']) {
                  <div>{{ lang.Bank_code_must_be_3_digits }}</div>
                  }
                </div>
                }

              </div>
              <!-- style="max-width:120px;" -->
              <div class="col-span-3 sm:col-span-1">
                <label for="transitnumber">{{ lang.Transit
                  }}<span style="color: red">*</span></label>
                <!-- (input)="credit_card_number($event)" -->
                <input class="form-control checking-field" id="branch" placeholder={{lang.Transit}}
                  name="branch" formControlName="transitnumber" autocomplete="off" maxlength="5" (input)="touchedmsg()"
                  (keypress)="($event.charCode >= 48 && $event.charCode < 58)" />
                @if (bankpay['transitnumber'].errors &&
                bankpay['transitnumber'].touched) {
                <div class="invalid text-red-500">
                  @if (bankpay['transitnumber'].errors['required']) {
                  <div>{{ lang.Please_enter_bank_transit }}</div>
                  }
                  @if (bankpay['transitnumber'].errors['checktransitnumbervalidator']) {
                  <div>{{ lang.Bank_transit_must_be_5_digits }}</div>
                  }
                </div>
                }

              </div>
              <div class="col-span-3 sm:col-span-1">
                <label for="accountNumber">{{ lang.Account_Number
                  }}<span style="color: red">*</span></label>
                <!-- (input)="credit_card_number($event)" -->
                <input class="form-control checking-field" id="accountnumber" placeholder={{lang.Account_Number}}
                  autocomplete="off" name="accountnumber" formControlName="accountnumber" maxlength="12"
                  (input)="touchedmsg()" (keypress)="($event.charCode >= 48 && $event.charCode < 58)" />
                @if (bankpay['accountnumber'].errors &&
                bankpay['accountnumber'].touched) {
                <div class="invalid text-red-500">
                  @if (bankpay['accountnumber'].errors['required']) {
                  <div>{{ lang.Please_enter_Account_number }}</div>
                  }
                  @if (bankpay['accountnumber'].errors['checkaccountnumbervalidator']) {
                  <div>{{ lang.Account_Number_must_be_5_12_digits }}</div>
                  }
                </div>
                }


              </div>
              @if(bankerrormsg){
              <div class="col-span-3 invalid text-red-500 ">{{bankerrormsg}}</div>
              }

              <div class="col-span-3">
                <label for="bankdetails">{{ lang.Bank_Details }}</label>
                <!-- (input)="credit_card_number($event)" -->
                <textarea class="form-control" id="bankname" placeholder={{lang.Bank_Details}} name="bankname"
                  formControlName="bankname" style="color: rgba(85, 85, 85, 0.9)" [ngModel]="banknameres"
                  rows="6"></textarea>
                @if (bankpay['bankname'].errors &&
                bankpay['bankname'].touched) {
                <div class="invalid text-red-500">
                  @if (bankpay['bankname'].errors['required']) {
                  <div>{{ lang.Please_verify_the_bank_details }}</div>
                  }

                </div>
                }


              </div>


              <div class="col-span-3">
                @if(verifybankdetails && verifybutton){
                <button type="submit"
                  class="w-36 h-14 mb-1 rounded-full border-[1px] border-indigo-200 bg-indigo-200 p-0.5 transition duration-700 ease-in-out hover:bg-white hover:border-green-500"
                  (click)="verifybank()">
                  {{lang.Verify}}
                </button>&nbsp;

                }

                @if(verifybankdetails){

                <button type="submit"
                  class="w-36 h-14 mb-1 rounded-full border-[1px] border-indigo-200 bg-gray-200 p-0.5  transition duration-700 ease-in-out hover:bg-white"
                  (click)="checkbankdetails($event)">
                  {{lang.Reset}}
                </button>
                }


                <!-- <form class="signbtn">
                      <button class="btn btn-primary btn-inline"  *ngIf="(verifybankdetails && verifybutton)"  (click)="verifybank()" style="margin-left:5px">{{lang.verify}}</button>
                      <button class="btn btn-primary btn-inline" *ngIf="verifybankdetails"  id="no" value="no" (click)="checkbankdetails($event)" >{{lang.reset}}</button>
                    </form>
                    <div class="clearfix"></div> -->


                <!-- <span *ngIf="(bankdetailsverify && verifybankdetails)"  class="help-block text-danger" style="margin-top:20px;margin-left:10px">{{lang.please_verify_the_bank_details}}</span> -->

              </div>


              <div class="col-span-2">
                <label for="uploadvoidCheque">{{ lang.Upload_Void_Cheque
                  }}<span style="color: red">*</span></label>
                <!-- (input)="credit_card_number($event)" -->
                <input type="file" formControlName="voidcheck" (change)="voidcheckupload($event)" />

                @if (bankpay['voidcheck'].errors &&
                bankpay['voidcheck'].touched) {
                <div class="invalid text-red-500">
                  @if (bankpay['voidcheck'].errors['required']) {
                  <div>{{ lang.Please_Upload_Void_Cheque_file }}</div>
                  }

                </div>
                }

              </div>
              <div class="col-span-1">
                @if(imagedisplay){
                <!-- <button type="button"
                      class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 border border-t-indigo-700"
                          (click)="showvoidcheckpreview()"
                          >
                          {{lang.preview}}
                        </button> -->

                <div class="">
                  <button type="button"
                    class="w-36 h-14 mb-2 mt-2 rounded-full border-2 border-indigo-200 bg-gray-200 p-0.5  hover:border-indigo-700 hover:bg-white"
                    (click)="showvoidcheckpreview()">
                    {{lang.Preview}}
                  </button>
                  <!-- <button type="button" class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto">Deactivate</button>
                          <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Cancel</button> -->
                </div>
                }
              </div>

              <div class="col-span-3">
                <input type="checkbox" (click)="homeaddresscheck($event)" />&nbsp;<span class="paymentaddress">{{
                  lang.Home_Address_same_as_billing_Address }}
                </span>
              </div>


              <div class="col-span-3 sm:col-span-1">
                <label for="maidenname">{{ lang.Street_Address
                  }}<span style="color: red">*</span></label>
                <input type="text" class="form-control checking-field" id="Address1"
                  placeholder={{lang.Street_Address}} formControlName="bankstreetaddress" autocomplete="off" />
                @if (bankpay['bankstreetaddress'].errors &&
                bankpay['bankstreetaddress'].touched) {
                <div class="invalid text-red-500">
                  @if (bankpay['bankstreetaddress'].errors['required']) {
                  <div>{{ lang.Please_enter_Street_Address}}</div>
                  }
                </div>
                }
              </div>
              <div class="col-span-3 sm:col-span-1">
                <label for="maidenname">{{ lang.Street_Address_Line_2 }}<span></span></label>
                <input type="text" class="form-control" id="Address2" formControlName="bankstreetaddressline2"
                  autocomplete="off" placeholder={{lang.Street_Address_Line_2}} />
              </div>
              <div class="col-span-3 sm:col-span-1"></div>

              <div class="col-span-3 sm:col-span-1">
                <label for="city">{{ lang.City }}<span style="color: red">*</span></label>
                <input type="text" class="form-control checking-field" id="City" placeholder={{lang.City}}
                  formControlName="bankcity" value="" />
                @if (bankpay['bankcity'].errors && bankpay['bankcity'].touched) {
                <div class="invalid text-red-500">
                  @if (bankpay['bankcity'].errors['required']) {
                  <div>{{ lang.Please_enter_City }}</div>
                  }
                </div>
                }
              </div>
              <div class="col-span-3 sm:col-span-1">
                <label for="Province">{{ lang.Province }}<span style="color: red">*</span></label>

                <select class="form-control" id="StateId" placeholder='Select' formControlName="bankprovince" value=""
                  (change)="provincelistpayment($event)" autocomplete="off">
                  <option value="">{{ lang.Select }}</option>
                  <option *ngFor="let name of configprovinceres" [value]="name.shortName">
                    {{ name.shortName }}
                  </option>
                </select>


                @if (bankpay['bankprovince'].errors && bankpay['bankprovince'].touched) {
                <div class="invalid text-red-500">
                  @if (bankpay['bankprovince'].errors['required']) {
                  <div>{{ lang.Please_select_Province }}</div>
                  }
                </div>
                }
              </div>
              <div class="col-span-3 sm:col-span-1">
                <label for="postalCode">{{ lang.Postal_Code }}<span style="color: red">*</span></label>
                <input type="text" class="form-control checking-field" id="PostalZip" formControlName="bankpostalcode"
                  autocomplete="off" (input)="changeTextToUppercasePayment('bankpostalcode', $event)"
                  placeholder={{lang.Postal_Code}} value="" />
                <input type="hidden" name="PostalZip" />

                @if (bankpay['bankpostalcode'].errors && bankpay['bankpostalcode'].touched) {
                <div class="invalid text-red-500">
                  @if (bankpay['bankpostalcode'].errors['required']) {
                  <div>{{ lang.Please_enter_postal_code }}</div>
                  }
                </div>
                } @if (bankinvalidPostalCode==true) {
                <div class="invalid text-red-500">
                  {{ lang.Please_enter_valid_Postal_Code }}
                </div>
                }


              </div>

              <div class="col-span-3">

                <button type="submit"
                  class="w-full sm:w-1/2 h-14 mb-1 max-2 rounded-full border-[1px] border-indigo-200 bg-indigo-200 p-0.5  transition duration-700 ease-in-out text-black mt-2"
                  (click)="showpadaggrement()">
                  {{lang.Review_and_Sign_the_PAD_Agreement}}
                </button>
                <span class="invalid text-red-500">{{padaggrementerror}}</span>
              </div>


            </div>
          </form>

          }




        </form>
      </div>
      <!-- First column -->

      <!-- Second column -->
      <div class="col-span-1 bg-white text-white">
        <!-- <div
          class="main"
          style="flex: 1; padding: 18.5px; overflow-x: auto"
        > -->
        <!-- <div
        class="form-row"
        *ngIf="finalenrollemntsummary.length == 0 ? false : true"
      > -->
        <div class="form-row" *ngIf="finalenrollemntsummary.length>0 || finalenrollemntsummaryLife.length>0 ">
          <div class="form-group col-md-12">



            <div>
              <table class="table table-bordered text-black font-thin" id="customerPlansTable">
                <!-- <thead>
                  <tr>
                    <th colspan="6" style="font-size: 14px;background-color: #ffff;">{{lang.plans_summary}}</th>
                  </tr>
                </thead> -->
                <tbody>
                  <tr class="bg-indigo-200">
                    <th class="w45">{{lang.Plan}}</th>
                    <!-- <th class="w25"></th> -->
                    <th class="w10">{{lang.Coverage}}</th>
                    <th class="align-center w15">{{lang.Amount || 'Amount'}}</th>
                    <th class="align-center w15">{{lang.Tax}}</th>
                    <th class="align-center w15">{{lang.Total}}</th>
                  </tr>
                  <ng-container *ngFor="
                let plan of finalenrollemntsummary;
                let i = index">
                    <tr style="background-color:#ffff">
                      <td colspan="6">
                       {{plan.packagename}}
                      </td>
                    </tr>
                    <tr *ngFor="let product of plan.products;let j = index">
                      <td>
                        {{product.name}}
                      </td>

                      <td class="align-center">
                        <span
                          *ngIf="product.planCoverage =='SINGLE' || product.planCoverage =='COUPLE' || product.planCoverage =='FAMILY';else placheck">{{capitalizeFirstLetter(product.planCoverage)}}</span>
                        <ng-template #placheck>---</ng-template>

                      </td>
                      <td class="align-right">
                        {{product.calculatedTax.price | currency:"USD"}}&nbsp;
                      </td>
                      <td class="align-right">
                        {{product.calculatedTax.tax | currency:"USD"}}&nbsp;
                      </td>
                      <td class="align-right">
                        {{product.calculatedTax.total | currency:"USD"}}&nbsp;
                      </td>
                    </tr>
                  </ng-container>
                  <ng-container *ngFor="
                let plan of finalenrollemntsummaryLife;
                let i = index">
                    <tr style="background-color:#ffff">
                      <td colspan="6">&nbsp;
                      {{plan.packagename}}
                      </td>
                    </tr>
                    <tr *ngFor="let product of plan.products;let j = index">
                      <td>&nbsp;
                        {{plan.name}}
                      </td>

                      <td class="align-center">
                        <span *ngIf="plan.coverage =='MEMBER' || plan.coverage =='MEMBER & SPOUSE';else placheck">
                         <span>
                          @if(plan.coverage =='MEMBER'){
                       {{lang.MEMBER}}
                          }
                          @else{
                           {{lang.Member_Spouse}}
                          }
                         </span>
                        </span>
                        <ng-template #placheck>---</ng-template>

                      </td>
                      <td class="align-right">

                        <span>{{lang.MEMBER}}:</span>

                        <table class="table text-nowrap" style="font-size:12px">

                          <tbody>
                            <tr class=" ">
                              <td class=" border-right">{{lang.Units}}</td>
                              <td>({{plan.member.coverageValue/plan.member.standardUnits}})</td>

                            </tr>
                            <tr class=" ">
                              <td class=" border-right">{{lang.Premium}}</td>
                              <td>{{plan.member.premium | currency:"USD"}}</td>

                            </tr>
                            <tr class=" ">
                              <td class=" border-right">{{lang.Amount}}</td>
                              <td>{{plan.member.amount | currency:"USD"}}</td>

                            </tr>
                            <tr class=" ">
                              <td class=" border-right">{{lang.Guarantee_Acceptance}}</td>
                              <td>
                                @if(plan.member.defaultRange>=plan.member.maximumCoverageWithoutEvidence){
                                {{plan.member.maximumCoverageWithoutEvidence | currency:"USD"}}
                                }
                                @else{
                                {{plan.member.defaultRange | currency:"USD"}}
                                }

                              </td>

                            </tr>
                            @if(plan.member.maximumCoverageWithoutEvidence>plan.member.defaultRange){
                            <tr class=" ">
                              <td class=" border-right">{{lang.Acceptance_pending_approval}}</td>
                              <td>

                                {{plan.member.maximumCoverageWithoutEvidence-plan.member.defaultRange | currency:"USD"}}



                              </td>

                            </tr>
                            }
                          </tbody>
                        </table>

                        @if(plan.coverage =='MEMBER & SPOUSE'){

                        <span>{{lang.SPOUSE}}:</span>
                        <table class="table text-nowrap" style="font-size:12px">

                          <tbody>
                            <tr class=" ">
                              <td class=" border-right">{{lang.Units}}</td>
                              <td>({{plan.spouse.coverageValue/plan.spouse.standardUnits}})</td>

                            </tr>
                            <tr class=" ">
                              <td class=" border-right">{{lang.Premium}}</td>
                              <td>{{plan.spouse.premium | currency:"USD"}}</td>

                            </tr>
                            <tr class=" ">
                              <td class=" border-right">{{lang.Amount}}</td>
                              <td>{{plan.spouse.amount | currency:"USD"}}</td>

                            </tr>
                            <tr class=" ">
                              <td class=" border-right">{{lang.Guarantee_Acceptance}}</td>
                              <td>
                                @if(plan.spouse.defaultRange>=plan.spouse.maximumCoverageWithoutEvidence){
                                {{plan.spouse.maximumCoverageWithoutEvidence | currency:"USD"}}
                                }
                                @else{
                                {{plan.spouse.defaultRange | currency:"USD"}}
                                }

                              </td>

                            </tr>
                            @if(plan.spouse.maximumCoverageWithoutEvidence>plan.spouse.defaultRange){
                            <tr class=" ">
                              <td class=" border-right">{{lang.Acceptance_pending_approval}}</td>
                              <td>

                                {{plan.spouse.maximumCoverageWithoutEvidence-plan.spouse.defaultRange | currency:"USD"}}



                              </td>

                            </tr>
                            }
                          </tbody>
                        </table>

                        }
                    <tr>
                      @if(plan.coverage =='MEMBER & SPOUSE'){
                      <td>{{lang.Total}}:${{plan.member.amount+plan.spouse.amount | number : '1.2-2'}}</td>
                      }
                      @if(plan.coverage =='MEMBER'){
                      <td>{{lang.Total}}:${{plan.member.amount | number : '1.2-2'}}</td>
                      }
                    </tr>
                    <!-- {{product.calculatedTax.price  | currency:"USD"}}&nbsp; -->
                    </td>
                    <td class="align-right">
                      <!-- <strong>Member:</strong>

                    <table class="table text-nowrap" style="font-size:12px">

                      <tbody>


                        <tr class=" ">
                          <td class=" border-right">Tax</td>
                          <td>{{plan.member.tax | currency:"USD"}}</td>

                        </tr>
                      </tbody>
                    </table> -->

                      <!-- @if(plan.coverage =='MEMBER & SPOUSE'){

                    <strong>Spouse:</strong>
                    <table class="table text-nowrap" style="font-size:12px">

                      <tbody>

                        <tr class=" ">
                          <td class=" border-right">Tax</td>
                          <td>{{plan.spouse.tax | currency:"USD"}}</td>

                        </tr>

                      </tbody>
                    </table>

              } -->
                      <tr>
                        @if(plan.coverage =='MEMBER & SPOUSE'){
                        <td>{{lang.Total}}:${{plan.member.tax | number : '1.2-2'}}</td>
                        }
                        @if(plan.coverage =='MEMBER'){
                        <td>{{lang.Total}}:${{plan.member.tax | number : '1.2-2'}}</td>
                        }
                      <tr>
                        <!-- {{product.calculatedTax.tax  | currency:"USD"}}&nbsp; -->
                    </td>
                    <td class="align-right">
                      {{product.calculatedTax.total | currency:"USD"}}&nbsp;
                    </td>
                    </tr>
                  </ng-container>


                  <tr class="bg-indigo-200">
                    <td colspan="4"><b>{{lang.Total}}</b></td>
                    <!-- <td colspan="3"></td> -->
                    <td class="grandTotal align-right">
                      {{planAmount | currency:"USD"}}&nbsp;
                    </td>
                  </tr>
                </tbody>
              </table>
              <br />



            </div>
          </div>
        </div>

        <div class="form-row optclass" *ngIf="planssummaryopt.length == 0 ? false : true">
          <div class="form-group col-md-12">
            <label for="cardnumnber"><strong>{{lang.opt_in_plans_summary}}</strong></label>

            <div>
              <table class="shopping">
                <tbody>
                  <tr>
                    <!-- <th>S. no</th> -->
                    <th>{{lang.Plan}}</th>
                    <th>{{lang.Details}}</th>
                    <!-- <th class="taxamount"> Amount</th>

                  <th class="taxamount">Tax</th>
                  <th class="taxamount">Total</th> -->
                  </tr>
                  <tr *ngFor="
                        let item of planssummaryopt;
                        let i = index
                      ">
                    <!-- <td>{{i+1}}</td> -->
                    <!-- {{item.groupName | lowercase}}- -->

                    <td>
                      {{ item.planname }}<br />({{
                      item.packagename
                      }})
                    </td>
                    <td>{{ item.details }}</td>
                    <!-- <td class="taxamount">{{currencySymbol}}{{item.planPrice | number : '1.2-2'}}</td>

                  <td class="taxamount"><span *ngIf="item.gstCheck" class="gsttaxes">{{item.gstPrice==0?"-":"(GST)&nbsp;"+currencySymbol+(item.gstPrice | number : '1.2-2')}}</span><span *ngIf="item.pstCheck" class="gsttaxes">{{item.pstPrice==0?"-":"(PST)&nbsp;"+currencySymbol+(item.pstPrice | number : '1.2-2')}}</span><span *ngIf="item.hstCheck"  class="gsttaxes">{{item.hstPrice==0?"-":"(HST)&nbsp;"+currencySymbol+(item.hstPrice | number : '1.2-2')}}</span><span *ngIf="!item.gstCheck && !item.pstCheck && !item.hstCheck ">-</span></td>
                  <td class="grandTotal">{{currencySymbol}}{{item.totalPrice | number : '1.2-2'}}</td> -->
                  </tr>

                  <tr>
                    <!-- <td>Total </td><td colspan="2"></td><td class="grandTotal">{{currencySymbol}}0.00</td> -->
                  </tr>
                  <!-- <tr>
                  <td colspan="3">

                  </td>
                  <td colspan="2" class="grandTotal">Total:&nbsp;{{currencySymbol}}{{planAmount | number : '1.2-2'}}</td>
                </tr> -->
                </tbody>
              </table>
            </div>
          </div>
        </div>




        <!-- </div> -->
      </div>

      <!-- Second column -->
    </div>
    <br>
    <div class="col-span-2 overflow-scroll">
    @if(addbenificaryData.length>0){
      <div class="w-3/4">
        <table class="table table-bordered text-black" id="customerPlansTable">
            <thead>
                  <tr>
                    <th  style="font-size: 14px;background-color: #ffff;">{{lang.Beneficiary_Information}}</th>
                  </tr>
                </thead>
          <tbody>
            <tr>
              <th>{{lang.Type}}</th>
              <th>{{lang.First_Name}}</th>
              <th>{{lang.Last_Name}}</th>
              <th>{{lang.Relation}}</th>
              <th>{{lang.Minor}}</th>
              <th>{{lang.Date_of_Birth}}</th>
              <th>{{lang.Contingent_First_Name}}</th>
              <th>{{lang.Contingent_Last_Name}}</th>
              <th>{{lang.Trustee_First_Name}}</th>
              <th>{{lang.Trustee_Last_Name}}</th>
              <th>{{lang.Percentage}}</th>
              <th>{{lang.Action}}</th>
            </tr>

            <tr *ngFor="
                  let broker of addbenificaryData;
                  let i = index
                " [ngClass]="{
                  orange: i % 2 == 0,
                  white: i % 2 != 0
                }">

              <td>{{capitalizeFirstLetter(broker.customerType)}}</td>

              <td>
                {{capitalizeFirstLetter(broker.firstName)}}
              </td>
              <td>
                {{capitalizeFirstLetter(broker.lastName)}}
              </td>
              <td>
                {{capitalizeFirstLetter(broker.relationship)}}
              </td>
              <td>
                @if(broker.isMinor=="true"){
                Yes
                }@else{
                No
                }
              </td>

              <td>

                {{broker.dob | date}}
              </td>

              <td>
                {{capitalizeFirstLetter(broker.contingentFirstName)}}
              </td>
              <td>
                {{capitalizeFirstLetter(broker.contingentLastName)}}
              </td>
              <td>

                @if(broker.trusteeFirstName==""){
                NA
                }@else{
                {{capitalizeFirstLetter(broker.trusteeFirstName)}}
                }

              </td>
              <td>
                @if(broker.trusteeLastName==""){
                NA
                }@else{
                {{capitalizeFirstLetter(broker.trusteeLastName)}}
                }
              </td>

              <td>
                {{broker.percentage}}
              </td>

              <td class="thBottomNone text-center inline-flex items-center">
                <svg class="h-10 w-10 text-green-600" (click)="editbenficiary($event, i, broker)"
                  *ngIf="enableEditIndex != i" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                  stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" />
                  <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />
                  <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
                  <line x1="16" y1="5" x2="19" y2="8" />
                </svg>
                <svg class="h-9 w-9 text-red-600" (click)="deleteBenficiary(broker, i)" *ngIf="enableEditIndex != i"
                  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                  stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" />
                  <rect x="3" y="4" width="18" height="4" rx="2" />
                  <path d="M5 8v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-10" />
                  <line x1="10" y1="12" x2="14" y2="12" />
                </svg>


              </td>

            </tr>
          </tbody>
        </table>


      </div>

      }
      </div>









    <div class="relative">

      <!-- Left Button -->
      <!-- <button class="fixed left-0 bottom-16  w-36 h-14 mb-1 rounded-full border-2 border-indigo-700 bg-gray-200 p-0.5 font-bold mx-2 sm:mx-12 hover:bg-white" (click)="gotoPlansPage()">
        {{ lang.previous }}
      </button> -->


      <button
        class="fixed text-center inline-flex items-center left-0 bottom-10  w-40 h-14 mb-1 rounded-full border-2 border-indigo-200 bg-indigo-200 p-0.5   mx-2 sm:mx-14 hover:bg-white"
        (click)="gotoPlansPage()">

        <svg class="h-10 w-10 text-sky-900" width="24" height="24" viewBox="0 0 24 24" stroke-width="2"
          stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" />
          <line x1="5" y1="12" x2="19" y2="12" />
          <line x1="5" y1="12" x2="11" y2="18" />
          <line x1="5" y1="12" x2="11" y2="6" />
        </svg>
        &nbsp;{{ lang.Previous }}
      </button>
      <!-- Right Button -->
      @if(paymentForm.get('paymentRadioCheck')?.value === 'CC'){


      <button
        class="fixed right-0 bottom-10 w-36 h-14 mb-1 rounded-full border-2 border-indigo-200 bg-indigo-200 p-0.5   mx-12 hover:bg-white"
        (click)="creditcardpaysubmit(paymentForm.value,paymentform2)">
        {{ lang.Submit }}
      </button>
      }
      @else{

      <button
        class="fixed right-0 bottom-10 w-36 h-14 mb-1 rounded-full border-2 border-indigo-200 bg-indigo-200 p-0.5   mx-12 hover:bg-white"
        (click)="bankpayformsubmit(bankpayForm.value)">
        {{ lang.Submit }}
      </button>

      }

    </div>
    }
  </div>
</div>
@if(languageData){
<app-confirmation-model [show]="showDeleteModal" [languageData]="languageData"  [message]="modalMessage" (confirmed)="onConfirmed($event)">
</app-confirmation-model>
}

@if(showModelDialog || showPADModelDiaslog){
<app-model-dialog [show]="showModelDialog" [showPAD]="showPADModelDiaslog" [languageData]="languageData"  [message]="pdfData" [headMessage]="header"
  (pdfconfirmed)="onPdfConfirmed($event)" (padPdfconfirmed)="onPADPdfConfirmed($event)">
</app-model-dialog>
}

@if(showModal && languageData){
<app-forms-model [show]="showModal" [editChildData]="editChildInfo" [languageData]="languageData" [buttonInfo]="childInfoModalAddAndUpdateButton"
  (submitForm)="onSubmitForm($event)" [formConfigData]="languageTokensArray" (close)="onCloseModal()">
</app-forms-model>
}
@if(ShowModelPopup && languageData){
<app-modelpopupconfirmation [show]="ShowModelPopup" [languageData]="languageData" [showHeaderMessage]="ShowModelPopupTitle"
  [showModelContent]="ShowModelPopupMessage" (closedModelpopup)="onClosedModelpopup($event)">

</app-modelpopupconfirmation>
}

@if(missingInformation){
<div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <!--
      Background backdrop, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100"
        To: "opacity-0"
    -->
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

  <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
    <div class="flex sm:min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
      <div
        class="relative transform overflow-hidden rounded-lg bg-white text-center mx-auto shadow-xl transition-all sm:w-1/3">
        <div class="bg-white">
          <div class="">
            <div
              class="flex items-center justify-center font-bold text-2xl h-16 border border-b-indigo-700 bg-[#00297A] text-white">
            {{lang.Missing_Information}}
            </div>
            <div class="text-left mx-2" style="  max-height: calc(100vh - 300px);
        overflow-y: auto;">
              @for(item of missingInfo; track item;let i=$index){
              <div>
                <span>{{i+1}}.&nbsp;{{item}}&nbsp;<span style="color: red">*</span></span>
              </div>
              }
            </div>
          </div>
        </div>
        <div class="bg-gray-50 px-2 sm:flex sm:flex-row-reverse sm:px-6 border border-t-indigo-700">
          <button type="button"
            class="w-36 h-14 mb-2 mt-2 rounded-full border-2 border-indigo-200 bg-indigo-200 p-0.5  hover:bg-white"
            (click)="closemissingInfoModel()">
            {{lang.Close}}
          </button>
          <!-- <button type="button" class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto">Deactivate</button>
            <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Cancel</button> -->
        </div>
      </div>
    </div>
  </div>
</div>
}

@if(planOptionsModel){
<div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <!--
      Background backdrop, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100"
        To: "opacity-0"
    -->
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

  <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
    <div class="flex sm:min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
      <!--
          Modal panel, show/hide based on modal state.

          Entering: "ease-out duration-300"
            From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            To: "opacity-100 translate-y-0 sm:scale-100"
          Leaving: "ease-in duration-200"
            From: "opacity-100 translate-y-0 sm:scale-100"
            To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        -->
      <div
        class="relative transform overflow-hidden rounded-lg bg-white text-center mx-auto shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-7xl max-w-2xl">
        <div class="bg-white">
          <div class="">
            <!-- <div class="text-center font-bold text-2xl">
                {{lang.choose_options}}
              </div> -->
            <div
              class="flex items-center justify-center font-bold text-2xl h-16 border border-b-indigo-700 bg-[#00297A] text-white">
              {{lang.Choose_Options}}
            </div>
            <div class="mt-3 mx-4 text-left" style="  max-height: calc(100vh - 200px);
        overflow-y: auto;">


              <input type="hidden" id="plandetailsobj" #optionPlanDetailsObj value="{{plandetailsobjvalue}}">
              <input type="hidden" id="optionumber" #optionNumberInput value="{{optionstitle.length}}">


              @for(option of optionstitle; track option;let i = $index){
              <div>
                <h3><b>{{option.name}}</b><span style="color: red">*</span></h3>

                @for(optionvalue of option.planOptionsValues; track optionvalue;let j = $index){
                <div class="row">
                  <div class="col-md-12" style="border: 1px solid black;padding:5px 15px;border-bottom:1px !important">
                    <input type="radio" value="{{optionvalue.json}}"
                      id="planselectionvalue-{{optionvalue.id}}-{{option.id}}" #optionUnseelct
                      class="optionselectionmethod-{{option.id}}"
                      (change)="planoptionselection($event,optionvalue,option.id,optionvalue.id,i,'optionselectionmethod-')">&nbsp;{{optionvalue.name}}

                  </div>
                </div>
                }
                <br>
              </div>
              }
            </div>
          </div>
        </div>
        <div id="planoptionmessage" class="invalid text-red-500"><b>{{optionmessage}}</b></div>

        <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 border border-t-indigo-700">


          <button type="button"
            class="w-36 h-14 mb-1 rounded-full border-2 border-indigo-200 bg-indigo-200 p-0.5  hover:bg-white"
            (click)="closeoptionsselection()">
            {{lang.Close}}
          </button>&nbsp;
          <button type="button"
            class="w-36 h-14 mb-1 rounded-full border-2 border-green-200 bg-indigo-200 p-0.5  hover:bg-white"
            (click)="confirmoptionsselection()">
            {{lang.Confirm}}
            <!-- <strong style="color: white">{{lang.confirm}}</strong> -->
          </button>
          <!-- <button type="button" class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto">Deactivate</button>
            <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Cancel</button> -->
        </div>
      </div>
    </div>
  </div>
</div>
}

@if(padAggrementModel){
<div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">

  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

  <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
    <div class="flex sm:min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">

      <div
        class="relative transform overflow-hidden rounded-xl bg-white text-center mx-auto shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-7xl max-w-2xl">
        <div class="bg-white">
          <div class="">
            <div
              class="flex items-center justify-center font-bold text-2xl h-16 border border-b-indigo-700 bg-[#00297A] text-white">
              {{lang.PAD_Agreement}}
            </div>
            <div class="text-center">
              <pdf-viewer [src]="generatePADaggrement" style=" height: 60vh;z-index:9999;overflow-y: scroll;"
                class="px-0 w-fill object-cover"></pdf-viewer>

            </div>
          </div>
        </div>
        <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 border border-t-indigo-700 gap-2">

          @if(signaturestatus){
          <button type="button"
            class="w-44 h-14 mb-1 rounded-full order-first border-2 border-green-200 bg-indigo-200 p-0.5  hover:bg-white"
            (click)="signaturestartmodel()">
            {{lang.Sign_Agree}}
          </button>
          }
          @if(agreestatus){
          <button type="button"
            class="w-36 h-14 mb-1 order-2 rounded-full border-2 border-green-200 bg-indigo-200 p-0.5  hover:bg-white"
            (click)="agreepadconditions()">
            {{lang.Agree}}
          </button>
          }
          @if(rejectPADModel){
          <button type="button"
            class="w-36 h-14 mb-1 order-3 rounded-full border-2 border-gray-200 bg-indigo-200 p-0.5  hover:bg-white"
            (click)="rejectPADpafaggrement()">
            {{lang.Reject}}
          </button>
          }

          <!-- <button type="button" class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto">Deactivate</button>
            <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Cancel</button> -->
        </div>
      </div>
    </div>
  </div>
</div>
}

@if(padAggrementFinalModel){
<div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">

  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

  <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
    <div class="flex sm:min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">

      <div
        class="relative transform overflow-hidden rounded-xl bg-white text-center mx-auto shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-7xl max-w-2xl">
        <div class="bg-white">
          <div class="">
            <div
              class="flex items-center justify-center font-bold text-2xl h-16 border border-b-indigo-700 bg-[#00297A] text-white">
              {{lang.PAD_Agreement}}
            </div>
            <div class="text-center">
              <pdf-viewer [src]="generatePADaggrement" style=" height: 60vh;z-index:9999;overflow-y: scroll;"
                class="px-0 w-fill object-cover"></pdf-viewer>

            </div>
          </div>
        </div>
        <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 border border-t-indigo-700 gap-2">


          @if(showagree){
          <button type="button"
            class="w-36 h-14 mb-1 order-2 rounded-full border-2 border-green-200 bg-indigo-200 p-0.5  hover:bg-white"
            (click)="agreepadconditions()">
            {{lang.Agree}}
          </button>
          }
          @if(rejectPADModel){
          <button type="button"
            class="w-36 h-14 mb-1 order-3 rounded-full border-2 border-indigo-200 bg-indigo-200 p-0.5  hover:bg-white"
            (click)="rejectPADpafaggrement1()">
            {{lang.Reject}}
          </button>
          }
          @if(closePADModel){
          <button type="button"
            class="w-36 h-14 mb-1 order-4 rounded-full border-2 border-indigo-200 bg-indigo-200 p-0.5  hover:bg-white"
            (click)="closePADAdggrementModel()">
            {{lang.Close}}
          </button>
          }
          <!-- <button type="button" class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto">Deactivate</button>
            <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Cancel</button> -->
        </div>
      </div>
    </div>
  </div>
</div>
}

@if(signModelForBankPay){
<div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">

  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

  <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
    <div class="flex sm:min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">

      <div
        class="relative transform overflow-hidden rounded-xl bg-white text-center mx-auto shadow-xl transition-all sm:my-8 sm:w-1/4 sm:max-w-7xl max-w-2xl">
        <div class="bg-white">
          <div class="">
            <div
              class="flex items-center justify-center font-bold text-2xl h-16 border border-b-indigo-700 bg-[#00297A] text-white">
              {{lang.Signature}}
            </div>
            <div class="text-center">

              <div class="signature-container col-span-1 w-full" style="border:1px solid #ccc;border-radius: 4px;">

                <!-- <button class="btn btn-primary btn-inline" (click)="clearPadcc()" [disabled]="signaturecheckvalue1cc">Clear</button> -->

                <!-- <canvas
            #canvascc
            (touchstart)="startDrawingcc($event)"
            (touchmove)="movedcc($event)"
            (click)="signaturestartcc($event)" required
          ></canvas> -->

                <signature-pad [options]="signaturePadOptions" (touchmove)="onMouseMove($event)"
                  (mousemove)="onMouseMove($event)" (onEndEvent)="drawComplete()"
                  (onBeginEvent)="drawStart()"></signature-pad>
                @if(signaturecheckvalue1cc){
                <div style="
          left: 10px;
          color: red;
          text-decoration: underline;cursor:pointer">
                  <p (click)="clearSignature()">x {{lang.Clear}}</p>
                </div>
                }

              </div>
              @if(showpaderrorMessage){
              <span class="invalid text-red-500">{{lang.Please_Sign_the_Signature}}</span>
              }
              <!-- <div class="mt-2 border-2 border-green-200 rounded-lg w-1/2"> -->


              <!-- <canvas
                  #canvasPadElement
                  (mousedown)="onMouseDownpad($event)" (touchstart)="onMouseDownpad($event)" (touchmove)="onMouseMovepad($event)"
      (mousemove)="onMouseMovepad($event)" required  style="border: 1px solid #ccc;border-radius: 4px;"
                ></canvas>
                @if(signaturecheckvalue1){
                  <div style="
                  left: 10px;
                  color: red;
                  text-decoration: underline;cursor:pointer">
                      <p (click)="clearPadSignature()">x {{lang.clear}}</p>
                  </div>
                }
        @if(signaturemessagepad){
                  <span
                  class="invalid text-red-500"
                  >{{lang.please_sign_the_signature}}</span
                >
        } -->
              <!-- </div> -->


            </div>
          </div>
        </div>
        <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 border border-t-indigo-700">


          <button type="button"
            class="w-36 h-14 mb-1 rounded-full border-2 border-indigo-200 bg-indigo-200 p-0.5  hover:bg-white"
            (click)="savePadsignature()">{{lang.Confirm}}

          </button>

          <!-- <button type="button" class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto">Deactivate</button>
            <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Cancel</button> -->
        </div>
      </div>
    </div>
  </div>
</div>


}

@if(imageDisplayShow){
<div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">

  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

  <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
    <div class="flex sm:min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">

      <div
        class="relative transform overflow-hidden rounded-xl bg-white text-center mx-auto shadow-xl transition-all sm:my-8 sm:w-1/4 sm:max-w-7xl max-w-2xl">
        <div class="bg-white">
          <div class="">
            <div
              class="flex items-center justify-center font-bold text-2xl h-16 border border-b-indigo-700 bg-[#00297A] text-white">
              {{lang.Preview}}
            </div>
            <div class="text-center">

              <div class="signature-container col-span-1 w-full" style="border:1px solid #ccc;border-radius: 4px;">


                @if(normalview){
                <img [src]="imagedisplay" />
                }

                @if(pdfview){

                <pdf-viewer [src]="imagedisplay" style=" height: 60vh;z-index:9999;overflow-y: scroll;"
                  class="px-0 w-fill object-cover"></pdf-viewer>

                }

              </div>
            </div>
          </div>
          <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 border border-t-indigo-700">


            <button type="button"
              class="w-36 h-14 mb-1 rounded-full border-2 border-indigo-200 bg-gray-200 p-0.5"
              (click)="closeImagePreview()">{{lang.Close}}

            </button>

            <!-- <button type="button" class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto">Deactivate</button>
            <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Cancel</button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

}



@if(iafginfo){
<div class="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
  <div class="bg-white  rounded-xl shadow-lg w-full md:w-1/2">
    <div
      class="flex items-center justify-center font-bold text-2xl h-16 border border-b-indigo-700 bg-[#00297A] text-white">
      <h2 class="text-[14px] font-semibold">IAFG Info</h2>
      <!-- <button (click)="onClose()" class="text-gray-500 hover:text-gray-800 flex items-end justify-end">&times;</button> -->
    </div>

    <form [formGroup]="iafggenderForm">
      <div class="grid grid-cols-1 sm:grid-cols-12 gap-2 p-2">
        <div class="sm:col-span-12">
          <label for="" class="">This Form have IAFG Insurance plans, do you wish to continue?<span
              class="text-danger">*</span></label>
          <div class="flex items-center space-x-2">
            <input type="radio" id="true" formControlName="memberinfo" value="true" class="form-radio text-blue-600"
              (change)="memberinfogenderCheck($event)" />
            <label for="true" class="text-gray-700">Yes</label>
          </div>

          <div class="flex items-center space-x-2">
            <input type="radio" id="false" formControlName="memberinfo" value="false" class="form-radio text-blue-600"
              (change)="memberinfogenderCheck($event)" />
            <label for="false" class="text-gray-700">No</label>
          </div>


          @if (IA['memberinfo'].errors && IA['memberinfo'].touched) {
          <div class="invalid text-red-500">
            @if (IA['memberinfo'].errors['required']) {
            <div>Please select IAFG GenderAtBirth status</div>
            }
          </div>
          }



        </div>

        @if(iafggenderForm.get('memberinfo')?.value === 'true'){
        <div class="sm:col-span-3">
          <label for="" class="">GenderAtBirth<span class="text-danger">*</span></label>

          <select class="form-control" placeholder="Select" formControlName="gender">

            <option value="" selected disabled>Select</option>

            <option value="male">Male</option>
            <option value="female">Female</option>
          </select>


          @if (IA['gender'].errors && IA['gender'].touched) {
          <div class="invalid text-red-500">
            @if (IA['gender'].errors['required']) {
            <div>Please select type</div>
            }
          </div>
          }

        </div>
        }
      </div>
    </form>




    <div class="flex justify-end gap-2 border border-t-indigo-700 px-2">
      <button type="button"
        class="w-36 h-14 mt-2 mb-2 rounded-full border-2 border-indigo-200 bg-indigo-200 p-0.5  hover:bg-white"
        (click)="hideIAFGgenderfrom()">
        {{ lang.close }}
      </button>
      <button type="submit"
        class="w-36 h-14 mt-2 mb-2 rounded-full border-2 border-indigo-200 p-0.5  hover:border-green-500"
        [disabled]="iafggenderForm.invalid" (click)="hideIAFGgenderfrom()">
        {{lang.submit}}
      </button>
    </div>

  </div>
</div>
}


@if(benficiary){
<div class="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
  <div class="bg-white  rounded-xl shadow-lg  overflow-scroll w-3/4">
    <div
      class="flex items-center justify-center font-bold text-2xl h-16 border border-b-indigo-700 bg-[#00297A] text-white">
      <h2 class="text-[14px] font-semibold">{{lang.Beneficiary_Information}}</h2>
      <!-- <button (click)="onClose()" class="text-gray-500 hover:text-gray-800 flex items-end justify-end">&times;</button> -->
    </div>

    @if(showBenificiaryForm){
    <form [formGroup]="benficiaryForm">

      <div class="grid grid-cols-1 sm:grid-cols-12 gap-2 p-2">
        <div class="sm:col-span-3">
          <label for="" class="">{{lang.Beneficiary}}<span class="text-danger">*</span></label>

          <select class="form-control" placeholder="Select" formControlName="customerType">

            <option value="" selected disabled>{{lang.Select}}</option>
            <option value="MEMBER" selected>{{lang.MEMBER}}</option>
            <option value="SPOUSE" selected>{{lang.SPOUSE}}</option>
          </select>


          @if (b['customerType'].errors && b['customerType'].touched) {
          <div class="invalid text-red-500">
            @if (b['customerType'].errors['required']) {
            <div>{{lang.Please_select_beneficiary}}</div>
            }
          </div>
          }

        </div>

        <div class="sm:col-span-3">
          <label for="" class="">{{lang.First_Name}}<span class="text-danger">*</span></label>
          <input class="form-control checking-field" type="text" placeholder={{lang.First_Name}} formControlName="firstName"
            (keypress)="alphabatesOnly($event)">

          @if (b['firstName'].errors && b['firstName'].touched) {
          <div class="invalid text-red-500">
            @if (b['firstName'].errors['required']) {
            <div>{{ lang.Please_enter_first_name}}</div>
            } @if (b['firstName'].errors['minlength']) {
            <div>{{ lang.First_Name_minimum_of_2_Characters_and_without_numbers}}</div>
            }
          </div>
          }

        </div>
        <div class="sm:col-span-3">
          <label for="" class="">{{lang.Last_Name}}<span class="text-danger">*</span></label>
          <input type="text" class="form-control checking-field" placeholder={{lang.Last_Name}} formControlName="lastName"
            (keypress)="alphabatesOnly($event)">


          @if (b['lastName'].errors && b['lastName'].touched) {
          <div class="invalid text-red-500">
            @if (b['lastName'].errors['required']) {
            <div>{{ lang.Please_enter_last_name}}</div>
            } @if (b['lastName'].errors['minlength']) {
            <div>{{ lang.Last_Name_minimum_of_2_Characters_and_without_numbers}}</div>
            }
          </div>
          }



        </div>
        <div class="sm:col-span-3">
          <label for="" class="">{{lang.Relation}}<span class="text-danger">*</span></label>


          <select class="form-control" placeholder="Select" formControlName="relationship"
            (change)="isChildCheck($event)">

            <option value="" selected disabled>{{lang.Select}}</option>
            <option *ngFor="let name of beneficariesDetails?.relationship" [value]="name.value">
              {{ name.label }}
            </option>

          </select>


          @if (b['relationship'].errors && b['relationship'].touched) {
          <div class="invalid text-red-500">
            @if (b['relationship'].errors['required']) {
            <div>{{lang.Please_select_relationship}}</div>
            }
          </div>
          }



        </div>
        @if(benficiaryForm.get('relationship')?.value === 'CHILD'){
        <div class="sm:col-span-3">
          <label for="" class="">{{lang.Is_this_person_under_age_18}}<span class="text-danger">*</span></label>
          <div class="flex items-center space-x-2">
            <input type="radio" id="true" formControlName="isMinor" value="true" class="form-radio text-blue-600"
              (change)="isMinorCheck($event)" />
            <label for="true" class="text-gray-700">{{lang.Yes}}</label>
          </div>

          <div class="flex items-center space-x-2">
            <input type="radio" id="false" formControlName="isMinor" value="false" class="form-radio text-blue-600"
              (change)="isMinorCheck($event)" />
            <label for="false" class="text-gray-700">{{lang.No}}</label>
          </div>


          @if (b['isMinor'].errors && b['isMinor'].touched) {
          <div class="invalid text-red-500">
            @if (b['isMinor'].errors['required']) {
            <div>{{lang.Please_select_minor_status}}</div>
            }
          </div>
          }



        </div>
        }

        @if(benficiaryForm.get('isMinor')?.value === 'true'){
        <div class="col-span-3">
          <label for="date_of_birth">{{ lang.Date_of_Birth }}<span style="color: red">*</span></label>

          <input type="text" (paste)="(false)" class="form-control" formControlName="dob" [minDate]="childminDate"
            [maxDate]="childmaxDate" #dp [bsConfig]="{dateInputFormat: 'MM-DD-YYYY',showWeekNumbers: false}" placeholder="MM-DD-YYYY" bsDatepicker
            (blur)="checkDateOfBirthMinor($event)" (input)="checkDateOfBirthMinor($event)"
            (change)="checkDateOfBirth($event)" (keypress)="numbersOnly($event)" />

          <!-- <input id="datepicker-autohide"  formControlName="date_of_birth" datepicker datepicker-autohide type="text" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"  placeholder="MM-DD-YYYY"> -->

          @if (b['dob'].errors && b['dob'].touched) {
          <div class="invalid text-red-500">
            @if (b['dob'].errors['required']) {
            <div>{{ lang.Please_Choose_Date_of_Birth }}</div>
            }
          </div>
          }
        </div>
        }
        <div class="sm:col-span-3">
          <label for="" class=""> {{lang.Contingent_First_Name}}<span class="text-danger">*</span></label>
          <input class="form-control checking-field" type="text" placeholder={{lang.First_Name}}
            formControlName="contingentFirstName" (keypress)="alphabatesOnly($event)">

          @if (b['contingentFirstName'].errors && b['contingentFirstName'].touched) {
          <div class="invalid text-red-500">
            @if (b['contingentFirstName'].errors['required']) {
            <div>{{ lang.Please_enter_first_name}}</div>
            } @if (b['contingentFirstName'].errors['minlength']) {
            <div>{{ lang.First_Name_minimum_of_2_Characters_and_without_numbers}}</div>
            }
          </div>
          }

        </div>
        <div class="sm:col-span-3">
          <label for="" class=""> {{lang.Contingent_Last_Name}}<span class="text-danger">*</span></label>
          <input type="text" class="form-control checking-field" placeholder={{lang.Last_Name}}
            formControlName="contingentLastName" (keypress)="alphabatesOnly($event)">

          @if (b['contingentLastName'].errors && b['contingentLastName'].touched) {
          <div class="invalid text-red-500">
            @if (b['contingentLastName'].errors['required']) {
            <div>{{ lang.Please_enter_last_name}}</div>
            } @if (b['contingentLastName'].errors['minlength']) {
            <div>{{ lang.Last_Name_minimum_of_2_Characters_and_without_numbers}}</div>
            }
          </div>
          }



        </div>

        @if(benficiaryForm.get('isMinor')?.value === 'true'){
        <div class="sm:col-span-3">
          <label for="" class=""> {{lang.Trustee_First_Name}}<span class="text-danger">*</span></label>
          <input class="form-control checking-field" type="text" placeholder={{lang.First_Name}}
            formControlName="trusteeFirstName" (keypress)="alphabatesOnly($event)">

          @if (b['trusteeFirstName'].errors && b['trusteeFirstName'].touched) {
          <div class="invalid text-red-500">
            @if (b['trusteeFirstName'].errors['required']) {
            <div>{{ lang.Please_enter_first_name}}</div>
            } @if (b['trusteeFirstName'].errors['minlength']) {
            <div>{{ lang.First_Name_minimum_of_2_Characters_and_without_numbers}}</div>
            }
          </div>
          }

        </div>
        <div class="sm:col-span-3">
          <label for="" class=""> {{lang.Trustee_Last_Name}}<span class="text-danger">*</span></label>
          <input type="text" class="form-control checking-field" placeholder={{lang.Last_Name}}
            formControlName="trusteeLastName" (keypress)="alphabatesOnly($event)">

          @if (b['trusteeLastName'].errors && b['trusteeLastName'].touched) {
          <div class="invalid text-red-500">
            @if (b['trusteeLastName'].errors['required']) {
            <div>{{ lang.Please_enter_last_name}}</div>
            } @if (b['trusteeLastName'].errors['minlength']) {
            <div>{{ lang.Last_Name_minimum_of_2_Characters_and_without_numbers}}</div>
            }
          </div>
          }



        </div>
        }
        <div class="sm:col-span-3">
          <label for="" class="">{{lang.Percentage}}<span class="text-danger">*</span></label>
          <input type="text" class="form-control checking-field" placeholder={{lang.Percentage}} formControlName="percentage"
            (keypress)="numbersOnly($event)" (blur)="checkpercentagevalue($event)"
            (input)="checkpercentagevalue($event)">


          @if (b['percentage'].errors && b['percentage'].touched) {
          <div class="invalid text-red-500">
            @if (b['percentage'].errors['required']) {
            <div>{{lang.Please_enter_percentage}}</div>
            }
          </div>
          }



        </div>
        <div class="sm:col-span-3">

          <button type="button"
            class="w-28 h-12 mt-12 rounded-full border-2 border-indigo-200 bg-indigo-200 p-0.5  transition duration-700 ease-in-out hover:bg-white"
            [hidden]="benficiaryForm.invalid" (click)="savebenficiary(benficiaryForm.value)">
            {{lang.Save}}
          </button>
        </div>






      </div>
    </form>
    }

    @if(addbenificaryData.length>0){
    <!-- <div class="form-group col-md-12"> -->
      <div style="overflow-x: auto;">
        <table class="table table-bordered text-black" id="customerPlansTable">
          <tbody>
            <tr>
              <th>{{lang.Type}}</th>
              <th>{{lang.First_Name}}</th>
              <th>{{lang.Last_Name}}</th>
              <th>{{lang.Relation}}</th>
              <th>{{lang.Minor}}</th>
              <th>{{lang.Date_of_Birth}}</th>
              <th>{{lang.Contingent_First_Name}}</th>
              <th>{{lang.Contingent_Last_Name}}</th>
              <th>{{lang.Trustee_First_Name}}</th>
              <th>{{lang.Trustee_Last_Name}}</th>
              <th>{{lang.Percentage}}</th>
              <th>{{lang.Action}}</th>
            </tr>
            <tr *ngFor="let broker of addbenificaryData; let i = index" [ngClass]="{ orange: i % 2 == 0, white: i % 2 != 0 }">
              <td>{{ capitalizeFirstLetter(broker.customerType) }}</td>
              <td>{{ capitalizeFirstLetter(broker.firstName) }}</td>
              <td>{{ capitalizeFirstLetter(broker.lastName) }}</td>
              <td>{{ capitalizeFirstLetter(broker.relationship) }}</td>
              <td>
                {{ broker.isMinor == "true" ? "Yes" : "No" }}
              </td>
              <td>{{ broker.dob | date }}</td>
              <td>{{ capitalizeFirstLetter(broker.contingentFirstName) }}</td>
              <td>{{ capitalizeFirstLetter(broker.contingentLastName) }}</td>
              <td>
                {{ broker.trusteeFirstName ? capitalizeFirstLetter(broker.trusteeFirstName) : 'NA' }}
              </td>
              <td>
                {{ broker.trusteeLastName ? capitalizeFirstLetter(broker.trusteeLastName) : 'NA' }}
              </td>
              <td>{{ broker.percentage }}</td>
              <td class="thBottomNone text-center inline-flex items-center">
                <svg class="h-10 w-10 text-green-600" (click)="editbenficiary($event, i, broker)" *ngIf="enableEditIndex != i" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" />
                  <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />
                  <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
                  <line x1="16" y1="5" x2="19" y2="8" />
                </svg>
                <svg class="h-9 w-9 text-red-600" (click)="deleteBenficiary(broker, i)" *ngIf="enableEditIndex != i" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" />
                  <rect x="3" y="4" width="18" height="4" rx="2" />
                  <path d="M5 8v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-10" />
                  <line x1="10" y1="12" x2="14" y2="12" />
                </svg>
              </td>
            </tr>
          </tbody>
        </table>
      </div>


      <button type="button"
        class="w-36 h-12 mt-8 rounded-full border-2 border-indigo-200 bg-indigo-200 p-0.5  transition duration-700 ease-in-out hover:bg-white"
        (click)="addnewbenficiary()">
        Add More
      </button>

    <!-- </div> -->
    <div class="flex justify-end gap-2 border border-t-indigo-700 px-2">
      <button type="button"
        class="w-36 h-14 mt-2 mb-2 rounded-full border-2 border-indigo-200  p-0.5  hover:bg-white"
        (click)="onClose()">
        {{ lang.Close }}
      </button>
      <button type="submit"
        class="w-36 h-14 mt-2 mb-2 rounded-full border-2 border-indigo-200 bg-indigo-200 p-0.5  hover:border-green-500"
        (click)="finalbenificiarySubmit()">
        {{lang.Submit}}
      </button>
    </div>
    }


  </div>
</div>
}


@if(addeditbenficiary){
<div class="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
  <div class="bg-white  rounded-xl shadow-lg w-full md:w-1/2">
    <div
      class="flex items-center justify-center font-bold text-2xl h-16 border border-b-indigo-700 bg-[#00297A] text-white">
      <h2 class="text-[14px] font-semibold">{{BenficairyModalAddAndUpdateButton}} {{lang.Beneficiary_Information}}</h2>
      <!-- <button (click)="onClose()" class="text-gray-500 hover:text-gray-800 flex items-end justify-end">&times;</button> -->
    </div>

    <form [formGroup]="benficiaryForm" (ngSubmit)="addeditbenficarySubmit()">

      <div class="grid grid-cols-1 sm:grid-cols-12 gap-2 p-2">

        <div class="sm:col-span-3">
          <label for="" class="">{{lang.Beneficiary}}<span class="text-danger">*</span></label>



          <select class="form-control" placeholder="Select" formControlName="customerType">

            <option value="" selected disabled>{{lang.Select}}</option>
            <option value="MEMBER" selected>{{lang.MEMBER}}</option>
            <option value="SPOUSE" selected>{{lang.SPOUSE}}</option>

          </select>


          @if (b['customerType'].errors && b['customerType'].touched) {
          <div class="invalid text-red-500">
            @if (b['customerType'].errors['required']) {
            <div>{{lang.Please_select_beneficiary}}</div>
            }
          </div>
          }

        </div>

        <div class="sm:col-span-3">
          <label for="" class="">{{lang.First_Name}}<span class="text-danger">*</span></label>
          <input class="form-control checking-field" type="text" placeholder={{lang.First_Name}} formControlName="firstName"
            (keypress)="alphabatesOnly($event)">

          @if (b['firstName'].errors && b['firstName'].touched) {
          <div class="invalid text-red-500">
            @if (b['firstName'].errors['required']) {
            <div>{{ lang.Please_enter_first_name}}</div>
            } @if (b['firstName'].errors['minlength']) {
            <div>{{ lang.First_Name_minimum_of_2_Characters_and_without_numbers}}</div>
            }
          </div>
          }

        </div>
        <div class="sm:col-span-3">
          <label for="" class="">{{lang.Last_Name}}<span class="text-danger">*</span></label>
          <input type="text" class="form-control checking-field" placeholder={{lang.Last_Name}} formControlName="lastName"
            (keypress)="alphabatesOnly($event)">


          @if (b['lastName'].errors && b['lastName'].touched) {
          <div class="invalid text-red-500">
            @if (b['lastName'].errors['required']) {
            <div>{{ lang.Please_enter_last_name}}</div>
            } @if (b['lastName'].errors['minlength']) {
            <div>{{ lang.Last_Name_minimum_of_2_Characters_and_without_numbers}}</div>
            }
          </div>
          }



        </div>
        <div class="sm:col-span-3">
          <label for="" class="">Relation<span class="text-danger">*</span></label>



          <select class="form-control" placeholder="Select" formControlName="relationship"
            (change)="isChildCheck($event)">

            <option value="" selected disabled>Select</option>
            <option *ngFor="let name of beneficariesDetails?.relationship" [value]="name.value">
              {{ name.label }}
            </option>

          </select>


          @if (b['relationship'].errors && b['relationship'].touched) {
          <div class="invalid text-red-500">
            @if (b['relationship'].errors['required']) {
            <div>{{lang.Please_select_relationship}}</div>
            }
          </div>
          }



        </div>
        @if(benficiaryForm.get('relationship')?.value === 'CHILD'){
        <div class="sm:col-span-3">
          <label for="" class="">{{lang.Is_this_person_under_age_18}}<span class="text-danger">*</span></label>
          <div class="flex items-center space-x-2">
            <input type="radio" id="true" formControlName="isMinor" value="true" class="form-radio text-blue-600"
              (change)="isMinorCheck($event)" />
            <label for="true" class="text-gray-700">{{lang.Yes}}</label>
          </div>

          <div class="flex items-center space-x-2">
            <input type="radio" id="false" formControlName="isMinor" value="false" class="form-radio text-blue-600"
              (change)="isMinorCheck($event)" />
            <label for="false" class="text-gray-700">{{lang.No}}</label>
          </div>


          @if (b['isMinor'].errors && b['isMinor'].touched) {
          <div class="invalid text-red-500">
            @if (b['isMinor'].errors['required']) {
            <div>{{lang.Please_select_minor_status}}</div>
            }
          </div>
          }



        </div>
        }

        @if(benficiaryForm.get('isMinor')?.value === 'true' && benficiaryForm.get('relationship')?.value === 'CHILD'){
        <div class="col-span-3">
          <label for="date_of_birth">{{ lang.Date_of_Birth }}<span style="color: red">*</span></label>

          <input type="text" (paste)="(false)" class="form-control" formControlName="dob" [minDate]="childminDate"
            [maxDate]="childmaxDate" #dp [bsConfig]="{dateInputFormat: 'MM-DD-YYYY',showWeekNumbers: false}" placeholder="MM-DD-YYYY" bsDatepicker
            (blur)="checkDateOfBirthMinor($event)" (input)="checkDateOfBirthMinor($event)"
            (change)="checkDateOfBirth($event)" (keypress)="numbersOnly($event)" />

          <!-- <input id="datepicker-autohide"  formControlName="date_of_birth" datepicker datepicker-autohide type="text" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"  placeholder="MM-DD-YYYY"> -->

          @if (b['dob'].errors && b['dob'].touched) {
          <div class="invalid text-red-500">
            @if (b['dob'].errors['required']) {
            <div>{{ lang.Please_Choose_Date_of_Birth }}</div>
            }
          </div>
          }
        </div>
        }
        <div class="sm:col-span-3">
          <label for="" class=""> {{lang.Contingent_First_Name}}<span class="text-danger">*</span></label>
          <input class="form-control checking-field" type="text" placeholder={{lang.First_Name}}
            formControlName="contingentFirstName" (keypress)="alphabatesOnly($event)">

          @if (b['contingentFirstName'].errors && b['contingentFirstName'].touched) {
          <div class="invalid text-red-500">
            @if (b['contingentFirstName'].errors['required']) {
            <div>{{ lang.Please_enter_first_name}}</div>
            } @if (b['contingentFirstName'].errors['minlength']) {
            <div>{{ lang.First_Name_minimum_of_2_Characters_and_without_numbers}}</div>
            }
          </div>
          }

        </div>
        <div class="sm:col-span-3">
          <label for="" class=""> {{lang.Contingent_Last_Name}}<span class="text-danger">*</span></label>
          <input type="text" class="form-control checking-field" placeholder="{{lang.Last_Name}}"
            formControlName="contingentLastName" (keypress)="alphabatesOnly($event)">

          @if (b['contingentLastName'].errors && b['contingentLastName'].touched) {
          <div class="invalid text-red-500">
            @if (b['contingentLastName'].errors['required']) {
            <div>{{ lang.Please_enter_last_name}}</div>
            } @if (b['contingentLastName'].errors['minlength']) {
            <div>{{ lang.Last_Name_minimum_of_2_Characters_and_without_numbers}}</div>
            }
          </div>
          }



        </div>
        @if(benficiaryForm.get('isMinor')?.value === 'true' && benficiaryForm.get('relationship')?.value === 'CHILD'){
        <div class="sm:col-span-3">
          <label for="" class=""> {{lang.Trustee_First_Name}}<span class="text-danger">*</span></label>
          <input class="form-control checking-field" type="text" placeholder={{lang.First_Name}}
            formControlName="trusteeFirstName" (keypress)="alphabatesOnly($event)">

          @if (b['trusteeFirstName'].errors && b['trusteeFirstName'].touched) {
          <div class="invalid text-red-500">
            @if (b['trusteeFirstName'].errors['required']) {
            <div>{{ lang.Please_enter_first_name}}</div>
            } @if (b['trusteeFirstName'].errors['minlength']) {
            <div>{{ lang.First_Name_minimum_of_2_Characters_and_without_numbers}}</div>
            }
          </div>
          }

        </div>
        <div class="sm:col-span-3">
          <label for="" class=""> {{lang.Trustee_Last_Name}}<span class="text-danger">*</span></label>
          <input type="text" class="form-control checking-field" placeholder={{lang.lastName}}
            formControlName="trusteeLastName" (keypress)="alphabatesOnly($event)">

          @if (b['trusteeLastName'].errors && b['trusteeLastName'].touched) {
          <div class="invalid text-red-500">
            @if (b['trusteeLastName'].errors['required']) {
            <div>{{ lang.Please_enter_last_name}}</div>
            } @if (b['trusteeLastName'].errors['minlength']) {
            <div>{{ lang.Last_Name_minimum_of_2_Characters_and_without_numbers}}</div>
            }
          </div>
          }



        </div>
        }
        <div class="sm:col-span-3">
          <label for="" class="">{{lang.Percentage}}<span class="text-danger">*</span></label>
          <input type="text" class="form-control checking-field" placeholder="Percentage" formControlName="percentage"
            (keypress)="numbersOnly($event)" (blur)="checkpercentagevalue($event)"
            (input)="checkpercentagevalue($event)">


          @if (b['percentage'].errors && b['percentage'].touched) {
          <div class="invalid text-red-500">
            @if (b['percentage'].errors['required']) {
            <div>{{lang.Please_enter_percentage}}</div>
            }
          </div>
          }



        </div>







      </div>



      <div class="flex justify-end gap-2 border border-t-indigo-700 px-2">
        <button type="button"
          class="w-36 h-14 mt-2 mb-2 rounded-full border-2 border-indigo-200 bg-indigo-200 p-0.5  hover:bg-white"
          (click)="onCloseaddeditbenificary()">
          {{ lang.Close }}
        </button>
        <button type="submit"
          class="w-36 h-14 mt-2 mb-2 rounded-full border-2 border-indigo-200 p-0.5  hover:border-green-500"
          [disabled]="benficiaryForm.invalid">
          {{lang.Submit}}
        </button>
      </div>
    </form>




  </div>
</div>
}
@if(benficiaryCount){
<div class="fixed inset-0 flex items-center justify-center z-50 backdrop-blur confirm-dialog ">
  <div class="relative px-4 min-h-screen md:flex md:items-center md:justify-center">
    <div class=" opacity-25 w-full h-full absolute z-10 inset-0"></div>
    <div
      class="bg-white rounded-[14px] border border-gray-400 md:max-w-max md:mx-auto p-4 fixed inset-x-0 bottom-0 z-50 mb-4 mx-4 md:relative shadow-lg">
      <div class="md:flex items-center">
        <div
          class="rounded-full border border-gray-300 flex items-center justify-center w-20 h-16 flex-shrink-0 mx-auto">
          <i class="bx bx-error text-3xl">
            &#9888;
          </i>
        </div>
        <div class="mt-4 md:mt-0 md:ml-6 text-center md:text-left">
          <p class="font-bold text-[16px]">Error!</p>
          <p class="text-[16px] text-gray-700 mt-1">{{benficiaryCountMessage}}.
          </p>
        </div>
      </div>
      <div class="text-center md:text-right mt-4 md:flex md:justify-end gap-2">
        <button id="confirm-delete-btn"
          class="block w-full md:inline-block md:w-auto px-4 py-3 md:py-2 border border-red-500  rounded-full font-semibold text-[14px] md:ml-2 md:order-1"
          (click)="benficiaryconfirm()">
          {{lang.Ok}}
        </button>&nbsp;
        <!-- <button id="confirm-cancel-btn" class="block w-full md:inline-block md:w-auto px-4 py-3 md:py-2 border border-gray-400 rounded-full font-semibold text-[14px] mt-4 md:mt-0 md:order-2" (click)="benficiaryconfirm()">
                    Cancel
                    </button> -->
      </div>
    </div>
  </div>
</div>
}

@if(showSpouseSignature){
<div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">

  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

  <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
    <div class="flex sm:min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">

      <div
        class="relative transform overflow-hidden rounded-xl bg-white text-center mx-auto shadow-xl transition-all sm:my-8 sm:w-1/4 sm:max-w-7xl max-w-2xl">
        <div class="bg-white">
          <div class="">
            <div
              class="flex items-center justify-center font-bold text-2xl h-16 border border-b-indigo-700 bg-[#00297A] text-white">
              {{lang.Spouse_Signature}}
            </div>
            <div class="text-center">

              <div class="signature-container col-span-1 w-full" style="border:1px solid #ccc;border-radius: 4px;">



                <signature-pad [options]="signaturePadOptions" (touchmove)="onMouseMove($event)"
                  (mousemove)="onMouseMove($event)" (onEndEvent)="drawComplete()"
                  (onBeginEvent)="drawStart()"></signature-pad>
                @if(signaturecheckvalue1cc){
                <div style="
                left: 10px;
                color: red;
                text-decoration: underline;cursor:pointer">
                  <p (click)="clearSignature()">x {{lang.Clear}}</p>
                </div>
                }

              </div>
              @if(showIAFGerrorMessage){
              <span class="invalid text-red-500">{{lang.please_sign_the_signature}}</span>
              }



            </div>
          </div>
        </div>
        <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 border border-t-indigo-700">


          <button type="button"
            class="w-36 h-14 mb-1 rounded-full border-2 border-indigo-200 bg-indigo-200 p-0.5  hover:bg-white"
            (click)="saveIAFGsignature()">{{lang.Confirm}}

          </button>

          <!-- <button type="button" class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto">Deactivate</button>
                  <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Cancel</button> -->
        </div>
      </div>
    </div>
  </div>
</div>


}
@if(ConfirmModel){
<div class="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
  <div class="bg-white  rounded-xl shadow-lg  md:w-1/2 overflow-scroll">
    <div
      class="flex items-center justify-center font-bold text-2xl h-16 border border-b-indigo-700 bg-[#00297A] text-white">
      <h2 class="text-[14px] font-semibold">{{lang.Info}}</h2>
      <!-- <button (click)="onClose()" class="text-gray-500 hover:text-gray-800 flex items-end justify-end">&times;</button> -->
    </div>
    <p class="p-8"><strong>{{lang.Important_Limitation_applies_for_the_first_24_months_of_this_coverage}}:</strong></p>
    <ul class="p-8" style="list-style: disc !important">
      @if(criticalplancheck){
      <p class="font-bold">{{lang.Life_Insurance}}:</p>
      <li>{{lang.If_the_cause_of_death_is_non_accidental_no_benefit_will_be_paid_but_premiums_will_be_refunded_with_5_interest_compounded_annually_This_limitation_is_removed_if_the_insured_Employee_or_Spouse_subsequently_apply_and_are_approved_for_additional_life_coverage_requiring_evidence_of_insurability_Death_from_suicide_within_24_months_of_the_effective_date_of_an_insured_person_s_coverage_is_excluded}}</li>
      <li>{{lang.Death_from_suicide_within_24_months_of_the_effective_date_of_an_insured_person_s_coverage_is_excluded}}</li>
      }
      @if(criticalplancheck){
        <p class="font-bold">{{lang.Critical_Illness_Insurance}}:</p>
      <li>{{lang.Pre_existing_Condition_Exclusion_applies_for_the_first_24_months_of_this_coverage_Any_symptom_condition_disorder_illness_pre_disease_or_disease_or_any_mental_nervous_or_psychiatric_condition_or_disorder_for_which_any_one_of_medical_advice_treatment_service_prescribed_medication_diagnosis_or_consultation_including_consultation_to_investigate_and_or_diagnose_where_diagnosis_has_not_yet_been_made_was_received_by_the_insured_or_would_have_been_received_by_a_prudent_individual_within_the_24_months_immediately_preceding_the_effective_date}}</li>
      <li>{{lang.This_pre_existing_condition_exclusion_is_removed_if_the_insured_Employee_subsequently_applies_and_is_approved_for_additional_critical_illness_coverage_requiring_evidence_of_insurability}}</li>
      }
    </ul>
    <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 border border-t-indigo-700">


      <button type="button"
        class="w-36 h-14 mb-1 rounded-full border-2 border-indigo-200 bg-indigo-200 p-0.5  hover:bg-white"
        (click)="closeConfirmModel()">{{lang.Confirm}}

      </button>
    </div>
  </div>
</div>


}
