




import { CommonModule, ViewportScroller } from '@angular/common';
import { Component, ElementRef, HostListener, inject, ViewChild, AfterViewInit, Renderer2 } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
  ReactiveFormsModule,
  AbstractControl,
} from '@angular/forms';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
// import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { CustomDatePipe } from './../pipes/customdate.pipe';
// import { environment } from './../'
import {
  BsDatepickerConfig,
  BsDatepickerModule,
} from 'ngx-bootstrap/datepicker';
import { DecimalPipe, formatNumber } from '@angular/common';
import { SignupformService } from './../services/signupform.service';
import { CorporateService } from './../services/corporate.service'
import { Meta } from '@angular/platform-browser';
import { environment } from './../environments/environment'
import { PdfViewerModule } from 'ng2-pdf-viewer';
// import SignaturePad from "signature_pad";
import { SignaturePad } from 'angular2-signaturepad';
import Swal from 'sweetalert2';
import { PhoneNumberPipe } from './../pipes/phone-number.pipe'
import {
  floatWith2Decimals,
  groupBy,
  sumBy,
  adjustWith2DecimalsForUI,
} from "./../commonfunctions/commonFunction";
import { SharedService } from '../shared/shared.service';

@Component({
  selector: 'app-corporate-signup',
  templateUrl: './corporate-signup.component.html',
  styleUrl: './corporate-signup.component.scss',
  providers: [CustomDatePipe, DecimalPipe, HttpClient, PhoneNumberPipe],
})
export class CorporateSignupComponent implements AfterViewInit {
  myDateValue: Date | undefined;
  Object = Object;
  date: Date = new Date();
  bsConfig: Partial<BsDatepickerConfig>;
  // bsConfig: any;

  minDate: Date = new Date();
  maxDate: Date = new Date();
  planEnrollmentminDate: Date = new Date();
  planEnrollmentmaxDate: Date = new Date();

  childminDate: Date = new Date();
  childmaxDate: Date = new Date();
  addeditbenficiary = false
  benficiaryCount = false;
  showaddmorebenficiary = true;
  benficiaryCountMessage: any;
  beneficariesDetails: any;
  benficiary = false;
  editBenficiaryIndex: any;
  showBenificiaryForm = true;
  showbenificaryTable = false;
  addbenificaryData: any = []
  showSpouseSignature = false;
  showIAFGerrorMessage = false;
  benficiaryForm =new FormGroup(
    {
      customerType: new FormControl(''),
      // type: new FormControl(''),
      firstName: new FormControl(''),
      lastName: new FormControl(''),
      isMinor: new FormControl(''),
      dob: new FormControl(''),
      relationship: new FormControl(''),
      percentage: new FormControl(''),
      contingentFirstName: new FormControl(''),
      contingentLastName: new FormControl(''),
      trusteeFirstName: new FormControl(''),
      trusteeLastName: new FormControl(''),
    })


  iafggenderForm = new FormGroup(
    {
      memberinfo: new FormControl(''),
      gender: new FormControl(''),
      spouseinfo: new FormControl(''),
      spousegender: new FormControl(''),

    })
  userForm = new FormGroup(
    {
      employername: new FormControl(''),
      planEnrollmentDate: new FormControl(''),
      firstName: new FormControl(''),
      lastName: new FormControl(''),
      email: new FormControl(''),
      phone_number: new FormControl(''),
      street_address_line1: new FormControl(''),
      street_address_line2: new FormControl(''),
      apt: new FormControl(''),
      city: new FormControl(''),
      province: new FormControl(''),
      workingProvince: new FormControl(''),
      postal_code: new FormControl(''),
      date_of_birth: new FormControl(''),
      gender: new FormControl(''),
      date_of_hiring: new FormControl(''),
      company_name: new FormControl(''),
      job_title: new FormControl(''),
      working_20hours: new FormControl(''),
      hours_per_week: new FormControl(''),
      provincial_health_coverage: new FormControl(''),
      dateOfArrival: new FormControl(''),
      isEmployeeImigrant: new FormControl(''),
      applylandedstaus: new FormControl(''),
      countryOrigin: new FormControl(''),
      coverageRequiredOfEmployee: new FormControl(''),
      dependentdateOfArrival: new FormControl(''),
      coverageRequiredOfDependents: new FormControl(''),
      dependentcountryOrigin: new FormControl(''),
      dependentLandedImigrant: new FormControl(''),
      dependentapplylandedstaus: new FormControl(''),
      dependentMorethan3years: new FormControl(''),
      having_spouse: new FormControl(''),
      spousefirstname: new FormControl(''),
      spouselastname: new FormControl(''),
      spousegender: new FormControl(''),
      // spouseemail: ["", Validators.compose([Validators.required, emailValidator])],
      // spouseemail: new FormControl(''),
      spousedateofbirth: new FormControl(''),
      spousehealthcard: new FormControl(''),
      Spousenameofthecarrier: new FormControl(''),
      spousecob: new FormControl(''),

      having_dependent_children: new FormControl(''),
      no_of_children: new FormControl(''),
      // childrenDetails: this.formBuilder.array([this.initChildDetails()]),
      // childrenDetails:  new FormControl(''),

      termsandconditions: new FormControl(''),
      disclouseradvisor: new FormControl(''),
      recaptchaReactivePerInfo: new FormControl(''),
    },
    { updateOn: 'change' }
  );
  paymentForm = new FormGroup(
    {
      cutomerId: new FormControl(''),
      PublicApiKey: new FormControl(''),
      SuccessUri: new FormControl(''),
      FailUri: new FormControl(''),
      FirstName: new FormControl(''),
      LastName: new FormControl(''),
      paymentRadioCheck: new FormControl(''),
      email: new FormControl(''),
      CardNumber: new FormControl(''),
      ExpirationMonth: new FormControl(''),
      ExporationYear: new FormControl(''),
      cvv: new FormControl(''),

      // signature:["", Validators.required],
      nameonthecard: new FormControl(''),

      // aptcheck: ["", Validators.compose([apt_suitecheck])],
      aptcheck: new FormControl(''),

      streetaddress: new FormControl(''),
      streetaddressline2: new FormControl(''),
      city: new FormControl(''),
      province: new FormControl(''),
      postalcode: new FormControl(''),
      recaptchaReactive: new FormControl(''),
    },
    { updateOn: 'change' }
  );
  bankpayForm = new FormGroup(
    {
      banknumber: new FormControl(''),

      transitnumber: new FormControl(''),
      accountnumber: new FormControl(''),
      bankname: new FormControl(''),
      voidcheck: new FormControl(''),
      checkbankdetails: new FormControl(''),
      bankaptcheck: new FormControl(''),
      bankstreetaddress: new FormControl(''),
      bankstreetaddressline2: new FormControl(''),
      bankcity: new FormControl(''),
      bankprovince: new FormControl(''),
      bankpostalcode: new FormControl(''),
    },
    { updateOn: 'change' }
  );
  submitted = false;
  private http = inject(HttpClient);
  public languageTokensArray: any = [];
  public lang: any = {};
  public configprovinceres: any;
  public configpworkingrovinceres: any
  activeTab: any = 0;
  previousTab: any = -1;
  bsValue = new Date();
  invalidPostalCodeForProvince: boolean = false;
  provineList: any;
  provineListPayment: any;
  provineListcreditPayment: any
  plansData: any = [{ groups: [{ plans: [] }] }];
  configplanEnrollmentDateFull: any;
  childInfoAddDataArray: any = [];
  childTableEditRowIndex: number = 0;
  public currencySymbol: any;
  public singleSummaryTable: boolean = true;
  employercheck: any;
  tabs = [
    {
      title: 'Personal Information',
      content: 'Content for the first tab.',
      background: 'bg-gray-200',
    },
    {
      title: 'Plan Selection',
      content: 'Content for the second tab.',
      background: 'bg-gray-200',
    },
    {
      title: 'Enrollment Summary',
      content: 'Content for the second tab.',
      background: 'bg-gray-200',
    },
  ];

  public paymentUri: any;
  public paymentsuccessuri: any;
  public customerid: any;
  public publicapikey: any;
  public cardType: string = '';
  public invalidcreditcard: boolean = false;
  public recaptchaResponse: any;
  public fusbillinfocaptchavalue: any;
  childTableRowsLength: number = 0;
  childInfoModalAddAndUpdateButton: string = '';
  noOfChildrenChangedValue: any = 1;
  showModal = false;
  showDeleteModal = false;
  showModelDialog = false;
  ShowModelPopup = false;
  showPADModelDiaslog = false;
  modalMessage = 'Do you want to proceed?';
  deleteChildIndex: any;
  editChildInfo: any;
  editChildIndex: any;
  spouseplaceholdername: any;
  checkedradioValue: boolean = false;
  pdfData: any;
  formConfigResult: any;
  public disabledelement: any;
  planobjdata: any = {};
  elementcvValues:any;
  public planAmount: any;
  // planobjdata: { packageId: any; packageName: any; planproductname: any; groupid: any; groupName: any; id: any; name: any; planLevel: any; planLevelParent: any; fusebillPlanID: any; planFrequencyID: any; isBundle: any; coverage: any; planCoverage: any; bundledProducts: never[]; products: never[]; };
  productobj: any;
  public planssummarymain: any = [];
  public planssummarymainLife: any = [];
  public planssummaryopt: any = [];
  public plansskumain: any = [];
  public plandetailsobjvalue: any;
  public optionstitle: any = [];
  paymentForm2: any;
  finalenrollemntsummary: any = [];
  planSummaryMainResult: any = [];
  banknameres: any;
  bankerrormsg: any;
  public verifybutton: boolean = false;
  public verifybankdetails: boolean = false;
  bankverifyDetails: any;
  bankfile: any;
  isFileUploaded: boolean = false;
  bankinvalidPostalCode: boolean = false;
  pdfscrc: any = 'https://api.groupbenefitz.ca/app/server/en/terms-conditions-en.pdf'
  header: any;
  failedarray: any = [];
  signatureNeeded: any = true;
  creditCradInvalidProvince: any;
  showClearButton: boolean = false;
  checked: { [userId: string]: boolean } = {};
  missingInfo: any = [];
  missingInformation = false;
  planOptionsModel = false;
  optionmessage: any;
  planoptionsValues: any = [];
  link: any;
  generatePADaggrement: any;
  padAggrementModel = false;
  padAggrementFinalModel = false;
  public signaturestatus: boolean = true;
  public rejectPADModel: boolean = true;
  public agreestatus: boolean = false;
  signModelForBankPay = false;
  closePADModel = false;
  signaturecheckvalue1cc: boolean = false;
  public signaturemessagecc: boolean = false;
  public signaturecheckcc: boolean = true;
  paymentemail: any;
  public signaturecheckvalue1: boolean = false;
  public signaturemessagepad: boolean = false;
  public acceptaggrepad: boolean = true;
  public padaggrementerror: any;
  public signaturecheck: boolean = true;
  public signaturemessage: boolean = false;
  imagedisplay: any = false;
  showpaderrorMessage: boolean = false;
  showagree: boolean = true;
  ShowModelPopupTitle: any;
  ShowModelPopupMessage: any;
  invalidCardDetails: boolean = false;
  public showexpiryyearerror: boolean = false;
  public showexpirymontherror: boolean = false;
  public futureexpiredate: boolean = false;
  visitedTabs: Set<string> = new Set<string>();
  modifiedBlocksCoveredByCompany: any;
  mainarray: any = [];
  public CORP_PAYROLL_NOT_MANDATORY_PACKAGES: any = [2, 8, 9];
  public CORP_PAYROLL_NOT_MANDATORY_PLANLEVELS: any = [19, 20, 21, 23, 25, 26];
  public companypaidproductid: any = [];
  public disabledcompanypaidproductid: any = [];
  public removedisabledcompanypaidproductid: any = [];
  public adddisabledcompanypaidproductid: any = [];
  public isDisabledplan: boolean = false;
  isExecutiveCP: boolean = false;


  public coveredEmployeeShare: any = 0;
  public paidEmployeeShare: any = 0;
  public totalEmployeeShare: any = 0;
  public cartDisplayAmount: any = 0;
  public allPlansAmountTotal: any = 0;
  public allPlansTotal: any = 0;
  public allPlansTotaltax: any = 0;
  public allCPPlansAmountTotal: any = 0;
  public allCCPlansAmountTotal: any = 0;
  public allEPPlansAmountTotal: any = 0;
  public allCPTaxTotal: any = 0;
  public allCCTaxTotal: any = 0;
  public allEPTaxTotal: any = 0;
  public companyPaidTotal: any = 0;
  public companyCoveredTotal: any = 0;
  public paidEmployeedTotal: any = 0;
  public companyCoveredCompanyShareTotal: any = 0;
  public companyCoveredCompanySharePlanAmountTotal: any = 0;
  public companyCoveredCompanyShareTaxTotal: any = 0;
  public companyCoveredEmployeeShareTotal: any = 0;
  public companyCoveredEmployeeSharePlanAmountTotal: any = 0;
  public companyCoveredEmployeeShareTaxTotal: any = 0;
  public effectiveCompanyTotalContibution: any = 0;
  public effectiveEmployeeTotalContibution: any = 0;
  public effectiveCompanyPlansAmountTotal: any = 0;
  public effectiveCompanyTaxTotal: any = 0;
  public alloptinplans: any = [];
  public showPaymenthMethod: boolean = false;
  public showPaymenthMethod1: boolean = true;
  // public bankpay: boolean = false;
  public creditcardform: boolean = false;
  public bankpaymentmethod: any;
  public directpayCCplansarr: any = [];
  public payrollCCplansarr: any = [];
  public employeeDealingPlansarr: any = [];

  public companySelectedPlans: any = [];
  public companyCoveredPlans: any = [];
  public EmployeeSelectedPlans: any = [];
  public allSelectedPlans: any = [];
  public companyDealingPlans: any = [];

  payrollCCPlantaxTotal: any;
  empDirectpayPlanamountTotal: any;
  empDirectpayPlantaxTotal: any;
  payrollCCPlansAmountTotal: any;
  payrollCCPlangrandTotal: any;
  empDirectpayPlangrandTotal: any;
  DirectCCpayPlanamountTotal: any;
  DirectCCpayPlantaxTotal: any;
  DirectCCpayPlangrandTotal: any;
  selectedTab: string = "tab1";
  companydealingplansTotal: any;
  companydealingplanstaxTotal: any;
  companydealingplansgrandTotal: any;
  deltaCompanyTax: any;
  public payrolldeduction: any;
  usePADPaymentMethod: any;
  useCreditCardPaymentMethod: any;
  requireSpouseEmail: any;
  requireDentalHealthCoverage: any;
  warnRequiredDependantMedicalExam: any;
  brokerConfig: any;
  configPlanEnrollmentDate: any;
  currency: any;
  brokerType: any;
  working_provincelistid: any;
  public applicantPlanEnrollmentDate: any = "";
  signaturecheckCC: boolean = false;
  signaturecheckPAD: boolean = false;
  voluntryplansCheck: boolean = false;
  upgradeplansCheck: boolean = false;
  companyplansCheck: boolean = true;
  imageDisplayShow = false;
  public normalview: boolean = false;
  public pdfview: boolean = false;
  companyName: any;
  completeapplicantinfo: any;
  applicantSpousename: any;
  applicantChildrenname: any;
  applicantfirstname: any;
  applicantFirstName: any;
  applicantLastName: any;
  applicantEmail: any;
  applicantPhoneNumber: any;
  applicantDateofhire: any;
  paymentFirstName: any;
  paymentLastName: any;
  public formData: any;
  public allFormData: any = {};
  public lastUpdatedType: any = "";
  smokerCheckValue: any;
  acceptanceCoverage: any;
  public signUpData: any = {};
  iafgplansCheck: any = [];
  corporateAutoPlanSelection: any;
  greenValue: any;
  yellowValue: any; names: any[] = [
    { label: 'Guaranteed', position: 10 },    // Display "Start" at 0%
    { label: 'Pending', position: 0 }, // Display "Quarter" at 25%

  ];
  minValue = 0;
  rangeValues: any = [];
  uploadfile: any;
  selectedLanguage: any;
  languageData: any;
  constructor(
    private Shared:SharedService,
    private _viewportScroller: ViewportScroller,
    private renderer: Renderer2,
    private _CorporateService: CorporateService,
    private _PhoneNumberPipe: PhoneNumberPipe,
    private formBuilder: FormBuilder,
    private _CustomDatePipe: CustomDatePipe,
    public elementRef: ElementRef,
    private _decimalPipe: DecimalPipe,
    private meta: Meta,
    public router: Router, private route: ActivatedRoute
  ) {
    this.minDate = new Date();
    this.minDate.setFullYear(1950);
    this.minDate.setMonth(0); // January
    this.minDate.setDate(1); // 1st

    // Set maxDate to December 31st, 2030
    this.maxDate = new Date();
    this.maxDate.setFullYear(2008);
    this.maxDate.setMonth(new Date().getMonth()); // current month
    this.maxDate.setDate(new Date().getDate()); // current date

    this.childminDate = new Date();
    this.childminDate.setFullYear(2006);
    this.childminDate.setMonth(0); // January
    this.childminDate.setDate(1); // 1st

    // Set maxDate to December 31st, 2030
    this.childmaxDate = new Date();
    this.childmaxDate.setFullYear(2024);
    this.childmaxDate.setMonth(new Date().getMonth()); // current month
    this.childmaxDate.setDate(new Date().getDate()); // current date


    this.planEnrollmentminDate= new Date(new Date().getFullYear(), new Date().getMonth() , 1);   //+1
    this.planEnrollmentmaxDate= new Date(this.planEnrollmentminDate.getFullYear(), this.planEnrollmentminDate.getMonth() + 4, 0);
    this.bsConfig = {
      containerClass: 'theme-default',
      dateInputFormat: 'MM-DD-YYYY',
      minDate: this.minDate,
      maxDate: this.maxDate,
    };
    this.benficiaryForm =this.formBuilder.group(
      {
        customerType: ['', Validators.required],
        // type: ['', Validators.required],
        firstName: [
          '',
          Validators.compose([Validators.required, Validators.minLength(2)]),
        ],
        lastName: [
          '',
          Validators.compose([Validators.required, Validators.minLength(2)]),
        ],
        isMinor:  [''],
        dob: [''],
        relationship: ['', Validators.required],
        contingentFirstName: ['', Validators.required],
        contingentLastName: ['', Validators.required],
        percentage: ['', Validators.required],
        trusteeFirstName: [''],
        trusteeLastName: [''],

      })

    this.iafggenderForm = this.formBuilder.group(
      {

        memberinfo: ['', Validators.required],
        gender: [''],
        spouseinfo: [''],
        spousegender: ['']
      })
    this.userForm = this.formBuilder.group(
      {
        employername: [''],
        planEnrollmentDate: ['', Validators.required],
        firstName: [
          '',
          Validators.compose([Validators.required, Validators.minLength(2)]),
        ],
        lastName: [
          '',
          Validators.compose([Validators.required, Validators.minLength(2)]),
        ],
        // email: ["", Validators.compose([Validators.required, emailValidator])],
        email: [
          '',
          [
            Validators.required,
            Validators.pattern(
              '^(?!\\s)[_A-Za-z0-9._%+-]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$'
            ),
          ],
        ],
        // phoneNumber: ['', [Validators.required, Validators.pattern(/^\(\d{3}\) \d{3}-\d{4}$/)]],

        // phone_number: [
        //   '',
        //   Validators.compose([
        //     Validators.required,
        //     Validators.pattern(/^\(\d{3}\) \d{3}-\d{4}$/),
        //   ]),
        // ],
        phone_number: ["",
          Validators.compose([Validators.required, Validators.minLength(17), Validators.maxLength(17), Validators.pattern(/^[^A-Za-z@#$%&*{}'";:<>?]+$/)]),
        ],
        // matrialstatus:["", Validators.required],

        // apt_suite: ["", Validators.compose([apt_suitecheck])],
        apt: [''],
        street_address_line1: ['', Validators.required],
        street_address_line2: [''],
        city: ['', Validators.required],
        province: ['', Validators.required],
        workingProvince: ['', Validators.required],
        postal_code: ['', Validators.compose([Validators.required])],
        // postalcode: ["", Validators.required],
        date_of_birth: ['', Validators.required],
        gender: ['', Validators.required],
        date_of_hiring: ['', Validators.required],
        company_name: ['', Validators.required],
        job_title: ['', Validators.required],
        working_20hours: [
          '',
          Validators.compose([Validators.required, hourselectioncondition]),
        ],
        hours_per_week: [
          '40',
          Validators.compose([Validators.required, validatehoursperweek]),
        ],
        provincial_health_coverage: [
          '',
          Validators.compose([Validators.required]),
        ],

        dateOfArrival: [''],
        isEmployeeImigrant: [''],
        applylandedstaus: [''],
        countryOrigin: [''],
        coverageRequiredOfEmployee: [''],

        dependentdateOfArrival: [''],
        coverageRequiredOfDependents: [''],
        dependentcountryOrigin: [''],
        dependentLandedImigrant: [''],
        dependentapplylandedstaus: [''],
        dependentMorethan3years: [''],

        having_spouse: ['', Validators.required],
        spousefirstname: [''],
        spouselastname: [''],
        spousegender: [''],
        // spouseemail: ["", Validators.compose([Validators.required, emailValidator])],
        // spouseemail: [
        //   '',
        //   [
        //     Validators.required,
        //     // Validators.pattern(this.commonPatternsService.emailPattern),
        //     Validators.pattern(
        //       '^(?!\\s)[_A-Za-z0-9._%+-]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$'
        //     ),
        //     //pattern(/^(?!\\s)*[\sA-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[a-z]{2,3}(?!\\s)*$/i),
        //     //          pattern('^(?!\\s)[_A-Za-z0-9-]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$')])],
        //   ],
        // ],
        spousedateofbirth: [''],
        having_dependent_children: ['', Validators.required],
        no_of_children: [''],
        // childrenDetails: this.formBuilder.array([this.initChildDetails()]),

        spousehealthcard: ['', Validators.required],
        Spousenameofthecarrier: [''],
        spousecob: [''],

        termsandconditions: ['', Validators.requiredTrue],
        disclouseradvisor: ['', Validators.requiredTrue],
        recaptchaReactivePerInfo: [''],
        // recaptchaReactivePerInfo: [""],
        // validatorcaptcha:["", Validators.requiredTrue],
      },
      { updateOn: 'change' }
    );

    this.paymentForm = this.formBuilder.group({
      cutomerId: [''],
      PublicApiKey: [''],
      SuccessUri: [''],
      FailUri: [''],
      FirstName: ['', Validators.required],
      LastName: ['', Validators.required],
      email: [''],
      paymentRadioCheck: ['', Validators.required],
      CardNumber: [
        '',
        Validators.compose([Validators.required, creditcardvalidation]),
      ],
      ExpirationMonth: ['', Validators.required],
      ExporationYear: ['', Validators.required],
      cvv: [
        '',
        Validators.compose([Validators.required, Validators.minLength(3)]),
      ],

      // signature:["", Validators.required],
      nameonthecard: [''],

      // aptcheck: ["", Validators.compose([apt_suitecheck])],
      aptcheck: [''],

      streetaddress: ['', Validators.required],
      streetaddressline2: [''],
      city: ['', Validators.required],
      province: ['', Validators.required],
      postalcode: ['', Validators.compose([Validators.required])],
      recaptchaReactive: ['', Validators.required],
    });
    this.bankpayForm = this.formBuilder.group({
      banknumber: [
        '',
        Validators.compose([Validators.required, checkbanknumber]),
      ],

      transitnumber: [
        '',
        Validators.compose([Validators.required, checktransitnumber]),
      ],
      accountnumber: [
        '',
        Validators.compose([Validators.required, checkaccountnumber]),
      ],
      bankname: ['', Validators.required],
      voidcheck: ['', Validators.compose([Validators.required])],
      checkbankdetails: [''],
      // bankaptcheck: ["", Validators.compose([apt_suitecheck])],
      bankaptcheck: [''],
      bankstreetaddress: ['', Validators.required],
      bankstreetaddressline2: [''],
      bankcity: ['', Validators.required],
      bankprovince: ['', Validators.required],
      bankpostalcode: ['', Validators.compose([Validators.required])],
    });
  }

  @ViewChild(SignaturePad) signaturePad!: SignaturePad;
  @ViewChild('signaturePad1') signaturePad1!: SignaturePad;
  @ViewChild('signaturePad2') signaturePad2!: SignaturePad;
  @ViewChild('optionNumberInput') optionNumberInput!: ElementRef;
  @ViewChild('optionPlanDetailsObj') optionPlanDetailsObj!: ElementRef;
  // @ViewChild('scrollContainerPlans') scrollContainerPlans!: ElementRef;

  signaturePadOptions: Object = {


    'canvasWidth': 300,
    'canvasHeight': 150
  };
  public cartcheckvalue: boolean = true;
  public plansnexttab: boolean = true;

  // @ViewChild('sigPad')
  // sigPad!: ElementRef<HTMLCanvasElement>;
  // @ViewChild('sigPad') sigPad;
  sigPadElement: any;
  context: any;
  isDrawing = false;
  img: any;
  // @ViewChild('sigPad', { static: false }) sigPad!: ElementRef<HTMLCanvasElement>;

  @ViewChild('canvasElement', { static: false }) canvas!: ElementRef<HTMLCanvasElement>;
  @ViewChild('canvasElementpad', { static: false }) canvaspad!: ElementRef<HTMLCanvasElement>;
  @ViewChild('scrollContainer') scrollContainer!: ElementRef;
  @ViewChild('scrollContainerPlans') scrollContainerPlans!: ElementRef;
  @ViewChild('planCheckdetails') planCheckdetails!: ElementRef;

  private ctx: CanvasRenderingContext2D | null = null;
  private ctxpad: CanvasRenderingContext2D | null = null;
  private resizeObserver: ResizeObserver | null = null;
  private drawing = false;
  sigPadElementpad: any;
  contextpad: any;
  isDrawingpad = false;

  @ViewChild('sigPad') sigPad: any;

  public specialplans: any;
  public specialpackages: any;
  public packagesInBlocks: any;
  public basepackages: any;
  BenficairyModalAddAndUpdateButton: string = '';
  beneficiaryError = false;
  beneficiaryErrorMessage = ''
  isEditable = true;
  enableEditIndex = null;
  iafginfo = false;
  ConfirmModel =false
  showyellowbar =false;
  criticalplancheck =false
  termLifeplancheck =false
  ngOnInit(): void {

this.getLanguageTokens()
    // setTimeout(() => this.initializeCanvas(), 20000);
    // setTimeout(() => this.initializeCanvaspad(), 40000);
    sessionStorage.setItem("timeStamp", new Date().getTime().toString())

    this.getIPAddress()
    this.postAnalyticsData()
    // this.getUserDetails()

    setInterval(() => {

      this.postAnalyticsData()
    }, 30000);



    sessionStorage.setItem("lanToken", 'en')

    sessionStorage.removeItem("fileurl")
    sessionStorage.removeItem("filename")
    sessionStorage.removeItem("signature")
    // this.paymentUri = environment.app.globalgrp + "#/paymentResponse";

    // this.paymentsuccessuri = environment.app.globalgrp + "#/paymentResponse";

    // this.customerid = sessionStorage.getItem("customerId");
    // this.publicapikey = sessionStorage.getItem("publicApiKey");

    // this.sigPadElement = this.sigPad.nativeElement;

    // this.context = this.sigPadElement.getContext('2d');
    // this.context.strokeStyle = 'black';

    if (sessionStorage.getItem("formlink")) {
      this.link = sessionStorage.getItem("formlink")
    } else {
      this.link = sessionStorage.getItem("formlink")

    }

    if (sessionStorage.getItem("paymentMethod")) {
    } else {
      sessionStorage.setItem("paymentMethod", "CC");
    }

    //localgrp
    //globalgrp
    this.fusbillinfocaptchavalue = '6LfVtGwUAAAAALHn9Ycaig9801f6lrPmouzuKF11';
    this.paymentUri = environment.app.globalgrp + "#/payment/corporate";

    this.paymentsuccessuri = environment.app.globalgrp + "#/payment/corporate";

    this.customerid = sessionStorage.getItem("customerId");
    this.publicapikey = sessionStorage.getItem("publicApiKey");

    let lang = 'en';
    let form = 'SHZMOxgC0301';

    // console.log(this.route.snapshot.params['formLink'])
    if (this.route.snapshot.params['formLink'] != undefined) {
      this._CorporateService.getCorporateData(this.route.snapshot.params['formLink'], this.route.snapshot.params['employeeLink']).subscribe({
        next: (result: any) => {
          // console.log(result);

          if (result.status == "200") {
            // if (confirm('Are you sure to delete?')) {
            //   alert("!")
            // }
            // if (result.data.waddellForm) {
            //   window.open(environment.app.waddellink, "_self")
            // }

            if (result.data.waddellForm) {

              window.open(environment.app.waddellink + this.route.snapshot.params['formLink'], "_self")
            }
            if (result.data.iafgOnly) {

              window.open(environment.app.iafglink + "/corporate/" + this.route.snapshot.params['formLink'], "_self")
            }
            this.corporateAutoPlanSelection =
              result.data.form.broker.corporateAutoPlanSelection;
            this.beneficariesDetails = result.data.validations.beneficiaries

            sessionStorage.setItem("formlink", this.route.snapshot.params['formLink']);
            this.employercheck = result.data.form.requireEmployerName == true ? true : false;

            if (this.employercheck == true) {
              this.userForm
                .get('employername')
                ?.setValidators([Validators.required]);
              this.userForm.get('employername')?.updateValueAndValidity();

            }

            else {
              this.userForm.get('employername')?.clearValidators();
              this.userForm.get('employername')?.updateValueAndValidity();
            }
            this.formConfigResult = result.data;

            sessionStorage.setItem("portal", "corporate");
            sessionStorage.setItem("formlink", this.route.snapshot.params['formLink']);
            sessionStorage.setItem("employeelink", this.route.snapshot.params['employeeLink']);

            this.applicantFirstName = result.data.employee.firstName || "";
            this.applicantLastName = result.data.employee.lastName || "";
            this.applicantEmail = result.data.employee.email || "";
            this.applicantPhoneNumber = result.data.employee.phone || "";
            this.companyName = result.data.form.broker.name || "";
            this.applicantDateofhire = ""
            this.deltaCompanyTax = result.data.deltaCompanyTax;
            (this.payrolldeduction = [
              {
                display: "Employee's payroll deduction",
                label: "YES",
              },
              {
                display: "Employee direct pay through CC/PAD",
                label: "NO",
              },
            ]),
              this.configprovinceres = result.data.provinces.sort((a: any, b: any) =>
                a.shortName > b.shortName ? 1 : -1
              );
            if (result.data.working_provinces) {
              this.configpworkingrovinceres = result.data.working_provinces.sort(
                (a: any, b: any) => (a.shortName > b.shortName ? 1 : -1)
              );
            } else {
              this.configpworkingrovinceres = result.data.provinces.sort((a: any, b: any) =>
                a.shortName > b.shortName ? 1 : -1
              );
            }
            this.applicantPlanEnrollmentDate = this._CustomDatePipe.transform(result.data.form.broker.policyStartDate) || ""

            this.languageTokensArray = result.data.language_details.languageTokens;
            this.configplanEnrollmentDateFull =
              result.data.validations.customer.planEnrollmentDatesFullMonth;
            setTimeout(() => {
              this.pdfData = this.formConfigResult.links.termsConditions;

            }, 10000);
            // this.lang = {};

            // for (let token of this.languageTokensArray) {
            //   this.lang[token.key] = token.value;
            // }
            sessionStorage.setItem(
              "brokerlogo",
              environment.app.logourl + this.formConfigResult.logo
            );
            sessionStorage.setItem(
              "brokerlink",
              // environment.app.grp + result.data.form.broker.link
              environment.app.grp + result.data.link

            );
            sessionStorage.setItem(
              "formName",
              // environment.app.grp + result.data.form.broker.link
              result.data.form.name

            );
            this.usePADPaymentMethod = result.data.form.usePadPaymentMethod;

            this.useCreditCardPaymentMethod =
              result.data.form.useCreditCardPaymentMethod;

            this.requireSpouseEmail = result.data.form.requireSpouseEmail;

            this.requireDentalHealthCoverage =
              result.data.form.requireDentalHealthCoverage;

            this.warnRequiredDependantMedicalExam =
              result.data.form.warnRequiredDependantMedicalExam;

            sessionStorage.setItem(
              "provincelist",
              JSON.stringify(result.data.broker_licensed_provinces)
            );
            this.brokerType = result.data.form.formType;

            sessionStorage.setItem("brokerType", this.brokerType);





            // this._sanitizer.bypassSecurityTrustHtml(termsApply)

            if (result.data.form !== null) {
              // this.brokerConfig = result.data.form.broker.logo || "";

              if (result.data.logo) {
                if (result.data.logo.includes("https://")) {
                  this.brokerConfig = null;
                } else {
                  this.brokerConfig =
                    environment.app.logourl + result.data.logo;
                }
              } else {
                this.brokerConfig = null;
              }

              //this.consoleMessage(this.brokerConfig)

              sessionStorage.setItem("brokerlogo", this.brokerConfig);
              sessionStorage.setItem(
                "payrollDeduction",
                result.data.form.broker.payrollDeduction
              );
              sessionStorage.setItem(
                "brokerlink",
                // environment.app.grp + result.data.form.broker.link
                environment.app.grp + result.data.link
              );

              let parentlogo: any = null;
              if (
                result.data.parentLogo &&
                result.data.parentLogo != result.data.logo
              ) {
                parentlogo = environment.app.logourl + result.data.parentLogo;
              }
              sessionStorage.setItem("parentBrokerLogo", parentlogo);
            } else {
              this.router.navigate(["error"]);
            }

            this.configPlanEnrollmentDate =
              result.data.validations.customer.planEnrollmentDatesFullMonth;
            sessionStorage.setItem(
              "countrydetails",
              JSON.stringify(result.data.countries)
            );

            result.data.countries.forEach((element: any) => {
              this.currency = element.currency;
              this.currencySymbol = element.currencySymbol;
            });

            sessionStorage.setItem(
              "provincelist",
              JSON.stringify(result.data.broker_licensed_provinces)
            );
            sessionStorage.setItem(
              "countrydetails",
              JSON.stringify(result.data.countries)
            );
            this.meta.updateTag(
              { name: 'keywords', content: this.formConfigResult.form.keywords },
              'name=keywords'
            );
            this.meta.updateTag(
              {
                name: 'description',
                content: this.formConfigResult.form.description,
              },
              'name=description'
            );

            // this.meta.updateTag({
            //   property: "og:url",
            //   content: environment.app.globalgrp + this.router.url,
            // });
            this.meta.updateTag({
              property: 'og:title',
              content: this.formConfigResult.form.broker.name,
            });
            this.meta.updateTag({ property: 'og:type', content: '' });
            this.meta.updateTag({
              property: 'og:image',
              content: environment.app.grp + this.formConfigResult.logo,
            });
            this.meta.updateTag({
              property: 'og:description',
              content: this.formConfigResult.form.broker.description,
            });

            if (
              !this.formConfigResult.form.broker.discoverable ||
              this.formConfigResult.form.broker.discoverable == 0
            ) {
              this.meta.addTag({ name: 'robots', content: 'noindex' });
            }
          }
          else {
            this.ShowModelPopup = true
            this.ShowModelPopupMessage = result.message ? result.message : result.error
            this.ShowModelPopupTitle = this.lang.Info

          }


        },
        error: (error) => {
          if ([0, 500, 501, 502, 503].includes(error.status)) {


            this.ShowModelPopup = true
            this.ShowModelPopupMessage = this.lang.Sorry_the_server_is_currently_undergoing_maintenance_Please_try_again_later_We_apologize_for_any_inconvenience;
            this.ShowModelPopupTitle = this.lang.Info
          }
          else {
            this.ShowModelPopup = true
            this.ShowModelPopupMessage = error.error.message
              ? error.error.message
              : error.error.error,
              this.ShowModelPopupTitle = this.lang.Info
            // Swal.fire({
            //   title: this.lang.Info,
            //   html: error.error.message
            //     ? error.error.message
            //     : error.error.error,

            //   showCancelButton: false,
            //   width: "30%",
            //   confirmButtonText: "Ok",
            //   // customClass: {
            //   //   header: 'swal-header-orange',
            //   //   popup: 'custom-swal-dialog',
            //   //   confirmButton: 'custom-ok-button-class',
            //   // }
            // }).then((result) => {
            //   if (result.value) {
            //     this.router.navigate(["error"]);
            //     if (
            //       error.error.message.includes("incomplete") ||
            //       error.error.message.includes(
            //         "The email address already exists"
            //       )
            //     ) {
            //       let formLink = sessionStorage.getItem("formlink");
            //       sessionStorage.clear();
            //       this.router.navigate([formLink]);
            //       window.location.reload();
            //     }
            //   } else {
            //   }
            // });
          }
        },
      });
    } else {
      this.router.navigate(["error"]);
    }
    // this.demofill()
  }

  public getLanguageTokens(){
    let body= {
      "project":"SignupForm",
      "url":"Corporate"
    }
    this.http
    .post('https://languageengineapi.aitestpro.com/string/project-url-strings',body, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .subscribe(
      (response: any) => {
        console.log(response)
        this.lang = {};
        this.languageData = response.strings
        this.selectedLanguage = sessionStorage.getItem("language")
        for (let token of response.strings) {
          this.lang[token.key] = token[this.selectedLanguage];
        }

        this.tabs = [
          {
            title: this.lang.Personal_Information,
            content: 'Content for the first tab.',
            background: 'bg-gray-200',
          },
          {
            title: this.lang.Plan_Selection,
            content: 'Content for the second tab.',
            background: 'bg-gray-200',
          },
          {
            title: this.lang.Enrollment_Summary,
            content: 'Content for the second tab.',
            background: 'bg-gray-200',
          },
        ];

      })


  }
  public demofill() {
    // ////this.consoleMessage(this.genRandonString(3));
    this.userForm.controls["planEnrollmentDate"].setValue("12-01-2022");
    this.userForm.controls["firstName"].setValue("JohnDemo");
    this.userForm.controls["lastName"].setValue("Test");
    this.userForm.controls["email"].setValue(
      "johndemoaitp" + this.genRandonString(3) + "@aitestpro.com"
    );
    this.userForm.controls["phone_number"].setValue("+1 (989) 898-9898");
    this.userForm.controls["apt"].setValue("A");
    this.userForm.controls["street_address_line1"].setValue("2 Seyton Dr");
    this.userForm.controls["street_address_line2"].setValue("");
    this.userForm.controls["city"].setValue("Nepean");
    this.userForm.controls["province"].setValue("ON");
    this.userForm.controls["postal_code"].setValue("K2K 3E7");
    this.userForm.controls["date_of_birth"].setValue("10-01-1956");
    this.userForm.controls["gender"].setValue("Male");
    this.userForm.controls["date_of_hiring"].setValue("06-09-2024");
    this.userForm.controls["date_of_hiring"].setValue("06-09-2024");
    this.userForm.controls["company_name"].setValue("Ideabytes");
    this.userForm.controls["job_title"].setValue("Employee");
    this.userForm.controls["working_20hours"].setValue("true");
    this.userForm.controls["provincial_health_coverage"].setValue("true");

    this.userForm.controls["having_spouse"].setValue("false");

    this.userForm.controls["having_dependent_children"].setValue("false");
    this.userForm.controls["termsandconditions"].setValue("true");
    this.userForm.controls["disclouseradvisor"].setValue("true");
    // this.userForm.controls['recaptchaReactivePerInfo'].setValue(true)

    //     termsandconditions: ["", Validators.requiredTrue],
    //     disclouseradvisor: ["", Validators.requiredTrue],
    //     recaptchaReactivePerInfo: ["", Validators.required],

    // this.userForm.setValue("planenrollmentdate")
    // this.userForm = fb.group(
    //   {
    //     planenrollmentdate: ["", Validators.required],
  }

  public genRandonString(length: any) {
    var chars = "abcdefghijklmnopqrstuvwxyz0123456789";
    var charLength = chars.length;
    var result = "";
    for (var i = 0; i < length; i++) {
      result += chars.charAt(Math.floor(Math.random() * charLength));
    }
    return result;
  }

  ngAfterViewInit(): void {

    // setTimeout(() => {
    //   this.sigPadElement = this.sigPad.nativeElement;

    //   this.context = this.sigPadElement.getContext('2d');
    // }, 10000);

    // setTimeout(() => this.initializeCanvas(), 10000);
    // setTimeout(() => this.initializeCanvaspad(), 40000);

    // this.initializeCanvas();

  }
  setupResizeObserver(): void {
    this.resizeObserver = new ResizeObserver(() => this.resizeCanvas());
    this.resizeObserver.observe(this.canvas.nativeElement);
  }
  postAnalyticsData() {



    var pageName = sessionStorage.getItem("pageName")

    if (sessionStorage.getItem("ipaddress")) {

      fetch(environment.app.grp + "api/common/analytics", {

        // Adding method type
        method: "POST",

        // Adding body or contents to send
        body: JSON.stringify({
          "ipAddress": sessionStorage.getItem("ipaddress"),
          "timestamp": sessionStorage.getItem("timeStamp"),
          "pageName": pageName ? pageName : "PersonalInfo",
          "gbReferer": window.document.referrer || "",
          "pageUrl": window.location.href
        }),

        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      })
    }


  }
  getIPAddress() {
    fetch('https://jsonip.com', { mode: 'cors' })
      .then((resp) => resp.json())
      .then((ip) => {
        // //this.consoleMessage(ip)
        sessionStorage.setItem("ipaddress", ip.ip);

      });


  }

  initializeCanvas(): void {
    if (this.canvas) {
      const canvasElement = this.canvas.nativeElement;
      this.ctx = canvasElement.getContext('2d');
      // this.ctx.strokeStyle = 'red';
      if (this.ctx) {
        // console.log('Canvas context initialized successfully');
        this.resizeCanvas();
        this.setupResizeObserver();
      } else {
        // console.error('Failed to get 2D context');
      }
    } else {
      // console.error('Canvas element is not available');
    }




  }


  initializeCanvaspad(): void {
    if (this.canvaspad) {
      const canvasElementpad = this.canvaspad.nativeElement;
      this.ctxpad = canvasElementpad.getContext('2d');
      // this.ctxpad.strokeStyle = 'red';
      if (this.ctxpad) {
        // console.log('Canvas context initialized successfully');
        this.resizeCanvaspad();
      } else {
        // console.error('Failed to get 2D context');
      }
    } else {
      // console.error('Canvas element is not available');
    }
  }
  resizeCanvas(): void {
    if (this.canvas && this.ctx) {
      const canvasElement = this.canvas.nativeElement;
      canvasElement.width = canvasElement.offsetWidth;
      canvasElement.height = canvasElement.offsetHeight;
      // console.log('Canvas resized:', canvasElement.width, canvasElement.height);
    } else {
      // console.error('Canvas element or context is not available');
    }
  }
  resizeCanvaspad(): void {
    if (this.canvaspad && this.ctxpad) {
      const canvasElementpad = this.canvaspad.nativeElement;
      canvasElementpad.width = canvasElementpad.offsetWidth;
      canvasElementpad.height = canvasElementpad.offsetHeight;
      // console.log('Canvas resized:', canvasElementpad.width, canvasElementpad.height);
    } else {
      // console.error('Canvas element or context is not available');
    }
  }
  startDrawing(event: MouseEvent): void {
    if (this.ctx) {
      this.drawing = true;
      // this.ctx.beginPath();
      // this.ctx.moveTo(event.offsetX, event.offsetY);
      const coords = this.relativeCoords(event);
      this.ctx.moveTo(coords.x, coords.y);
      // console.log('Drawing started at:', event.offsetX, event.offsetY);
    } else {
      // console.error('Context not initialized');
    }

    this.isDrawing = true;

  }

  draw(event: MouseEvent): void {
    if (this.drawing && this.ctx) {
      const coords = this.relativeCoords(event);

      this.ctx.lineTo(coords.x, coords.y);
      this.ctx.stroke();

      // console.log('Drawing at:', event.offsetX, event.offsetY);
    }


  }

  stopDrawing(): void {
    if (this.drawing) {
      this.drawing = false;
      // console.log('Drawing stopped');
    }
  }



  clearCreditCardSignature() {
    const canvasElement = this.canvas.nativeElement;
    // this.ctx.clearRect(0, 0, canvasElement.width, canvasElement.height);
    // this.ctx.beginPath();
    this.signaturecheckvalue1cc = false;
    sessionStorage.removeItem("signature")
    this.signaturemessagecc = true
    this.signaturecheckcc = true
  }

  clearPadSignature() {
    // const canvasElement = this.canvaspad.nativeElement;
    // this.ctxpad.clearRect(0, 0, canvasElement.width, canvasElement.height);
    // this.ctxpad.beginPath();
    // this.signaturecheckvalue1 = false;
    // // this.signaturecheckvalue1 = false;
    // this.signaturemessagepad =true
    // sessionStorage.removeItem("signature")
  }
  // clear(): void {
  //   const canvasElement = this.canvas.nativeElement;
  //   this.ctx.clearRect(0, 0, canvasElement.width, canvasElement.height);
  // }

  save(): void {
    const canvasElement = this.canvas.nativeElement;
    const dataURL = canvasElement.toDataURL('image/png');
    // console.log(dataURL); // Use this data URL to save the image or send it to a server
  }


  drawComplete() {
    // will be notified of szimek/signature_pad's onEnd event
    // console.log(this.signaturePad.toDataURL());
  }
  drawCompleteCC() {
    // will be notified of szimek/signature_pad's onEnd event
    // console.log(this.signaturePad1.toDataURL());
  }
  drawCompletePad() {
    // will be notified of szimek/signature_pad's onEnd event
    // console.log(this.signaturePad2.toDataURL());
  }
  drawStart() {
    // will be notified of szimek/signature_pad's onBegin event
    // console.log('begin drawing');
  }
  drawStartCC() {
    // will be notified of szimek/signature_pad's onBegin event
    // console.log('begin drawing');
  }
  drawStartpad() {
    // will be notified of szimek/signature_pad's onBegin event
    // console.log('begin drawing');
  }
  clearSignature() {
    this.signaturePad.clear();
    this.signaturecheckvalue1cc = false;
  }
  clearSignatureCC() {
    this.signaturePad1.clear();
    this.signaturecheckCC = false;
  }

  clearSignaturePAD() {
    this.signaturePad2.clear();
    this.signaturecheckPAD = false;
  }
  saveSignature() {
    const base64Data = this.signaturePad.toDataURL();
    // console.log(base64Data);
    // You can send the base64Data to your server here
  }
  get f(): { [key: string]: AbstractControl } {
    return this.userForm.controls;
  }
  get b(): { [key: string]: AbstractControl } {
    return this.benficiaryForm.controls;
  }

  get IA(): { [key: string]: AbstractControl } {
    return this.iafggenderForm.controls;
  }
  get payment(): { [key: string]: AbstractControl } {
    return this.paymentForm.controls;
  }
  get bankpay(): { [key: string]: AbstractControl } {
    return this.bankpayForm.controls;
  }

  setActiveTab(index: number) {
    this.previousTab = this.activeTab;
    this.activeTab = index;
    this.visitedTabs.add(this.previousTab);
  }
  @HostListener('document:mouseup', ['$event'])
  onMouseUp(e: any) {
    this.drawing = false;
  }


  // onMouseDown(e:any) {

  //   if (this.ctx) {
  //   this.drawing = true;
  //   const coords = this.relativeCoords(e);
  //   this.ctx.moveTo(coords.x, coords.y);
  //   }

  //   //this.consoleMessage("down")
  // }

  // onMouseMove(e:any) {
  //   if (this.drawing && this.ctx) {

  //     const coords = this.relativeCoords(e);
  //     this.ctx.lineTo(coords.x, coords.y);
  //     this.ctx.stroke();
  //     this.signaturecheckvalue1cc = true;
  //     this.signaturemessagecc = false
  //     this.signaturecheckcc = false;
  //     //this.consoleMessage("up")


  // }


  // }

  onMouseMove(e: any) {

    this.signaturecheckvalue1cc = true;
    this.signaturemessagecc = false


    // if (this.isDrawing) {
    //   const coords = this.relativeCoords(e);
    //   this.context.lineTo(coords.x, coords.y);
    //   this.context.stroke();
    //   //this.consoleMessage("up")
    //   this.signaturecheckvalue1cc = true;
    //   this.signaturemessagecc = false
    //   this.signaturecheckcc = false;
    // }
  }
  onMouseMoveCredit(e: any) {

    this.signaturecheckCC = true;
    this.signaturemessagecc = false;

  }
  onMouseMovePad(e: any) {

    this.signaturecheckPAD = true;

  }
  onMouseDown(e: any) {
    this.isDrawing = true;
    // const coords = this.relativeCoords(e);
    // this.context.moveTo(coords.x, coords.y);

    //this.consoleMessage("down")
  }

  // onMouseMove(e) {

  //   if (this.isDrawing) {
  //     const coords = this.relativeCoords(e);
  //     this.context.lineTo(coords.x, coords.y);
  //     this.context.stroke();
  //     //this.consoleMessage("up")
  //     this.signaturecheckvalue1cc = true;
  //     this.signaturemessagecc = false
  //     this.signaturecheckcc = false;
  //   }
  // }
  // onMouseDownpad(e:any) {


  //   if (this.ctxpad) {
  //     this.isDrawingpad = true;
  //     const coords = this.relativeCoords(e);
  //     this.ctxpad.moveTo(coords.x, coords.y);
  //     }

  // }

  // onMouseMovepad(e:any) {
  //   if (this.isDrawingpad && this.ctxpad) {
  //     const coords = this.relativeCoords(e);
  //     this.ctxpad.lineTo(coords.x, coords.y);
  //     this.ctxpad.stroke();
  //     this.signaturecheckvalue1 = true;
  //     this.signaturemessagepad =false;
  //   }
  // }


  private relativeCoords(event: any): { x: number, y: number } {
    const bounds = event.target.getBoundingClientRect();
    const cords = {
      clientX: event.clientX || event.changedTouches[0].clientX,
      clientY: event.clientY || event.changedTouches[0].clientY
    };
    const x = cords.clientX - bounds.left;
    const y = cords.clientY - bounds.top;
    return { x, y };
  }

  formatPhoneNumber(event: any) {
    // const input = event.target.value.replace(/\D/g, '');
    // if (input.length <= 3) {
    //   event.target.value = `(${input}`;
    // } else if (input.length <= 6) {
    //   event.target.value = `(${input.slice(0, 3)}) ${input.slice(3)}`;
    // } else {
    //   event.target.value = `(${input.slice(0, 3)}) ${input.slice(
    //     3,
    //     6
    //   )}-${input.slice(6, 10)}`;
    // }
  }
  phoneFormat(event: any) {
    const inputValue = this.userForm.get("phone_number")?.value;

    this.userForm.get("phone_number")?.setValue(this._PhoneNumberPipe.transform(inputValue), { emitEvent: false });
  }
  alphabatesOnly(event: any) {
    const key = event.key || String.fromCharCode(event.keyCode);
    // Check if the key is an alphabetical character (A-Z or a-z) or a space
    if (/^[\p{L}\p{M}\s]+$/u.test(key)) {
      return true;
    } else {
      return false;
    }



  }
  public paymentmethod(event: any) {
    // this.signaturePad.clear();
    // this.clearPadSignature()
    // this.clearCreditCardSignature()
    if (event.target.value == "PAD") {
      // this.bankpay = true;
      // this.creditcardform = false;
      sessionStorage.setItem("paymentMethod", "PAD");
      // $("#bankpay").prop("checked", true);

    } else {

      // this.bankpay = false;
      // this.creditcardform = true;
      sessionStorage.setItem("paymentMethod", "CC");
      // $("#creditcard").prop("checked", true);

      this.bankfile = ""
      this.imagedisplay = false
      this.imagedisplay = ""
      sessionStorage.removeItem("fileurl")
      sessionStorage.removeItem("filename")
      this.isFileUploaded = true
      this.Shared.setMessage("")
      this.bankpayForm.get('voidcheck')?.reset();

      // $('.paymentmethod').not(this).prop('checked', false);
    }
  }

  public provincelist(event: any) {
    // alert(event.target.value)

    if (event.target.value == 'QC') {
      // jQuery("#provinceinfo-modal").modal("show");
    }

    this.configprovinceres.forEach((element: any) => {
      if (element.shortName == event.target.value) {
        this.provineList = element;
        // this.provincialHealthcareUrl = element.provincialHealthcareUrl;
        // this.provincialZipcodes = element.zipcodes.split(",");
        // this.provincelistid = element.id;
        // this.state_id = parseInt(element.id);
        // this.fusebillid = parseInt(element.fusebillId);
        // this.statename = element.name;
      }
    });

    // ////this.consoleMessage(this.userForm.get('postalcode').value)
    if (this.userForm.controls['postal_code'].value) {
      if (
        this.provineList.zipcodes
          .split(',')
          .indexOf(this.userForm.controls['postal_code'].value[0]) == -1
      ) {
        this.invalidPostalCodeForProvince = true;
        this.userForm.controls['postal_code'].markAsTouched();
      } else {
        this.invalidPostalCodeForProvince = false;
      }

      if (this.userForm.controls['postal_code'].value.length == 0) {
        this.invalidPostalCodeForProvince = false;
      }
    }
  }
  public workingProvincelist(event: any) {
    // alert(event.target.value)

    if (event.target.value == 'QC') {
      // jQuery("#provinceinfo-modal").modal("show");
    }

    this.configpworkingrovinceres.forEach((element: any) => {
      if (element.shortName == event.target.value) {
        this.working_provincelistid = element.id;
      }
    });



  }
  public provincelistpayment(event: any) {
    // alert(event.target.value)

    if (event.target.value == 'QC') {
      // jQuery("#provinceinfo-modal").modal("show");
    }

    this.configprovinceres.forEach((element: any) => {
      if (element.shortName == event.target.value) {
        this.provineListPayment = element;

        // console.log(this.provineListPayment)
        // this.provincialHealthcareUrl = element.provincialHealthcareUrl;
        // this.provincialZipcodes = element.zipcodes.split(",");
        // this.provincelistid = element.id;
        // this.state_id = parseInt(element.id);
        // this.fusebillid = parseInt(element.fusebillId);
        // this.statename = element.name;
      }
    });

    // ////this.consoleMessage(this.userForm.get('postalcode').value)
    if (this.bankpayForm.controls['bankpostalcode'].value) {
      if (
        this.provineListPayment.zipcodes
          .split(',')
          .indexOf(this.bankpayForm.controls['bankpostalcode'].value[0]) == -1
      ) {
        this.bankinvalidPostalCode = true;
        this.bankpayForm.controls['bankpostalcode'].markAsTouched();
      } else {
        this.bankinvalidPostalCode = false;
      }

      if (this.bankpayForm.controls['bankpostalcode'].value.length == 0) {
        this.bankinvalidPostalCode = false;
      }
    }
  }
  public provincelistCreditPayment(event: any) {
    // alert(event.target.value)

    if (event.target.value == 'QC') {
      // jQuery("#provinceinfo-modal").modal("show");
    }

    this.configprovinceres.forEach((element: any) => {
      if (element.shortName == event.target.value) {
        this.provineListcreditPayment = element;
        // this.provincialHealthcareUrl = element.provincialHealthcareUrl;
        // this.provincialZipcodes = element.zipcodes.split(",");
        // this.provincelistid = element.id;
        // this.state_id = parseInt(element.id);
        // this.fusebillid = parseInt(element.fusebillId);
        // this.statename = element.name;
      }
    });

    // ////this.consoleMessage(this.userForm.get('postalcode').value)
    if (this.paymentForm.controls['postalcode'].value) {
      if (
        this.provineListcreditPayment.zipcodes
          .split(',')
          .indexOf(this.paymentForm.controls['postalcode'].value[0]) == -1
      ) {
        this.creditCradInvalidProvince = true;
        this.paymentForm.controls['postalcode'].markAsTouched();
      } else {
        this.creditCradInvalidProvince = false;
      }

      if (this.paymentForm.controls['postalcode'].value.length == 0) {
        this.creditCradInvalidProvince = false;
      }
    }
  }
  changeTextToUppercase(field: any, event: any) {
    const obj: any = {};
    obj['postal_code'] =
      this.userForm.controls['postal_code'].value?.toUpperCase();

    this.userForm.patchValue(obj);
    if (
      this.provineList.zipcodes.split(',').indexOf(event.target.value[0]) == -1
    ) {
      this.invalidPostalCodeForProvince = true;
    } else {
      this.invalidPostalCodeForProvince = false;
      var postalRegexp = /^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$/gm;
      if (event.target.value && !postalRegexp.test(event.target.value)) {
        this.invalidPostalCodeForProvince = true;
      } else {
        this.invalidPostalCodeForProvince = false;
      }
    }

    if (event.target.value.length == 0) {
      this.invalidPostalCodeForProvince = false;
    }
  }
  changeTextToUppercasePayment(field: any, event: any) {
    const obj: any = {};
    obj['bankpostalcode'] =
      this.bankpayForm.controls['bankpostalcode'].value?.toUpperCase();

    this.bankpayForm.patchValue(obj);
    if (
      this.provineListPayment.zipcodes
        .split(',')
        .indexOf(event.target.value[0]) == -1
    ) {
      this.bankinvalidPostalCode = true;
    } else {
      this.bankinvalidPostalCode = false;
      var postalRegexp = /^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$/gm;
      if (event.target.value && !postalRegexp.test(event.target.value)) {
        this.bankinvalidPostalCode = true;
      } else {
        this.bankinvalidPostalCode = false;
      }
    }

    if (event.target.value.length == 0) {
      this.bankinvalidPostalCode = false;
    }
  }
  changeTextToUppercaseCreditPayment(field: any, event: any) {
    const obj: any = {};
    obj['postalcode'] =
      this.paymentForm.controls['postalcode'].value?.toUpperCase();

    this.paymentForm.patchValue(obj);
    if (
      this.provineListcreditPayment.zipcodes
        .split(',')
        .indexOf(event.target.value[0]) == -1
    ) {
      this.creditCradInvalidProvince = true;
    } else {
      this.creditCradInvalidProvince = false;
      var postalRegexp = /^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$/gm;
      if (event.target.value && !postalRegexp.test(event.target.value)) {
        this.creditCradInvalidProvince = true;
      } else {
        this.creditCradInvalidProvince = false;
      }
    }

    if (event.target.value.length == 0) {
      this.creditCradInvalidProvince = false;
    }
  }

  spouseInfoCheck(event: any) {
    if (event.target.value == 'true') {
      this.userForm
        .get('spousefirstname')
        ?.setValidators([Validators.required, Validators.minLength(2)]);
      this.userForm
        .get('spouselastname')
        ?.setValidators([Validators.required, Validators.minLength(2)]);
      this.userForm.get('spousegender')?.setValidators([Validators.required]);
      this.userForm
        .get('spousedateofbirth')
        ?.setValidators([Validators.required]);
      this.userForm
        .get('spousehealthcard')
        ?.setValidators([Validators.required]);
      if (this.userForm.value.provincial_health_coverage == 'false') {
        this.userForm
          .get('coverageRequiredOfDependents')
          ?.setValidators([Validators.required]);
      }
      else{
        this.userForm.get('coverageRequiredOfDependents')?.reset();
        this.userForm.get('coverageRequiredOfDependents')?.clearValidators();
        this.userForm
          .get('coverageRequiredOfDependents')
          ?.updateValueAndValidity();
      }
    } else {
      if (this.userForm.value.having_dependent_children == 'true') {
        if (this.userForm.value.provincial_health_coverage == 'false') {
          this.userForm
            .get('coverageRequiredOfDependents')
            ?.setValidators([Validators.required]);
        }
        else{
          this.userForm.get('coverageRequiredOfDependents')?.reset();
          this.userForm.get('coverageRequiredOfDependents')?.clearValidators();
          this.userForm
            .get('coverageRequiredOfDependents')
            ?.updateValueAndValidity();
        }
      } else {
        // this.showlandeddependentDetails =false;

        // $("#coverageRequiredOfDependentsno").click();
        this.userForm.get('coverageRequiredOfDependents')?.reset();
        this.userForm.get('dependentdateOfArrival')?.reset();
        this.userForm.get('dependentcountryOrigin')?.reset();
        this.userForm.get('dependentLandedImigrant')?.reset();
        this.userForm.get('dependentapplylandedstaus')?.reset();
        this.userForm.get('dependentMorethan3years')?.reset();
        this.userForm.get('coverageRequiredOfDependents')?.clearValidators();
        this.userForm
          .get('coverageRequiredOfDependents')
          ?.updateValueAndValidity();
      }

      this.userForm.get('spousefirstname')?.clearValidators();
      this.userForm.get('spousefirstname')?.updateValueAndValidity();

      this.userForm.get('spouselastname')?.clearValidators();
      this.userForm.get('spouselastname')?.updateValueAndValidity();

      this.userForm.get('spousegender')?.clearValidators();
      this.userForm.get('spousegender')?.updateValueAndValidity();

      this.userForm.get('spousedateofbirth')?.clearValidators();
      this.userForm.get('spousedateofbirth')?.updateValueAndValidity();

      this.userForm.get('spousehealthcard')?.clearValidators();
      this.userForm.get('spousehealthcard')?.updateValueAndValidity();
    }
  }

  spousehealthcard(event: any) {
    if (event.target.value == 'true') {
      this.userForm
        .get('Spousenameofthecarrier')
        ?.setValidators([Validators.required]);
      this.userForm.get('spousecob')?.setValidators([Validators.required]);
    } else {
      this.userForm.get('Spousenameofthecarrier')?.clearValidators();
      this.userForm.get('Spousenameofthecarrier')?.updateValueAndValidity();

      this.userForm.get('spousecob')?.clearValidators();
      this.userForm.get('spousecob')?.updateValueAndValidity();
    }
  }
  workingHoursCheck(event: any) { }
  public formatCreditCardNumber(event: any): void {
    let inputVal = event.target.value.replace(/\s+/g, ''); // Remove existing spaces

    if (inputVal.length > 0) {
      // Format the input with spaces every 4 digits
      inputVal = inputVal.match(/.{1,4}/g).join(' ');
    }
    this.paymentForm.patchValue({ CardNumber: inputVal });

    // this.creditCardForm.patchValue({ CardNumber: inputVal });
  }
  public formatAmericaCreditCardNumber(event: any): void {
    let inputVal = event.target.value.replace(/\s+/g, ''); // Remove existing spaces

    if (inputVal.length > 0) {
      // Format the input with spaces every 4 digits
      // inputVal = inputVal.match(/.{1,4}/g).join(' ');

      inputVal = inputVal.replace(
        /(\d{4})(\d{1,6})?(\d{1,5})?/,
        function (match: any, p1: any, p2: any, p3: any) {
          return p1 + (p2 ? ' ' + p2 : '') + (p3 ? ' ' + p3 : '');
        }
      );
    }
    this.paymentForm.patchValue({ CardNumber: inputVal });
    // if (this.isValidAmexCard(inputVal.replaceAll(' ',''))) {
    //   console.log("This is a valid American Express card number.");
    // } else {
    //   console.log("This is not a valid American Express card number.");
    // }
    // this.creditCardForm.patchValue({ CardNumber: inputVal });
  }
  public isValidAmexCard(cardNumber: any) {
    // Remove any non-digit characters
    const cleanedCardNumber = cardNumber.replace(/\D/g, '');

    // Check if the card number has 15 digits and starts with 34 or 37
    const isAmex = /^3[47]\d{13}$/.test(cleanedCardNumber);

    return isAmex;
  }
  public isValidAmexCardNumber(val: any) {
    // Define the regex pattern for formatted Amex card number
    const amexPattern = /^3[47][0-9]{2} [0-9]{6} [0-9]{5}$/;
    // console.log(amexPattern);
    return amexPattern.test(val.replaceAll(' ', ''));
  }
  public creditcardlblurmethod(event: any) {
    // this.postalvalue = event.target.value
    this.validateCreditCardNumber(event);

    let val = event.target.value;

    // console.log(val.slice(0, 2));

    const inputField = this.paymentForm.controls['CardNumber'];
    // this.userForm.controls['postalcode']

    if (val.slice(0, 2) == 34 || val.slice(0, 2) == 37) {
      // this.creditcardvalidationAmerica(val)
      // inputField.setValidators([Validators.required, creditcardvalidationAmerica(val)]);
      // inputField.updateValueAndValidity();

      this.formatAmericaCreditCardNumber(event);
      // this.isValidAmexCardNumber(val)
    } else {
      // this.creditcardvalidation(val)
      // inputField.setValidators([Validators.required, creditcardvalidation(val)]);
      // inputField.updateValueAndValidity();

      this.formatCreditCardNumber(event);
    }

    val = val.replaceAll(' ', '');
    let checksum = 0; // running checksum total
    let j = 1; // takes value of 1 or 2
    // Process each digit one by one starting from the last
    for (let i = val.length - 1; i >= 0; i--) {
      let calc = 0;
      // Extract the next digit and multiply by 1 or 2 on alternative digits.
      calc = Number(val.charAt(i)) * j;

      // If the result is in two digits add 1 to the checksum total
      if (calc > 9) {
        checksum = checksum + 1;
        calc = calc - 10;
      }
      // Add the units element to the checksum total
      checksum = checksum + calc;
      // Switch the value of j
      if (j == 1) {
        j = 2;
      } else {
        j = 1;
      }
    }
    //Check if it is divisible by 10 or not.
    // return (checksum % 10) == 0;
    if (checksum % 10 == 0) {
      this.invalidcreditcard = false;
    } else {
      this.invalidcreditcard = true;
    }

    // if (event.target.value.length == 0) {
    //   this.invalidcreditcard = false;
    //   consol.log("false")
    // }
  }

  validateCreditCardNumber(val: any) {
    const cardImage = document.getElementById('card-image');

    // console.log(val.target.value);
    const visaPattern = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
    const mastPattern = /^(?:5[1-5][0-9]{14})$/;
    const amexPattern = /^(?:3[47][0-9]{13})$/;
    const discPattern = /^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/;

    const ccNum: string = val.target.value.replaceAll(' ', '');

    const isVisa = visaPattern.test(ccNum) === true;
    const isMast = mastPattern.test(ccNum) === true;
    const isAmex = amexPattern.test(ccNum) === true;
    const isDisc = discPattern.test(ccNum) === true;

    if (isVisa || isMast || isAmex || isDisc) {
      // at least one regex matches, so the card number is valid.
      if (isVisa) {
        // this.cardType = 'Visa card'
        this.cardType = './../../assets/img/visa.svg';
        // Visa-specific logic goes here
      } else if (isMast) {
        // this.cardType = 'Master card'
        this.cardType = './../../assets/img/mastercard.svg';
        // Mastercard-specific logic goes here
      } else if (isAmex) {
        this.cardType = './../../assets/img/american-express.svg';
        // cardImage['src']= 'path/to/amex.png';
        // cardImage.style.display = 'inline';
        // AMEX-specific logic goes here
      } else if (isDisc) {
        this.cardType = './../../assets/img/discover.svg';
        // Discover-specific logic goes here
      }
      // return of (null);
    } else {
      this.cardType = '';
      // cardImage.style.display = 'none';
      // return of({
      //   invalid: true
      // });
      // alert("Please enter a valid card number.");
    }
  }
  resolved(captchaResponse: any) {
    this.recaptchaResponse = captchaResponse;
  }
  filterExpiryDate(event: any) {
    let val = ''

    if (this.paymentForm.controls['ExpirationMonth'].value && this.paymentForm.controls['ExporationYear'].value) {
      val = this.paymentForm.controls['ExpirationMonth'].value + "/" + this.paymentForm.controls['ExporationYear'].value
    }
    // console.log('VAL', val);
    let regex: any = val.match(/^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$/);

    if (regex) {
      // console.log('VALID format');
    } else {
      // console.log('INVALID format');
    }

    var exp = new Date(
      this.checkFullYearFormat(1 * regex[2]),
      1 * regex[1],
      1
    ).valueOf();
    var now = new Date();
    var currMonth = new Date(now.getFullYear(), now.getMonth(), 1).valueOf();
    // console.log(currMonth)
    if (exp <= currMonth) {
      this.invalidCardDetails = true
      // alert('Invalid Expiry Date. Date should be greater than current date');


    } else {
      this.invalidCardDetails = false
      // alert('Valid Expiry Date');
    }
  }

  checkFullYearFormat(yearVal: any) {
    var FIXED_YEAR = 50;
    if (yearVal < 100) {
      var nowYear = new Date().getFullYear();
      yearVal += Math.floor(nowYear / 100) * 100;
      if (yearVal > nowYear + FIXED_YEAR) {
        yearVal -= 100;
      } else if (yearVal <= nowYear - 100 + FIXED_YEAR) {
        yearVal += 100;
      }
    }
    return yearVal;
  }
  // public changeexpiryyear(event) {
  //   let today;
  //   let someday;
  //   let expirymonth = this.paymentForm.get("ExpirationMonth").value;

  //   let expiryyear = "20" + event.target.value;

  //   // alert(expiryyear)

  //   var oneDate = moment();
  //   var yearName = oneDate.format("YYYY");

  //   var oneMonth = moment();
  //   var MonthName = oneMonth.format("MM");

  //   if (expiryyear == yearName) {
  //     if (expirymonth < MonthName) {
  //       this.showexpiryyearerror = true;
  //       this.futureexpiredate = false;
  //     } else {
  //       this.showexpiryyearerror = false;
  //       this.showexpirymontherror = false;
  //       // this.showexpiryyearerror =false
  //       today = this.addMonths(new Date(), +6);
  //       someday = new Date();

  //       someday.setFullYear(expiryyear, expirymonth, 0);

  //       if (someday >= today) {
  //         this.futureexpiredate = false;
  //       } else {
  //         this.futureexpiredate = true;
  //         this.showexpiryyearerror = false;
  //         this.showexpirymontherror = false;
  //       }
  //     }
  //   } else {
  //     this.showexpiryyearerror = false;
  //     this.showexpirymontherror = false;
  //     today = this.addMonths(new Date(), +6);
  //     someday = new Date();

  //     someday.setFullYear(expiryyear, expirymonth, 0);
  //     if (expirymonth && expiryyear) {
  //       if (someday >= today) {
  //         this.futureexpiredate = false;
  //       } else {
  //         this.futureexpiredate = true;
  //         this.showexpiryyearerror = false;
  //         this.showexpirymontherror = false;
  //       }
  //     }
  //   }
  // }
  // public addMonths(date:any, months:any) {
  //   date.setMonth(date.getMonth() + months);
  //   return date;
  //   // alert(date)
  // }
  // public changeexpirymonth(event) {
  //   let today;
  //   let someday;

  //   let expiryyear;

  //   if (this.paymentForm.get("ExporationYear").value) {
  //     expiryyear = "20" + this.paymentForm.get("ExporationYear").value;
  //   } else {
  //     expiryyear = this.paymentForm.get("ExporationYear").value;
  //   }

  //   let expirymonth = event.target.value;

  //   var oneDate = moment();
  //   var yearName = oneDate.format("YYYY");

  //   var oneMonth = moment();
  //   var MonthName = oneMonth.format("MM");

  //   if (expiryyear == yearName) {
  //     if (expirymonth < MonthName) {
  //       this.showexpirymontherror = true;
  //       this.futureexpiredate = false;
  //     } else {
  //       this.showexpiryyearerror = false;
  //       this.showexpirymontherror = false;
  //       today = this.addMonths(new Date(), +6);
  //       someday = new Date();

  //       someday.setFullYear(expiryyear, event.target.value, 0);

  //       if (someday >= today) {
  //         this.futureexpiredate = false;
  //       } else {
  //         this.futureexpiredate = true;
  //         this.showexpiryyearerror = false;
  //         this.showexpirymontherror = false;
  //       }
  //     }
  //   } else {
  //     this.showexpirymontherror = false;
  //     this.showexpiryyearerror = false;
  //     this.futureexpiredate = false;

  //     today = this.addMonths(new Date(), +6);
  //     someday = new Date();

  //     someday.setFullYear(expiryyear, event.target.value, 0);
  //     if (expiryyear && event.target.value) {
  //       if (someday >= today) {
  //         this.futureexpiredate = false;
  //       } else {
  //         this.futureexpiredate = true;
  //         this.showexpiryyearerror = false;
  //         this.showexpirymontherror = false;
  //       }
  //     }
  //   }
  // }
  public provincialCoverageHealth(value: any) {
    // alert("test")
    if (value.target.value == 'true') {
      this.spouseplaceholdername = this.lang.Name_As_Shown_On_Provincial_Health_Card;

      this.userForm.get('dateOfArrival')?.clearValidators();
      this.userForm.get('dateOfArrival')?.updateValueAndValidity();
      this.userForm.get('isEmployeeImigrant')?.clearValidators();
      this.userForm.get('isEmployeeImigrant')?.updateValueAndValidity();
      this.userForm.get('applylandedstaus')?.clearValidators();
      this.userForm.get('applylandedstaus')?.updateValueAndValidity();
      this.userForm.get('countryOrigin')?.clearValidators();
      this.userForm.get('countryOrigin')?.updateValueAndValidity();
      this.userForm.get('coverageRequiredOfEmployee')?.clearValidators();


      // this.userForm.get('coverageRequiredOfDependents').clearValidators();
      // this.userForm.get('coverageRequiredOfDependents').updateValueAndValidity();
      // this.checkhealthcardstatus = false;

      if (this.userForm.get('having_spouse')?.value == 'true' || this.userForm.get('having_dependent_children')?.value === 'true') {

        this.userForm.get('coverageRequiredOfDependents')?.reset();
        this.userForm.get('coverageRequiredOfDependents')?.clearValidators();
        this.userForm.get('coverageRequiredOfEmployee')?.updateValueAndValidity();


      }
      else{
        this.userForm.get('coverageRequiredOfDependents')?.reset();
        this.userForm.get('coverageRequiredOfDependents')?.clearValidators();
        this.userForm.get('coverageRequiredOfEmployee')?.updateValueAndValidity();
      }

    } else {
      this.spouseplaceholdername = this.lang.First_Name;

      this.userForm.get('dateOfArrival')?.setValidators(Validators.required);
      this.userForm
        .get('isEmployeeImigrant')
        ?.setValidators(Validators.required);
      this.userForm.get('countryOrigin')?.setValidators(Validators.required);
      this.userForm
        .get('coverageRequiredOfEmployee')
        ?.setValidators(Validators.required);
      // this.checkhealthcardstatus = true;
      if (this.userForm.get('having_spouse')?.value == 'true' || this.userForm.get('having_dependent_children')?.value === 'true') {

        this.userForm
          .get('coverageRequiredOfDependents')
          ?.setValidators([Validators.required]);

      }
      else {
        this.userForm.get('coverageRequiredOfDependents')?.reset();
        this.userForm.get('coverageRequiredOfDependents')?.clearValidators();
      }
    }
  }

  public landedimmigrantStatus(value: any) {
    // alert("test")
    if (value.target.value == 'true') {
      this.userForm.get('applylandedstaus')?.clearValidators();
      this.userForm.get('applylandedstaus')?.updateValueAndValidity();
    } else {
      this.userForm.get('applylandedstaus')?.setValidators(Validators.required);
      this.userForm.get('applylandedstaus')?.updateValueAndValidity();

      // this.checkhealthcardstatus = true;
    }
  }

  public dependentCoverage(value: any) {
    if (value.target.value == 'true') {
      this.userForm
        .get('dependentdateOfArrival')
        ?.setValidators(Validators.required);
      this.userForm
        .get('dependentLandedImigrant')
        ?.setValidators(Validators.required);
      this.userForm
        .get('dependentMorethan3years')
        ?.setValidators(Validators.required);
      this.userForm
        .get('dependentcountryOrigin')
        ?.setValidators(Validators.required);
    } else {
      this.userForm.get('dependentdateOfArrival')?.clearValidators();
      this.userForm.get('dependentdateOfArrival')?.updateValueAndValidity();

      this.userForm.get('dependentLandedImigrant')?.clearValidators();
      this.userForm.get('dependentLandedImigrant')?.updateValueAndValidity();

      this.userForm.get('dependentapplylandedstaus')?.clearValidators();
      this.userForm.get('dependentapplylandedstaus')?.updateValueAndValidity();

      this.userForm.get('dependentMorethan3years')?.clearValidators();
      this.userForm.get('dependentMorethan3years')?.updateValueAndValidity();

      this.userForm.get('dependentcountryOrigin')?.clearValidators();
      this.userForm.get('dependentcountryOrigin')?.updateValueAndValidity();
      // this.checkhealthcardstatus = true;
    }
  }

  public dependentLandedImigrant(value: any) {
    // alert("test")
    if (value.target.value == 'true') {
      this.userForm.get('dependentapplylandedstaus')?.clearValidators();
      this.userForm.get('dependentapplylandedstaus')?.updateValueAndValidity();
    } else {
      this.userForm
        .get('dependentapplylandedstaus')
        ?.setValidators([Validators.required]);
      // this.checkhealthcardstatus = true;
    }
  }

  public havingchildren(event: any) {
    //this.consoleMessage("havingchildren: ");
    if (event.target.value == 'true') {
      this.userForm
        .get('no_of_children')
        ?.setValue(this.noOfChildrenChangedValue);

      if (this.userForm.value.provincial_health_coverage == 'false') {
        this.userForm
          .get('coverageRequiredOfDependents')
          ?.setValidators([Validators.required]);
      }
    } else {
      if (this.userForm.value.having_spouse == 'true') {
        if (this.userForm.value.provincial_health_coverage == 'false') {
          this.userForm
            .get('coverageRequiredOfDependents')
            ?.setValidators([Validators.required]);
        }
      } else {
        // this.showlandeddependentDetails =false;

        // $("#coverageRequiredOfDependentsno").click();
        this.userForm.get('coverageRequiredOfDependents')?.reset();
        this.userForm.get('dependentdateOfArrival')?.reset();
        this.userForm.get('dependentcountryOrigin')?.reset();
        this.userForm.get('dependentLandedImigrant')?.reset();
        this.userForm.get('dependentapplylandedstaus')?.reset();
        this.userForm.get('dependentMorethan3years')?.reset();
        this.userForm.get('coverageRequiredOfDependents')?.clearValidators();
        this.userForm
          .get('coverageRequiredOfDependents')
          ?.updateValueAndValidity();
      }

      // this.childrenhealthinformation = false;
      this.childInfoAddDataArray.shift();
      this.childTableRowsLength = this.childInfoAddDataArray.length;
      this.userForm.get('childrenDetails')?.reset();
      this.userForm.get('childrenDetails')?.clearValidators();
      this.userForm.get('no_of_children')?.clearValidators();
      this.userForm.get('no_of_children')?.updateValueAndValidity();
    }
  }

  public childAddFun() {
    this.showModal = true;
    this.childInfoModalAddAndUpdateButton = 'Add';
    this.editChildInfo = ''
  }

  public termandconditions() {
    this.showModelDialog = true;
    this.pdfData = this.formConfigResult.links.termsConditions;
    this.header = this.lang.Terms_Conditions
  }

  public advisorDisclousre() {
    this.showModelDialog = true;
    this.pdfData = this.formConfigResult.links.disclosureAgreement;
    this.header = this.lang.Advisor_Disclosure
  }

  public DeleteChild(index: any) {
    this.deleteChildIndex = index;
    this.showDeleteModal = true;
  }
  public EditChild(index: any) {
    this.editChildIndex = index;
    this.childInfoModalAddAndUpdateButton = 'Update';
    this.showModal = true;

    this.editChildInfo = this.childInfoAddDataArray[index];

    // console.log(this.editChildInfo);
  }
  onConfirmed(confirmed: boolean) {
    if (confirmed) {
      // console.log(this.deleteChildIndex);

      this.childInfoAddDataArray.splice(this.deleteChildIndex, 1);
      this.showDeleteModal = false;
      // console.log('Confirmation result:', confirmed);
      this.childTableRowsLength = this.childInfoAddDataArray.length;
      this.userForm
        .get('no_of_children')
        ?.setValue(this.childInfoAddDataArray.length);

      if (this.childInfoAddDataArray.length == 0) {
        this.userForm.get('having_dependent_children')?.setValue('false');
        this.userForm.get('no_of_children')?.clearValidators();
        this.userForm.get('no_of_children')?.updateValueAndValidity();
      }
    } else {
      this.showDeleteModal = false;
    }
  }

  onPdfConfirmed(confirmed: boolean) {
    if (confirmed) {
      this.showModelDialog = false;
    } else {
      this.showModelDialog = false;
    }
  }
  onPADPdfConfirmed(confirmed: boolean) {
    if (confirmed) {
      this.showPADModelDiaslog = false;
    } else {
      this.showPADModelDiaslog = false;
      this.acceptAggrement()
    }
  }
  onClosedModelpopup(confirmed: boolean) {
    if (confirmed) {
      this.ShowModelPopup = false;
    } else {
      this.ShowModelPopup = false;
    }
  }

  public acceptAggrement() {
    setTimeout(() => {

      let inputData = {
        "url": sessionStorage.getItem("fileurl"),
        "filename": sessionStorage.getItem("filename"),
        "signature": sessionStorage.getItem("signature")
      }

      this._CorporateService.padsignature(inputData).subscribe((result: any) => {
        if (result.status == "200") {

          this.showPADModelDiaslog = true
          this.pdfData = result.data.url
          this.header = "PAD Agreement"
          sessionStorage.setItem("failed", "")


        } else {
          this.failedarray.push("updatePAD")
          //this.consoleMessage(this.failedarray)
          sessionStorage.setItem("failed", JSON.stringify(this.failedarray))

        }

      })




    }, 1000);
  }

  onSubmitForm(data: any) {
    this.childInfoAddDataArray[this.childTableEditRowIndex];
    // console.log('Form submitted:', data);
    if (this.childInfoModalAddAndUpdateButton == 'Add') {
      this.childInfoAddDataArray.push(data);
      this.childTableRowsLength = this.childInfoAddDataArray.length;
      this.userForm
        .get('no_of_children')
        ?.setValue(this.childInfoAddDataArray.length);

      // console.log(this.childTableRowsLength);
      // console.log(this.childInfoAddDataArray);

      this.showModal = false;
    } else {
      this.childInfoAddDataArray[this.editChildIndex] = data;
      this.showModal = false;
    }
  }

  onCloseModal() {
    this.showModal = false;
  }
  deleteChildrenSwal(index: any) {
    // Swal.fire({
    //   title: this.lang.Info,
    //   text: "Are you sure you want to delete?",
    //   showCancelButton: true,
    //   confirmButtonColor: "#10104d",
    //   cancelButtonColor: "#10104d",
    //   width: "30%",
    //   confirmButtonText: "Yes",
    //   customClass: {
    //     header: 'swal-header-orange',
    //     popup: 'custom-swal-dialog',
    //     confirmButton: 'custom-ok-button-class',
    //   }
    // }).then((result:any) => {
    //   if (result.value) {
    //     this.childInforTableDelete(index);
    //   }
    // });
  }

  childInforTableDelete(index: any) {
    if (this.childInfoAddDataArray.length == 1) {
      // document.getElementById('Dependentchildrenno').click();
    }

    this.childInfoAddDataArray.splice(index, 1);
    this.noOfChildrenChangedValue = this.childInfoAddDataArray.length;

    this.childTableRowsLength = this.childInfoAddDataArray.length;
  }

  public childdsiablility(value: any) {
    // this.childendisability = value.target.checked;
  }
  public isMinorCheck(value:any){
    if (value.target.value == 'true') {


      this.benficiaryForm.get('dob')?.setValidators(Validators.required);
      this.benficiaryForm.get('dob')?.updateValueAndValidity();

      this.benficiaryForm.get('trusteeFirstName')?.setValidators(Validators.required);
      this.benficiaryForm.get('trusteeFirstName')?.updateValueAndValidity();


      this.benficiaryForm.get('trusteeLastName')?.setValidators(Validators.required);
      this.benficiaryForm.get('trusteeLastName')?.updateValueAndValidity();
    }else{
      this.benficiaryForm.get('dob')?.clearValidators();
      this.benficiaryForm.get('dob')?.updateValueAndValidity();

      this.benficiaryForm.get('trusteeFirstName')?.clearValidators();
      this.benficiaryForm.get('trusteeFirstName')?.updateValueAndValidity();


      this.benficiaryForm.get('trusteeLastName')?.clearValidators();
      this.benficiaryForm.get('trusteeLastName')?.updateValueAndValidity();
    }

  }
  public isChildCheck(value:any){
    if (value.target.value == 'CHILD') {
      this.benficiaryForm.get('isMinor')?.setValidators(Validators.required);
      this.benficiaryForm.get('isMinor')?.updateValueAndValidity();
    }else{
      this.benficiaryForm.get('dob')?.reset();
      this.benficiaryForm.get('trusteeFirstName')?.reset();
      this.benficiaryForm.get('trusteeLastName')?.reset();

      this.benficiaryForm.get('isMinor')?.clearValidators();
      this.benficiaryForm.get('isMinor')?.updateValueAndValidity();
      this.benficiaryForm.get('dob')?.clearValidators();
      this.benficiaryForm.get('dob')?.updateValueAndValidity();

      this.benficiaryForm.get('trusteeFirstName')?.clearValidators();
      this.benficiaryForm.get('trusteeFirstName')?.updateValueAndValidity();


      this.benficiaryForm.get('trusteeLastName')?.clearValidators();
      this.benficiaryForm.get('trusteeLastName')?.updateValueAndValidity();


    }

  }


  public addeditbenficarySubmit(){

    console.log(this.benficiaryForm.value)
    if (this.benficiaryForm.invalid) {
      console.log(this.benficiaryForm.value);
      return;
    }

    let obj = {
      customerType:this.benficiaryForm.value.customerType,
      type:"Primary",
      firstName:this.benficiaryForm.value.firstName,
      lastName:this.benficiaryForm.value.lastName,
      isMinor:this.benficiaryForm.value.isMinor ||"",
      dob :this.benficiaryForm.value.dob || null,
      relationship:this.benficiaryForm.value.relationship,
      percentage :parseFloat(this.benficiaryForm.value.percentage??'0'),
      contingentFirstName:this.benficiaryForm.value.contingentFirstName,
      contingentLastName:this.benficiaryForm.value.contingentLastName,
      trusteeFirstName:this.benficiaryForm.value.trusteeFirstName || "",
      trusteeLastName:this.benficiaryForm.value.trusteeLastName || "",


    };
    if (this.BenficairyModalAddAndUpdateButton == this.lang.Add) {
         this.addbenificaryData.push(obj);
      // this.childTableRowsLength = this.childInfoAddDataArray.length;
      // this.userForm
      //   .get('no_of_children')
      //   ?.setValue(this.childInfoAddDataArray.length);
      this.benficiaryForm.reset()
      this.benficiaryForm.get('dob')?.reset();
      this.benficiaryForm.get('dob')?.clearValidators();
      this.addeditbenficiary = false;
      sessionStorage.setItem(
        'addbenificaryData',
        JSON.stringify(this.addbenificaryData)
      );
      var total = 0;
      for (let i = 0; i < this.addbenificaryData.length; i++) {
        total += parseFloat(this.addbenificaryData[i].percentage);
      }

      if(total>=100){
        this.showaddmorebenficiary =false;
      }else{
        this.showaddmorebenficiary =true;

      }




    } else {
      // this.benficiaryForm.value?['contactDetails']={}
      // this.benficiaryForm.value?['trusteeDetails']={'firstName':'','lastName':''}
      console.log(this.addbenificaryData[this.editBenficiaryIndex])
      console.log(this.benficiaryForm.value)
      this.addbenificaryData[this.editBenficiaryIndex] = obj;
      this.addeditbenficiary = false;
      console.log(this.addbenificaryData)
      sessionStorage.setItem(
        'addbenificaryData',
        JSON.stringify(this.addbenificaryData)
      );
      var total = 0;
      for (let i = 0; i < this.addbenificaryData.length; i++) {
        total += parseFloat(this.addbenificaryData[i].percentage);
      }

      if(total>=100){
        this.showaddmorebenficiary =false;
      }else{
        this.showaddmorebenficiary =true;

      }

      this.benficiaryForm.reset()
      this.benficiaryForm.get('dob')?.reset();
      this.benficiaryForm.get('dob')?.clearValidators();
    }


  }
  public addnewbenficiary() {
    this.addeditbenficiary = true
    this.BenficairyModalAddAndUpdateButton = this.lang.Add;

  }
  onClose() {
    this.benficiary = false
  }
  onCloseaddeditbenificary() {
    this.addeditbenficiary = false
    this.benficiaryForm.reset()
  }
  public savebenficiary(value:any) {
    if (this.benficiaryForm.invalid) {
      console.log(this.benficiaryForm.value);
      return;
    }
    console.log(JSON.stringify(this.benficiaryForm.value, null, 2));
    // alert(this.insurancestatename)
    let obj = {
      customerType:this.benficiaryForm.value.customerType,
      type:"Primary",
      firstName:this.benficiaryForm.value.firstName,
      lastName:this.benficiaryForm.value.lastName,
      isMinor:this.benficiaryForm.value.isMinor ||"",
      dob :this.benficiaryForm.value.dob || null,
      relationship:this.benficiaryForm.value.relationship,
      percentage :parseFloat(this.benficiaryForm.value.percentage??'0'),
      contingentFirstName:this.benficiaryForm.value.contingentFirstName,
      contingentLastName:this.benficiaryForm.value.contingentLastName,
      trusteeFirstName:this.benficiaryForm.value.trusteeFirstName || "",
      trusteeLastName:this.benficiaryForm.value.trusteeLastName || "",


    };
    this.addbenificaryData.push(obj)

    console.log(this.addbenificaryData)
    this.benficiaryForm.reset()
    this.showBenificiaryForm=false;
    this.showbenificaryTable =true
    let licenceExist = false;



    var total = 0;
    for (let i = 0; i < this.addbenificaryData.length; i++) {
      total += parseFloat(this.addbenificaryData[i].percentage);
    }

    if(total>=100){
      this.showaddmorebenficiary =false;
    }else{
      this.showaddmorebenficiary =true;

    }

      sessionStorage.setItem(
        'addbenificaryData',
        JSON.stringify(this.addbenificaryData)
      );

  }

  public deleteBenficiary(license: any, i: number) {
    Swal.fire({
      title: this.lang.Alert,
      text: this.lang.Are_you_sure_you_want_to_delete_this_Benficiary,
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: this.lang.Proceed,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this.addbenificaryData.splice(i, 1);

        if (this.addbenificaryData.length == 0) {
          this.showBenificiaryForm = true;
          this.showbenificaryTable = false
        } else {
          this.showBenificiaryForm = false;
          this.showbenificaryTable = true
        }
        // sessionStorage.setItem(
        //   'addlicenseformData',
        //   JSON.stringify(this.addlicenseformData)
        // );
        sessionStorage.setItem(
          'addbenificaryData',
          JSON.stringify(this.addbenificaryData)
        );
        var total = 0;
        for (let i = 0; i < this.addbenificaryData.length; i++) {
          total += parseFloat(this.addbenificaryData[i].percentage);
        }

        if (total >= 100) {
          this.showaddmorebenficiary = false;
        } else {
          this.showaddmorebenficiary = true;

        }

      }
    });
  }
  public finalbenificiarySubmit() {
    var total = 0;
    for (let i = 0; i < this.addbenificaryData.length; i++) {
      total += parseFloat(this.addbenificaryData[i].percentage);
    }
    // if(total==100){
    // this.benficiary =false

    const validateBeneficiaries = this.addbenificaryData.reduce((acc: any, obj: any) => {
      const compositeKey = `${obj.customerType}_${obj.type}`;
      if (!acc[compositeKey]) {
        acc[compositeKey] = 0;
      }
      acc[compositeKey] += parseFloat(obj.percentage);
      return acc;
    }, {});

    let beneficiaryError = "";
    for (const key in validateBeneficiaries) {
      if (validateBeneficiaries.hasOwnProperty(key)) {
        const [customerType, type] = key.split("_");
        console.log(`The customerType ${customerType} and type ${type} with perc: ${validateBeneficiaries[key]}`);

        if (validateBeneficiaries[key] != 100) {
          beneficiaryError += `CustomerType ${customerType} and type ${type} has percentage issue. \n`
        }
      }
    }




    if (beneficiaryError != "") {
      this.benficiaryCount = true
      this.benficiaryCountMessage = this.lang.Distributed_percentages_must_equal_100_and_not_more
      return false;
    }

    else {
      this.benficiary = false

    }

  }


  public benficiaryconfirm() {
    this.benficiaryCount = false
  }
  public editbenficiary(e:any, i:any, broker:any){
    this.addeditbenficiary =true
    console.log(broker)

    if(broker.isMinor=="true"){

      this.benficiaryForm.get('isMinor')?.setValidators(Validators.required);
      this.benficiaryForm.get('isMinor')?.updateValueAndValidity();


      this.benficiaryForm.get('dob')?.setValidators(Validators.required);
      this.benficiaryForm.get('dob')?.updateValueAndValidity();

      this.benficiaryForm.get('trusteeFirstName')?.setValidators(Validators.required);
      this.benficiaryForm.get('trusteeFirstName')?.updateValueAndValidity();


      this.benficiaryForm.get('trusteeLastName')?.setValidators(Validators.required);
      this.benficiaryForm.get('trusteeLastName')?.updateValueAndValidity();

    }else{
      this.benficiaryForm.get('isMinor')?.clearValidators();
      this.benficiaryForm.get('isMinor')?.updateValueAndValidity();

      this.benficiaryForm.get('dob')?.clearValidators();
      this.benficiaryForm.get('dob')?.updateValueAndValidity();

      this.benficiaryForm.get('trusteeFirstName')?.clearValidators();
      this.benficiaryForm.get('trusteeFirstName')?.updateValueAndValidity();


      this.benficiaryForm.get('trusteeLastName')?.clearValidators();
      this.benficiaryForm.get('trusteeLastName')?.updateValueAndValidity();
    }
    this.benficiaryForm.patchValue(broker)

    this.editBenficiaryIndex = i;
    this.BenficairyModalAddAndUpdateButton = this.lang.Update;

    }
  public checkpercentagevalue(event: any) {
    console.log(event.target.value)
    if (event.target.value <= 100) {


    } else {
      this.benficiaryForm.controls['percentage'].reset();
      this.benficiaryForm.controls['percentage'].markAsTouched();
    }

  }
  onSubmit(value: any, tabId: number): void {
    // let data = this.userForm.value;
    // console.log(data)
    // this.submitted = true;

    // console.log(this.tabIndex)
    // setTimeout(() => {
    //   this.testFun();
    // }, 1000);

    if (this.userForm.invalid) {
      this.userForm.markAllAsTouched();
      const invalid: any = [];
      const controls: any = this.userForm.controls;
      //this.consoleMessage(controls)







      // childrenDetails: this.formBuilder.array([this.initChildDetails()]),
      // childrenDetails:  new FormControl(''),

      for (let name in controls) {
        if (controls[name].invalid) {


          if (name == 'employername') {
            name = this.lang.Enter_Employer_Name;
          }
          if (name == 'dependentdateOfArrival') {
            name = this.lang.Select_dependent_Date_of_Arriaval;
          }
          if (name == 'dependentLandedImigrant') {
            name = this.lang.Select_dependent_Date_of_Arriaval;
          }
          if (name == 'dependentMorethan3years') {
            name = this.lang.Select_dependent_living_status_in_Canada;
          }
          if (name == 'coverageRequiredOfDependents') {
            name = this.lang.Select_dependent_coverage;
          }
          if (name == 'dependentapplylandedstaus') {
            name = this.lang.Select_dependent_apply_landed_immigrant;
          }
          if (name == 'dependentcountryOrigin') {
            name = this.lang.Select_dependent_Country_Origin;
          }
          if (name == 'dateOfArrival') {
            name = this.lang.Select_employee_date_of_arrival;
          }
          if (name == 'isEmployeeImigrant') {
            name = this.lang.Select_employee_immigrant_status;
          }
          if (name == 'applylandedstaus') {
            name = this.lang.Select_employee_apply_landed_status;
          }
          if (name == 'countryOrigin') {
            name = this.lang.Select_employee_country_of_origin;
          }
          if (name == 'coverageRequiredOfEmployee') {
            name = this.lang.Select_employee_coverage;
          }
          if (name == 'planEnrollmentDate') {
            name = this.lang.Select_Plan_Enrollment_Date;
          }
          if (name == 'firstName') {
            name = this.lang.Enter_First_Name;
          }
          if (name == 'lastName') {
            name = this.lang.Enter_Last_Name;
          }
          if (name == 'email') {
            name = this.lang.Enter_Email;
          }
          if (name == 'phone_number') {
            name = this.lang.Enter_Phone_Number;
          }
          if (name == 'street_address_line1') {
            name = this.lang.Enter_Street_Address;
          }
          if (name == 'city') {
            name = this.lang.Enter_City;
          }
          if (name == 'province') {
            name = this.lang.Select_Province_of_Residence;
          }
          if (name == 'workingProvince') {
            name = this.lang.Select_Province_of_Employment;
          }
          if (name == 'postal_code') {
            name = this.lang.Enter_Postal_Code;
          }
          if (name == 'gender') {
            name = this.lang.Select_Gender;
          }
          if (name == 'date_of_hiring') {
            name = this.lang.Select_Date_of_hire;
          }
          if (name == 'date_of_birth') {
            name = this.lang.Select_Date_of_birth;
          }
          if (name == 'company_name') {
            name = this.lang.Enter_Company_Name;
          }
          if (name == 'job_title') {
            name = this.lang.Enter_Job_Title;
          }
          if (name == 'working_20hours') {
            name = this.lang.Choose_work_hours_status;
          }
          if (name == 'hours_per_week') {
            name = this.lang.Enter_Hours_per_week;
          }
          if (name == 'provincial_health_coverage') {
            name = this.lang.Choose_Health_Card_status;
          }
          if (name == 'having_spouse') {
            name = this.lang.Choose_Spouse_status;
          }
          if (name == 'spousefirstname') {
            name = this.lang.Enter_Spouse_First_Name;
          }
          if (name == 'spouselastname') {
            name = this.lang.Enter_Spouse_Last_Name;
          }
          if (name == 'spousegender') {
            name = this.lang.Select_Spouse_Gender;
          }
          if (name == 'spouseemail') {
            name = this.lang.Enter_Spouse_Email;
          }
          if (name == 'spousedateofbirth') {
            name = this.lang.Select_Spouse_Date_of_birth;
          }
          if (name == 'having_dependent_children') {
            name = this.lang.Choose_Dependent_Children_status;
          }
          if (name == 'spousehealthcard') {
            name = this.lang.Choose_Spouse_Insurance_status;
          }
          if (name == 'Spousenameofthecarrier') {
            name = this.lang.Enter_Spouse_name_of_Carrier;
          }
          if (name == 'spousecob') {
            name = this.lang.Select_Spouse_COB;
          }
          if (name == 'termsandconditions') {
            name = this.lang.Select_Terms_and_Conditions;
          }
          if (name == 'disclouseradvisor') {
            name = this.lang.Select_Advisor_Disclosure;
          }
          invalid.push(name);
        }
      }
      this.missingInformation = true
      // jQuery("#missingInfo-modal").modal("show");

      this.missingInfo = invalid;
      ////this.consoleMessage(invalid)
      return invalid;
    } else {
      let SpouseDetails: any = {};

      SpouseDetails =
        value.having_spouse == 'true'
          ? {
            first_name: this.capitalize(value.spousefirstname),
            last_name: this.capitalize(value.spouselastname),
            firstName: this.capitalize(value.spousefirstname),
            lastName: this.capitalize(value.spouselastname),
            email: value.spouseemail || '',
            date_of_birth: this._CustomDatePipe.transform(
              value.spousedateofbirth
            ),
            dob: this._CustomDatePipe.transform(value.spousedateofbirth),
            gender: value.spousegender,
            is_spouse_having_healthcard:
              value.spousehealthcard == 'true' ? true : false,
            spouse_carrier_name:
              value.spousehealthcard == 'true'
                ? value.Spousenameofthecarrier
                : '',
            cobCoverage:
              value.spousehealthcard == 'true' ? value.spousecob : '',
          }
          : {};

      let mainDetails: any = {};

      //this.consoleMessage(value.applylandedstaus)
      mainDetails = {
        dateOfArrival:
          this._CustomDatePipe.transform(value.dateOfArrival) || '',
        countryOfOrigin: value.countryOrigin || '',
        isLandedImmigrant: value.isEmployeeImigrant ? Boolean(value.isEmployeeImigrant) : false,
        coverageRequired: value.coverageRequiredOfEmployee ? Boolean(value.coverageRequiredOfEmployee) : false,
        applyingForLandingStatus: value.applylandedstaus
          ? Boolean(value.applylandedstaus)
          : false,
        dependentDetails:
          value.having_dependent_children == 'true' ||
            value.having_dependent_children == 'true'
            ? {
              coverageRequired: value.coverageRequiredOfDependents ?
                Boolean(value.coverageRequiredOfDependents) : false,
              dateOfArrival:
                value.coverageRequiredOfDependents == 'true'
                  ? this._CustomDatePipe.transform(
                    value.dependentdateOfArrival
                  )
                  : '',
              countryOfOrigin:
                value.coverageRequiredOfDependents == 'true'
                  ? value.dependentcountryOrigin
                  : '',
              isLandedImmigrant:
                value.coverageRequiredOfDependents == 'true'
                  ? Boolean(value.dependentLandedImigrant)
                  : false,
              applyingForLandingStatus: value.dependentMorethan3years
                ? Boolean(value.dependentMorethan3years)
                : false,
              stayFor3Months:
                value.coverageRequiredOfDependents == 'true'
                  ? Boolean(value.dependentMorethan3years)
                  : false,
            }
            : {},
      };
      let childrenarray: any = [];
      let childrenobj: any = {};
      let childarry: any = []
      if (this.childInfoAddDataArray.length > 0) {
        this.childInfoAddDataArray.forEach((element: any) => {
          childrenobj = {};
          childrenobj.first_name = this.capitalize(element.firstName);
          childrenobj.last_name = this.capitalize(element.lastName);
          childrenobj.firstName = this.capitalize(element.firstName);
          childrenobj.lastName = this.capitalize(element.lastName);
          childrenobj.gender = element.gender;
          childrenobj.date_of_birth = this._CustomDatePipe.transform(
            element.date_of_birth
          );
          childrenobj.dob = this._CustomDatePipe.transform(
            element.date_of_birth
          );
          childrenobj.is_child_having_healthcard =
            element.is_child_having_healthcard == "true" ? true : false;
          childrenobj.child_carrier_name = element.child_carrier_name || "";
          childrenobj.enrolledInUniversity =
            element.enrolledInUniversity == "true" ? true : false;
          childrenobj.isDisabled =
            element.isDisabled == "true" ? true : false;
          childrenobj.graduationDay =
            this._CustomDatePipe.transform(element.graduationDay) || "";
          childrenarray.push(childrenobj);
        });
      }


      // console.log(childrenarray)
      let inputData: any = {
        recaptchaCheck: false,
        g_recaptcha_response: '',

        planEnrollmentDate: this.userForm.value.planEnrollmentDate
          ? this._CustomDatePipe.transform(
            this.userForm.value.planEnrollmentDate
          )
          : undefined,
        first_name: this.capitalize(value.firstName),
        firstName: this.capitalize(value.firstName),
        last_name: this.capitalize(value.lastName),
        lastName: this.capitalize(value.lastName),
        email: value.email,
        phone_number: value.phone_number,
        // phone_number: "+1 (514) 336-5595",
        // "marital_status":value.matrialstatus,
        street_address_line1: value.street_address_line1,
        street_address_line2: value.street_address_line2 || '',
        city: this.capitalize(value.city),
        province: value.province,
        province_id: parseInt(this.provineList.id),
        working_province_id: this.working_provincelistid,
        workingProvince: value.workingProvince,
        country: 'Canada',
        postal_code: value.postal_code,
        date_of_birth: this._CustomDatePipe.transform(value.date_of_birth),
        dob: this._CustomDatePipe.transform(value.date_of_birth),
        gender: value.gender,
        company_name: value.company_name,
        job_title: this.capitalize(value.job_title),
        date_of_hiring: this._CustomDatePipe.transform(value.date_of_hiring),
        working_20hours: value.working_20hours == 'true' ? true : false,
        hours_per_week:
          value.working_20hours == 'true' ? parseInt(value.hours_per_week) : 0,
        provincial_health_coverage:
          value.provincial_health_coverage == 'true' ? true : false,
        work_visa: '',
        having_spouse: value.having_spouse == 'true' ? true : false,
        spouse_details: SpouseDetails,
        having_dependent_children:
          value.having_dependent_children == 'true' ? true : false,
        no_of_children:
          value.having_dependent_children == 'true' ? value.no_of_children : 0,
        // children_details:
        //   this.childInfoAddDataArray.length > 0
        //     ? this.childInfoAddDataArray
        //     : '',
        children_details: childrenarray.length > 0
          ? childrenarray
          : '',
        signature: '',
        fusebillCustomer: true,
        formId: parseInt(this.formConfigResult.form.id),
        brokerId: parseInt(this.formConfigResult.form.brokerId),
        // "formId":1,
        termsandconditions: true,
        disclouseradvisor: true,
        state: this.provineList.name,
        state_id: parseInt(this.provineList.id),
        fusebillStateId: parseInt(this.provineList?.fusebillId),
        fusebillCountryId: 124,
        country_id: 1,

        // "fusebillCustomerId":sessionStorage.getItem("fusebillCustomerId") || null,
        currency: 'CAD',
        currencySymbol: '$',
        apt: value.apt,
        pageUrl: window.location.href,
        employerName: value.employername || "",
        //  "fusebillCustomerAddressId":sessionStorage.getItem("fusebillCustomerAddressId") || null
      };

      inputData.employeeKey = sessionStorage.getItem("employeelink");

      if (inputData.employeeKey && inputData.employeeKey != "undefined") {
        inputData.employeeKey = sessionStorage.getItem("employeelink");
        inputData.employeeGeneric = false;
      } else {
        inputData.employeeKey = "";
        inputData.employeeGeneric = true;
      }
      if (
        inputData.provincial_health_coverage == false
      ) {
        inputData['phrDetails'] = mainDetails;
      }

      if (this.userForm.get('having_dependent_children')?.value === 'true') {
        if (this.childTableRowsLength > 0) {
        }
        else {
          const invalid: any = []
          this.missingInformation = true


          invalid.push(this.lang.Please_add_at_least_one_child);
          this.missingInfo = invalid;
          return invalid;
        };
      }
      this._CorporateService.validation(inputData).subscribe({
        next: (result: any) => {
          if (result.status == '200') {

            if (this.activeTab < this.tabs.length - 1) {

              this.previousTab = this.activeTab;
              this.activeTab++;
              this.visitedTabs.add(this.previousTab);


            }

            sessionStorage.setItem(
              "customerTierId",
              result.data.customer.customerTierId
            );
            sessionStorage.setItem("employeeshare", "0");
            this.mainarray = [];

            sessionStorage.setItem("planDetailsArrayBlock", "[]");
            this.effectiveEmployeeTotalContibution = 0;
            sessionStorage.setItem("totalAmount", "0");
            sessionStorage.setItem("totalAmountUI", "0");

            sessionStorage.setItem("allSelectedPlans", "[]");

            sessionStorage.setItem("plansummary", "[]");
            sessionStorage.setItem("selectedPlans", "{}");
            sessionStorage.setItem("selectedPlansLife", "{}");
            sessionStorage.setItem("plansummarymain", "[]");
            sessionStorage.setItem("plansummaryopt", "[]");
            sessionStorage.removeItem("Spousesignature")

            this.addbenificaryData = []
            sessionStorage.setItem(
              "corporateStateId",
              result.data.workingProvince.id
            );
            sessionStorage.setItem(
              "corporateStateName",
              result.data.workingProvince.name
            );
            sessionStorage.setItem(
              "companyStateId",
              result.data.corporateProvince.id
            );


            this.allFormData = {}
            this.formData = {}
            sessionStorage.setItem('plansummaryLife', '[]');
            sessionStorage.setItem('planssummarymainLife', '[]');


            setTimeout(() => {
              sessionStorage.setItem("Data", JSON.stringify(inputData));
              this.autoSelectCompanyPaidPlans();

              sessionStorage.setItem(
                "enrollmentdate",
                result.data.planEnrollmentDate
              );

            }, 2000);

            ///here
            sessionStorage.removeItem("paymentfail");
            this.companypaidproductid = [];
            this.disabledcompanypaidproductid = [];
            this.removedisabledcompanypaidproductid = [];
            this.adddisabledcompanypaidproductid = [];
            // this.applicantfirstname = this.capitalize(value.givenname);
            // this.planssummary = JSON.parse(
            //   sessionStorage.getItem("plansummary")
            // );


            this.applicantSpousename = value.spousefirstname || "";
            this.applicantfirstname = inputData.firstName;

            // console.log(this.applicantfirstname)

            childrenarray.forEach((element: any) => {
              childarry.push(element.first_name);
            });
            this.applicantChildrenname = childarry || "";

            if (
              value.having_spouse == "true" &&
              value.having_dependent_children == "true"
            ) {
              this.completeapplicantinfo =
                this.lang.Based_on_the_information_provided_you_your_spouse
                +
                " " +this.applicantSpousename +
                " " +
                this.lang.and_your_dependent_s+
                " " +
                this.applicantChildrenname +
                " " +
                this.lang.are_eligible_to_apply_for_the_following_plans;
            } else if (value.having_spouse == "true") {
              this.completeapplicantinfo =
                this.lang.Based_on_the_information_provided_you_your_spouse +
                " " +
                this.applicantSpousename +
                " " +
                this.lang.are_eligible_to_apply_for_the_following_plans;
            } else if (value.having_dependent_children == "true") {
              this.completeapplicantinfo =
                this.lang.Based_on_the_information_provided_you_and_your_dependent_s +
                " " +
                this.applicantChildrenname +
                " " +
                this.lang.are_eligible_to_apply_for_the_following_plans;
            } else {
              this.completeapplicantinfo = this.lang.Based_on_the_information_provided_you_are_eligible_to_apply_for_the_following_plans;
            }
            sessionStorage.setItem(
              "completeapplicantinfo",
              this.completeapplicantinfo
            );


            this.specialpackages = [];
            this.packagesInBlocks = result.data.blocks;
            this.basepackages = result.data.packages;
            let companyplans = this.convertpackagesToPlans2(
              this.packagesInBlocks
            )[1];
            let baseplans = this.convertpackagesToPlans2(this.basepackages)[1];
            this.calculateBasePlanDeltasforCCplans(
              this.packagesInBlocks.coveredByCompany,
              baseplans,
              companyplans
            );

            this.mainarray = this.cobminedcommonfunction(
              this.packagesInBlocks.paidByCompany,
              this.modifiedBlocksCoveredByCompany,
              this.packagesInBlocks.paidByEmployee
            );

            let allPackages = [];
            for (let key in this.mainarray) {
              //this.consoleMessage(key)
              allPackages.push(this.mainarray[key]);
            }
            this.mainarray = allPackages;

            console.log(this.mainarray)

            this.mainarray.forEach((element: any) => {
              element.groups.forEach((group: any) => {
                if (group.categorization.paidByCompany == 1) {
                  group.plans.forEach((plan: any) => {
                    let pl = plan
                    Object.keys(plan.productAddonss).forEach((key) => {
                      plan.productAddonss[key].forEach((product: any) => {

                        if (pl.isBundle) {
                          this.companypaidproductid.push(pl);
                        }
                        else {
                          this.companypaidproductid.push(product.planlevel);
                        }


                        const dom: HTMLElement = this.elementRef.nativeElement;

                        const plan: any = dom.querySelector("#plancheck" + product.id);

                        if (plan) {
                          plan.checked = false;
                        }
                        // $("#plancheck" + product.id).prop("checked", false);
                      });
                    });
                  });
                }

                setTimeout(() => {
                  if (group.categorization.coveredByCompany == 1) {
                    group.plans.forEach((plan: any) => {
                      Object.keys(plan.productAddonss).forEach((key) => {
                        plan.productAddonss[key].forEach((product: any) => {
                          // $("#plancheck" + product.id).prop("checked", false);
                          const dom: HTMLElement = this.elementRef.nativeElement;

                          const plan: any = dom.querySelector("#plancheck" + product.id);

                          if (plan) {
                            plan.checked = false;
                          }
                        });
                      });
                    });
                  }
                  if (group.categorization.paidByEmployee == 1) {
                    group.plans.forEach((plan: any) => {

                      if(plan.productAddonss){
                      Object.keys(plan.productAddonss).forEach((key) => {
                        plan.productAddonss[key].forEach((product: any) => {
                          // $("#plancheck" + product.id).prop("checked", false);
                          const dom: HTMLElement = this.elementRef.nativeElement;

                          const plan: any = dom.querySelector("#plancheck" + product.id);

                          if (plan) {
                            plan.checked = false;
                          }
                        });
                      });
                    }
                    });
                  }
                }, 1000);
              });
            });

            this.planAmount = ''
            this.plansnexttab = true;
            sessionStorage.setItem("stateid", result.data.province.id);


            sessionStorage.setItem(
              "planDetailsArray",
              JSON.stringify(this.specialpackages)
            );
            sessionStorage.setItem(
              "planDetailsArrayBlock",
              JSON.stringify(this.mainarray)
            );
          } else {


            this.ShowModelPopup = true
            this.ShowModelPopupMessage = result.message
              ? result.message
              : result.error
            this.ShowModelPopupTitle = this.lang.Info
          }
        },
        error: (error) => {
          if ([0, 500, 501, 502, 503].includes(error.status)) {

            this.ShowModelPopup = true
            this.ShowModelPopupMessage = this.lang.Sorry_the_server_is_currently_undergoing_maintenance_Please_try_again_later_We_apologize_for_any_inconvenience;
            this.ShowModelPopupTitle = this.lang.Info
          } else {



            this.ShowModelPopup = true
            this.ShowModelPopupMessage = error.error.message
              ? error.error.message
              : error.error.error
            this.ShowModelPopupTitle = this.lang.Info
          }
        },
      });
    }
  }

  showPackageName(groups:any) {
    // if (category == 'upgrade') console.log(groups[0].name)
    // return
    // console.log(category)

    for (let grp of groups){
      if (!grp || grp?.length <= 0) {
        return false
      }
      if (!grp || !grp?.plans || grp?.plans?.length <= 0) {
        return false
      }else{
        return true
      }

    }


    return false
  }
  public getSliderBackground(
    rangeValue: any,
    maxRange: any,
    stepCount: any,
    defaultRange: any
  ): any {
    const percentage = (rangeValue / maxRange) * 100;
    let gradientColor = "white";


    // if (percentage >= 8 && stepCount === 25000) {
    //   gradientColor = "green";
    // }
    // return `linear-gradient(to right, ${gradientColor} ${percentage}%, white ${percentage}%)`;



    if (rangeValue <= defaultRange) {
      this.greenValue = (defaultRange / maxRange) * 100;
      this.names = [
        { label: 'Guaranteed', position: this.greenValue },    // Display "Start" at 0%
        { label: 'Pending', position: 100 - this.greenValue }, // Display "Quarter" at 25%

      ];
      this.showyellowbar =false
      return `linear-gradient(to right, green ${(defaultRange / maxRange) * 100}%, lightgray 0%)`;
    } else if (rangeValue > defaultRange) {
      const greenPart = adjustWith2DecimalsForUI((defaultRange / maxRange) * 100);
      console.log(greenPart)
      this.yellowValue = adjustWith2DecimalsForUI((100 - greenPart) * 100);
      // const yellowPart = ((rangeValue - defaultRange) / 4.5) + greenPart;
      // const yellowPart = adjustWith2DecimalsForUI(100 - greenPart)
      const yellowPart =adjustWith2DecimalsForUI((rangeValue / maxRange)*100)
      this.names = [
        { label: 'Guaranteed', position: greenPart },    // Display "Start" at 0%
        { label: 'Pending', position: yellowPart }, // Display "Quarter" at 25%

      ];
      this.showyellowbar =true
      // return`linear-gradient(to right, green ${greenPart}%, yellow ${yellowPart}%)`;
      // return `linear-gradient(to right, red 30%, yellow 70%)`;
      //  return `linear-gradient(to right, green 0%, green 30%, yellow 30%, yellow 100%)`  working
      return `linear-gradient(to right,  green 0%,
      green ${greenPart}%,
      yellow ${greenPart}%,
      yellow ${yellowPart}%,
      lightgray  ${yellowPart}%,
      lightgray  100%)`;
       //  return `linear-gradient(to right, red ${greenPart}%, yellow ${yellowPart}%)`
      // return`linear-gradient(to right, green 0%, green ${greenPart}%, yellow ${yellowPart}%, yellow 100%)`;

    }
  }
  public getSliderBackground1q(
    rangeValue: any,
    maxRange: any,
    stepCount: any
  ): string {
    const percentage = (rangeValue / maxRange) * 100;
    let gradientColor = "#efe378";

    if (percentage >= 11 && stepCount === 25000) {
      gradientColor = "green";
    }
    return `linear-gradient(to right, ${gradientColor} ${percentage}%, #efe378 ${percentage}%)`;
  }
  public getSliderBackground1(
    rangeValue: any,
    maxRange: any,
    stepCount: any
  ): string {
    const percentage = (rangeValue / maxRange) * 100;
    let gradientColor = "yellow";

    if (percentage >= 11 && stepCount === 25000) {
      gradientColor = "green";
    }
    return `linear-gradient(to right, ${gradientColor} ${percentage}%, yellow ${percentage}%)`;
  }
  public isDataAvailable(type: string, category: string, feild?: string): boolean {
    // console.log(this.allFormData)
    // console.log(type)
    // console.log(category)
    // console.log(feild)
    const bool = feild ? !(this.allFormData[type] && this.allFormData[type][category] && this.allFormData[type][category][feild]) : !(this.allFormData[type] && this.allFormData[type][category]);
    return bool
  }

  public isDataAvailableSpouse(type: string, category: string, feild?: string): boolean {

    if (this.allFormData[type]) {
      console.log(this.allFormData[type]['member'])
    }

    if (this.allFormData[type] && this.allFormData[type]['member']) {
      return false
    }
    else {
      return true
    }


  }
  public generateTicks(maxRange: any, stepCount: any): any[] {
    const ticks = [];
    const totalTicks = maxRange / stepCount;

    for (let i = 0; i <= totalTicks; i++) {
      ticks.push(i * stepCount);
    }

    return ticks;
  }

  public updateTotal(data: any) {
    const memberPremium =
      data.member && data.member.premium > 0 ? data.member.premium : 0;
    const spousePremium =
      data.spouse && data.spouse.premium > 0 ? data.spouse.premium : 0;
    const dependentPremium =
      data.dependent && data.dependent.premium > 0 ? data.dependent.premium : 0;
    const total = memberPremium + spousePremium + dependentPremium;

    return total;
  }
  public updateFormData(
    type: string,
    category: string,
    newData: any,
    remove: boolean = false
  ): void {


    if (this.lastUpdatedType !== type) {
      this.formData = this.allFormData[type]
        ? { ...this.allFormData[type] }
        : {};
    }

    let updatedFormData = { ...this.formData };
    let shouldUpdateTotal = false;
    console.log(updatedFormData)
    if (remove) {
      delete updatedFormData[category];
      delete updatedFormData.total;
    } else if (typeof newData === "object" && newData !== null) {
      updatedFormData[category] = { ...updatedFormData[category], ...newData };
      shouldUpdateTotal = "coverage" in newData || category === "dependent" || "isSmoker" in newData;
    } else {
      updatedFormData[category] = newData;
    }

    if (
      shouldUpdateTotal &&
      ["member", "spouse", "dependent"].includes(category)
    ) {
      updatedFormData.total = this.updateTotal(updatedFormData);
    }

    this.formData = updatedFormData;
    this.allFormData[type] = { ...this.formData };
    // this.allFormData = { ...this.allFormData, [type]: this.formData };
    this.lastUpdatedType = type;
    console.log("formData:", this.allFormData);
  }
  public updateFormData1(
    type: string,
    category: string,
    newData: any,
    remove: boolean = false
  ): void {

    console.log(type)
    console.log(category)
    console.log(newData)
    if (this.lastUpdatedType !== type) {
      this.formData = this.allFormData[type]
        ? { ...this.allFormData[type] }
        : {};
    }

    let updatedFormData = { ...this.formData };
    let shouldUpdateTotal = false;

    if (remove) {
      delete updatedFormData[category];
      delete updatedFormData.total;
    } else if (typeof newData === "object" && newData !== null) {
      updatedFormData[category] = { ...updatedFormData[category], ...newData };
      shouldUpdateTotal = "coverage" in newData || category === "dependent" || "isSmoker" in newData;
    } else {
      updatedFormData[category] = newData;
    }

    if (
      shouldUpdateTotal &&
      ["member", "spouse", "dependent"].includes(category)
    ) {
      updatedFormData.total = this.updateTotal(updatedFormData);
    }

    this.formData = updatedFormData;
    this.allFormData[type] = { ...this.formData };
    // this.allFormData = { ...this.allFormData, [type]: this.formData };
    this.lastUpdatedType = type;
    console.log("formData:", this.allFormData);
  }
  public calculateAge(dob: string) {
    const today = new Date();
    const birthDate = new Date(dob);

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  }

  public updatePremium(
    type: string,
    category: string,
    newIsSmokerValue: string
  ) {

    //  if (this.allFormData[type][category]) {
    //   const isSmokerValue = event.target.value=== "yes" ? "smoker" : "nonSmoker";

    //   console.log(isSmokerValue)
    //   let gender:any = this.userForm.value.gender?.toLowerCase()
    //   let value: any = 0;
    //   console.log(this.allFormData[type][category]["coverage"])
    //   value=this.allFormData[type][category]["coverage"][gender][isSmokerValue]
    //   console.log(value)
    //   const premium = (this.allFormData[type][category]["coverage"].maximumCoverageWithoutEvidence * value) / 1000;
    //   console.log(parseFloat(premium.toFixed(2)))
    //   this.updateFormData(type, category, {
    //     isSmoker: isSmokerValue,
    //     premium: parseFloat(premium.toFixed(2))
    //   });
    // }

    console.log(this.allFormData)
    const coverage = this.allFormData[type][category].coverage.maximumCoverageWithoutEvidence / 25000;
    const isSmoker = newIsSmokerValue === "yes" ? "smoker" : "nonSmoker";
    let value: any = 0;
    let gender: any = this.userForm.value.gender?.toLowerCase()
    let spouseGender: any = this.userForm.value.spousegender?.toLowerCase()

    console.log(spouseGender)

    if (category === "member") {
      value = this.allFormData[type][category]["coverage"]["price"][gender][isSmoker]
    }

    if (category === "spouse") {
      value = this.allFormData[type][category]["coverage"]["price"][spouseGender][isSmoker]
    }
    console.log({ value })

    this.updateFormData(type, category, { constant: value });

    if (coverage > 0) {
      const premium = (coverage * value);
      // const premium = (coverage * value) / 1000;
      return parseFloat(premium.toFixed(2));
    }
    console.log("formData:", this.allFormData);
    return 0;

  }

  public updateDependentPremium(type: string, category: string, coverage: number) {
    // const constant = coverage > 5000 ? 2.56 : 1.28;
    // this.updateFormData(type, category, { constant: constant });

    // const p =
    //   (constant * coverage) / 1000;
    // return parseFloat(p.toFixed(2));
  }

  public onCheckboxChangeP(
    event: any,
    type: string,
    category: string,
    key1: string,
    value1: any,
    key2?: string,
    value2?: any
  ): void {

    console.log(this.allFormData)


    console.log(value1)
    console.log(value1?.member)
    // console.log(value1?.spouse)
    if (category == 'member') {
      this.updateFormData(type, 'member', { coverage: value1.member })
    }
    if (category == 'spouse') {
      if (this.userForm.value.having_spouse === 'true') {
        this.updateFormData(type, 'spouse', { coverage: value1.spouse });
      }
    }



    let planSummaryLife = JSON.parse(sessionStorage.getItem('plansummaryLife') || '[]');
    if (event.target.checked) {
      this.updateFormData(type, category, { [key1]: value1 });
      const dom: HTMLElement = this.elementRef.nativeElement;
      const plan: any = dom.querySelector("." + category + "-" + type + "-no");
      console.log(plan)
      setTimeout(() => {
        // plan.click()
      }, 100);


    } else {

      console.log(type)
      console.log(category)
      this.updateFormData(type, category, {}, true);

      if (category == 'spouse') {
        this.updateFormData(type, 'member', {}, true);


        const planCheck = this.renderer.selectRootElement("#planCheckLife" + type + "-" + 'MEMBER', true);
        this.renderer.setProperty(planCheck, 'checked', false);
      }
      if (category == 'member') {
        this.updateFormData(type, 'spouse', {}, true);

        planSummaryLife.forEach((element: any, index: any) => {

          if (element.coverage == 'MEMBER & SPOUSE') {
            const planCheck = this.renderer.selectRootElement("#planCheckLife" + type + "-" + 'SPOUSE', true);
            this.renderer.setProperty(planCheck, 'checked', false);
          }

        })

      }
      this.unselectplanLife(event)
    }


  }
  public onInputChangeP(e: Event, type: string, category: string): void {
    const inputElement = e.target as HTMLInputElement;
    if (inputElement && this.allFormData[type][category]) {
      const obj = { [inputElement.name]: inputElement.value };
      this.updateFormData(type, category, obj);
    }
  }




  public handleRangeChangeForDependent(
    value: any,
    type: string,
    category: string
  ): void {
    // if (this.allFormData[type][category]) {
    //   this.updateFormData(type, category, {
    //     // coverage: value,
    //     premium: this.updateDependentPremium(type, category, value),
    //   });
    // }
  }

  // public handleRadioChange(event: any, type: string, category: string, options: any, plan: any, product: any, pckage: any, group: any, value: any): void {


  //   // console.log(plan)
  //   // console.log(product)
  //   // console.log(pckage)
  //   console.log(group.categorization)

  //   let isSmokerValue = event.target.value;
  //   this.allFormData[type][category].coverage = value

  //   if (this.allFormData[type][category]) {

  //     this.updateFormData(type, category, {
  //       isSmoker: isSmokerValue,
  //       premium: this.updatePremium(type, category, isSmokerValue),
  //     });
  //   }
  //   this.rangeValues = [];
  //   console.log(this.allFormData)
  //   let plansummaryLife = JSON.parse(sessionStorage.getItem('plansummaryLife') || '[]');
  //   let lifeCoverage = ''
  //   let memberObj: any = {}
  //   let spouseObj: any = {}

  //   if (category == "member") {
  //     lifeCoverage = "MEMBER"
  //   }
  //   else {
  //     lifeCoverage = "MEMBER & SPOUSE"
  //   }


  //   let plandetails = event.target.attributes.data.value;

  //   let plandetailsobj = plandetails.split('##');


  //   plansummaryLife.forEach((element: any, index: any) => {

  //     if (element.planname == plandetailsobj[14]) {
  //       plansummaryLife.splice(index, 1)
  //     }
  //     sessionStorage.setItem('plansummaryLife', JSON.stringify(plansummaryLife));

  //   })
  //   setTimeout(() => {

  //     let obj: any = {
  //       // upgradeCalculation: {},
  //       paidByCompany: group.categorization.paidByCompany,
  //       coveredByCompany: group.categorization.coveredByCompany,
  //       paidByEmployee: group.categorization.paidByEmployee,

  //       isBundle: plan.isBundle,
  //       enrollmentDate: this.userForm.value.planEnrollmentDate
  //         ? this._CustomDatePipe.transform(
  //           this.userForm.value.planEnrollmentDate
  //         )
  //         : undefined,
  //       name: plandetailsobj[14],
  //       details: plandetailsobj[0],
  //       packagename: plandetailsobj[0],
  //       groupName: plandetailsobj[1],
  //       amount: this.allFormData[type].total,
  //       // amount: this.allFormData[type][category].premium,
  //       planCoverage: plandetailsobj[2],
  //       planPrice: adjustWith2DecimalsForUI(this.allFormData[type].total),
  //       amountUI:
  //         '$' +
  //         adjustWith2DecimalsForUI(this.allFormData[type].total),
  //       gst:
  //         plandetailsobj[4] == null ||
  //           plandetailsobj[4] == '' ||
  //           plandetailsobj[4] == undefined ||
  //           plandetailsobj[4] == 'null'
  //           ? 0
  //           : parseFloat(plandetailsobj[4]),
  //       hst:
  //         plandetailsobj[5] == null ||
  //           plandetailsobj[5] == '' ||
  //           plandetailsobj[5] == undefined ||
  //           plandetailsobj[5] == 'null'
  //           ? 0
  //           : parseFloat(plandetailsobj[5]),
  //       pst:
  //         plandetailsobj[6] == null ||
  //           plandetailsobj[6] == '' ||
  //           plandetailsobj[6] == undefined ||
  //           plandetailsobj[6] == 'null'
  //           ? 0
  //           : parseFloat(plandetailsobj[6]),
  //       qst:
  //         plandetailsobj[17] == null ||
  //           plandetailsobj[17] == '' ||
  //           plandetailsobj[17] == undefined ||
  //           plandetailsobj[17] == 'null'
  //           ? 0
  //           : parseFloat(plandetailsobj[17]),
  //       gstCheck:
  //         plandetailsobj[4] == null ||
  //           plandetailsobj[4] == '' ||
  //           plandetailsobj[4] == undefined ||
  //           plandetailsobj[4] == 'null'
  //           ? false
  //           : true,
  //       hstCheck:
  //         plandetailsobj[5] == null ||
  //           plandetailsobj[5] == '' ||
  //           plandetailsobj[5] == undefined ||
  //           plandetailsobj[5] == 'null'
  //           ? false
  //           : true,
  //       pstCheck:
  //         plandetailsobj[6] == null ||
  //           plandetailsobj[6] == '' ||
  //           plandetailsobj[6] == undefined ||
  //           plandetailsobj[6] == 'null'
  //           ? false
  //           : true,
  //       qstCheck:
  //         plandetailsobj[17] == null ||
  //           plandetailsobj[17] == '' ||
  //           plandetailsobj[17] == undefined ||
  //           plandetailsobj[17] == 'null'
  //           ? false
  //           : true,
  //       id: parseFloat(plandetailsobj[7]),
  //       fusebillPlanID:
  //         plandetailsobj[8] == null ||
  //           plandetailsobj[8] == '' ||
  //           plandetailsobj[8] == undefined ||
  //           plandetailsobj[8] == 'null'
  //           ? 0
  //           : parseFloat(plandetailsobj[8]),
  //       planFrequencyID:
  //         plandetailsobj[9] == null ||
  //           plandetailsobj[9] == '' ||
  //           plandetailsobj[9] == undefined ||
  //           plandetailsobj[9] == 'null'
  //           ? 0
  //           : parseFloat(plandetailsobj[9]),
  //       optIn: plandetailsobj[10] == 'true' ? true : false,
  //       planname: plandetailsobj[14],
  //       planLevel: parseInt(plandetailsobj[15]),
  //       packageId: parseInt(plandetailsobj[16]),
  //       options: [],
  //       version: plan.version,
  //       description: plan.description,
  //       insuranceType: plan.insuranceType,


  //       //  "disallowedPlanLevels":plandetailsobj[11]
  //     };

  //     console.log(obj)
  //     this.productobj = {
  //       id: product.id,
  //       productId: product.productId,
  //       name: product.name,
  //       planProductId: product.planProductId,
  //       price: this.allFormData[type].total,
  //       tax: this._decimalPipe
  //         .transform(
  //           product.calculatedTax ? product.calculatedTax.tax : 0,
  //           '1.2-2'
  //         )
  //         ?.replace(/,/g, ''),
  //       total: this._decimalPipe
  //         .transform(
  //           product.calculatedTax ? product.calculatedTax.total : 0,
  //           '1.2-2'
  //         )
  //         ?.replace(/,/g, ''),
  //       tax_details:
  //         product.bundledTaxes && product.bundledTaxes.length > 0
  //           ? product.bundledTaxes
  //           : product.taxesDataJSON,
  //       calculatedTax: this.calculateTaxForProductTaxesData(this.allFormData[type].total, product.taxesDataJSON ?? []),
  //       bundledProducts: product.bundledProducts,
  //       planCoverage: lifeCoverage,
  //       planLevel: product.planlevel ? product.planlevel.id : '',
  //       planLevelParent: product.planlevel ? product.planlevel.parentId : '',
  //       description: product.description,
  //       coverage: lifeCoverage,
  //       version: product.version
  //     };

  //     this.productobj.total = this._decimalPipe
  //       .transform(
  //         this.productobj.calculatedTax ? this.productobj.calculatedTax.total : 0,
  //         '1.2-2'
  //       )
  //       ?.replace(/,/g, '')
  //     this.productobj.tax = this._decimalPipe
  //       .transform(
  //         this.productobj.calculatedTax ? this.productobj.calculatedTax.tax : 0,
  //         '1.2-2'
  //       )
  //       ?.replace(/,/g, '')

  //     if (category == "member") {

  //       memberObj = {
  //         "planStartDate": this.userForm.value.planEnrollmentDate
  //           ? this._CustomDatePipe.transform(
  //             this.userForm.value.planEnrollmentDate
  //           )
  //           : undefined,
  //         "units": (this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence / this.allFormData[type][category].coverage.minimumCoverage),
  //         "standardUnits": this.allFormData[type]["member"].coverage.minimumCoverage,
  //         "coverage": "MEMBER",
  //         "guaranteedAcceptance": this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence >=
  //           this.allFormData[type]["member"].coverage.defaultRange ? true : false, //defalutRange
  //         "pendingMedicalQuestionaire": this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <
  //           this.allFormData[type]["member"].coverage.defaultRange ? true : false,
  //         "smoker": this.allFormData[type]["member"].isSmoker == "yes" ? true : false,
  //         "age": this.allFormData[type]["member"].coverage.age,
  //         "gender": this.userForm.value.gender?.toLowerCase(),
  //         "province": "ON",
  //         "provinceId": 12,
  //         "amount": this.allFormData[type]["member"].premium,
  //         "tax": adjustWith2DecimalsForUI(this.productobj.tax),
  //         "taxRate": "", //not mandatory,
  //         "taxName": "",
  //         "coverageValue": this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence,
  //         "premium": this.allFormData[type]["member"].constant,
  //         "total": adjustWith2DecimalsForUI(this.productobj.total),
  //       }
  //       obj["member"] = memberObj
  //       obj["spouse"] = {}
  //     }
  //     else {

  //       memberObj = {
  //         "planStartDate": this.userForm.value.planEnrollmentDate
  //           ? this._CustomDatePipe.transform(
  //             this.userForm.value.planEnrollmentDate
  //           )
  //           : undefined,
  //         "units": (this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence / this.allFormData[type][category].coverage.minimumCoverage),
  //         "standardUnits": this.allFormData[type]["member"].coverage.minimumCoverage,
  //         "coverage": "MEMBER",
  //         "guaranteedAcceptance": this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence >=
  //           this.allFormData[type]["member"].coverage.defaultRange ? true : false, //defalutRange
  //         "pendingMedicalQuestionaire": this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <
  //           this.allFormData[type]["member"].coverage.defaultRange ? true : false,
  //         "smoker": this.allFormData[type]["member"].isSmoker == "yes" ? true : false,
  //         "age": this.allFormData[type]["member"].coverage.age,
  //         "gender": this.userForm.value.gender?.toLowerCase(),
  //         "province": "ON",
  //         "provinceId": 12,
  //         "amount": this.allFormData[type]["member"].premium,
  //         "tax": adjustWith2DecimalsForUI(this.productobj.tax),
  //         "taxRate": "", //not mandatory,
  //         "taxName": "",
  //         "coverageValue": this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence,
  //         "premium": this.allFormData[type]["member"].constant,
  //         "total": adjustWith2DecimalsForUI(this.productobj.total),
  //       }
  //       spouseObj = {
  //         "planStartDate": this.userForm.value.planEnrollmentDate
  //           ? this._CustomDatePipe.transform(
  //             this.userForm.value.planEnrollmentDate
  //           )
  //           : undefined,
  //         "units": (this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence / this.allFormData[type][category].coverage.minimumCoverage),
  //         "standardUnits": this.allFormData[type]["spouse"].coverage.minimumCoverage,
  //         "coverage": "MEMBER & SPOUSE",
  //         "guaranteedAcceptance": this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence >=
  //           this.allFormData[type]["spouse"].coverage.defaultRange ? true : false, //defalutRange
  //         "pendingMedicalQuestionaire": this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <
  //           this.allFormData[type]["spouse"].coverage.defaultRange ? true : false,
  //         "smoker": this.allFormData[type]["spouse"].isSmoker == "yes" ? true : false,
  //         "age": this.allFormData[type]["spouse"].coverage.age,
  //         "gender": this.userForm.value.spousegender?.toLowerCase(),
  //         "province": "ON",
  //         "provinceId": 12,
  //         "amount": this.allFormData[type]["spouse"].premium,
  //         "tax": adjustWith2DecimalsForUI(this.productobj.tax),
  //         "taxRate": "", //not mandatory,
  //         "taxName": "",
  //         "coverageValue": this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence,
  //         "premium": this.allFormData[type]["spouse"].constant,
  //         "total": adjustWith2DecimalsForUI(this.productobj.total),
  //       }

  //       obj["member"] = memberObj
  //       obj["spouse"] = spouseObj
  //     }

  //     let obj1: any = {
  //       packageId: plan.packageId,
  //       packageName: pckage.name,

  //       planproductname: this.productobj.name,

  //       groupid: plan.planLevel,
  //       groupName: group.name,
  //       id: plan.id,
  //       name: plan.name,
  //       planLevel: plan.planLevel ? plan.planLevel : group.id,
  //       planLevelParent: group.parentId,
  //       fusebillPlanID: plan.fusebillId,
  //       planFrequencyID: plan.frqMonthly,
  //       isBundle: plan.isBundle,
  //       coverage: lifeCoverage,
  //       planCoverage: product.planCoverage,
  //       bundledProducts: [],
  //       products: [],
  //       version: plan.version,


  //     };
  //     obj1.products.push(this.productobj);
  //     //this.consoleMessage(obj1)
  //     // console.log(obj)
  //     // console.log(obj1)
  //     this.addtoplansummaryLife(obj, obj1);
  //   }, 100);

  //   for (let i = this.minValue; i <= this.allFormData[type][category]?.coverage?.maximumCoverageWithEvidence; i += 50000) {
  //     this.rangeValues.push(i);
  //   }
  // }
  // public handleRangeChange(value: any, type: string, category: string, options: any, plan: any, product: any, pckage: any, group: any, event: any, data: any): void {
  //   // console.log(value)
  //   console.log(type)
  //   console.log(event)
  //   this.allFormData[type][category].coverage = data

  //   console.log(this.allFormData)
  //   if (this.allFormData[type][category]) {
  //     this.updateFormData(type, category, {
  //       // coverage: value,
  //       isSmoker: this.allFormData[type][category].isSmoker,
  //       premium: this.updatePremium(type, category, this.allFormData[type][category].isSmoker),
  //     });
  //   }
  //   this.rangeValues = [];
  //   let lifeCoverage = ''
  //   let memberObj: any = {}
  //   let spouseObj: any = {}
  //   if (category == "member") {
  //     lifeCoverage = "MEMBER"
  //   } else {
  //     lifeCoverage = "MEMBER & SPOUSE"
  //   }
  //   console.log(this.plansData)
  //   let plansummaryLife = JSON.parse(sessionStorage.getItem('plansummaryLife') || '[]');


  //   let plandetails = event.target.attributes.data.value;

  //   let plandetailsobj = plandetails.split('##');


  //   plansummaryLife.forEach((element: any, index: any) => {

  //     if (element.planname == plandetailsobj[14]) {
  //       plansummaryLife.splice(index, 1)
  //     }
  //     sessionStorage.setItem('plansummaryLife', JSON.stringify(plansummaryLife));

  //   })
  //   setTimeout(() => {
  //     // console.log(this.allFormData[type][category].premium)

  //     let obj: any = {
  //       isBundle: plan.isBundle,
  //       // enrollmentDate: moment(
  //       //   this.userForm.get("planenrollmentdate").value,
  //       //   "MM-DD-YYYY"
  //       // ).format("YYYY-MM-DD"),
  //       enrollmentDate: this.userForm.value.planEnrollmentDate
  //         ? this._CustomDatePipe.transform(
  //           this.userForm.value.planEnrollmentDate
  //         )
  //         : undefined,
  //       // "name":plandetailsobj[1]+"-"+plandetailsobj[2],
  //       name: plandetailsobj[14],
  //       details: plandetailsobj[0],
  //       packagename: plandetailsobj[0],
  //       groupName: plandetailsobj[1],
  //       amount: this.allFormData[type].total,
  //       // amount: this.allFormData[type][category].premium,
  //       planCoverage: plandetailsobj[2],
  //       planPrice: adjustWith2DecimalsForUI(this.allFormData[type].total),
  //       amountUI:
  //         '$' +
  //         adjustWith2DecimalsForUI(this.allFormData[type].total),
  //       gst:
  //         plandetailsobj[4] == null ||
  //           plandetailsobj[4] == '' ||
  //           plandetailsobj[4] == undefined ||
  //           plandetailsobj[4] == 'null'
  //           ? 0
  //           : parseFloat(plandetailsobj[4]),
  //       hst:
  //         plandetailsobj[5] == null ||
  //           plandetailsobj[5] == '' ||
  //           plandetailsobj[5] == undefined ||
  //           plandetailsobj[5] == 'null'
  //           ? 0
  //           : parseFloat(plandetailsobj[5]),
  //       pst:
  //         plandetailsobj[6] == null ||
  //           plandetailsobj[6] == '' ||
  //           plandetailsobj[6] == undefined ||
  //           plandetailsobj[6] == 'null'
  //           ? 0
  //           : parseFloat(plandetailsobj[6]),
  //       qst:
  //         plandetailsobj[17] == null ||
  //           plandetailsobj[17] == '' ||
  //           plandetailsobj[17] == undefined ||
  //           plandetailsobj[17] == 'null'
  //           ? 0
  //           : parseFloat(plandetailsobj[17]),
  //       gstCheck:
  //         plandetailsobj[4] == null ||
  //           plandetailsobj[4] == '' ||
  //           plandetailsobj[4] == undefined ||
  //           plandetailsobj[4] == 'null'
  //           ? false
  //           : true,
  //       hstCheck:
  //         plandetailsobj[5] == null ||
  //           plandetailsobj[5] == '' ||
  //           plandetailsobj[5] == undefined ||
  //           plandetailsobj[5] == 'null'
  //           ? false
  //           : true,
  //       pstCheck:
  //         plandetailsobj[6] == null ||
  //           plandetailsobj[6] == '' ||
  //           plandetailsobj[6] == undefined ||
  //           plandetailsobj[6] == 'null'
  //           ? false
  //           : true,
  //       qstCheck:
  //         plandetailsobj[17] == null ||
  //           plandetailsobj[17] == '' ||
  //           plandetailsobj[17] == undefined ||
  //           plandetailsobj[17] == 'null'
  //           ? false
  //           : true,
  //       id: parseFloat(plandetailsobj[7]),
  //       fusebillPlanID:
  //         plandetailsobj[8] == null ||
  //           plandetailsobj[8] == '' ||
  //           plandetailsobj[8] == undefined ||
  //           plandetailsobj[8] == 'null'
  //           ? 0
  //           : parseFloat(plandetailsobj[8]),
  //       planFrequencyID:
  //         plandetailsobj[9] == null ||
  //           plandetailsobj[9] == '' ||
  //           plandetailsobj[9] == undefined ||
  //           plandetailsobj[9] == 'null'
  //           ? 0
  //           : parseFloat(plandetailsobj[9]),
  //       optIn: plandetailsobj[10] == 'true' ? true : false,
  //       planname: plandetailsobj[14],
  //       planLevel: parseInt(plandetailsobj[15]),
  //       packageId: parseInt(plandetailsobj[16]),
  //       options: [],
  //       version: plan.version,
  //       description: plan.description,
  //       insuranceType: plan.insuranceType,
  //       upgradeCalculation: '',
  //       paidByCompany: group.categorization.paidByCompany,
  //       coveredByCompany: group.categorization.coveredByCompany,
  //       paidByEmployee: group.categorization.paidByEmployee,

  //       //  "disallowedPlanLevels":plandetailsobj[11]
  //     };
  //     if (plandetailsobj[11] != null || plandetailsobj[11] != 'null') {
  //       if (plandetailsobj[11].includes(plandetailsobj[12])) {
  //       }
  //     }
  //     ///this.consoleMessage(obj)
  //     this.productobj = {
  //       id: product.id,
  //       productId: product.productId,
  //       name: product.name,
  //       planProductId: product.planProductId,
  //       price: this.allFormData[type].total,
  //       tax: this._decimalPipe
  //         .transform(
  //           product.calculatedTax ? product.calculatedTax.tax : 0,
  //           '1.2-2'
  //         )
  //         ?.replace(/,/g, ''),
  //       total: this._decimalPipe
  //         .transform(
  //           product.calculatedTax ? product.calculatedTax.total : 0,
  //           '1.2-2'
  //         )
  //         ?.replace(/,/g, ''),
  //       tax_details:
  //         product.bundledTaxes && product.bundledTaxes.length > 0
  //           ? product.bundledTaxes
  //           : product.taxesDataJSON,
  //       calculatedTax: this.calculateTaxForProductTaxesData(this.allFormData[type].total, product.taxesDataJSON ?? []),
  //       bundledProducts: product.bundledProducts,
  //       planCoverage: lifeCoverage,
  //       planLevel: product.planlevel ? product.planlevel.id : '',
  //       planLevelParent: product.planlevel ? product.planlevel.parentId : '',
  //       description: product.description,
  //       coverage: lifeCoverage,
  //       version: product.version
  //     };

  //     this.productobj.total = this._decimalPipe
  //       .transform(
  //         this.productobj.calculatedTax ? this.productobj.calculatedTax.total : 0,
  //         '1.2-2'
  //       )
  //       ?.replace(/,/g, '')
  //     this.productobj.tax = this._decimalPipe
  //       .transform(
  //         this.productobj.calculatedTax ? this.productobj.calculatedTax.tax : 0,
  //         '1.2-2'
  //       )
  //       ?.replace(/,/g, '')

  //     if (category == "member") {

  //       memberObj = {
  //         "planStartDate": this.userForm.value.planEnrollmentDate
  //           ? this._CustomDatePipe.transform(
  //             this.userForm.value.planEnrollmentDate
  //           )
  //           : undefined,
  //         "units": (this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence / this.allFormData[type][category].coverage.minimumCoverage),
  //         "standardUnits": this.allFormData[type]["member"].coverage.minimumCoverage,
  //         "coverage": "MEMBER",
  //         "guaranteedAcceptance": this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence >=
  //           this.allFormData[type]["member"].coverage.defaultRange ? true : false, //defalutRange
  //         "pendingMedicalQuestionaire": this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <
  //           this.allFormData[type]["member"].coverage.defaultRange ? true : false,
  //         "smoker": this.allFormData[type]["member"].isSmoker == "yes" ? true : false,
  //         "age": this.allFormData[type]["member"].coverage.age,
  //         "gender": this.userForm.value.gender?.toLowerCase(),
  //         "province": "ON",
  //         "provinceId": 12,
  //         "amount": this.allFormData[type]["member"].premium,
  //         "tax": adjustWith2DecimalsForUI(this.productobj.tax),
  //         "taxRate": "", //not mandatory,
  //         "taxName": "",
  //         "coverageValue": this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence,
  //         "premium": this.allFormData[type]["member"].constant,
  //         "total": adjustWith2DecimalsForUI(this.productobj.total),
  //       }
  //       obj["member"] = memberObj
  //       obj["spouse"] = {}
  //     }
  //     else {

  //       memberObj = {
  //         "planStartDate": this.userForm.value.planEnrollmentDate
  //           ? this._CustomDatePipe.transform(
  //             this.userForm.value.planEnrollmentDate
  //           )
  //           : undefined,
  //         "units": (this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence / this.allFormData[type][category].coverage.minimumCoverage),
  //         "standardUnits": this.allFormData[type]["member"].coverage.minimumCoverage,
  //         "coverage": "MEMBER",
  //         "guaranteedAcceptance": this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence >=
  //           this.allFormData[type]["member"].coverage.defaultRange ? true : false, //defalutRange
  //         "pendingMedicalQuestionaire": this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <
  //           this.allFormData[type]["member"].coverage.defaultRange ? true : false,
  //         "smoker": this.allFormData[type]["member"].isSmoker == "yes" ? true : false,
  //         "age": this.allFormData[type]["member"].coverage.age,
  //         "gender": this.userForm.value.gender?.toLowerCase(),
  //         "province": "ON",
  //         "provinceId": 12,
  //         "amount": this.allFormData[type]["member"].premium,
  //         "tax": adjustWith2DecimalsForUI(this.productobj.tax),
  //         "taxRate": "", //not mandatory,
  //         "taxName": "",
  //         "coverageValue": this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence,
  //         "premium": this.allFormData[type]["member"].constant,
  //         "total": adjustWith2DecimalsForUI(this.productobj.total),
  //       }
  //       spouseObj = {
  //         "planStartDate": this.userForm.value.planEnrollmentDate
  //           ? this._CustomDatePipe.transform(
  //             this.userForm.value.planEnrollmentDate
  //           )
  //           : undefined,
  //         "units": (this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence / this.allFormData[type][category].coverage.minimumCoverage),
  //         "standardUnits": this.allFormData[type]["spouse"].coverage.minimumCoverage,
  //         "coverage": "MEMBER & SPOUSE",
  //         "guaranteedAcceptance": this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence >=
  //           this.allFormData[type]["spouse"].coverage.defaultRange ? true : false, //defalutRange
  //         "pendingMedicalQuestionaire": this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <
  //           this.allFormData[type]["spouse"].coverage.defaultRange ? true : false,
  //         "smoker": this.allFormData[type]["spouse"].isSmoker == "yes" ? true : false,
  //         "age": this.allFormData[type]["spouse"].coverage.age,
  //         "gender": this.userForm.value.spousegender?.toLowerCase(),
  //         "province": "ON",
  //         "provinceId": 12,
  //         "amount": this.allFormData[type]["spouse"].premium,
  //         "tax": adjustWith2DecimalsForUI(this.productobj.tax),
  //         "taxRate": "", //not mandatory,
  //         "taxName": "",
  //         "coverageValue": this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence,
  //         "premium": this.allFormData[type]["spouse"].constant,
  //         "total": adjustWith2DecimalsForUI(this.productobj.total),
  //       }

  //       obj["member"] = memberObj
  //       obj["spouse"] = spouseObj
  //     }

  //     let obj1: any = {
  //       packageId: plan.packageId,
  //       packageName: pckage.name,

  //       planproductname: this.productobj.name,

  //       groupid: plan.planLevel,
  //       groupName: group.name,
  //       id: plan.id,
  //       name: plan.name,
  //       planLevel: plan.planLevel ? plan.planLevel : group.id,
  //       planLevelParent: group.parentId,
  //       fusebillPlanID: plan.fusebillId,
  //       planFrequencyID: plan.frqMonthly,
  //       isBundle: plan.isBundle,
  //       coverage: lifeCoverage,
  //       planCoverage: product.planCoverage,
  //       bundledProducts: [],
  //       products: [],
  //       version: plan.version

  //     };
  //     obj1.products.push(this.productobj);

  //     this.addtoplansummaryLife(obj, obj1);
  //   }, 100);
  //   for (let i = this.minValue; i <= this.allFormData[type][category]?.coverage?.maximumCoverageWithEvidence; i += 50000) {
  //     this.rangeValues.push(i);
  //   }
  // }
  // public handleRadioChange(event: any, type: string, category: string, options: any, plan: any, product: any, pckage: any, group: any, value: any): void {


  //   // console.log(plan)
  //   this.rangeValues = []

  //   let isSmokerValue = event.target.value;
  //   this.allFormData[type][category].coverage = value

  //   if (this.allFormData[type][category]) {

  //     this.updateFormData(type, category, {
  //       isSmoker: isSmokerValue,
  //       premium: this.updatePremium(type, category, isSmokerValue),
  //     });
  //   }

  //   console.log(this.allFormData)
  //   let plansummaryLife = JSON.parse(sessionStorage.getItem('plansummaryLife') || '[]');
  //   let lifeCoverage = ''
  //   let memberObj: any = {}
  //   let spouseObj: any = {}

  //   if (category == "member") {
  //     lifeCoverage = "MEMBER"
  //   }
  //   else {
  //     lifeCoverage = "MEMBER & SPOUSE"
  //   }
  //   if (this.allFormData[type]['spouse']) {
  //     lifeCoverage = "MEMBER & SPOUSE"

  //   }

  //   let plandetails = event.target.attributes.data.value;

  //   let plandetailsobj = plandetails.split('##');


  //   plansummaryLife.forEach((element: any, index: any) => {

  //     if (element.planname == plandetailsobj[14]) {
  //       plansummaryLife.splice(index, 1)
  //     }
  //     sessionStorage.setItem('plansummaryLife', JSON.stringify(plansummaryLife));

  //   })

  //   setTimeout(() => {
  //     // console.log(this.allFormData[type][category].premium)

  //     let obj: any = {
  //       isBundle: plan.isBundle,
  //       categorization:{
  //         paidByCompany: 0,
  //         coveredByCompany: 0,
  //         paidByEmployee: 1,
  //       },
  //       // enrollmentDate: moment(
  //       //   this.userForm.get("planenrollmentdate").value,
  //       //   "MM-DD-YYYY"
  //       // ).format("YYYY-MM-DD"),
  //       enrollmentDate: this.userForm.value.planEnrollmentDate
  //         ? this._CustomDatePipe.transform(
  //           this.userForm.value.planEnrollmentDate
  //         )
  //         : undefined,
  //       // "name":plandetailsobj[1]+"-"+plandetailsobj[2],
  //       name: plandetailsobj[14],
  //       details: plandetailsobj[0],
  //       packagename: plandetailsobj[0],
  //       groupName: plandetailsobj[1],
  //       amount: this.allFormData[type].total,
  //       // amount: this.allFormData[type][category].premium,
  //       planCoverage: plandetailsobj[2],
  //       planPrice: adjustWith2DecimalsForUI(this.allFormData[type].total),
  //       amountUI:
  //         '$' +
  //         adjustWith2DecimalsForUI(this.allFormData[type].total),
  //       gst:
  //         plandetailsobj[4] == null ||
  //           plandetailsobj[4] == '' ||
  //           plandetailsobj[4] == undefined ||
  //           plandetailsobj[4] == 'null'
  //           ? 0
  //           : parseFloat(plandetailsobj[4]),
  //       hst:
  //         plandetailsobj[5] == null ||
  //           plandetailsobj[5] == '' ||
  //           plandetailsobj[5] == undefined ||
  //           plandetailsobj[5] == 'null'
  //           ? 0
  //           : parseFloat(plandetailsobj[5]),
  //       pst:
  //         plandetailsobj[6] == null ||
  //           plandetailsobj[6] == '' ||
  //           plandetailsobj[6] == undefined ||
  //           plandetailsobj[6] == 'null'
  //           ? 0
  //           : parseFloat(plandetailsobj[6]),
  //       qst:
  //         plandetailsobj[17] == null ||
  //           plandetailsobj[17] == '' ||
  //           plandetailsobj[17] == undefined ||
  //           plandetailsobj[17] == 'null'
  //           ? 0
  //           : parseFloat(plandetailsobj[17]),
  //       gstCheck:
  //         plandetailsobj[4] == null ||
  //           plandetailsobj[4] == '' ||
  //           plandetailsobj[4] == undefined ||
  //           plandetailsobj[4] == 'null'
  //           ? false
  //           : true,
  //       hstCheck:
  //         plandetailsobj[5] == null ||
  //           plandetailsobj[5] == '' ||
  //           plandetailsobj[5] == undefined ||
  //           plandetailsobj[5] == 'null'
  //           ? false
  //           : true,
  //       pstCheck:
  //         plandetailsobj[6] == null ||
  //           plandetailsobj[6] == '' ||
  //           plandetailsobj[6] == undefined ||
  //           plandetailsobj[6] == 'null'
  //           ? false
  //           : true,
  //       qstCheck:
  //         plandetailsobj[17] == null ||
  //           plandetailsobj[17] == '' ||
  //           plandetailsobj[17] == undefined ||
  //           plandetailsobj[17] == 'null'
  //           ? false
  //           : true,
  //       id: parseFloat(plandetailsobj[7]),
  //       fusebillPlanID:
  //         plandetailsobj[8] == null ||
  //           plandetailsobj[8] == '' ||
  //           plandetailsobj[8] == undefined ||
  //           plandetailsobj[8] == 'null'
  //           ? 0
  //           : parseFloat(plandetailsobj[8]),
  //       planFrequencyID:
  //         plandetailsobj[9] == null ||
  //           plandetailsobj[9] == '' ||
  //           plandetailsobj[9] == undefined ||
  //           plandetailsobj[9] == 'null'
  //           ? 0
  //           : parseFloat(plandetailsobj[9]),
  //       optIn: plandetailsobj[10] == 'true' ? true : false,
  //       planname: plandetailsobj[14],
  //       planLevel: parseInt(plandetailsobj[15]),
  //       packageId: parseInt(plandetailsobj[16]),
  //       options: [],
  //       version: plan.version,
  //       description: plan.description,
  //       insuranceType: plan.insuranceType

  //       //  "disallowedPlanLevels":plandetailsobj[11]
  //     };
  //     if (plandetailsobj[11] != null || plandetailsobj[11] != 'null') {
  //       if (plandetailsobj[11].includes(plandetailsobj[12])) {
  //       }
  //     }
  //     ///this.consoleMessage(obj)
  //     this.productobj = {
  //       id: product.id,
  //       productId: product.productId,
  //       name: product.name,
  //       planProductId: product.planProductId,
  //       price: this.allFormData[type].total,
  //       tax: this._decimalPipe
  //         .transform(
  //           product.calculatedTax ? product.calculatedTax.tax : 0,
  //           '1.2-2'
  //         )
  //         ?.replace(/,/g, ''),
  //       total: this._decimalPipe
  //         .transform(
  //           product.calculatedTax ? product.calculatedTax.total : 0,
  //           '1.2-2'
  //         )
  //         ?.replace(/,/g, ''),
  //       tax_details:
  //         product.bundledTaxes && product.bundledTaxes.length > 0
  //           ? product.bundledTaxes
  //           : product.taxesDataJSON,
  //       calculatedTax: this.calculateTaxForProductTaxesData(this.allFormData[type].total, product.taxesDataJSON ?? []),
  //       bundledProducts: product.bundledProducts,
  //       planCoverage: lifeCoverage,
  //       planLevel: product.planlevel ? product.planlevel.id : '',
  //       planLevelParent: product.planlevel ? product.planlevel.parentId : '',
  //       description: product.description,
  //       coverage: lifeCoverage,
  //       version: product.version
  //     };

  //     this.productobj.total = this._decimalPipe
  //       .transform(
  //         this.productobj.calculatedTax ? this.productobj.calculatedTax.total : 0,
  //         '1.2-2'
  //       )
  //       ?.replace(/,/g, '')
  //     this.productobj.tax = this._decimalPipe
  //       .transform(
  //         this.productobj.calculatedTax ? this.productobj.calculatedTax.tax : 0,
  //         '1.2-2'
  //       )
  //       ?.replace(/,/g, '')


  //     if (category == "member") {

  //       memberObj = {
  //         "planStartDate": this.userForm.value.planEnrollmentDate
  //           ? this._CustomDatePipe.transform(
  //             this.userForm.value.planEnrollmentDate
  //           )
  //           : undefined,
  //         "units": (this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence / this.allFormData[type][category].coverage.minimumCoverage),
  //         "standardUnits": this.allFormData[type]["member"].coverage.minimumCoverage,
  //         "coverage": "MEMBER",
  //         "guaranteedAcceptance": this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <=
  //           this.allFormData[type]["member"].coverage.defaultRange ? true : false, //defalutRange
  //         "pendingMedicalQuestionaire": this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence >
  //           this.allFormData[type]["member"].coverage.defaultRange ? true : false,
  //         // "guaranteedAcceptanceAmount":this.allFormData[type]["member"].coverageValue <=this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverageValue/25000)*this.allFormData[type][category].constant):this.allFormData[type]["member"].coverage.defaultRange,

  //         "guaranteedAcceptanceAmount": adjustWith2DecimalsForUI(this.allFormData[type]["member"].coverageValue <= this.allFormData[type]["member"].coverage.defaultRange ? ((this.allFormData[type]["member"].coverageValue / 25000) * this.allFormData[type][category].constant) : ((this.allFormData[type]["member"].coverage.defaultRange / 25000) * this.allFormData[type][category].constant)),
  //         "pendingApprovalAmount": adjustWith2DecimalsForUI(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence > this.allFormData[type]["member"].coverage.defaultRange ? ((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence - this.allFormData[type]["member"].coverage.defaultRange) / 25000) * this.allFormData[type][category].constant : 0) || 0,
  //         "smoker": this.allFormData[type]["member"].isSmoker == "yes" ? true : false,
  //         "age": this.allFormData[type]["member"].coverage.age,
  //         "gender": this.userForm.value.gender?.toLowerCase(),
  //         "province": "ON",
  //         "provinceId": 12,
  //         "amount": this.allFormData[type]["member"].premium,
  //         "tax": adjustWith2DecimalsForUI(this.productobj.tax),
  //         "taxRate": "", //not mandatory,
  //         "taxName": "",
  //         "coverageValue": this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence,
  //         "premium": this.allFormData[type]["member"].constant,
  //         "total": adjustWith2DecimalsForUI(this.productobj.total),
  //         "coverageUnitPrice": this.allFormData[type]["member"].coverage.coverageUnitPrice,
  //         "defaultRange": this.allFormData[type]["member"].coverage.defaultRange,
  //         "maximumCoverageWithEvidence": this.allFormData[type]["member"].coverage.maximumCoverageWithEvidence,
  //         "maximumCoverageWithoutEvidence": this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence,
  //         "minimumCoverage": this.allFormData[type]["member"].coverage.minimumCoverage,
  //       }

  //       memberObj["guaranteedAcceptanceTax"] = adjustWith2DecimalsForUI(this.calculateTaxForProductTaxesData(this.allFormData[type]["member"].coverageValue <= this.allFormData[type]["member"].coverage.defaultRange ? ((this.allFormData[type]["member"].coverageValue / 25000) * this.allFormData[type]["member"].constant) : ((this.allFormData[type]["member"].coverage.defaultRange / 25000) * this.allFormData[type]["member"].constant), product.taxesDataJSON ?? []).tax)
  //       memberObj["guaranteedAcceptanceTotal"] = adjustWith2DecimalsForUI(this.calculateTaxForProductTaxesData(this.allFormData[type]["member"].coverageValue <= this.allFormData[type]["member"].coverage.defaultRange ? ((this.allFormData[type]["member"].coverageValue / 25000) * this.allFormData[type]["member"].constant) : ((this.allFormData[type]["member"].coverage.defaultRange / 25000) * this.allFormData[type]["member"].constant), product.taxesDataJSON ?? []).total)

  //       memberObj["pendingApprovalTax"] = adjustWith2DecimalsForUI(this.calculateTaxForProductTaxesData(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence > this.allFormData[type]["member"].coverage.defaultRange ? ((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence - this.allFormData[type]["member"].coverage.defaultRange) / 25000) * this.allFormData[type]["member"].constant : 0, product.taxesDataJSON ?? []).tax)
  //       memberObj["pendingApprovalTotal"] = adjustWith2DecimalsForUI(this.calculateTaxForProductTaxesData(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence > this.allFormData[type]["member"].coverage.defaultRange ? ((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence - this.allFormData[type]["member"].coverage.defaultRange) / 25000) * this.allFormData[type]["member"].constant : 0, product.taxesDataJSON ?? []).total)

  //       obj["member"] = memberObj
  //       obj["spouse"] = {}
  //       if (this.allFormData[type]['spouse']) {
  //         spouseObj = {
  //           "planStartDate": this.userForm.value.planEnrollmentDate
  //             ? this._CustomDatePipe.transform(
  //               this.userForm.value.planEnrollmentDate
  //             )
  //             : undefined,
  //           "units": (this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence / this.allFormData[type][category].coverage.minimumCoverage),
  //           "standardUnits": this.allFormData[type]["spouse"].coverage.minimumCoverage,
  //           "coverage": "MEMBER & SPOUSE",
  //           "guaranteedAcceptance": this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <=
  //             this.allFormData[type]["spouse"].coverage.defaultRange ? true : false, //defalutRange
  //           "pendingMedicalQuestionaire": this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence >
  //             this.allFormData[type]["spouse"].coverage.defaultRange ? true : false,
  //           "guaranteedAcceptanceAmount": this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <= this.allFormData[type]["spouse"].coverage.defaultRange ? ((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence / 25000) * this.allFormData[type][category].constant) : ((this.allFormData[type]["spouse"].coverage.defaultRange / 25000) * this.allFormData[type][category].constant),
  //           "pendingApprovalAmount": this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence > this.allFormData[type]["spouse"].coverage.defaultRange ? ((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence - this.allFormData[type]["spouse"].coverage.defaultRange) / 25000) * this.allFormData[type][category].constant : 0 || 0,
  //           "smoker": this.allFormData[type]["spouse"].isSmoker == "yes" ? true : false,
  //           "age": this.allFormData[type]["spouse"].coverage.age,
  //           "gender": this.userForm.value.spousegender?.toLowerCase(),
  //           "province": "ON",
  //           "provinceId": 12,
  //           "amount": this.allFormData[type]["spouse"].premium,
  //           "tax": adjustWith2DecimalsForUI(this.productobj.tax),
  //           "taxRate": "", //not mandatory,
  //           "taxName": "",
  //           "coverageValue": this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence,
  //           "premium": this.allFormData[type]["spouse"].constant,
  //           "total": adjustWith2DecimalsForUI(this.productobj.total),
  //           "coverageUnitPrice": this.allFormData[type]["spouse"].coverage.coverageUnitPrice,
  //           "defaultRange": this.allFormData[type]["spouse"].coverage.defaultRange,
  //           "maximumCoverageWithEvidence": this.allFormData[type]["spouse"].coverage.maximumCoverageWithEvidence,
  //           "maximumCoverageWithoutEvidence": this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence,
  //           "minimumCoverage": this.allFormData[type]["spouse"].coverage.minimumCoverage,
  //         }






  //         obj["member"] = memberObj
  //         obj["spouse"] = spouseObj

  //         spouseObj["guaranteedAcceptanceTax"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <= this.allFormData[type]["spouse"].coverage.defaultRange ? ((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence / 25000) * this.allFormData[type][category].constant) : ((this.allFormData[type]["spouse"].coverage.defaultRange / 25000) * this.allFormData[type][category].constant), product.taxesDataJSON ?? []).tax
  //         spouseObj["guaranteedAcceptanceTotal"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <= this.allFormData[type]["spouse"].coverage.defaultRange ? ((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence / 25000) * this.allFormData[type][category].constant) : ((this.allFormData[type]["spouse"].coverage.defaultRange / 25000) * this.allFormData[type][category].constant), product.taxesDataJSON ?? []).total

  //         spouseObj["pendingApprovalTax"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence > this.allFormData[type]["spouse"].coverage.defaultRange ? ((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence - this.allFormData[type]["spouse"].coverage.defaultRange) / 25000) * this.allFormData[type][category].constant : 0, product.taxesDataJSON ?? []).tax
  //         spouseObj["pendingApprovalTotal"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence > this.allFormData[type]["spouse"].coverage.defaultRange ? ((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence - this.allFormData[type]["spouse"].coverage.defaultRange) / 25000) * this.allFormData[type][category].constant : 0, product.taxesDataJSON ?? []).total
  //       }
  //     }
  //     else {

  //       memberObj = {
  //         "planStartDate": this.userForm.value.planEnrollmentDate
  //           ? this._CustomDatePipe.transform(
  //             this.userForm.value.planEnrollmentDate
  //           )
  //           : undefined,
  //         "units": (this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence / this.allFormData[type][category].coverage.minimumCoverage),
  //         "standardUnits": this.allFormData[type]["member"].coverage.minimumCoverage,
  //         "coverage": "MEMBER",
  //         "guaranteedAcceptance": this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <=
  //           this.allFormData[type]["member"].coverage.defaultRange ? true : false, //defalutRange
  //         "pendingMedicalQuestionaire": this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence >
  //           this.allFormData[type]["member"].coverage.defaultRange ? true : false,
  //         "guaranteedAcceptanceAmount": this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <= this.allFormData[type]["member"].coverage.defaultRange ? ((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence / 25000) * this.allFormData[type]["member"].constant) : ((this.allFormData[type]["member"].coverage.defaultRange / 25000) * this.allFormData[type]["member"].constant),

  //         "pendingApprovalAmount": this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence > this.allFormData[type]["member"].coverage.defaultRange ? ((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence - this.allFormData[type]["member"].coverage.defaultRange) / 25000) * this.allFormData[type]["member"].constant : 0 || 0,
  //         "smoker": this.allFormData[type]["member"].isSmoker == "yes" ? true : false,
  //         "age": this.allFormData[type]["member"].coverage.age,
  //         "gender": this.userForm.value.gender?.toLowerCase(),
  //         "province": "ON",
  //         "provinceId": 12,
  //         "amount": this.allFormData[type]["member"].premium,
  //         "tax": adjustWith2DecimalsForUI(this.productobj.tax),
  //         "taxRate": "", //not mandatory,
  //         "taxName": "",
  //         "coverageValue": this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence,
  //         "premium": this.allFormData[type]["member"].constant,
  //         "total": adjustWith2DecimalsForUI(this.productobj.total),
  //         "coverageUnitPrice": this.allFormData[type]["member"].coverage.coverageUnitPrice,
  //         "defaultRange": this.allFormData[type]["member"].coverage.defaultRange,
  //         "maximumCoverageWithEvidence": this.allFormData[type]["member"].coverage.maximumCoverageWithEvidence,
  //         "maximumCoverageWithoutEvidence": this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence,
  //         "minimumCoverage": this.allFormData[type]["member"].coverage.minimumCoverage,
  //       }
  //       spouseObj = {
  //         "planStartDate": this.userForm.value.planEnrollmentDate
  //           ? this._CustomDatePipe.transform(
  //             this.userForm.value.planEnrollmentDate
  //           )
  //           : undefined,
  //         "units": (this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence / this.allFormData[type][category].coverage.minimumCoverage),
  //         "standardUnits": this.allFormData[type]["spouse"].coverage.minimumCoverage,
  //         "coverage": "MEMBER & SPOUSE",
  //         "guaranteedAcceptance": this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <=
  //           this.allFormData[type]["spouse"].coverage.defaultRange ? true : false, //defalutRange
  //         "pendingMedicalQuestionaire": this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence >
  //           this.allFormData[type]["spouse"].coverage.defaultRange ? true : false,
  //         "guaranteedAcceptanceAmount": this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <= this.allFormData[type]["spouse"].coverage.defaultRange ? ((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence / 25000) * this.allFormData[type][category].constant) : ((this.allFormData[type]["spouse"].coverage.defaultRange / 25000) * this.allFormData[type][category].constant),
  //         "pendingApprovalAmount": this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence > this.allFormData[type]["spouse"].coverage.defaultRange ? ((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence - this.allFormData[type]["spouse"].coverage.defaultRange) / 25000) * this.allFormData[type][category].constant : 0 || 0,
  //         "smoker": this.allFormData[type]["spouse"].isSmoker == "yes" ? true : false,
  //         "age": this.allFormData[type]["spouse"].coverage.age,
  //         "gender": this.userForm.value.spousegender?.toLowerCase(),
  //         "province": "ON",
  //         "provinceId": 12,
  //         "amount": this.allFormData[type]["spouse"].premium,
  //         "tax": adjustWith2DecimalsForUI(this.productobj.tax),
  //         "taxRate": "", //not mandatory,
  //         "taxName": "",
  //         "coverageValue": this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence,
  //         "premium": this.allFormData[type]["spouse"].constant,
  //         "total": adjustWith2DecimalsForUI(this.productobj.total),
  //         "coverageUnitPrice": this.allFormData[type]["spouse"].coverage.coverageUnitPrice,
  //         "defaultRange": this.allFormData[type]["spouse"].coverage.defaultRange,
  //         "maximumCoverageWithEvidence": this.allFormData[type]["spouse"].coverage.maximumCoverageWithEvidence,
  //         "maximumCoverageWithoutEvidence": this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence,
  //         "minimumCoverage": this.allFormData[type]["spouse"].coverage.minimumCoverage,
  //       }

  //       obj["member"] = memberObj
  //       obj["spouse"] = spouseObj


  //       memberObj["guaranteedAcceptanceTax"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <= this.allFormData[type]["member"].coverage.defaultRange ? ((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence / 25000) * this.allFormData[type]["member"].constant) : ((this.allFormData[type]["member"].coverage.defaultRange / 25000) * this.allFormData[type]["member"].constant), product.taxesDataJSON ?? []).tax
  //       memberObj["guaranteedAcceptanceTotal"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <= this.allFormData[type]["member"].coverage.defaultRange ? ((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence / 25000) * this.allFormData[type]["member"].constant) : ((this.allFormData[type]["member"].coverage.defaultRange / 25000) * this.allFormData[type]["member"].constant), product.taxesDataJSON ?? []).total

  //       spouseObj["guaranteedAcceptanceTax"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <= this.allFormData[type]["spouse"].coverage.defaultRange ? ((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence / 25000) * this.allFormData[type]["spouse"].constant) : ((this.allFormData[type]["spouse"].coverage.defaultRange / 25000) * this.allFormData[type]["spouse"].constant), product.taxesDataJSON ?? []).tax
  //       spouseObj["guaranteedAcceptanceTotal"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <= this.allFormData[type]["spouse"].coverage.defaultRange ? ((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence / 25000) * this.allFormData[type]["spouse"].constant) : ((this.allFormData[type]["spouse"].coverage.defaultRange / 25000) * this.allFormData[type]["spouse"].constant), product.taxesDataJSON ?? []).total

  //       memberObj["pendingApprovalTax"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence > this.allFormData[type]["member"].coverage.defaultRange ? ((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence - this.allFormData[type]["member"].coverage.defaultRange) / 25000) * this.allFormData[type]["member"].constant : 0, product.taxesDataJSON ?? []).tax
  //       memberObj["pendingApprovalTotal"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence > this.allFormData[type]["member"].coverage.defaultRange ? ((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence - this.allFormData[type]["member"].coverage.defaultRange) / 25000) * this.allFormData[type]["member"].constant : 0, product.taxesDataJSON ?? []).total

  //       spouseObj["pendingApprovalTax"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence > this.allFormData[type]["spouse"].coverage.defaultRange ? ((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence - this.allFormData[type]["spouse"].coverage.defaultRange) / 25000) * this.allFormData[type]["spouse"].constant : 0, product.taxesDataJSON ?? []).tax
  //       spouseObj["pendingApprovalTotal"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence > this.allFormData[type]["spouse"].coverage.defaultRange ? ((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence - this.allFormData[type]["spouse"].coverage.defaultRange) / 25000) * this.allFormData[type]["spouse"].constant : 0, product.taxesDataJSON ?? []).total

  //     }
  //     let obj1: any = {
  //       packageId: plan.packageId,
  //       packageName: pckage.name,

  //       planproductname: this.productobj.name,

  //       groupid: plan.planLevel,
  //       groupName: group.name,
  //       id: plan.id,
  //       name: plan.name,
  //       planLevel: plan.planLevel ? plan.planLevel : group.id,
  //       planLevelParent: group.parentId,
  //       fusebillPlanID: plan.fusebillId,
  //       planFrequencyID: plan.frqMonthly,
  //       isBundle: plan.isBundle,
  //       coverage: lifeCoverage,
  //       planCoverage: product.planCoverage,
  //       bundledProducts: [],
  //       products: [],
  //       version: plan.version

  //     };
  //     obj1.products.push(this.productobj);
  //     //this.consoleMessage(obj1)
  //     // console.log(obj)
  //     // console.log(obj1)
  //     this.addtoplansummaryLife(obj, obj1);
  //   }, 100);
  //   for (let i = this.minValue; i <= this.allFormData[type][category]?.coverage?.maximumCoverageWithEvidence; i += 100000) {
  //     this.rangeValues.push(i);
  //   }

  //    /*
  //   Form Here the code works for auto slection of Smoker in Life and critical
  //    */

  // //   plansummaryLife.forEach((element:any,index:any) => {


  // //     if(category=="member"){

  // //         if(element.member.smoker==isSmokerValue){
  // //       }
  // //       else{


  // //         const dom: HTMLElement = this.elementRef.nativeElement;

  // //         const plan: any = dom.querySelector("."+"member"+"-" + element.planCoverage + "-"+isSmokerValue);
  // //         console.log(plan)
  // //         setTimeout(() => {
  // //           plan.click()
  // //         }, 1000);

  // //     }
  // //     }
  // //     if(category=="spouse"){


  // //         if(element.spouse.smoker==isSmokerValue){
  // //       }
  // //       else{


  // //         const dom: HTMLElement = this.elementRef.nativeElement;

  // //         const plan: any = dom.querySelector("."+"spouse"+"-" + element.planCoverage + "-"+isSmokerValue);
  // //         console.log(plan)
  // //         setTimeout(() => {
  // //           plan.click()
  // //         }, 1000);

  // //     }
  // //     }




  // // })
  // }
  // public handleRangeChange(value: any, type: string, category: string, options: any, plan: any, product: any, pckage: any, group: any, event: any, data: any): void {
  //   // console.log(value)
  //   // console.log(type)
  //   // console.log(event)

  //   if (data.maximumCoverageWithoutEvidence == 0) {
  //     data.maximumCoverageWithoutEvidence = 25000
  //   }
  //   this.allFormData[type][category].coverage = data

  //   // this.sliderValue = this.allFormData[type][category].coverage.maximumCoverageWithoutEvidence


  //   this.rangeValues = [];


  //   if (this.allFormData[type][category]) {
  //     this.updateFormData(type, category, {
  //       // coverage: value,
  //       isSmoker: this.allFormData[type][category].isSmoker,
  //       premium: this.updatePremium(type, category, this.allFormData[type][category].isSmoker),
  //     });
  //   }
  //   let lifeCoverage = ''
  //   let memberObj: any = {}
  //   let spouseObj: any = {}
  //   if (category == "member") {
  //     lifeCoverage = "MEMBER"
  //   } else {
  //     lifeCoverage = "MEMBER & SPOUSE"
  //   }
  //   if (this.allFormData[type]['spouse']) {
  //     lifeCoverage = "MEMBER & SPOUSE"

  //   }
  //   console.log(this.plansData)
  //   let plansummaryLife = JSON.parse(sessionStorage.getItem('plansummaryLife') || '[]');


  //   let plandetails = event.target.attributes.data.value;

  //   let plandetailsobj = plandetails.split('##');


  //   plansummaryLife.forEach((element: any, index: any) => {

  //     if (element.planname == plandetailsobj[14]) {
  //       plansummaryLife.splice(index, 1)
  //     }
  //     sessionStorage.setItem('plansummaryLife', JSON.stringify(plansummaryLife));

  //   })
  //   setTimeout(() => {
  //     // console.log(this.allFormData[type][category].premium)

  //     let obj: any = {
  //       isBundle: plan.isBundle,
  //       // enrollmentDate: moment(
  //       //   this.userForm.get("planenrollmentdate").value,
  //       //   "MM-DD-YYYY"
  //       // ).format("YYYY-MM-DD"),
  //       categorization:{
  //       paidByCompany: 0,
  //       coveredByCompany: 0,
  //       paidByEmployee: 1,
  //     },
  //       enrollmentDate: this.userForm.value.planEnrollmentDate
  //         ? this._CustomDatePipe.transform(
  //           this.userForm.value.planEnrollmentDate
  //         )
  //         : undefined,
  //       // "name":plandetailsobj[1]+"-"+plandetailsobj[2],
  //       name: plandetailsobj[14],
  //       details: plandetailsobj[0],
  //       packagename: plandetailsobj[0],
  //       groupName: plandetailsobj[1],
  //       amount: this.allFormData[type].total,
  //       // amount: this.allFormData[type][category].premium,
  //       planCoverage: plandetailsobj[2],
  //       planPrice: adjustWith2DecimalsForUI(this.allFormData[type].total),
  //       amountUI:
  //         '$' +
  //         adjustWith2DecimalsForUI(this.allFormData[type].total),
  //       gst:
  //         plandetailsobj[4] == null ||
  //           plandetailsobj[4] == '' ||
  //           plandetailsobj[4] == undefined ||
  //           plandetailsobj[4] == 'null'
  //           ? 0
  //           : parseFloat(plandetailsobj[4]),
  //       hst:
  //         plandetailsobj[5] == null ||
  //           plandetailsobj[5] == '' ||
  //           plandetailsobj[5] == undefined ||
  //           plandetailsobj[5] == 'null'
  //           ? 0
  //           : parseFloat(plandetailsobj[5]),
  //       pst:
  //         plandetailsobj[6] == null ||
  //           plandetailsobj[6] == '' ||
  //           plandetailsobj[6] == undefined ||
  //           plandetailsobj[6] == 'null'
  //           ? 0
  //           : parseFloat(plandetailsobj[6]),
  //       qst:
  //         plandetailsobj[17] == null ||
  //           plandetailsobj[17] == '' ||
  //           plandetailsobj[17] == undefined ||
  //           plandetailsobj[17] == 'null'
  //           ? 0
  //           : parseFloat(plandetailsobj[17]),
  //       gstCheck:
  //         plandetailsobj[4] == null ||
  //           plandetailsobj[4] == '' ||
  //           plandetailsobj[4] == undefined ||
  //           plandetailsobj[4] == 'null'
  //           ? false
  //           : true,
  //       hstCheck:
  //         plandetailsobj[5] == null ||
  //           plandetailsobj[5] == '' ||
  //           plandetailsobj[5] == undefined ||
  //           plandetailsobj[5] == 'null'
  //           ? false
  //           : true,
  //       pstCheck:
  //         plandetailsobj[6] == null ||
  //           plandetailsobj[6] == '' ||
  //           plandetailsobj[6] == undefined ||
  //           plandetailsobj[6] == 'null'
  //           ? false
  //           : true,
  //       qstCheck:
  //         plandetailsobj[17] == null ||
  //           plandetailsobj[17] == '' ||
  //           plandetailsobj[17] == undefined ||
  //           plandetailsobj[17] == 'null'
  //           ? false
  //           : true,
  //       id: parseFloat(plandetailsobj[7]),
  //       fusebillPlanID:
  //         plandetailsobj[8] == null ||
  //           plandetailsobj[8] == '' ||
  //           plandetailsobj[8] == undefined ||
  //           plandetailsobj[8] == 'null'
  //           ? 0
  //           : parseFloat(plandetailsobj[8]),
  //       planFrequencyID:
  //         plandetailsobj[9] == null ||
  //           plandetailsobj[9] == '' ||
  //           plandetailsobj[9] == undefined ||
  //           plandetailsobj[9] == 'null'
  //           ? 0
  //           : parseFloat(plandetailsobj[9]),
  //       optIn: plandetailsobj[10] == 'true' ? true : false,
  //       planname: plandetailsobj[14],
  //       planLevel: parseInt(plandetailsobj[15]),
  //       packageId: parseInt(plandetailsobj[16]),
  //       options: [],
  //       version: plan.version,
  //       description: plan.description,
  //       insuranceType: plan.insuranceType

  //       //  "disallowedPlanLevels":plandetailsobj[11]
  //     };
  //     if (plandetailsobj[11] != null || plandetailsobj[11] != 'null') {
  //       if (plandetailsobj[11].includes(plandetailsobj[12])) {
  //       }
  //     }
  //     ///this.consoleMessage(obj)
  //     this.productobj = {
  //       id: product.id,
  //       productId: product.productId,
  //       name: product.name,
  //       planProductId: product.planProductId,
  //       price: this.allFormData[type].total,
  //       tax: this._decimalPipe
  //         .transform(
  //           product.calculatedTax ? product.calculatedTax.tax : 0,
  //           '1.2-2'
  //         )
  //         ?.replace(/,/g, ''),
  //       total: this._decimalPipe
  //         .transform(
  //           product.calculatedTax ? product.calculatedTax.total : 0,
  //           '1.2-2'
  //         )
  //         ?.replace(/,/g, ''),
  //       tax_details:
  //         product.bundledTaxes && product.bundledTaxes.length > 0
  //           ? product.bundledTaxes
  //           : product.taxesDataJSON,
  //       calculatedTax: this.calculateTaxForProductTaxesData(this.allFormData[type].total, product.taxesDataJSON ?? []),
  //       bundledProducts: product.bundledProducts,
  //       planCoverage: lifeCoverage,
  //       planLevel: product.planlevel ? product.planlevel.id : '',
  //       planLevelParent: product.planlevel ? product.planlevel.parentId : '',
  //       description: product.description,
  //       coverage: lifeCoverage,
  //       version: product.version
  //     };

  //     this.productobj.total = this._decimalPipe
  //       .transform(
  //         this.productobj.calculatedTax ? this.productobj.calculatedTax.total : 0,
  //         '1.2-2'
  //       )
  //       ?.replace(/,/g, '')
  //     this.productobj.tax = this._decimalPipe
  //       .transform(
  //         this.productobj.calculatedTax ? this.productobj.calculatedTax.tax : 0,
  //         '1.2-2'
  //       )
  //       ?.replace(/,/g, '')
  //     // console.log(this.allFormData)
  //     // console.log(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence )
  //     // console.log(this.allFormData[type]["member"].coverage.defaultRange)
  //     // console.log((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence-this.allFormData[type]["member"].coverage.defaultRange)/25000)

  //     // console.log(((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence-this.allFormData[type]["member"].coverage.defaultRange)/25000)*this.allFormData[type][category].constant)
  //     if (category == "member") {

  //       memberObj = {
  //         "planStartDate": this.userForm.value.planEnrollmentDate
  //           ? this._CustomDatePipe.transform(
  //             this.userForm.value.planEnrollmentDate
  //           )
  //           : undefined,
  //         "units": (this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence / this.allFormData[type][category].coverage.minimumCoverage),
  //         "standardUnits": this.allFormData[type]["member"].coverage.minimumCoverage,
  //         "coverage": "MEMBER",
  //         "guaranteedAcceptance": this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <=
  //           this.allFormData[type]["member"].coverage.defaultRange ? true : false, //defalutRange
  //         "pendingMedicalQuestionaire": this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence >
  //           this.allFormData[type]["member"].coverage.defaultRange ? true : false,


  //         "guaranteedAcceptanceAmount": this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <= this.allFormData[type]["member"].coverage.defaultRange ? ((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence / 25000) * this.allFormData[type]["member"].constant) : ((this.allFormData[type]["member"].coverage.defaultRange / 25000) * this.allFormData[type]["member"].constant),
  //         "pendingApprovalAmount": this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence > this.allFormData[type]["member"].coverage.defaultRange ? ((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence - this.allFormData[type]["member"].coverage.defaultRange) / 25000) * this.allFormData[type]["member"].constant : 0 || 0,
  //         "smoker": this.allFormData[type]["member"].isSmoker == "yes" ? true : false,
  //         "age": this.allFormData[type]["member"].coverage.age,
  //         "gender": this.userForm.value.gender?.toLowerCase(),
  //         "province": "ON",
  //         "provinceId": 12,
  //         "amount": this.allFormData[type]["member"].premium,
  //         "tax": adjustWith2DecimalsForUI(this.productobj.tax),
  //         "taxRate": "", //not mandatory,
  //         "taxName": "",
  //         "coverageValue": this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence,
  //         "premium": this.allFormData[type]["member"].constant,
  //         "total": adjustWith2DecimalsForUI(this.productobj.total),
  //         "coverageUnitPrice": this.allFormData[type]["member"].coverage.coverageUnitPrice,
  //         "defaultRange": this.allFormData[type]["member"].coverage.defaultRange,
  //         "maximumCoverageWithEvidence": this.allFormData[type]["member"].coverage.maximumCoverageWithEvidence,
  //         "maximumCoverageWithoutEvidence": this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence,
  //         "minimumCoverage": this.allFormData[type]["member"].coverage.minimumCoverage,
  //       }
  //       memberObj["guaranteedAcceptanceTax"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <= this.allFormData[type]["member"].coverage.defaultRange ? ((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence / 25000) * this.allFormData[type]["member"].constant) : ((this.allFormData[type]["member"].coverage.defaultRange / 25000) * this.allFormData[type]["member"].constant), product.taxesDataJSON ?? []).tax
  //       memberObj["guaranteedAcceptanceTotal"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <= this.allFormData[type]["member"].coverage.defaultRange ? ((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence / 25000) * this.allFormData[type]["member"].constant) : ((this.allFormData[type]["member"].coverage.defaultRange / 25000) * this.allFormData[type]["member"].constant), product.taxesDataJSON ?? []).total

  //       memberObj["pendingApprovalTax"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence > this.allFormData[type]["member"].coverage.defaultRange ? ((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence - this.allFormData[type]["member"].coverage.defaultRange) / 25000) * this.allFormData[type]["member"].constant : 0, product.taxesDataJSON ?? []).tax
  //       memberObj["pendingApprovalTotal"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence > this.allFormData[type]["member"].coverage.defaultRange ? ((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence - this.allFormData[type]["member"].coverage.defaultRange) / 25000) * this.allFormData[type]["member"].constant : 0, product.taxesDataJSON ?? []).total

  //       obj["member"] = memberObj
  //       obj["spouse"] = {}

  //       if (this.allFormData[type]['spouse']) {
  //         spouseObj = {
  //           "planStartDate": this.userForm.value.planEnrollmentDate
  //             ? this._CustomDatePipe.transform(
  //               this.userForm.value.planEnrollmentDate
  //             )
  //             : undefined,
  //           "units": (this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence / this.allFormData[type][category].coverage.minimumCoverage),
  //           "standardUnits": this.allFormData[type]["spouse"].coverage.minimumCoverage,
  //           "coverage": "MEMBER & SPOUSE",
  //           "guaranteedAcceptance": this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <=
  //             this.allFormData[type]["spouse"].coverage.defaultRange ? true : false, //defalutRange
  //           "pendingMedicalQuestionaire": this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence >
  //             this.allFormData[type]["spouse"].coverage.defaultRange ? true : false,
  //           "guaranteedAcceptanceAmount": this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <= this.allFormData[type]["spouse"].coverage.defaultRange ? ((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence / 25000) * this.allFormData[type]["spouse"].constant) : ((this.allFormData[type]["spouse"].coverage.defaultRange / 25000) * this.allFormData[type]["spouse"].constant),
  //           "pendingApprovalAmount": this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence > this.allFormData[type]["spouse"].coverage.defaultRange ? ((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence - this.allFormData[type]["spouse"].coverage.defaultRange) / 25000) * this.allFormData[type]["spouse"].constant : 0 || 0,
  //           "smoker": this.allFormData[type]["spouse"].isSmoker == "yes" ? true : false,
  //           "age": this.allFormData[type]["spouse"].coverage.age,
  //           "gender": this.userForm.value.spousegender?.toLowerCase(),
  //           "province": "ON",
  //           "provinceId": 12,
  //           "amount": this.allFormData[type]["spouse"].premium,
  //           "tax": adjustWith2DecimalsForUI(this.productobj.tax),
  //           "taxRate": "", //not mandatory,
  //           "taxName": "",
  //           "coverageValue": this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence,
  //           "premium": this.allFormData[type]["spouse"].constant,
  //           "total": adjustWith2DecimalsForUI(this.productobj.total),
  //           "coverageUnitPrice": this.allFormData[type]["spouse"].coverage.coverageUnitPrice,
  //           "defaultRange": this.allFormData[type]["spouse"].coverage.defaultRange,
  //           "maximumCoverageWithEvidence": this.allFormData[type]["spouse"].coverage.maximumCoverageWithEvidence,
  //           "maximumCoverageWithoutEvidence": this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence,
  //           "minimumCoverage": this.allFormData[type]["spouse"].coverage.minimumCoverage,
  //         }






  //         obj["member"] = memberObj
  //         obj["spouse"] = spouseObj

  //         spouseObj["guaranteedAcceptanceTax"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <= this.allFormData[type]["spouse"].coverage.defaultRange ? ((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence / 25000) * this.allFormData[type]["spouse"].constant) : ((this.allFormData[type]["spouse"].coverage.defaultRange / 25000) * this.allFormData[type]["spouse"].constant), product.taxesDataJSON ?? []).tax
  //         spouseObj["guaranteedAcceptanceTotal"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <= this.allFormData[type]["spouse"].coverage.defaultRange ? ((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence / 25000) * this.allFormData[type]["spouse"].constant) : ((this.allFormData[type]["spouse"].coverage.defaultRange / 25000) * this.allFormData[type]["spouse"].constant), product.taxesDataJSON ?? []).total

  //         spouseObj["pendingApprovalTax"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence > this.allFormData[type]["spouse"].coverage.defaultRange ? ((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence - this.allFormData[type]["spouse"].coverage.defaultRange) / 25000) * this.allFormData[type]["spouse"].constant : 0, product.taxesDataJSON ?? []).tax
  //         spouseObj["pendingApprovalTotal"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence > this.allFormData[type]["spouse"].coverage.defaultRange ? ((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence - this.allFormData[type]["spouse"].coverage.defaultRange) / 25000) * this.allFormData[type]["spouse"].constant : 0, product.taxesDataJSON ?? []).total
  //       }




  //     }
  //     else {

  //       memberObj = {
  //         "planStartDate": this.userForm.value.planEnrollmentDate
  //           ? this._CustomDatePipe.transform(
  //             this.userForm.value.planEnrollmentDate
  //           )
  //           : undefined,
  //         "units": (this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence / this.allFormData[type][category].coverage.minimumCoverage),
  //         "standardUnits": this.allFormData[type]["member"].coverage.minimumCoverage,
  //         "coverage": "MEMBER",
  //         "guaranteedAcceptance": this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <=
  //           this.allFormData[type]["member"].coverage.defaultRange ? true : false, //defalutRange
  //         "pendingMedicalQuestionaire": this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence >
  //           this.allFormData[type]["member"].coverage.defaultRange ? true : false,
  //         "guaranteedAcceptanceAmount": this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <= this.allFormData[type]["member"].coverage.defaultRange ? ((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence / 25000) * this.allFormData[type]["member"].constant) : ((this.allFormData[type]["member"].coverage.defaultRange / 25000) * this.allFormData[type]["member"].constant),
  //         "pendingApprovalAmount": this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence > this.allFormData[type]["member"].coverage.defaultRange ? ((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence - this.allFormData[type]["member"].coverage.defaultRange) / 25000) * this.allFormData[type]["member"].constant : 0 || 0,
  //         "smoker": this.allFormData[type]["member"].isSmoker == "yes" ? true : false,
  //         "age": this.allFormData[type]["member"].coverage.age,
  //         "gender": this.userForm.value.gender?.toLowerCase(),
  //         "province": "ON",
  //         "provinceId": 12,
  //         "amount": this.allFormData[type]["member"].premium,
  //         "tax": adjustWith2DecimalsForUI(this.productobj.tax),
  //         "taxRate": "", //not mandatory,
  //         "taxName": "",
  //         "coverageValue": this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence,
  //         "premium": this.allFormData[type]["member"].constant,
  //         "total": adjustWith2DecimalsForUI(this.productobj.total),
  //         "coverageUnitPrice": this.allFormData[type]["member"].coverage.coverageUnitPrice,
  //         "defaultRange": this.allFormData[type]["member"].coverage.defaultRange,
  //         "maximumCoverageWithEvidence": this.allFormData[type]["member"].coverage.maximumCoverageWithEvidence,
  //         "maximumCoverageWithoutEvidence": this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence,
  //         "minimumCoverage": this.allFormData[type]["member"].coverage.minimumCoverage,
  //       }
  //       spouseObj = {
  //         "planStartDate": this.userForm.value.planEnrollmentDate
  //           ? this._CustomDatePipe.transform(
  //             this.userForm.value.planEnrollmentDate
  //           )
  //           : undefined,
  //         "units": (this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence / this.allFormData[type][category].coverage.minimumCoverage),
  //         "standardUnits": this.allFormData[type]["spouse"].coverage.minimumCoverage,
  //         "coverage": "MEMBER & SPOUSE",
  //         "guaranteedAcceptance": this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <=
  //           this.allFormData[type]["spouse"].coverage.defaultRange ? true : false, //defalutRange
  //         "pendingMedicalQuestionaire": this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence >
  //           this.allFormData[type]["spouse"].coverage.defaultRange ? true : false,
  //         "guaranteedAcceptanceAmount": this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <= this.allFormData[type]["spouse"].coverage.defaultRange ? ((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence / 25000) * this.allFormData[type]["spouse"].constant) : ((this.allFormData[type]["spouse"].coverage.defaultRange / 25000) * this.allFormData[type][category].constant),
  //         "pendingApprovalAmount": this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence > this.allFormData[type]["spouse"].coverage.defaultRange ? ((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence - this.allFormData[type]["spouse"].coverage.defaultRange) / 25000) * this.allFormData[type]["spouse"].constant : 0 || 0,
  //         "smoker": this.allFormData[type]["spouse"].isSmoker == "yes" ? true : false,
  //         "age": this.allFormData[type]["spouse"].coverage.age,
  //         "gender": this.userForm.value.spousegender?.toLowerCase(),
  //         "province": "ON",
  //         "provinceId": 12,
  //         "amount": this.allFormData[type]["spouse"].premium,
  //         "tax": adjustWith2DecimalsForUI(this.productobj.tax),
  //         "taxRate": "", //not mandatory,
  //         "taxName": "",
  //         "coverageValue": this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence,
  //         "premium": this.allFormData[type]["spouse"].constant,
  //         "total": adjustWith2DecimalsForUI(this.productobj.total),
  //         "coverageUnitPrice": this.allFormData[type]["spouse"].coverage.coverageUnitPrice,
  //         "defaultRange": this.allFormData[type]["spouse"].coverage.defaultRange,
  //         "maximumCoverageWithEvidence": this.allFormData[type]["spouse"].coverage.maximumCoverageWithEvidence,
  //         "maximumCoverageWithoutEvidence": this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence,
  //         "minimumCoverage": this.allFormData[type]["spouse"].coverage.minimumCoverage,
  //       }

  //       obj["member"] = memberObj
  //       obj["spouse"] = spouseObj
  //       memberObj["guaranteedAcceptanceTax"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <= this.allFormData[type]["member"].coverage.defaultRange ? ((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence / 25000) * this.allFormData[type]["member"].constant) : ((this.allFormData[type]["member"].coverage.defaultRange / 25000) * this.allFormData[type]["member"].constant), product.taxesDataJSON ?? []).tax
  //       memberObj["guaranteedAcceptanceTotal"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <= this.allFormData[type]["member"].coverage.defaultRange ? ((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence / 25000) * this.allFormData[type]["member"].constant) : ((this.allFormData[type]["member"].coverage.defaultRange / 25000) * this.allFormData[type]["member"].constant), product.taxesDataJSON ?? []).total
  //       spouseObj["guaranteedAcceptanceTax"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <= this.allFormData[type]["spouse"].coverage.defaultRange ? ((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence / 25000) * this.allFormData[type]["spouse"].constant) : ((this.allFormData[type]["spouse"].coverage.defaultRange / 25000) * this.allFormData[type]["spouse"].constant), product.taxesDataJSON ?? []).tax
  //       spouseObj["guaranteedAcceptanceTotal"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <= this.allFormData[type]["spouse"].coverage.defaultRange ? ((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence / 25000) * this.allFormData[type]["spouse"].constant) : ((this.allFormData[type]["spouse"].coverage.defaultRange / 25000) * this.allFormData[type]["spouse"].constant), product.taxesDataJSON ?? []).total

  //       memberObj["pendingApprovalTax"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence > this.allFormData[type]["member"].coverage.defaultRange ? ((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence - this.allFormData[type]["member"].coverage.defaultRange) / 25000) * this.allFormData[type]["member"].constant : 0, product.taxesDataJSON ?? []).tax
  //       memberObj["pendingApprovalTotal"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence > this.allFormData[type]["member"].coverage.defaultRange ? ((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence - this.allFormData[type]["member"].coverage.defaultRange) / 25000) * this.allFormData[type]["member"].constant : 0, product.taxesDataJSON ?? []).total

  //       spouseObj["pendingApprovalTax"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence > this.allFormData[type]["member"].coverage.defaultRange ? ((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence - this.allFormData[type]["member"].coverage.defaultRange) / 25000) * this.allFormData[type]["member"].constant : 0, product.taxesDataJSON ?? []).tax
  //       spouseObj["pendingApprovalTotal"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence > this.allFormData[type]["member"].coverage.defaultRange ? ((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence - this.allFormData[type]["member"].coverage.defaultRange) / 25000) * this.allFormData[type]["member"].constant : 0, product.taxesDataJSON ?? []).total

  //     }

  //     let MSguranteeAcceptanceAmount = adjustWith2DecimalsForUI(memberObj["guaranteedAcceptanceAmount"]+(spouseObj["guaranteedAcceptanceAmount"]??0))
  //     let MSguranteeAcceptancetTAX = adjustWith2DecimalsForUI(memberObj["guaranteedAcceptanceTax"]+(spouseObj["guaranteedAcceptanceTax"]??0))
  //     let MSguranteeAcceptancetTotal = adjustWith2DecimalsForUI(memberObj["guaranteedAcceptanceTotal"]+(spouseObj["guaranteedAcceptanceTotal"]??0))

  //     let MSpendingApprovalAmount = adjustWith2DecimalsForUI(memberObj["pendingApprovalAmount"]+(spouseObj["pendingApprovalAmount"]??0))
  //     let MSpendingApprovalTAX = adjustWith2DecimalsForUI(memberObj["pendingApprovalTax"]+(spouseObj["pendingApprovalTax"]??0))
  //     let MASpendingApprovalTotal = adjustWith2DecimalsForUI(memberObj["pendingApprovalTotal"]+(spouseObj["pendingApprovalTotal"]??0))

  //     let obj1: any = {
  //       packageId: plan.packageId,
  //       packageName: pckage.name,

  //       planproductname: this.productobj.name,

  //       groupid: plan.planLevel,
  //       groupName: group.name,
  //       id: plan.id,
  //       name: plan.name,
  //       planLevel: plan.planLevel ? plan.planLevel : group.id,
  //       planLevelParent: group.parentId,
  //       fusebillPlanID: plan.fusebillId,
  //       planFrequencyID: plan.frqMonthly,
  //       isBundle: plan.isBundle,
  //       coverage: lifeCoverage,
  //       planCoverage: product.planCoverage,
  //       bundledProducts: [],
  //       products: [],
  //       version:plan.version,
  //       guaranteedAcceptanceDetails:{
  //         "amount":MSguranteeAcceptanceAmount,
  //         "tax":MSguranteeAcceptancetTAX,
  //         "total":MSguranteeAcceptancetTotal,
  //         "products": [{
  //           id: product.id,
  //           productId: product.productId,
  //           name: product.name,
  //           planProductId: product.planProductId,
  //           price: adjustWith2DecimalsForUI(this.allFormData[type].total),
  //           tax_details:
  //             product.bundledTaxes && product.bundledTaxes.length > 0
  //               ? product.bundledTaxes
  //               : product.taxesDataJSON,
  //           calculatedTax: this.calculateTaxForProductTaxesData(MSguranteeAcceptanceAmount,product.taxesDataJSON??[]),
  //           bundledProducts: product.bundledProducts,
  //           planCoverage: lifeCoverage,
  //           planLevel: product.planlevel ? product.planlevel.id : '',
  //           planLevelParent: product.planlevel ? product.planlevel.parentId : '',
  //           description: product.description,
  //           coverage: lifeCoverage,
  //           version:product.version,
  //           total :this._decimalPipe
  //           .transform(
  //             this.calculateTaxForProductTaxesData(MSguranteeAcceptanceAmount,product.taxesDataJSON??[]) ? this.calculateTaxForProductTaxesData(MSguranteeAcceptanceAmount,product.taxesDataJSON??[]).total : 0,
  //             '1.2-2'
  //           )
  //           ?.replace(/,/g, ''),
  //           tax : this._decimalPipe
  //           .transform(
  //             this.calculateTaxForProductTaxesData(MSguranteeAcceptanceAmount,product.taxesDataJSON??[]) ? this.calculateTaxForProductTaxesData(MSguranteeAcceptanceAmount,product.taxesDataJSON??[]).tax : 0,
  //             '1.2-2'
  //           )
  //           ?.replace(/,/g, '')
  //         }]


  //       },
  //       pendingApprovalDetails:{
  //         "amount":MSpendingApprovalAmount,
  //         "tax":MSpendingApprovalTAX,
  //         "total":MASpendingApprovalTotal,
  //         "products": [{
  //           id: product.id,
  //           productId: product.productId,
  //           name: product.name,
  //           planProductId: product.planProductId,
  //           price: adjustWith2DecimalsForUI(this.allFormData[type].total),
  //           tax_details:
  //             product.bundledTaxes && product.bundledTaxes.length > 0
  //               ? product.bundledTaxes
  //               : product.taxesDataJSON,
  //           calculatedTax: this.calculateTaxForProductTaxesData(MSpendingApprovalAmount,product.taxesDataJSON??[]),
  //           bundledProducts: product.bundledProducts,
  //           planCoverage: lifeCoverage,
  //           planLevel: product.planlevel ? product.planlevel.id : '',
  //           planLevelParent: product.planlevel ? product.planlevel.parentId : '',
  //           description: product.description,
  //           coverage: lifeCoverage,
  //           version:product.version,
  //           total :this._decimalPipe
  //           .transform(
  //             this.calculateTaxForProductTaxesData(MSpendingApprovalAmount,product.taxesDataJSON??[]) ? this.calculateTaxForProductTaxesData(MSpendingApprovalAmount,product.taxesDataJSON??[]).total : 0,
  //             '1.2-2'
  //           )
  //           ?.replace(/,/g, ''),
  //           tax : this._decimalPipe
  //           .transform(
  //             this.calculateTaxForProductTaxesData(MSpendingApprovalAmount,product.taxesDataJSON??[]) ? this.calculateTaxForProductTaxesData(MSpendingApprovalAmount,product.taxesDataJSON??[]).tax : 0,
  //             '1.2-2'
  //           )
  //           ?.replace(/,/g, '')
  //         }]
  //       },
  //       selectedDetails:{
  //         "amount": adjustWith2DecimalsForUI(this.allFormData[type].total),
  //         "total":adjustWith2DecimalsForUI(this.calculateTaxForProductTaxesData(this.allFormData[type].total,product.taxesDataJSON??[]) ? this.calculateTaxForProductTaxesData(this.allFormData[type].total,product.taxesDataJSON??[]).total : 0),

  //         "tax" :adjustWith2DecimalsForUI(this.calculateTaxForProductTaxesData(this.allFormData[type].total,product.taxesDataJSON??[]) ? this.calculateTaxForProductTaxesData(this.allFormData[type].total,product.taxesDataJSON??[]).tax : 0),
  //         "products":[this.productobj]
  //       }


  //     };

  //     obj["guaranteedAcceptanceDetails"]=obj1.guaranteedAcceptanceDetails
  //     obj["pendingApprovalDetails"]=obj1.pendingApprovalDetails
  //     obj["selectedDetails"]=obj1.selectedDetails
  //     this.addtoplansummaryLife(obj, obj1);
  //   }, 100);
  //   for (let i = this.minValue; i <= this.allFormData[type][category]?.coverage?.maximumCoverageWithEvidence; i += 100000) {
  //     this.rangeValues.push(i);
  //   }
  // }
  public handleRadioChange(event: any, type: string, category: string,options:any,plan:any,product:any,pckage:any,group:any,value:any): void {
    let plansummaryLife = JSON.parse(sessionStorage.getItem('plansummaryLife') || '[]');
    let isSmokerValue = event.target.value;
    let plandetails = event.target.attributes.data.value;

    let plandetailsobj = plandetails.split('##');
    let smokerCheck:any =''

    if(isSmokerValue=="no"){
      smokerCheck=false
    }
    else{
      smokerCheck =true
    }

    this.rangeValues =[]


    this.allFormData[type][category].coverage=value

    if (this.allFormData[type][category]) {

      this.updateFormData(type, category, {
        isSmoker: isSmokerValue,
        premium: this.updatePremium(type, category, isSmokerValue),
      });
    }

    console.log(this.allFormData)
let lifeCoverage=''
let memberObj:any={}
let spouseObj:any={}

if(category=="member"){
  lifeCoverage="MEMBER"
}
else{
lifeCoverage="MEMBER & SPOUSE"
}

if(this.allFormData[type]['spouse']){
  lifeCoverage="MEMBER & SPOUSE"

}







    plansummaryLife.forEach((element:any,index:any) => {

      if(element.planname==plandetailsobj[14]){
        plansummaryLife.splice(index,1)
      }
      sessionStorage.setItem('plansummaryLife', JSON.stringify(plansummaryLife));

    })
    let memberPrice = this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence >this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence-this.allFormData[type]["member"].coverage.defaultRange)/25000):0
    let spousePrice:any;
    if(this.allFormData[type]['spouse']){
      spousePrice = this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence >this.allFormData[type]["spouse"].coverage.defaultRange?((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence-this.allFormData[type]["spouse"].coverage.defaultRange)/25000):0
      }


    setTimeout(() => {
      // console.log(this.allFormData[type][category].premium)

      let obj: any = {
        isBundle: plan.isBundle,
        // enrollmentDate: moment(
        //   this.userForm.get("planenrollmentdate").value,
        //   "MM-DD-YYYY"
        // ).format("YYYY-MM-DD"),
        categorization:{
          paidByCompany: 0,
          coveredByCompany: 0,
          paidByEmployee: 1,
        },
        enrollmentDate: this.userForm.value.planEnrollmentDate
          ? this._CustomDatePipe.transform(
              this.userForm.value.planEnrollmentDate
            )
          : undefined,
        // "name":plandetailsobj[1]+"-"+plandetailsobj[2],
        name: plandetailsobj[14],
        details: plandetailsobj[0],
        packagename: plandetailsobj[0],
        groupName: plandetailsobj[1],
        amount: this.allFormData[type].total,
        // amount: this.allFormData[type][category].premium,
        planCoverage: plandetailsobj[2],
        planPrice: adjustWith2DecimalsForUI(this.allFormData[type].total),
        amountUI:
          '$' +
          adjustWith2DecimalsForUI(this.allFormData[type].total),
        gst:
          plandetailsobj[4] == null ||
          plandetailsobj[4] == '' ||
          plandetailsobj[4] == undefined ||
          plandetailsobj[4] == 'null'
            ? 0
            : parseFloat(plandetailsobj[4]),
        hst:
          plandetailsobj[5] == null ||
          plandetailsobj[5] == '' ||
          plandetailsobj[5] == undefined ||
          plandetailsobj[5] == 'null'
            ? 0
            : parseFloat(plandetailsobj[5]),
        pst:
          plandetailsobj[6] == null ||
          plandetailsobj[6] == '' ||
          plandetailsobj[6] == undefined ||
          plandetailsobj[6] == 'null'
            ? 0
            : parseFloat(plandetailsobj[6]),
        qst:
          plandetailsobj[17] == null ||
          plandetailsobj[17] == '' ||
          plandetailsobj[17] == undefined ||
          plandetailsobj[17] == 'null'
            ? 0
            : parseFloat(plandetailsobj[17]),
        gstCheck:
          plandetailsobj[4] == null ||
          plandetailsobj[4] == '' ||
          plandetailsobj[4] == undefined ||
          plandetailsobj[4] == 'null'
            ? false
            : true,
        hstCheck:
          plandetailsobj[5] == null ||
          plandetailsobj[5] == '' ||
          plandetailsobj[5] == undefined ||
          plandetailsobj[5] == 'null'
            ? false
            : true,
        pstCheck:
          plandetailsobj[6] == null ||
          plandetailsobj[6] == '' ||
          plandetailsobj[6] == undefined ||
          plandetailsobj[6] == 'null'
            ? false
            : true,
        qstCheck:
          plandetailsobj[17] == null ||
          plandetailsobj[17] == '' ||
          plandetailsobj[17] == undefined ||
          plandetailsobj[17] == 'null'
            ? false
            : true,
        id: parseFloat(plandetailsobj[7]),
        fusebillPlanID:
          plandetailsobj[8] == null ||
          plandetailsobj[8] == '' ||
          plandetailsobj[8] == undefined ||
          plandetailsobj[8] == 'null'
            ? 0
            : parseFloat(plandetailsobj[8]),
        planFrequencyID:
          plandetailsobj[9] == null ||
          plandetailsobj[9] == '' ||
          plandetailsobj[9] == undefined ||
          plandetailsobj[9] == 'null'
            ? 0
            : parseFloat(plandetailsobj[9]),
        optIn: plandetailsobj[10] == 'true' ? true : false,
        planname: plandetailsobj[14],
        planLevel: parseInt(plandetailsobj[15]),
        packageId: parseInt(plandetailsobj[16]),
        options: [],
        version:plan.version,
        description: plan.description,
        insuranceType:plan.insuranceType

        //  "disallowedPlanLevels":plandetailsobj[11]
      };
      if (plandetailsobj[11] != null || plandetailsobj[11] != 'null') {
        if (plandetailsobj[11].includes(plandetailsobj[12])) {
        }
      }
     ///this.consoleMessage(obj)
      this.productobj = {
        id: product.id,
        productId: product.productId,
        name: product.name,
        planProductId: product.planProductId,
        price: adjustWith2DecimalsForUI(this.allFormData[type].total),
        tax: this._decimalPipe
          .transform(
            product.calculatedTax ? product.calculatedTax.tax : 0,
            '1.2-2'
          )
          ?.replace(/,/g, ''),
        total: this._decimalPipe
          .transform(
            product.calculatedTax ? product.calculatedTax.total : 0,
            '1.2-2'
          )
          ?.replace(/,/g, ''),
        tax_details:
          product.bundledTaxes && product.bundledTaxes.length > 0
            ? product.bundledTaxes
            : product.taxesDataJSON,
        calculatedTax: this.calculateTaxForProductTaxesData(this.allFormData[type].total,product.taxesDataJSON??[]),
        bundledProducts: product.bundledProducts,
        planCoverage: lifeCoverage,
        planLevel: product.planlevel ? product.planlevel.id : '',
        planLevelParent: product.planlevel ? product.planlevel.parentId : '',
        description: product.description,
        coverage: lifeCoverage,
        version:product.version
      };

      this.productobj.total = this._decimalPipe
      .transform(
        this.productobj.calculatedTax ? this.productobj.calculatedTax.total : 0,
        '1.2-2'
      )
      ?.replace(/,/g, '')
      this.productobj.tax = this._decimalPipe
      .transform(
        this.productobj.calculatedTax ? this.productobj.calculatedTax.tax : 0,
        '1.2-2'
      )
      ?.replace(/,/g, '')


      if(category=="member"){

        memberObj={
      "planStartDate":this.userForm.value.planEnrollmentDate
      ? this._CustomDatePipe.transform(
          this.userForm.value.planEnrollmentDate
        )
      : undefined,
      "units":(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence/this.allFormData[type][category].coverage.minimumCoverage),
      "standardUnits":this.allFormData[type]["member"].coverage.minimumCoverage,
      "coverage":"MEMBER",
      "guaranteedAcceptance":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <=
      this.allFormData[type]["member"].coverage.defaultRange?true:false, //defalutRange
      "pendingMedicalQuestionaire":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence >
      this.allFormData[type]["member"].coverage.defaultRange?true:false,
      // "guaranteedAcceptanceAmount":this.allFormData[type]["member"].coverageValue <=this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverageValue/25000)*this.allFormData[type][category].constant):this.allFormData[type]["member"].coverage.defaultRange,

      "guaranteedAcceptanceAmount":adjustWith2DecimalsForUI(this.allFormData[type]["member"].coverageValue <=this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverageValue/25000)*this.allFormData[type][category].constant):((this.allFormData[type]["member"].coverage.defaultRange/25000)*this.allFormData[type][category].constant)),
      "pendingApprovalAmount":adjustWith2DecimalsForUI(memberPrice*this.allFormData[type][category].constant )||0,
      "smoker":this.allFormData[type]["member"].isSmoker=="yes"?true:false,
      "age":this.allFormData[type]["member"].coverage.age,
      "gender":this.userForm.value.gender?.toLowerCase(),
      "province":"ON",
      "provinceId":12,
      "amount":this.allFormData[type]["member"].premium,
      "tax": adjustWith2DecimalsForUI(this.productobj.tax),
      "taxRate":"", //not mandatory,
      "taxName":"",
      "coverageValue":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence,
      "premium":this.allFormData[type]["member"].constant,
      "total":adjustWith2DecimalsForUI(this.productobj.total),
      "coverageUnitPrice":this.allFormData[type]["member"].coverage.coverageUnitPrice,
      "defaultRange":this.allFormData[type]["member"].coverage.defaultRange,
      "maximumCoverageWithEvidence":this.allFormData[type]["member"].coverage.maximumCoverageWithEvidence,
      "maximumCoverageWithoutEvidence":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence,
      "minimumCoverage":this.allFormData[type]["member"].coverage.minimumCoverage,
        }

        memberObj["guaranteedAcceptanceTax"] = adjustWith2DecimalsForUI(this.calculateTaxForProductTaxesData(this.allFormData[type]["member"].coverageValue <=this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverageValue/25000)*this.allFormData[type]["member"].constant):((this.allFormData[type]["member"].coverage.defaultRange/25000)*this.allFormData[type]["member"].constant),product.taxesDataJSON??[]).tax)
        memberObj["guaranteedAcceptanceTotal"] =adjustWith2DecimalsForUI(this.calculateTaxForProductTaxesData(this.allFormData[type]["member"].coverageValue <=this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverageValue/25000)*this.allFormData[type]["member"].constant):((this.allFormData[type]["member"].coverage.defaultRange/25000)*this.allFormData[type]["member"].constant),product.taxesDataJSON??[]).total)

        memberObj["pendingApprovalTax"] = adjustWith2DecimalsForUI(this.calculateTaxForProductTaxesData(memberPrice*this.allFormData[type]["member"].constant,product.taxesDataJSON??[]).tax)
        memberObj["pendingApprovalTotal"] = adjustWith2DecimalsForUI(this.calculateTaxForProductTaxesData(memberPrice*this.allFormData[type]["member"].constant,product.taxesDataJSON??[]).total)

        obj["member"]= memberObj
        obj["spouse"]= {}
        if(this.allFormData[type]['spouse']){
        spouseObj={
          "planStartDate":this.userForm.value.planEnrollmentDate
  ? this._CustomDatePipe.transform(
      this.userForm.value.planEnrollmentDate
    )
  : undefined,
          "units":(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence/this.allFormData[type][category].coverage.minimumCoverage),
          "standardUnits":this.allFormData[type]["spouse"].coverage.minimumCoverage,
          "coverage":"MEMBER & SPOUSE",
          "guaranteedAcceptance":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <=
  this.allFormData[type]["spouse"].coverage.defaultRange?true:false, //defalutRange
  "pendingMedicalQuestionaire":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence >
  this.allFormData[type]["spouse"].coverage.defaultRange?true:false,
  "guaranteedAcceptanceAmount":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["spouse"].coverage.defaultRange?((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type][category].constant):((this.allFormData[type]["spouse"].coverage.defaultRange/25000)*this.allFormData[type][category].constant),
  "pendingApprovalAmount":adjustWith2DecimalsForUI(spousePrice*this.allFormData[type][category].constant )||0,
          "smoker":this.allFormData[type]["spouse"].isSmoker=="yes"?true:false,
          "age":this.allFormData[type]["spouse"].coverage.age,
          "gender":this.userForm.value.spousegender?.toLowerCase(),
          "province":"ON",
          "provinceId":12,
          "amount":this.allFormData[type]["spouse"].premium,
          "tax":adjustWith2DecimalsForUI(this.productobj.tax),
          "taxRate":"", //not mandatory,
          "taxName":"",
          "coverageValue":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence,
          "premium":this.allFormData[type]["spouse"].constant,
          "total":adjustWith2DecimalsForUI(this.productobj.total),
          "coverageUnitPrice":this.allFormData[type]["spouse"].coverage.coverageUnitPrice,
          "defaultRange":this.allFormData[type]["spouse"].coverage.defaultRange,
          "maximumCoverageWithEvidence":this.allFormData[type]["spouse"].coverage.maximumCoverageWithEvidence,
          "maximumCoverageWithoutEvidence":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence,
          "minimumCoverage":this.allFormData[type]["spouse"].coverage.minimumCoverage,
            }






        obj["member"]= memberObj
        obj["spouse"]= spouseObj

        spouseObj["guaranteedAcceptanceTax"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["spouse"].coverage.defaultRange?((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type][category].constant):((this.allFormData[type]["spouse"].coverage.defaultRange/25000)*this.allFormData[type][category].constant),product.taxesDataJSON??[]).tax
        spouseObj["guaranteedAcceptanceTotal"] =this.calculateTaxForProductTaxesData(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["spouse"].coverage.defaultRange?((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type][category].constant):((this.allFormData[type]["spouse"].coverage.defaultRange/25000)*this.allFormData[type][category].constant),product.taxesDataJSON??[]).total

        spouseObj["pendingApprovalTax"] = this.calculateTaxForProductTaxesData(spousePrice*this.allFormData[type][category].constant,product.taxesDataJSON??[]).tax
        spouseObj["pendingApprovalTotal"] = this.calculateTaxForProductTaxesData(spousePrice*this.allFormData[type][category].constant,product.taxesDataJSON??[]).total
          }
      }
      else{

        memberObj={
          "planStartDate":this.userForm.value.planEnrollmentDate
      ? this._CustomDatePipe.transform(
          this.userForm.value.planEnrollmentDate
        )
      : undefined,
          "units":(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence/this.allFormData[type][category].coverage.minimumCoverage),
          "standardUnits":this.allFormData[type]["member"].coverage.minimumCoverage,
          "coverage":"MEMBER",
          "guaranteedAcceptance":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <=
          this.allFormData[type]["member"].coverage.defaultRange?true:false, //defalutRange
          "pendingMedicalQuestionaire":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence >
          this.allFormData[type]["member"].coverage.defaultRange?true:false,
          "guaranteedAcceptanceAmount":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["member"].constant):((this.allFormData[type]["member"].coverage.defaultRange/25000)*this.allFormData[type]["member"].constant),

          "pendingApprovalAmount":adjustWith2DecimalsForUI(memberPrice*this.allFormData[type][category].constant )||0,
          "smoker":this.allFormData[type]["member"].isSmoker=="yes"?true:false,
          "age":this.allFormData[type]["member"].coverage.age,
          "gender":this.userForm.value.gender?.toLowerCase(),
          "province":"ON",
          "provinceId":12,
          "amount":this.allFormData[type]["member"].premium,
          "tax": adjustWith2DecimalsForUI(this.productobj.tax),
          "taxRate":"", //not mandatory,
          "taxName":"",
          "coverageValue":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence,
          "premium":this.allFormData[type]["member"].constant,
          "total":adjustWith2DecimalsForUI(this.productobj.total),
          "coverageUnitPrice":this.allFormData[type]["member"].coverage.coverageUnitPrice,
          "defaultRange":this.allFormData[type]["member"].coverage.defaultRange,
          "maximumCoverageWithEvidence":this.allFormData[type]["member"].coverage.maximumCoverageWithEvidence,
          "maximumCoverageWithoutEvidence":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence,
          "minimumCoverage":this.allFormData[type]["member"].coverage.minimumCoverage,
            }
            spouseObj={
              "planStartDate":this.userForm.value.planEnrollmentDate
      ? this._CustomDatePipe.transform(
          this.userForm.value.planEnrollmentDate
        )
      : undefined,
              "units":(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence/this.allFormData[type][category].coverage.minimumCoverage),
              "standardUnits":this.allFormData[type]["spouse"].coverage.minimumCoverage,
              "coverage":"MEMBER & SPOUSE",
              "guaranteedAcceptance":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <=
      this.allFormData[type]["spouse"].coverage.defaultRange?true:false, //defalutRange
      "pendingMedicalQuestionaire":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence >
      this.allFormData[type]["spouse"].coverage.defaultRange?true:false,
      "guaranteedAcceptanceAmount":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["spouse"].coverage.defaultRange?((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type][category].constant):((this.allFormData[type]["spouse"].coverage.defaultRange/25000)*this.allFormData[type][category].constant),
      "pendingApprovalAmount":adjustWith2DecimalsForUI(spousePrice*this.allFormData[type][category].constant )||0,
              "smoker":this.allFormData[type]["spouse"].isSmoker=="yes"?true:false,
              "age":this.allFormData[type]["spouse"].coverage.age,
              "gender":this.userForm.value.spousegender?.toLowerCase(),
              "province":"ON",
              "provinceId":12,
              "amount":this.allFormData[type]["spouse"].premium,
              "tax":adjustWith2DecimalsForUI(this.productobj.tax),
              "taxRate":"", //not mandatory,
              "taxName":"",
              "coverageValue":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence,
              "premium":this.allFormData[type]["spouse"].constant,
              "total":adjustWith2DecimalsForUI(this.productobj.total),
              "coverageUnitPrice":this.allFormData[type]["spouse"].coverage.coverageUnitPrice,
              "defaultRange":this.allFormData[type]["spouse"].coverage.defaultRange,
              "maximumCoverageWithEvidence":this.allFormData[type]["spouse"].coverage.maximumCoverageWithEvidence,
              "maximumCoverageWithoutEvidence":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence,
              "minimumCoverage":this.allFormData[type]["spouse"].coverage.minimumCoverage,
                }

      obj["member"]= memberObj
      obj["spouse"]= spouseObj


      memberObj["guaranteedAcceptanceTax"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["member"].constant):((this.allFormData[type]["member"].coverage.defaultRange/25000)*this.allFormData[type]["member"].constant),product.taxesDataJSON??[]).tax
      memberObj["guaranteedAcceptanceTotal"] =this.calculateTaxForProductTaxesData(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["member"].constant):((this.allFormData[type]["member"].coverage.defaultRange/25000)*this.allFormData[type]["member"].constant),product.taxesDataJSON??[]).total

      spouseObj["guaranteedAcceptanceTax"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["spouse"].coverage.defaultRange?((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["spouse"].constant):((this.allFormData[type]["spouse"].coverage.defaultRange/25000)*this.allFormData[type]["spouse"].constant),product.taxesDataJSON??[]).tax
      spouseObj["guaranteedAcceptanceTotal"] =this.calculateTaxForProductTaxesData(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["spouse"].coverage.defaultRange?((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["spouse"].constant):((this.allFormData[type]["spouse"].coverage.defaultRange/25000)*this.allFormData[type]["spouse"].constant),product.taxesDataJSON??[]).total

      memberObj["pendingApprovalTax"] = this.calculateTaxForProductTaxesData(memberPrice*this.allFormData[type]["member"].constant,product.taxesDataJSON??[]).tax
      memberObj["pendingApprovalTotal"] = this.calculateTaxForProductTaxesData(memberPrice*this.allFormData[type]["member"].constant,product.taxesDataJSON??[]).total

      spouseObj["pendingApprovalTax"] = this.calculateTaxForProductTaxesData(spousePrice*this.allFormData[type]["spouse"].constant,product.taxesDataJSON??[]).tax
      spouseObj["pendingApprovalTotal"] = this.calculateTaxForProductTaxesData(spousePrice*this.allFormData[type]["spouse"].constant,product.taxesDataJSON??[]).total

      }

      let MSguranteeAcceptanceAmount = adjustWith2DecimalsForUI(memberObj["guaranteedAcceptanceAmount"]+(spouseObj["guaranteedAcceptanceAmount"]??0))
      let MSguranteeAcceptancetTAX = adjustWith2DecimalsForUI(memberObj["guaranteedAcceptanceTax"]+(spouseObj["guaranteedAcceptanceTax"]??0))
      let MSguranteeAcceptancetTotal = adjustWith2DecimalsForUI(memberObj["guaranteedAcceptanceTotal"]+(spouseObj["guaranteedAcceptanceTotal"]??0))

      let MSpendingApprovalAmount = adjustWith2DecimalsForUI(memberObj["pendingApprovalAmount"]+(spouseObj["pendingApprovalAmount"]??0))
      let MSpendingApprovalTAX = adjustWith2DecimalsForUI(memberObj["pendingApprovalTax"]+(spouseObj["pendingApprovalTax"]??0))
      let MSpendingApprovalTotal = adjustWith2DecimalsForUI(memberObj["pendingApprovalTotal"]+(spouseObj["pendingApprovalTotal"]??0))

      let obj1: any = {
        packageId: plan.packageId,
        packageName: pckage.name,

        planproductname: this.productobj.name,

        groupid: plan.planLevel,
        groupName: group.name,
        id: plan.id,
        name: plan.name,
        planLevel: plan.planLevel ? plan.planLevel : group.id,
        planLevelParent: group.parentId,
        fusebillPlanID: plan.fusebillId,
        planFrequencyID: plan.frqMonthly,
        isBundle: plan.isBundle,
        coverage: lifeCoverage,
        planCoverage: product.planCoverage,
        bundledProducts: [],
        products: [],
        version:plan.version,
        guaranteedAcceptanceDetails:{
          "amount":MSguranteeAcceptanceAmount,
          "tax":MSguranteeAcceptancetTAX,
          "total":MSguranteeAcceptancetTotal,
          "products": [{
            id: product.id,
            productId: product.productId,
            name: product.name,
            planProductId: product.planProductId,
            price: adjustWith2DecimalsForUI(this.allFormData[type].total),
            tax_details:
              product.bundledTaxes && product.bundledTaxes.length > 0
                ? product.bundledTaxes
                : product.taxesDataJSON,
            calculatedTax: this.calculateTaxForProductTaxesData(MSguranteeAcceptanceAmount,product.taxesDataJSON??[]),
            bundledProducts: product.bundledProducts,
            planCoverage: lifeCoverage,
            planLevel: product.planlevel ? product.planlevel.id : '',
            planLevelParent: product.planlevel ? product.planlevel.parentId : '',
            description: product.description,
            coverage: lifeCoverage,
            version:product.version,
            total :this._decimalPipe
            .transform(
              this.calculateTaxForProductTaxesData(MSguranteeAcceptanceAmount,product.taxesDataJSON??[]) ? this.calculateTaxForProductTaxesData(MSguranteeAcceptanceAmount,product.taxesDataJSON??[]).total : 0,
              '1.2-2'
            )
            ?.replace(/,/g, ''),
            tax : this._decimalPipe
            .transform(
              this.calculateTaxForProductTaxesData(MSguranteeAcceptanceAmount,product.taxesDataJSON??[]) ? this.calculateTaxForProductTaxesData(MSguranteeAcceptanceAmount,product.taxesDataJSON??[]).tax : 0,
              '1.2-2'
            )
            ?.replace(/,/g, '')
          }]


        },
        pendingApprovalDetails:{
          "amount":MSpendingApprovalAmount,
          "tax":this._decimalPipe
          .transform(
            this.calculateTaxForProductTaxesData(MSpendingApprovalAmount,product.taxesDataJSON??[]) ? this.calculateTaxForProductTaxesData(MSpendingApprovalAmount,product.taxesDataJSON??[]).tax : 0,
            '1.2-2'
          )
          ?.replace(/,/g, ''),
          "total":this._decimalPipe
          .transform(
            this.calculateTaxForProductTaxesData(MSpendingApprovalAmount,product.taxesDataJSON??[]) ? this.calculateTaxForProductTaxesData(MSpendingApprovalAmount,product.taxesDataJSON??[]).total : 0,
            '1.2-2'
          )
          ?.replace(/,/g, ''),
          "products": [{
            id: product.id,
            productId: product.productId,
            name: product.name,
            planProductId: product.planProductId,
            price: adjustWith2DecimalsForUI(this.allFormData[type].total),
            tax_details:
              product.bundledTaxes && product.bundledTaxes.length > 0
                ? product.bundledTaxes
                : product.taxesDataJSON,
            calculatedTax: this.calculateTaxForProductTaxesData(MSpendingApprovalAmount,product.taxesDataJSON??[]),
            bundledProducts: product.bundledProducts,
            planCoverage: lifeCoverage,
            planLevel: product.planlevel ? product.planlevel.id : '',
            planLevelParent: product.planlevel ? product.planlevel.parentId : '',
            description: product.description,
            coverage: lifeCoverage,
            version:product.version,
            total :this._decimalPipe
            .transform(
              this.calculateTaxForProductTaxesData(MSpendingApprovalAmount,product.taxesDataJSON??[]) ? this.calculateTaxForProductTaxesData(MSpendingApprovalAmount,product.taxesDataJSON??[]).total : 0,
              '1.2-2'
            )
            ?.replace(/,/g, ''),
            tax : this._decimalPipe
            .transform(
              this.calculateTaxForProductTaxesData(MSpendingApprovalAmount,product.taxesDataJSON??[]) ? this.calculateTaxForProductTaxesData(MSpendingApprovalAmount,product.taxesDataJSON??[]).tax : 0,
              '1.2-2'
            )
            ?.replace(/,/g, '')
          }]
        },
        selectedDetails:{
          "amount": adjustWith2DecimalsForUI(this.allFormData[type].total),
          "total":adjustWith2DecimalsForUI(this.calculateTaxForProductTaxesData(this.allFormData[type].total,product.taxesDataJSON??[]) ? this.calculateTaxForProductTaxesData(this.allFormData[type].total,product.taxesDataJSON??[]).total : 0),

          "tax" :adjustWith2DecimalsForUI(this.calculateTaxForProductTaxesData(this.allFormData[type].total,product.taxesDataJSON??[]) ? this.calculateTaxForProductTaxesData(this.allFormData[type].total,product.taxesDataJSON??[]).tax : 0),
          "products":[this.productobj]
        }


      };

      obj["guaranteedAcceptanceDetails"]=obj1.guaranteedAcceptanceDetails
      obj["pendingApprovalDetails"]=obj1.pendingApprovalDetails
      obj["selectedDetails"]=obj1.selectedDetails
      obj1.products.push(this.productobj);

      this.addtoplansummaryLife(obj, obj1);
    }, 100);
    for (let i = this.minValue; i <= this.allFormData[type][category]?.coverage?.maximumCoverageWithEvidence; i += 100000) {
      this.rangeValues.push(i);
    }


    /*
    Form Here the code works for auto slection of Smoker in Life and critical vice versa
     */

    plansummaryLife.forEach((element:any,index:any) => {


      if(category=="member"){

          if(element.member.smoker==isSmokerValue){
        }
        else{


          const dom: HTMLElement = this.elementRef.nativeElement;

          const plan: any = dom.querySelector("."+"member"+"-" + element.planCoverage + "-"+isSmokerValue);
          console.log(plan)
          setTimeout(() => {
            plan.click()
          }, 1000);

      }
      }
      if(category=="spouse"){


          if(element.spouse.smoker==isSmokerValue){
        }
        else{


          const dom: HTMLElement = this.elementRef.nativeElement;

          const plan: any = dom.querySelector("."+"spouse"+"-" + element.planCoverage + "-"+isSmokerValue);
          console.log(plan)
          setTimeout(() => {
            plan.click()
          }, 1000);

      }
      }




  })
  }

  public handleRangeChange(value: any, type: string, category: string,options:any,plan:any,product:any,pckage:any,group:any,event:any,data:any): void {
    // console.log(value)
    // console.log(type)
    // console.log(event)
    // console.log(data)


    if(data.maximumCoverageWithoutEvidence==0){
      data.maximumCoverageWithoutEvidence=25000
    }
    this.allFormData[type][category].coverage=data

    // this.sliderValue = this.allFormData[type][category].coverage.maximumCoverageWithoutEvidence


    this.rangeValues = [];




    if (this.allFormData[type][category]) {
      this.updateFormData(type, category, {
        // coverage: value,
        isSmoker: this.allFormData[type][category].isSmoker,
        premium: this.updatePremium(type, category, this.allFormData[type][category].isSmoker),
      });
    }
    let lifeCoverage=''
    let memberObj:any={}
let spouseObj:any={}
if(category=="member"){
  lifeCoverage="MEMBER"
}else{
lifeCoverage="MEMBER & SPOUSE"
}

if(this.allFormData[type]['spouse']){
  lifeCoverage="MEMBER & SPOUSE"

}
    console.log(this.plansData)
    let plansummaryLife = JSON.parse(sessionStorage.getItem('plansummaryLife') || '[]');


    let plandetails = event.target.attributes.data.value;

    let plandetailsobj = plandetails.split('##');


    plansummaryLife.forEach((element:any,index:any) => {

      if(element.planname==plandetailsobj[14]){
        plansummaryLife.splice(index,1)
      }
      sessionStorage.setItem('plansummaryLife', JSON.stringify(plansummaryLife));

    })
    setTimeout(() => {
      // console.log(this.allFormData[type][category].premium)

      let obj: any = {
        isBundle: plan.isBundle,
        // enrollmentDate: moment(
        //   this.userForm.get("planenrollmentdate").value,
        //   "MM-DD-YYYY"
        // ).format("YYYY-MM-DD"),
        categorization:{
          paidByCompany: 0,
          coveredByCompany: 0,
          paidByEmployee: 1,
        },
        enrollmentDate: this.userForm.value.planEnrollmentDate
          ? this._CustomDatePipe.transform(
              this.userForm.value.planEnrollmentDate
            )
          : undefined,
        // "name":plandetailsobj[1]+"-"+plandetailsobj[2],
        name: plandetailsobj[14],
        details: plandetailsobj[0],
        packagename: plandetailsobj[0],
        groupName: plandetailsobj[1],
        amount: this.allFormData[type].total,
        // amount: this.allFormData[type][category].premium,
        planCoverage: plandetailsobj[2],
        planPrice: adjustWith2DecimalsForUI(this.allFormData[type].total),
        amountUI:
          '$' +
          adjustWith2DecimalsForUI(this.allFormData[type].total),
        gst:
          plandetailsobj[4] == null ||
          plandetailsobj[4] == '' ||
          plandetailsobj[4] == undefined ||
          plandetailsobj[4] == 'null'
            ? 0
            : parseFloat(plandetailsobj[4]),
        hst:
          plandetailsobj[5] == null ||
          plandetailsobj[5] == '' ||
          plandetailsobj[5] == undefined ||
          plandetailsobj[5] == 'null'
            ? 0
            : parseFloat(plandetailsobj[5]),
        pst:
          plandetailsobj[6] == null ||
          plandetailsobj[6] == '' ||
          plandetailsobj[6] == undefined ||
          plandetailsobj[6] == 'null'
            ? 0
            : parseFloat(plandetailsobj[6]),
        qst:
          plandetailsobj[17] == null ||
          plandetailsobj[17] == '' ||
          plandetailsobj[17] == undefined ||
          plandetailsobj[17] == 'null'
            ? 0
            : parseFloat(plandetailsobj[17]),
        gstCheck:
          plandetailsobj[4] == null ||
          plandetailsobj[4] == '' ||
          plandetailsobj[4] == undefined ||
          plandetailsobj[4] == 'null'
            ? false
            : true,
        hstCheck:
          plandetailsobj[5] == null ||
          plandetailsobj[5] == '' ||
          plandetailsobj[5] == undefined ||
          plandetailsobj[5] == 'null'
            ? false
            : true,
        pstCheck:
          plandetailsobj[6] == null ||
          plandetailsobj[6] == '' ||
          plandetailsobj[6] == undefined ||
          plandetailsobj[6] == 'null'
            ? false
            : true,
        qstCheck:
          plandetailsobj[17] == null ||
          plandetailsobj[17] == '' ||
          plandetailsobj[17] == undefined ||
          plandetailsobj[17] == 'null'
            ? false
            : true,
        id: parseFloat(plandetailsobj[7]),
        fusebillPlanID:
          plandetailsobj[8] == null ||
          plandetailsobj[8] == '' ||
          plandetailsobj[8] == undefined ||
          plandetailsobj[8] == 'null'
            ? 0
            : parseFloat(plandetailsobj[8]),
        planFrequencyID:
          plandetailsobj[9] == null ||
          plandetailsobj[9] == '' ||
          plandetailsobj[9] == undefined ||
          plandetailsobj[9] == 'null'
            ? 0
            : parseFloat(plandetailsobj[9]),
        optIn: plandetailsobj[10] == 'true' ? true : false,
        planname: plandetailsobj[14],
        planLevel: parseInt(plandetailsobj[15]),
        packageId: parseInt(plandetailsobj[16]),
        options: [],
        version:plan.version,
        description: plan.description,
        insuranceType:plan.insuranceType

        //  "disallowedPlanLevels":plandetailsobj[11]
      };
      if (plandetailsobj[11] != null || plandetailsobj[11] != 'null') {
        if (plandetailsobj[11].includes(plandetailsobj[12])) {
        }
      }
     ///this.consoleMessage(obj)
      this.productobj = {
        id: product.id,
        productId: product.productId,
        name: product.name,
        planProductId: product.planProductId,
        price:adjustWith2DecimalsForUI(this.allFormData[type].total),
        tax: this._decimalPipe
          .transform(
            product.calculatedTax ? product.calculatedTax.tax : 0,
            '1.2-2'
          )
          ?.replace(/,/g, ''),
        total: this._decimalPipe
          .transform(
            product.calculatedTax ? product.calculatedTax.total : 0,
            '1.2-2'
          )
          ?.replace(/,/g, ''),
        tax_details:
          product.bundledTaxes && product.bundledTaxes.length > 0
            ? product.bundledTaxes
            : product.taxesDataJSON,
        calculatedTax: this.calculateTaxForProductTaxesData(this.allFormData[type].total,product.taxesDataJSON??[]),
        bundledProducts: product.bundledProducts,
        planCoverage: lifeCoverage,
        planLevel: product.planlevel ? product.planlevel.id : '',
        planLevelParent: product.planlevel ? product.planlevel.parentId : '',
        description: product.description,
        coverage: lifeCoverage,
        version:product.version
      };

      this.productobj.total = this._decimalPipe
      .transform(
        this.productobj.calculatedTax ? this.productobj.calculatedTax.total : 0,
        '1.2-2'
      )
      ?.replace(/,/g, '')
      this.productobj.tax = this._decimalPipe
      .transform(
        this.productobj.calculatedTax ? this.productobj.calculatedTax.tax : 0,
        '1.2-2'
      )
      ?.replace(/,/g, '')
      // console.log(this.allFormData)
      // console.log(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence )
      // console.log(this.allFormData[type]["member"].coverage.defaultRange)
      // console.log((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence-this.allFormData[type]["member"].coverage.defaultRange)/25000)

      // console.log(((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence-this.allFormData[type]["member"].coverage.defaultRange)/25000)*this.allFormData[type][category].constant)

      let memberPrice = this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence >this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence-this.allFormData[type]["member"].coverage.defaultRange)/25000):0
      let spousePrice:any;
      if(this.allFormData[type]['spouse']){
        spousePrice = this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence >this.allFormData[type]["spouse"].coverage.defaultRange?((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence-this.allFormData[type]["spouse"].coverage.defaultRange)/25000):0
        }
      // console.log(price)
      // console.log(price*this.allFormData[type]["member"].constant)
      // console.log(product.taxesDataJSON)
      // console.log(this.calculateTaxForProductTaxesData(price*this.allFormData[type]["member"].constant,product.taxesDataJSON??[]))
      // console.log(this.calculateTaxForProductTaxesData(price*this.allFormData[type]["member"].constant,product.taxesDataJSON??[]).tax)
      // console.log(this.calculateTaxForProductTaxesData(price*this.allFormData[type]["member"].constant,product.taxesDataJSON??[]).total)
      if(category=="member"){

        memberObj={
      "planStartDate":this.userForm.value.planEnrollmentDate
      ? this._CustomDatePipe.transform(
          this.userForm.value.planEnrollmentDate
        )
      : undefined,
      "units":(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence/this.allFormData[type][category].coverage.minimumCoverage),
      "standardUnits":this.allFormData[type]["member"].coverage.minimumCoverage,
      "coverage":"MEMBER",
      "guaranteedAcceptance":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <=
      this.allFormData[type]["member"].coverage.defaultRange?true:false, //defalutRange
      "pendingMedicalQuestionaire":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence >
      this.allFormData[type]["member"].coverage.defaultRange?true:false,


      "guaranteedAcceptanceAmount":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["member"].constant):((this.allFormData[type]["member"].coverage.defaultRange/25000)*this.allFormData[type]["member"].constant),
      "pendingApprovalAmount":adjustWith2DecimalsForUI(memberPrice*this.allFormData[type][category].constant )||0,
      "smoker":this.allFormData[type]["member"].isSmoker=="yes"?true:false,
      "age":this.allFormData[type]["member"].coverage.age,
      "gender":this.userForm.value.gender?.toLowerCase(),
      "province":"ON",
      "provinceId":12,
      "amount":this.allFormData[type]["member"].premium,
      "tax": adjustWith2DecimalsForUI(this.productobj.tax),
      "taxRate":"", //not mandatory,
      "taxName":"",
      "coverageValue":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence,
      "premium":this.allFormData[type]["member"].constant,
      "total":adjustWith2DecimalsForUI(this.productobj.total),
      "coverageUnitPrice":this.allFormData[type]["member"].coverage.coverageUnitPrice,
      "defaultRange":this.allFormData[type]["member"].coverage.defaultRange,
      "maximumCoverageWithEvidence":this.allFormData[type]["member"].coverage.maximumCoverageWithEvidence,
      "maximumCoverageWithoutEvidence":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence,
      "minimumCoverage":this.allFormData[type]["member"].coverage.minimumCoverage,
        }
        memberObj["guaranteedAcceptanceTax"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["member"].constant):((this.allFormData[type]["member"].coverage.defaultRange/25000)*this.allFormData[type]["member"].constant),product.taxesDataJSON??[]).tax
        memberObj["guaranteedAcceptanceTotal"] =this.calculateTaxForProductTaxesData(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["member"].constant):((this.allFormData[type]["member"].coverage.defaultRange/25000)*this.allFormData[type]["member"].constant),product.taxesDataJSON??[]).total

        memberObj["pendingApprovalTax"] = this.calculateTaxForProductTaxesData(memberPrice*this.allFormData[type]["member"].constant,product.taxesDataJSON??[]).tax
        memberObj["pendingApprovalTotal"] = this.calculateTaxForProductTaxesData(memberPrice*this.allFormData[type]["member"].constant,product.taxesDataJSON??[]).total

        obj["member"]= memberObj
        obj["spouse"]= {}

        if(this.allFormData[type]['spouse']){
          spouseObj={
            "planStartDate":this.userForm.value.planEnrollmentDate
    ? this._CustomDatePipe.transform(
        this.userForm.value.planEnrollmentDate
      )
    : undefined,
            "units":(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence/this.allFormData[type][category].coverage.minimumCoverage),
            "standardUnits":this.allFormData[type]["spouse"].coverage.minimumCoverage,
            "coverage":"MEMBER & SPOUSE",
            "guaranteedAcceptance":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <=
    this.allFormData[type]["spouse"].coverage.defaultRange?true:false, //defalutRange
    "pendingMedicalQuestionaire":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence >
    this.allFormData[type]["spouse"].coverage.defaultRange?true:false,
    "guaranteedAcceptanceAmount":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["spouse"].coverage.defaultRange?((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["spouse"].constant):((this.allFormData[type]["spouse"].coverage.defaultRange/25000)*this.allFormData[type]["spouse"].constant),
    "pendingApprovalAmount":adjustWith2DecimalsForUI(spousePrice*this.allFormData[type][category].constant )||0,
            "smoker":this.allFormData[type]["spouse"].isSmoker=="yes"?true:false,
            "age":this.allFormData[type]["spouse"].coverage.age,
            "gender":this.userForm.value.spousegender?.toLowerCase(),
            "province":"ON",
            "provinceId":12,
            "amount":this.allFormData[type]["spouse"].premium,
            "tax":adjustWith2DecimalsForUI(this.productobj.tax),
            "taxRate":"", //not mandatory,
            "taxName":"",
            "coverageValue":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence,
            "premium":this.allFormData[type]["spouse"].constant,
            "total":adjustWith2DecimalsForUI(this.productobj.total),
            "coverageUnitPrice":this.allFormData[type]["spouse"].coverage.coverageUnitPrice,
            "defaultRange":this.allFormData[type]["spouse"].coverage.defaultRange,
            "maximumCoverageWithEvidence":this.allFormData[type]["spouse"].coverage.maximumCoverageWithEvidence,
            "maximumCoverageWithoutEvidence":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence,
            "minimumCoverage":this.allFormData[type]["spouse"].coverage.minimumCoverage,
              }






          obj["member"]= memberObj
          obj["spouse"]= spouseObj

          spouseObj["guaranteedAcceptanceTax"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["spouse"].coverage.defaultRange?((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["spouse"].constant):((this.allFormData[type]["spouse"].coverage.defaultRange/25000)*this.allFormData[type]["spouse"].constant),product.taxesDataJSON??[]).tax
          spouseObj["guaranteedAcceptanceTotal"] =this.calculateTaxForProductTaxesData(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["spouse"].coverage.defaultRange?((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["spouse"].constant):((this.allFormData[type]["spouse"].coverage.defaultRange/25000)*this.allFormData[type]["spouse"].constant),product.taxesDataJSON??[]).total

          spouseObj["pendingApprovalTax"] = this.calculateTaxForProductTaxesData(spousePrice*this.allFormData[type]["spouse"].constant,product.taxesDataJSON??[]).tax
          spouseObj["pendingApprovalTotal"] = this.calculateTaxForProductTaxesData(spousePrice*this.allFormData[type]["spouse"].constant,product.taxesDataJSON??[]).total
            }




      }
      else{

        memberObj={
          "planStartDate":this.userForm.value.planEnrollmentDate
      ? this._CustomDatePipe.transform(
          this.userForm.value.planEnrollmentDate
        )
      : undefined,
          "units":(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence/this.allFormData[type][category].coverage.minimumCoverage),
          "standardUnits":this.allFormData[type]["member"].coverage.minimumCoverage,
          "coverage":"MEMBER",
          "guaranteedAcceptance":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <=
          this.allFormData[type]["member"].coverage.defaultRange?true:false, //defalutRange
          "pendingMedicalQuestionaire":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence >
          this.allFormData[type]["member"].coverage.defaultRange?true:false,
          "guaranteedAcceptanceAmount":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["member"].constant):((this.allFormData[type]["member"].coverage.defaultRange/25000)*this.allFormData[type]["member"].constant),
          "pendingApprovalAmount":adjustWith2DecimalsForUI(memberPrice*this.allFormData[type][category].constant )||0,
          "smoker":this.allFormData[type]["member"].isSmoker=="yes"?true:false,
          "age":this.allFormData[type]["member"].coverage.age,
          "gender":this.userForm.value.gender?.toLowerCase(),
          "province":"ON",
          "provinceId":12,
          "amount":this.allFormData[type]["member"].premium,
          "tax":adjustWith2DecimalsForUI(this.productobj.tax),
          "taxRate":"", //not mandatory,
          "taxName":"",
          "coverageValue":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence,
          "premium":this.allFormData[type]["member"].constant,
          "total":adjustWith2DecimalsForUI(this.productobj.total),
          "coverageUnitPrice":this.allFormData[type]["member"].coverage.coverageUnitPrice,
      "defaultRange":this.allFormData[type]["member"].coverage.defaultRange,
      "maximumCoverageWithEvidence":this.allFormData[type]["member"].coverage.maximumCoverageWithEvidence,
      "maximumCoverageWithoutEvidence":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence,
      "minimumCoverage":this.allFormData[type]["member"].coverage.minimumCoverage,
            }
            spouseObj={
              "planStartDate":this.userForm.value.planEnrollmentDate
      ? this._CustomDatePipe.transform(
          this.userForm.value.planEnrollmentDate
        )
      : undefined,
              "units":(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence/this.allFormData[type][category].coverage.minimumCoverage),
              "standardUnits":this.allFormData[type]["spouse"].coverage.minimumCoverage,
              "coverage":"MEMBER & SPOUSE",
              "guaranteedAcceptance":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <=
      this.allFormData[type]["spouse"].coverage.defaultRange?true:false, //defalutRange
      "pendingMedicalQuestionaire":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence >
      this.allFormData[type]["spouse"].coverage.defaultRange?true:false,
      "guaranteedAcceptanceAmount":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["spouse"].coverage.defaultRange?((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["spouse"].constant):((this.allFormData[type]["spouse"].coverage.defaultRange/25000)*this.allFormData[type][category].constant),
      "pendingApprovalAmount":adjustWith2DecimalsForUI(spousePrice*this.allFormData[type][category].constant )||0,
      "smoker":this.allFormData[type]["spouse"].isSmoker=="yes"?true:false,
              "age":this.allFormData[type]["spouse"].coverage.age,
              "gender":this.userForm.value.spousegender?.toLowerCase(),
              "province":"ON",
              "provinceId":12,
              "amount":this.allFormData[type]["spouse"].premium,
              "tax":adjustWith2DecimalsForUI(this.productobj.tax),
              "taxRate":"", //not mandatory,
              "taxName":"",
              "coverageValue":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence,
              "premium":this.allFormData[type]["spouse"].constant,
              "total":adjustWith2DecimalsForUI(this.productobj.total),
              "coverageUnitPrice":this.allFormData[type]["spouse"].coverage.coverageUnitPrice,
      "defaultRange":this.allFormData[type]["spouse"].coverage.defaultRange,
      "maximumCoverageWithEvidence":this.allFormData[type]["spouse"].coverage.maximumCoverageWithEvidence,
      "maximumCoverageWithoutEvidence":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence,
      "minimumCoverage":this.allFormData[type]["spouse"].coverage.minimumCoverage,
                }

      obj["member"]= memberObj
      obj["spouse"]= spouseObj

      // let price = this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence > this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence-this.allFormData[type]["member"].coverage.defaultRange)/25000):0*this.allFormData[type]["member"].constant

      //   console.log(price)
      //   console.log(this.calculateTaxForProductTaxesData(price,product.taxesDataJSON??[]))
      memberObj["guaranteedAcceptanceTax"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["member"].constant):((this.allFormData[type]["member"].coverage.defaultRange/25000)*this.allFormData[type]["member"].constant),product.taxesDataJSON??[]).tax
      memberObj["guaranteedAcceptanceTotal"] =this.calculateTaxForProductTaxesData(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["member"].constant):((this.allFormData[type]["member"].coverage.defaultRange/25000)*this.allFormData[type]["member"].constant),product.taxesDataJSON??[]).total
      spouseObj["guaranteedAcceptanceTax"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["spouse"].coverage.defaultRange?((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["spouse"].constant):((this.allFormData[type]["spouse"].coverage.defaultRange/25000)*this.allFormData[type]["spouse"].constant),product.taxesDataJSON??[]).tax
      spouseObj["guaranteedAcceptanceTotal"] =this.calculateTaxForProductTaxesData(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["spouse"].coverage.defaultRange?((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["spouse"].constant):((this.allFormData[type]["spouse"].coverage.defaultRange/25000)*this.allFormData[type]["spouse"].constant),product.taxesDataJSON??[]).total

      memberObj["pendingApprovalTax"] = this.calculateTaxForProductTaxesData(memberPrice*this.allFormData[type]["member"].constant,product.taxesDataJSON??[]).tax
      memberObj["pendingApprovalTotal"] = this.calculateTaxForProductTaxesData(memberPrice*this.allFormData[type]["member"].constant,product.taxesDataJSON??[]).total

      spouseObj["pendingApprovalTax"] = this.calculateTaxForProductTaxesData(spousePrice*this.allFormData[type]["member"].constant,product.taxesDataJSON??[]).tax
      spouseObj["pendingApprovalTotal"] = this.calculateTaxForProductTaxesData(spousePrice*this.allFormData[type]["member"].constant,product.taxesDataJSON??[]).total

      }


      console.log(memberObj["pendingApprovalTax"])
      let MSguranteeAcceptanceAmount = adjustWith2DecimalsForUI(memberObj["guaranteedAcceptanceAmount"]+(spouseObj["guaranteedAcceptanceAmount"]??0))
      let MSguranteeAcceptancetTAX = adjustWith2DecimalsForUI(memberObj["guaranteedAcceptanceTax"]+(spouseObj["guaranteedAcceptanceTax"]??0))
      let MSguranteeAcceptancetTotal = adjustWith2DecimalsForUI(memberObj["guaranteedAcceptanceTotal"]+(spouseObj["guaranteedAcceptanceTotal"]??0))

      let MSpendingApprovalAmount = adjustWith2DecimalsForUI(memberObj["pendingApprovalAmount"]+(spouseObj["pendingApprovalAmount"]??0))
      let MSpendingApprovalTAX = adjustWith2DecimalsForUI(memberObj["pendingApprovalTax"]+(spouseObj["pendingApprovalTax"]??0))
      let MSpendingApprovalTotal = adjustWith2DecimalsForUI(memberObj["pendingApprovalTotal"]+(spouseObj["pendingApprovalTotal"]??0))

      let obj1: any = {
        packageId: plan.packageId,
        packageName: pckage.name,

        planproductname: this.productobj.name,

        groupid: plan.planLevel,
        groupName: group.name,
        id: plan.id,
        name: plan.name,
        planLevel: plan.planLevel ? plan.planLevel : group.id,
        planLevelParent: group.parentId,
        fusebillPlanID: plan.fusebillId,
        planFrequencyID: plan.frqMonthly,
        isBundle: plan.isBundle,
        coverage: lifeCoverage,
        planCoverage: product.planCoverage,
        bundledProducts: [],
        products: [],
        version:plan.version,
        guaranteedAcceptanceDetails:{
          "amount":MSguranteeAcceptanceAmount,
          "tax":MSguranteeAcceptancetTAX,
          "total":MSguranteeAcceptancetTotal,
          "products": [{
            id: product.id,
            productId: product.productId,
            name: product.name,
            planProductId: product.planProductId,
            price: adjustWith2DecimalsForUI(MSguranteeAcceptanceAmount),
            tax_details:
              product.bundledTaxes && product.bundledTaxes.length > 0
                ? product.bundledTaxes
                : product.taxesDataJSON,
            calculatedTax: this.calculateTaxForProductTaxesData(MSguranteeAcceptanceAmount,product.taxesDataJSON??[]),
            bundledProducts: product.bundledProducts,
            planCoverage: lifeCoverage,
            planLevel: product.planlevel ? product.planlevel.id : '',
            planLevelParent: product.planlevel ? product.planlevel.parentId : '',
            description: product.description,
            coverage: lifeCoverage,
            version:product.version,
            total :this._decimalPipe
            .transform(
              this.calculateTaxForProductTaxesData(MSguranteeAcceptanceAmount,product.taxesDataJSON??[]) ? this.calculateTaxForProductTaxesData(MSguranteeAcceptanceAmount,product.taxesDataJSON??[]).total : 0,
              '1.2-2'
            )
            ?.replace(/,/g, ''),
            tax : this._decimalPipe
            .transform(
              this.calculateTaxForProductTaxesData(MSguranteeAcceptanceAmount,product.taxesDataJSON??[]) ? this.calculateTaxForProductTaxesData(MSguranteeAcceptanceAmount,product.taxesDataJSON??[]).tax : 0,
              '1.2-2'
            )
            ?.replace(/,/g, '')
          }]


        },
        pendingApprovalDetails:{
          "amount":MSpendingApprovalAmount,
          "tax":this._decimalPipe
          .transform(
            this.calculateTaxForProductTaxesData(MSpendingApprovalAmount,product.taxesDataJSON??[]) ? this.calculateTaxForProductTaxesData(MSpendingApprovalAmount,product.taxesDataJSON??[]).tax : 0,
            '1.2-2'
          )
          ?.replace(/,/g, ''),
          "total":this._decimalPipe
          .transform(
            this.calculateTaxForProductTaxesData(MSpendingApprovalAmount,product.taxesDataJSON??[]) ? this.calculateTaxForProductTaxesData(MSpendingApprovalAmount,product.taxesDataJSON??[]).total : 0,
            '1.2-2'
          )
          ?.replace(/,/g, ''),
          "products": [{
            id: product.id,
            productId: product.productId,
            name: product.name,
            planProductId: product.planProductId,
            price: adjustWith2DecimalsForUI(MSpendingApprovalAmount),
            tax_details:
              product.bundledTaxes && product.bundledTaxes.length > 0
                ? product.bundledTaxes
                : product.taxesDataJSON,
            calculatedTax: this.calculateTaxForProductTaxesData(MSpendingApprovalAmount,product.taxesDataJSON??[]),
            bundledProducts: product.bundledProducts,
            planCoverage: lifeCoverage,
            planLevel: product.planlevel ? product.planlevel.id : '',
            planLevelParent: product.planlevel ? product.planlevel.parentId : '',
            description: product.description,
            coverage: lifeCoverage,
            version:product.version,
            total :this._decimalPipe
            .transform(
              this.calculateTaxForProductTaxesData(MSpendingApprovalAmount,product.taxesDataJSON??[]) ? this.calculateTaxForProductTaxesData(MSpendingApprovalAmount,product.taxesDataJSON??[]).total : 0,
              '1.2-2'
            )
            ?.replace(/,/g, ''),
            tax : this._decimalPipe
            .transform(
              this.calculateTaxForProductTaxesData(MSpendingApprovalAmount,product.taxesDataJSON??[]) ? this.calculateTaxForProductTaxesData(MSpendingApprovalAmount,product.taxesDataJSON??[]).tax : 0,
              '1.2-2'
            )
            ?.replace(/,/g, '')
          }]
        },
        selectedDetails:{
          "amount": adjustWith2DecimalsForUI(this.allFormData[type].total),
          "total":adjustWith2DecimalsForUI(this.calculateTaxForProductTaxesData(this.allFormData[type].total,product.taxesDataJSON??[]) ? this.calculateTaxForProductTaxesData(this.allFormData[type].total,product.taxesDataJSON??[]).total : 0),

          "tax" :adjustWith2DecimalsForUI(this.calculateTaxForProductTaxesData(this.allFormData[type].total,product.taxesDataJSON??[]) ? this.calculateTaxForProductTaxesData(this.allFormData[type].total,product.taxesDataJSON??[]).tax : 0),
          "products":[this.productobj]
        }


      };

      obj["guaranteedAcceptanceDetails"]=obj1.guaranteedAcceptanceDetails
      obj["pendingApprovalDetails"]=obj1.pendingApprovalDetails
      obj["selectedDetails"]=obj1.selectedDetails


      obj1.products.push(this.productobj);
      this.addtoplansummaryLife(obj, obj1);
    }, 100);
    for (let i = this.minValue; i <= this.allFormData[type][category]?.coverage?.maximumCoverageWithEvidence; i += 100000) {
      this.rangeValues.push(i);
    }
  }
//   public handleRadioChange(event: any, type: string, category: string,options:any,plan:any,product:any,pckage:any,group:any,value:any): void {
//     let plansummaryLife = JSON.parse(sessionStorage.getItem('plansummaryLife') || '[]');
//     let isSmokerValue = event.target.value;
//     let plandetails = event.target.attributes.data.value;

//     let plandetailsobj = plandetails.split('##');
//     let smokerCheck:any =''

//     if(isSmokerValue=="no"){
//       smokerCheck=false
//     }
//     else{
//       smokerCheck =true
//     }

//     this.rangeValues =[]


//     this.allFormData[type][category].coverage=value

//     if (this.allFormData[type][category]) {

//       this.updateFormData(type, category, {
//         isSmoker: isSmokerValue,
//         premium: this.updatePremium(type, category, isSmokerValue),
//       });
//     }

//     console.log(this.allFormData)
// let lifeCoverage=''
// let memberObj:any={}
// let spouseObj:any={}

// if(category=="member"){
//   lifeCoverage="MEMBER"
// }
// else{
// lifeCoverage="MEMBER & SPOUSE"
// }

// if(this.allFormData[type]['spouse']){
//   lifeCoverage="MEMBER & SPOUSE"

// }







//     plansummaryLife.forEach((element:any,index:any) => {

//       if(element.planname==plandetailsobj[14]){
//         plansummaryLife.splice(index,1)
//       }
//       sessionStorage.setItem('plansummaryLife', JSON.stringify(plansummaryLife));

//     })
//     let memberPrice = this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence >this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence-this.allFormData[type]["member"].coverage.defaultRange)/25000):0
//     let spousePrice:any;
//     if(this.allFormData[type]['spouse']){
//       spousePrice = this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence >this.allFormData[type]["spouse"].coverage.defaultRange?((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence-this.allFormData[type]["spouse"].coverage.defaultRange)/25000):0
//       }


//     setTimeout(() => {
//       // console.log(this.allFormData[type][category].premium)

//       let obj: any = {
//         isBundle: plan.isBundle,
//         // enrollmentDate: moment(
//         //   this.userForm.get("planenrollmentdate").value,
//         //   "MM-DD-YYYY"
//         // ).format("YYYY-MM-DD"),
//         categorization:{
//           paidByCompany: 0,
//           coveredByCompany: 0,
//           paidByEmployee: 1,
//         },
//         enrollmentDate: this.userForm.value.planEnrollmentDate
//           ? this._CustomDatePipe.transform(
//               this.userForm.value.planEnrollmentDate
//             )
//           : undefined,
//         // "name":plandetailsobj[1]+"-"+plandetailsobj[2],
//         name: plandetailsobj[14],
//         details: plandetailsobj[0],
//         packagename: plandetailsobj[0],
//         groupName: plandetailsobj[1],
//         amount: this.allFormData[type].total,
//         // amount: this.allFormData[type][category].premium,
//         planCoverage: plandetailsobj[2],
//         planPrice: adjustWith2DecimalsForUI(this.allFormData[type].total),
//         amountUI:
//           '$' +
//           adjustWith2DecimalsForUI(this.allFormData[type].total),
//         gst:
//           plandetailsobj[4] == null ||
//           plandetailsobj[4] == '' ||
//           plandetailsobj[4] == undefined ||
//           plandetailsobj[4] == 'null'
//             ? 0
//             : parseFloat(plandetailsobj[4]),
//         hst:
//           plandetailsobj[5] == null ||
//           plandetailsobj[5] == '' ||
//           plandetailsobj[5] == undefined ||
//           plandetailsobj[5] == 'null'
//             ? 0
//             : parseFloat(plandetailsobj[5]),
//         pst:
//           plandetailsobj[6] == null ||
//           plandetailsobj[6] == '' ||
//           plandetailsobj[6] == undefined ||
//           plandetailsobj[6] == 'null'
//             ? 0
//             : parseFloat(plandetailsobj[6]),
//         qst:
//           plandetailsobj[17] == null ||
//           plandetailsobj[17] == '' ||
//           plandetailsobj[17] == undefined ||
//           plandetailsobj[17] == 'null'
//             ? 0
//             : parseFloat(plandetailsobj[17]),
//         gstCheck:
//           plandetailsobj[4] == null ||
//           plandetailsobj[4] == '' ||
//           plandetailsobj[4] == undefined ||
//           plandetailsobj[4] == 'null'
//             ? false
//             : true,
//         hstCheck:
//           plandetailsobj[5] == null ||
//           plandetailsobj[5] == '' ||
//           plandetailsobj[5] == undefined ||
//           plandetailsobj[5] == 'null'
//             ? false
//             : true,
//         pstCheck:
//           plandetailsobj[6] == null ||
//           plandetailsobj[6] == '' ||
//           plandetailsobj[6] == undefined ||
//           plandetailsobj[6] == 'null'
//             ? false
//             : true,
//         qstCheck:
//           plandetailsobj[17] == null ||
//           plandetailsobj[17] == '' ||
//           plandetailsobj[17] == undefined ||
//           plandetailsobj[17] == 'null'
//             ? false
//             : true,
//         id: parseFloat(plandetailsobj[7]),
//         fusebillPlanID:
//           plandetailsobj[8] == null ||
//           plandetailsobj[8] == '' ||
//           plandetailsobj[8] == undefined ||
//           plandetailsobj[8] == 'null'
//             ? 0
//             : parseFloat(plandetailsobj[8]),
//         planFrequencyID:
//           plandetailsobj[9] == null ||
//           plandetailsobj[9] == '' ||
//           plandetailsobj[9] == undefined ||
//           plandetailsobj[9] == 'null'
//             ? 0
//             : parseFloat(plandetailsobj[9]),
//         optIn: plandetailsobj[10] == 'true' ? true : false,
//         planname: plandetailsobj[14],
//         planLevel: parseInt(plandetailsobj[15]),
//         packageId: parseInt(plandetailsobj[16]),
//         options: [],
//         version:plan.version,
//         description: plan.description,
//         insuranceType:plan.insuranceType

//         //  "disallowedPlanLevels":plandetailsobj[11]
//       };
//       if (plandetailsobj[11] != null || plandetailsobj[11] != 'null') {
//         if (plandetailsobj[11].includes(plandetailsobj[12])) {
//         }
//       }
//      ///this.consoleMessage(obj)
//       this.productobj = {
//         id: product.id,
//         productId: product.productId,
//         name: product.name,
//         planProductId: product.planProductId,
//         price: adjustWith2DecimalsForUI(this.allFormData[type].total),
//         tax: this._decimalPipe
//           .transform(
//             product.calculatedTax ? product.calculatedTax.tax : 0,
//             '1.2-2'
//           )
//           ?.replace(/,/g, ''),
//         total: this._decimalPipe
//           .transform(
//             product.calculatedTax ? product.calculatedTax.total : 0,
//             '1.2-2'
//           )
//           ?.replace(/,/g, ''),
//         tax_details:
//           product.bundledTaxes && product.bundledTaxes.length > 0
//             ? product.bundledTaxes
//             : product.taxesDataJSON,
//         calculatedTax: this.calculateTaxForProductTaxesData(this.allFormData[type].total,product.taxesDataJSON??[]),
//         bundledProducts: product.bundledProducts,
//         planCoverage: lifeCoverage,
//         planLevel: product.planlevel ? product.planlevel.id : '',
//         planLevelParent: product.planlevel ? product.planlevel.parentId : '',
//         description: product.description,
//         coverage: lifeCoverage,
//         version:product.version
//       };

//       this.productobj.total = this._decimalPipe
//       .transform(
//         this.productobj.calculatedTax ? this.productobj.calculatedTax.total : 0,
//         '1.2-2'
//       )
//       ?.replace(/,/g, '')
//       this.productobj.tax = this._decimalPipe
//       .transform(
//         this.productobj.calculatedTax ? this.productobj.calculatedTax.tax : 0,
//         '1.2-2'
//       )
//       ?.replace(/,/g, '')


//       if(category=="member"){

//         memberObj={
//       "planStartDate":this.userForm.value.planEnrollmentDate
//       ? this._CustomDatePipe.transform(
//           this.userForm.value.planEnrollmentDate
//         )
//       : undefined,
//       "units":(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence/this.allFormData[type][category].coverage.minimumCoverage),
//       "standardUnits":this.allFormData[type]["member"].coverage.minimumCoverage,
//       "coverage":"MEMBER",
//       "guaranteedAcceptance":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <=
//       this.allFormData[type]["member"].coverage.defaultRange?true:false, //defalutRange
//       "pendingMedicalQuestionaire":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence >
//       this.allFormData[type]["member"].coverage.defaultRange?true:false,
//       // "guaranteedAcceptanceAmount":this.allFormData[type]["member"].coverageValue <=this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverageValue/25000)*this.allFormData[type][category].constant):this.allFormData[type]["member"].coverage.defaultRange,

//       "guaranteedAcceptanceAmount":adjustWith2DecimalsForUI(this.allFormData[type]["member"].coverageValue <=this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverageValue/25000)*this.allFormData[type][category].constant):((this.allFormData[type]["member"].coverage.defaultRange/25000)*this.allFormData[type][category].constant)),
//       "pendingApprovalAmount":adjustWith2DecimalsForUI(memberPrice*this.allFormData[type][category].constant )||0,
//       "smoker":this.allFormData[type]["member"].isSmoker=="yes"?true:false,
//       "age":this.allFormData[type]["member"].coverage.age,
//       "gender":this.userForm.value.gender?.toLowerCase(),
//       "province":"ON",
//       "provinceId":12,
//       "amount":this.allFormData[type]["member"].premium,
//       "tax": adjustWith2DecimalsForUI(this.productobj.tax),
//       "taxRate":"", //not mandatory,
//       "taxName":"",
//       "coverageValue":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence,
//       "premium":this.allFormData[type]["member"].constant,
//       "total":adjustWith2DecimalsForUI(this.productobj.total),
//       "coverageUnitPrice":this.allFormData[type]["member"].coverage.coverageUnitPrice,
//       "defaultRange":this.allFormData[type]["member"].coverage.defaultRange,
//       "maximumCoverageWithEvidence":this.allFormData[type]["member"].coverage.maximumCoverageWithEvidence,
//       "maximumCoverageWithoutEvidence":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence,
//       "minimumCoverage":this.allFormData[type]["member"].coverage.minimumCoverage,
//         }

//         memberObj["guaranteedAcceptanceTax"] = adjustWith2DecimalsForUI(this.calculateTaxForProductTaxesData(this.allFormData[type]["member"].coverageValue <=this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverageValue/25000)*this.allFormData[type]["member"].constant):((this.allFormData[type]["member"].coverage.defaultRange/25000)*this.allFormData[type]["member"].constant),product.taxesDataJSON??[]).tax)
//         memberObj["guaranteedAcceptanceTotal"] =adjustWith2DecimalsForUI(this.calculateTaxForProductTaxesData(this.allFormData[type]["member"].coverageValue <=this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverageValue/25000)*this.allFormData[type]["member"].constant):((this.allFormData[type]["member"].coverage.defaultRange/25000)*this.allFormData[type]["member"].constant),product.taxesDataJSON??[]).total)

//         memberObj["pendingApprovalTax"] = adjustWith2DecimalsForUI(this.calculateTaxForProductTaxesData(memberPrice*this.allFormData[type]["member"].constant,product.taxesDataJSON??[]).tax)
//         memberObj["pendingApprovalTotal"] = adjustWith2DecimalsForUI(this.calculateTaxForProductTaxesData(memberPrice*this.allFormData[type]["member"].constant,product.taxesDataJSON??[]).total)

//         obj["member"]= memberObj
//         obj["spouse"]= {}
//         if(this.allFormData[type]['spouse']){
//         spouseObj={
//           "planStartDate":this.userForm.value.planEnrollmentDate
//   ? this._CustomDatePipe.transform(
//       this.userForm.value.planEnrollmentDate
//     )
//   : undefined,
//           "units":(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence/this.allFormData[type][category].coverage.minimumCoverage),
//           "standardUnits":this.allFormData[type]["spouse"].coverage.minimumCoverage,
//           "coverage":"MEMBER & SPOUSE",
//           "guaranteedAcceptance":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <=
//   this.allFormData[type]["spouse"].coverage.defaultRange?true:false, //defalutRange
//   "pendingMedicalQuestionaire":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence >
//   this.allFormData[type]["spouse"].coverage.defaultRange?true:false,
//   "guaranteedAcceptanceAmount":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["spouse"].coverage.defaultRange?((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type][category].constant):((this.allFormData[type]["spouse"].coverage.defaultRange/25000)*this.allFormData[type][category].constant),
//   "pendingApprovalAmount":adjustWith2DecimalsForUI(spousePrice*this.allFormData[type][category].constant )||0,
//           "smoker":this.allFormData[type]["spouse"].isSmoker=="yes"?true:false,
//           "age":this.allFormData[type]["spouse"].coverage.age,
//           "gender":this.userForm.value.spousegender?.toLowerCase(),
//           "province":"ON",
//           "provinceId":12,
//           "amount":this.allFormData[type]["spouse"].premium,
//           "tax":adjustWith2DecimalsForUI(this.productobj.tax),
//           "taxRate":"", //not mandatory,
//           "taxName":"",
//           "coverageValue":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence,
//           "premium":this.allFormData[type]["spouse"].constant,
//           "total":adjustWith2DecimalsForUI(this.productobj.total),
//           "coverageUnitPrice":this.allFormData[type]["spouse"].coverage.coverageUnitPrice,
//           "defaultRange":this.allFormData[type]["spouse"].coverage.defaultRange,
//           "maximumCoverageWithEvidence":this.allFormData[type]["spouse"].coverage.maximumCoverageWithEvidence,
//           "maximumCoverageWithoutEvidence":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence,
//           "minimumCoverage":this.allFormData[type]["spouse"].coverage.minimumCoverage,
//             }






//         obj["member"]= memberObj
//         obj["spouse"]= spouseObj

//         spouseObj["guaranteedAcceptanceTax"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["spouse"].coverage.defaultRange?((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type][category].constant):((this.allFormData[type]["spouse"].coverage.defaultRange/25000)*this.allFormData[type][category].constant),product.taxesDataJSON??[]).tax
//         spouseObj["guaranteedAcceptanceTotal"] =this.calculateTaxForProductTaxesData(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["spouse"].coverage.defaultRange?((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type][category].constant):((this.allFormData[type]["spouse"].coverage.defaultRange/25000)*this.allFormData[type][category].constant),product.taxesDataJSON??[]).total

//         spouseObj["pendingApprovalTax"] = this.calculateTaxForProductTaxesData(spousePrice*this.allFormData[type][category].constant,product.taxesDataJSON??[]).tax
//         spouseObj["pendingApprovalTotal"] = this.calculateTaxForProductTaxesData(spousePrice*this.allFormData[type][category].constant,product.taxesDataJSON??[]).total
//           }
//       }
//       else{

//         memberObj={
//           "planStartDate":this.userForm.value.planEnrollmentDate
//       ? this._CustomDatePipe.transform(
//           this.userForm.value.planEnrollmentDate
//         )
//       : undefined,
//           "units":(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence/this.allFormData[type][category].coverage.minimumCoverage),
//           "standardUnits":this.allFormData[type]["member"].coverage.minimumCoverage,
//           "coverage":"MEMBER",
//           "guaranteedAcceptance":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <=
//           this.allFormData[type]["member"].coverage.defaultRange?true:false, //defalutRange
//           "pendingMedicalQuestionaire":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence >
//           this.allFormData[type]["member"].coverage.defaultRange?true:false,
//           "guaranteedAcceptanceAmount":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["member"].constant):((this.allFormData[type]["member"].coverage.defaultRange/25000)*this.allFormData[type]["member"].constant),

//           "pendingApprovalAmount":adjustWith2DecimalsForUI(memberPrice*this.allFormData[type][category].constant )||0,
//           "smoker":this.allFormData[type]["member"].isSmoker=="yes"?true:false,
//           "age":this.allFormData[type]["member"].coverage.age,
//           "gender":this.userForm.value.gender?.toLowerCase(),
//           "province":"ON",
//           "provinceId":12,
//           "amount":this.allFormData[type]["member"].premium,
//           "tax": adjustWith2DecimalsForUI(this.productobj.tax),
//           "taxRate":"", //not mandatory,
//           "taxName":"",
//           "coverageValue":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence,
//           "premium":this.allFormData[type]["member"].constant,
//           "total":adjustWith2DecimalsForUI(this.productobj.total),
//           "coverageUnitPrice":this.allFormData[type]["member"].coverage.coverageUnitPrice,
//           "defaultRange":this.allFormData[type]["member"].coverage.defaultRange,
//           "maximumCoverageWithEvidence":this.allFormData[type]["member"].coverage.maximumCoverageWithEvidence,
//           "maximumCoverageWithoutEvidence":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence,
//           "minimumCoverage":this.allFormData[type]["member"].coverage.minimumCoverage,
//             }
//             spouseObj={
//               "planStartDate":this.userForm.value.planEnrollmentDate
//       ? this._CustomDatePipe.transform(
//           this.userForm.value.planEnrollmentDate
//         )
//       : undefined,
//               "units":(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence/this.allFormData[type][category].coverage.minimumCoverage),
//               "standardUnits":this.allFormData[type]["spouse"].coverage.minimumCoverage,
//               "coverage":"MEMBER & SPOUSE",
//               "guaranteedAcceptance":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <=
//       this.allFormData[type]["spouse"].coverage.defaultRange?true:false, //defalutRange
//       "pendingMedicalQuestionaire":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence >
//       this.allFormData[type]["spouse"].coverage.defaultRange?true:false,
//       "guaranteedAcceptanceAmount":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["spouse"].coverage.defaultRange?((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type][category].constant):((this.allFormData[type]["spouse"].coverage.defaultRange/25000)*this.allFormData[type][category].constant),
//       "pendingApprovalAmount":adjustWith2DecimalsForUI(spousePrice*this.allFormData[type][category].constant )||0,
//               "smoker":this.allFormData[type]["spouse"].isSmoker=="yes"?true:false,
//               "age":this.allFormData[type]["spouse"].coverage.age,
//               "gender":this.userForm.value.spousegender?.toLowerCase(),
//               "province":"ON",
//               "provinceId":12,
//               "amount":this.allFormData[type]["spouse"].premium,
//               "tax":adjustWith2DecimalsForUI(this.productobj.tax),
//               "taxRate":"", //not mandatory,
//               "taxName":"",
//               "coverageValue":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence,
//               "premium":this.allFormData[type]["spouse"].constant,
//               "total":adjustWith2DecimalsForUI(this.productobj.total),
//               "coverageUnitPrice":this.allFormData[type]["spouse"].coverage.coverageUnitPrice,
//               "defaultRange":this.allFormData[type]["spouse"].coverage.defaultRange,
//               "maximumCoverageWithEvidence":this.allFormData[type]["spouse"].coverage.maximumCoverageWithEvidence,
//               "maximumCoverageWithoutEvidence":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence,
//               "minimumCoverage":this.allFormData[type]["spouse"].coverage.minimumCoverage,
//                 }

//       obj["member"]= memberObj
//       obj["spouse"]= spouseObj


//       memberObj["guaranteedAcceptanceTax"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["member"].constant):((this.allFormData[type]["member"].coverage.defaultRange/25000)*this.allFormData[type]["member"].constant),product.taxesDataJSON??[]).tax
//       memberObj["guaranteedAcceptanceTotal"] =this.calculateTaxForProductTaxesData(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["member"].constant):((this.allFormData[type]["member"].coverage.defaultRange/25000)*this.allFormData[type]["member"].constant),product.taxesDataJSON??[]).total

//       spouseObj["guaranteedAcceptanceTax"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["spouse"].coverage.defaultRange?((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["spouse"].constant):((this.allFormData[type]["spouse"].coverage.defaultRange/25000)*this.allFormData[type]["spouse"].constant),product.taxesDataJSON??[]).tax
//       spouseObj["guaranteedAcceptanceTotal"] =this.calculateTaxForProductTaxesData(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["spouse"].coverage.defaultRange?((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["spouse"].constant):((this.allFormData[type]["spouse"].coverage.defaultRange/25000)*this.allFormData[type]["spouse"].constant),product.taxesDataJSON??[]).total

//       memberObj["pendingApprovalTax"] = this.calculateTaxForProductTaxesData(memberPrice*this.allFormData[type]["member"].constant,product.taxesDataJSON??[]).tax
//       memberObj["pendingApprovalTotal"] = this.calculateTaxForProductTaxesData(memberPrice*this.allFormData[type]["member"].constant,product.taxesDataJSON??[]).total

//       spouseObj["pendingApprovalTax"] = this.calculateTaxForProductTaxesData(spousePrice*this.allFormData[type]["spouse"].constant,product.taxesDataJSON??[]).tax
//       spouseObj["pendingApprovalTotal"] = this.calculateTaxForProductTaxesData(spousePrice*this.allFormData[type]["spouse"].constant,product.taxesDataJSON??[]).total

//       }

//       let MSguranteeAcceptanceAmount = adjustWith2DecimalsForUI(memberObj["guaranteedAcceptanceAmount"]+(spouseObj["guaranteedAcceptanceAmount"]??0))
//       let MSguranteeAcceptancetTAX = adjustWith2DecimalsForUI(memberObj["guaranteedAcceptanceTax"]+(spouseObj["guaranteedAcceptanceTax"]??0))
//       let MSguranteeAcceptancetTotal = adjustWith2DecimalsForUI(memberObj["guaranteedAcceptanceTotal"]+(spouseObj["guaranteedAcceptanceTotal"]??0))

//       let MSpendingApprovalAmount = adjustWith2DecimalsForUI(memberObj["pendingApprovalAmount"]+(spouseObj["pendingApprovalAmount"]??0))
//       let MSpendingApprovalTAX = adjustWith2DecimalsForUI(memberObj["pendingApprovalTax"]+(spouseObj["pendingApprovalTax"]??0))
//       let MSpendingApprovalTotal = adjustWith2DecimalsForUI(memberObj["pendingApprovalTotal"]+(spouseObj["pendingApprovalTotal"]??0))

//       let obj1: any = {
//         packageId: plan.packageId,
//         packageName: pckage.name,

//         planproductname: this.productobj.name,

//         groupid: plan.planLevel,
//         groupName: group.name,
//         id: plan.id,
//         name: plan.name,
//         planLevel: plan.planLevel ? plan.planLevel : group.id,
//         planLevelParent: group.parentId,
//         fusebillPlanID: plan.fusebillId,
//         planFrequencyID: plan.frqMonthly,
//         isBundle: plan.isBundle,
//         coverage: lifeCoverage,
//         planCoverage: product.planCoverage,
//         bundledProducts: [],
//         products: [],
//         version:plan.version,
//         guaranteedAcceptanceDetails:{
//           "amount":MSguranteeAcceptanceAmount,
//           "tax":MSguranteeAcceptancetTAX,
//           "total":MSguranteeAcceptancetTotal,
//           "products": [{
//             id: product.id,
//             productId: product.productId,
//             name: product.name,
//             planProductId: product.planProductId,
//             price: adjustWith2DecimalsForUI(this.allFormData[type].total),
//             tax_details:
//               product.bundledTaxes && product.bundledTaxes.length > 0
//                 ? product.bundledTaxes
//                 : product.taxesDataJSON,
//             calculatedTax: this.calculateTaxForProductTaxesData(MSguranteeAcceptanceAmount,product.taxesDataJSON??[]),
//             bundledProducts: product.bundledProducts,
//             planCoverage: lifeCoverage,
//             planLevel: product.planlevel ? product.planlevel.id : '',
//             planLevelParent: product.planlevel ? product.planlevel.parentId : '',
//             description: product.description,
//             coverage: lifeCoverage,
//             version:product.version,
//             total :this._decimalPipe
//             .transform(
//               this.calculateTaxForProductTaxesData(MSguranteeAcceptanceAmount,product.taxesDataJSON??[]) ? this.calculateTaxForProductTaxesData(MSguranteeAcceptanceAmount,product.taxesDataJSON??[]).total : 0,
//               '1.2-2'
//             )
//             ?.replace(/,/g, ''),
//             tax : this._decimalPipe
//             .transform(
//               this.calculateTaxForProductTaxesData(MSguranteeAcceptanceAmount,product.taxesDataJSON??[]) ? this.calculateTaxForProductTaxesData(MSguranteeAcceptanceAmount,product.taxesDataJSON??[]).tax : 0,
//               '1.2-2'
//             )
//             ?.replace(/,/g, '')
//           }]


//         },
//         pendingApprovalDetails:{
//           "amount":MSpendingApprovalAmount,
//           "tax":this._decimalPipe
//           .transform(
//             this.calculateTaxForProductTaxesData(MSpendingApprovalAmount,product.taxesDataJSON??[]) ? this.calculateTaxForProductTaxesData(MSpendingApprovalAmount,product.taxesDataJSON??[]).tax : 0,
//             '1.2-2'
//           )
//           ?.replace(/,/g, ''),
//           "total":this._decimalPipe
//           .transform(
//             this.calculateTaxForProductTaxesData(MSpendingApprovalAmount,product.taxesDataJSON??[]) ? this.calculateTaxForProductTaxesData(MSpendingApprovalAmount,product.taxesDataJSON??[]).total : 0,
//             '1.2-2'
//           )
//           ?.replace(/,/g, ''),
//           "products": [{
//             id: product.id,
//             productId: product.productId,
//             name: product.name,
//             planProductId: product.planProductId,
//             price: adjustWith2DecimalsForUI(this.allFormData[type].total),
//             tax_details:
//               product.bundledTaxes && product.bundledTaxes.length > 0
//                 ? product.bundledTaxes
//                 : product.taxesDataJSON,
//             calculatedTax: this.calculateTaxForProductTaxesData(MSpendingApprovalAmount,product.taxesDataJSON??[]),
//             bundledProducts: product.bundledProducts,
//             planCoverage: lifeCoverage,
//             planLevel: product.planlevel ? product.planlevel.id : '',
//             planLevelParent: product.planlevel ? product.planlevel.parentId : '',
//             description: product.description,
//             coverage: lifeCoverage,
//             version:product.version,
//             total :this._decimalPipe
//             .transform(
//               this.calculateTaxForProductTaxesData(MSpendingApprovalAmount,product.taxesDataJSON??[]) ? this.calculateTaxForProductTaxesData(MSpendingApprovalAmount,product.taxesDataJSON??[]).total : 0,
//               '1.2-2'
//             )
//             ?.replace(/,/g, ''),
//             tax : this._decimalPipe
//             .transform(
//               this.calculateTaxForProductTaxesData(MSpendingApprovalAmount,product.taxesDataJSON??[]) ? this.calculateTaxForProductTaxesData(MSpendingApprovalAmount,product.taxesDataJSON??[]).tax : 0,
//               '1.2-2'
//             )
//             ?.replace(/,/g, '')
//           }]
//         },
//         selectedDetails:{
//           "amount": adjustWith2DecimalsForUI(this.allFormData[type].total),
//           "total":adjustWith2DecimalsForUI(this.calculateTaxForProductTaxesData(this.allFormData[type].total,product.taxesDataJSON??[]) ? this.calculateTaxForProductTaxesData(this.allFormData[type].total,product.taxesDataJSON??[]).total : 0),

//           "tax" :adjustWith2DecimalsForUI(this.calculateTaxForProductTaxesData(this.allFormData[type].total,product.taxesDataJSON??[]) ? this.calculateTaxForProductTaxesData(this.allFormData[type].total,product.taxesDataJSON??[]).tax : 0),
//           "products":[this.productobj]
//         }


//       };

//       obj["guaranteedAcceptanceDetails"]=obj1.guaranteedAcceptanceDetails
//       obj["pendingApprovalDetails"]=obj1.pendingApprovalDetails
//       obj["selectedDetails"]=obj1.selectedDetails
//       obj1.products.push(this.productobj);

//       this.addtoplansummaryLife(obj, obj1);
//     }, 100);
//     for (let i = this.minValue; i <= this.allFormData[type][category]?.coverage?.maximumCoverageWithEvidence; i += 100000) {
//       this.rangeValues.push(i);
//     }


//     /*
//     Form Here the code works for auto slection of Smoker in Life and critical vice versa
//      */

//     plansummaryLife.forEach((element:any,index:any) => {


//       if(category=="member"){

//           if(element.member.smoker==isSmokerValue){
//         }
//         else{


//           const dom: HTMLElement = this.elementRef.nativeElement;

//           const plan: any = dom.querySelector("."+"member"+"-" + element.planCoverage + "-"+isSmokerValue);
//           console.log(plan)
//           setTimeout(() => {
//             plan.click()
//           }, 1000);

//       }
//       }
//       if(category=="spouse"){


//           if(element.spouse.smoker==isSmokerValue){
//         }
//         else{


//           const dom: HTMLElement = this.elementRef.nativeElement;

//           const plan: any = dom.querySelector("."+"spouse"+"-" + element.planCoverage + "-"+isSmokerValue);
//           console.log(plan)
//           setTimeout(() => {
//             plan.click()
//           }, 1000);

//       }
//       }




//   })
//   }

//   public handleRangeChange(value: any, type: string, category: string,options:any,plan:any,product:any,pckage:any,group:any,event:any,data:any): void {
//     // console.log(value)
//     // console.log(type)
//     // console.log(event)
//     // console.log(data)


//     if(data.maximumCoverageWithoutEvidence==0){
//       data.maximumCoverageWithoutEvidence=25000
//     }
//     this.allFormData[type][category].coverage=data

//     // this.sliderValue = this.allFormData[type][category].coverage.maximumCoverageWithoutEvidence


//     this.rangeValues = [];




//     if (this.allFormData[type][category]) {
//       this.updateFormData(type, category, {
//         // coverage: value,
//         isSmoker: this.allFormData[type][category].isSmoker,
//         premium: this.updatePremium(type, category, this.allFormData[type][category].isSmoker),
//       });
//     }
//     let lifeCoverage=''
//     let memberObj:any={}
// let spouseObj:any={}
// if(category=="member"){
//   lifeCoverage="MEMBER"
// }else{
// lifeCoverage="MEMBER & SPOUSE"
// }

// if(this.allFormData[type]['spouse']){
//   lifeCoverage="MEMBER & SPOUSE"

// }
//     console.log(this.plansData)
//     let plansummaryLife = JSON.parse(sessionStorage.getItem('plansummaryLife') || '[]');


//     let plandetails = event.target.attributes.data.value;

//     let plandetailsobj = plandetails.split('##');


//     plansummaryLife.forEach((element:any,index:any) => {

//       if(element.planname==plandetailsobj[14]){
//         plansummaryLife.splice(index,1)
//       }
//       sessionStorage.setItem('plansummaryLife', JSON.stringify(plansummaryLife));

//     })
//     setTimeout(() => {
//       // console.log(this.allFormData[type][category].premium)

//       let obj: any = {
//         isBundle: plan.isBundle,
//         // enrollmentDate: moment(
//         //   this.userForm.get("planenrollmentdate").value,
//         //   "MM-DD-YYYY"
//         // ).format("YYYY-MM-DD"),
//         categorization:{
//           paidByCompany: 0,
//           coveredByCompany: 0,
//           paidByEmployee: 1,
//         },
//         enrollmentDate: this.userForm.value.planEnrollmentDate
//           ? this._CustomDatePipe.transform(
//               this.userForm.value.planEnrollmentDate
//             )
//           : undefined,
//         // "name":plandetailsobj[1]+"-"+plandetailsobj[2],
//         name: plandetailsobj[14],
//         details: plandetailsobj[0],
//         packagename: plandetailsobj[0],
//         groupName: plandetailsobj[1],
//         amount: this.allFormData[type].total,
//         // amount: this.allFormData[type][category].premium,
//         planCoverage: plandetailsobj[2],
//         planPrice: adjustWith2DecimalsForUI(this.allFormData[type].total),
//         amountUI:
//           '$' +
//           adjustWith2DecimalsForUI(this.allFormData[type].total),
//         gst:
//           plandetailsobj[4] == null ||
//           plandetailsobj[4] == '' ||
//           plandetailsobj[4] == undefined ||
//           plandetailsobj[4] == 'null'
//             ? 0
//             : parseFloat(plandetailsobj[4]),
//         hst:
//           plandetailsobj[5] == null ||
//           plandetailsobj[5] == '' ||
//           plandetailsobj[5] == undefined ||
//           plandetailsobj[5] == 'null'
//             ? 0
//             : parseFloat(plandetailsobj[5]),
//         pst:
//           plandetailsobj[6] == null ||
//           plandetailsobj[6] == '' ||
//           plandetailsobj[6] == undefined ||
//           plandetailsobj[6] == 'null'
//             ? 0
//             : parseFloat(plandetailsobj[6]),
//         qst:
//           plandetailsobj[17] == null ||
//           plandetailsobj[17] == '' ||
//           plandetailsobj[17] == undefined ||
//           plandetailsobj[17] == 'null'
//             ? 0
//             : parseFloat(plandetailsobj[17]),
//         gstCheck:
//           plandetailsobj[4] == null ||
//           plandetailsobj[4] == '' ||
//           plandetailsobj[4] == undefined ||
//           plandetailsobj[4] == 'null'
//             ? false
//             : true,
//         hstCheck:
//           plandetailsobj[5] == null ||
//           plandetailsobj[5] == '' ||
//           plandetailsobj[5] == undefined ||
//           plandetailsobj[5] == 'null'
//             ? false
//             : true,
//         pstCheck:
//           plandetailsobj[6] == null ||
//           plandetailsobj[6] == '' ||
//           plandetailsobj[6] == undefined ||
//           plandetailsobj[6] == 'null'
//             ? false
//             : true,
//         qstCheck:
//           plandetailsobj[17] == null ||
//           plandetailsobj[17] == '' ||
//           plandetailsobj[17] == undefined ||
//           plandetailsobj[17] == 'null'
//             ? false
//             : true,
//         id: parseFloat(plandetailsobj[7]),
//         fusebillPlanID:
//           plandetailsobj[8] == null ||
//           plandetailsobj[8] == '' ||
//           plandetailsobj[8] == undefined ||
//           plandetailsobj[8] == 'null'
//             ? 0
//             : parseFloat(plandetailsobj[8]),
//         planFrequencyID:
//           plandetailsobj[9] == null ||
//           plandetailsobj[9] == '' ||
//           plandetailsobj[9] == undefined ||
//           plandetailsobj[9] == 'null'
//             ? 0
//             : parseFloat(plandetailsobj[9]),
//         optIn: plandetailsobj[10] == 'true' ? true : false,
//         planname: plandetailsobj[14],
//         planLevel: parseInt(plandetailsobj[15]),
//         packageId: parseInt(plandetailsobj[16]),
//         options: [],
//         version:plan.version,
//         description: plan.description,
//         insuranceType:plan.insuranceType

//         //  "disallowedPlanLevels":plandetailsobj[11]
//       };
//       if (plandetailsobj[11] != null || plandetailsobj[11] != 'null') {
//         if (plandetailsobj[11].includes(plandetailsobj[12])) {
//         }
//       }
//      ///this.consoleMessage(obj)
//       this.productobj = {
//         id: product.id,
//         productId: product.productId,
//         name: product.name,
//         planProductId: product.planProductId,
//         price:adjustWith2DecimalsForUI(this.allFormData[type].total),
//         tax: this._decimalPipe
//           .transform(
//             product.calculatedTax ? product.calculatedTax.tax : 0,
//             '1.2-2'
//           )
//           ?.replace(/,/g, ''),
//         total: this._decimalPipe
//           .transform(
//             product.calculatedTax ? product.calculatedTax.total : 0,
//             '1.2-2'
//           )
//           ?.replace(/,/g, ''),
//         tax_details:
//           product.bundledTaxes && product.bundledTaxes.length > 0
//             ? product.bundledTaxes
//             : product.taxesDataJSON,
//         calculatedTax: this.calculateTaxForProductTaxesData(this.allFormData[type].total,product.taxesDataJSON??[]),
//         bundledProducts: product.bundledProducts,
//         planCoverage: lifeCoverage,
//         planLevel: product.planlevel ? product.planlevel.id : '',
//         planLevelParent: product.planlevel ? product.planlevel.parentId : '',
//         description: product.description,
//         coverage: lifeCoverage,
//         version:product.version
//       };

//       this.productobj.total = this._decimalPipe
//       .transform(
//         this.productobj.calculatedTax ? this.productobj.calculatedTax.total : 0,
//         '1.2-2'
//       )
//       ?.replace(/,/g, '')
//       this.productobj.tax = this._decimalPipe
//       .transform(
//         this.productobj.calculatedTax ? this.productobj.calculatedTax.tax : 0,
//         '1.2-2'
//       )
//       ?.replace(/,/g, '')
//       // console.log(this.allFormData)
//       // console.log(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence )
//       // console.log(this.allFormData[type]["member"].coverage.defaultRange)
//       // console.log((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence-this.allFormData[type]["member"].coverage.defaultRange)/25000)

//       // console.log(((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence-this.allFormData[type]["member"].coverage.defaultRange)/25000)*this.allFormData[type][category].constant)

//       let memberPrice = this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence >this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence-this.allFormData[type]["member"].coverage.defaultRange)/25000):0
//       let spousePrice:any;
//       if(this.allFormData[type]['spouse']){
//         spousePrice = this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence >this.allFormData[type]["spouse"].coverage.defaultRange?((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence-this.allFormData[type]["spouse"].coverage.defaultRange)/25000):0
//         }
//       // console.log(price)
//       // console.log(price*this.allFormData[type]["member"].constant)
//       // console.log(product.taxesDataJSON)
//       // console.log(this.calculateTaxForProductTaxesData(price*this.allFormData[type]["member"].constant,product.taxesDataJSON??[]))
//       // console.log(this.calculateTaxForProductTaxesData(price*this.allFormData[type]["member"].constant,product.taxesDataJSON??[]).tax)
//       // console.log(this.calculateTaxForProductTaxesData(price*this.allFormData[type]["member"].constant,product.taxesDataJSON??[]).total)
//       if(category=="member"){

//         memberObj={
//       "planStartDate":this.userForm.value.planEnrollmentDate
//       ? this._CustomDatePipe.transform(
//           this.userForm.value.planEnrollmentDate
//         )
//       : undefined,
//       "units":(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence/this.allFormData[type][category].coverage.minimumCoverage),
//       "standardUnits":this.allFormData[type]["member"].coverage.minimumCoverage,
//       "coverage":"MEMBER",
//       "guaranteedAcceptance":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <=
//       this.allFormData[type]["member"].coverage.defaultRange?true:false, //defalutRange
//       "pendingMedicalQuestionaire":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence >
//       this.allFormData[type]["member"].coverage.defaultRange?true:false,


//       "guaranteedAcceptanceAmount":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["member"].constant):((this.allFormData[type]["member"].coverage.defaultRange/25000)*this.allFormData[type]["member"].constant),
//       "pendingApprovalAmount":adjustWith2DecimalsForUI(memberPrice*this.allFormData[type][category].constant )||0,
//       "smoker":this.allFormData[type]["member"].isSmoker=="yes"?true:false,
//       "age":this.allFormData[type]["member"].coverage.age,
//       "gender":this.userForm.value.gender?.toLowerCase(),
//       "province":"ON",
//       "provinceId":12,
//       "amount":this.allFormData[type]["member"].premium,
//       "tax": adjustWith2DecimalsForUI(this.productobj.tax),
//       "taxRate":"", //not mandatory,
//       "taxName":"",
//       "coverageValue":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence,
//       "premium":this.allFormData[type]["member"].constant,
//       "total":adjustWith2DecimalsForUI(this.productobj.total),
//       "coverageUnitPrice":this.allFormData[type]["member"].coverage.coverageUnitPrice,
//       "defaultRange":this.allFormData[type]["member"].coverage.defaultRange,
//       "maximumCoverageWithEvidence":this.allFormData[type]["member"].coverage.maximumCoverageWithEvidence,
//       "maximumCoverageWithoutEvidence":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence,
//       "minimumCoverage":this.allFormData[type]["member"].coverage.minimumCoverage,
//         }
//         memberObj["guaranteedAcceptanceTax"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["member"].constant):((this.allFormData[type]["member"].coverage.defaultRange/25000)*this.allFormData[type]["member"].constant),product.taxesDataJSON??[]).tax
//         memberObj["guaranteedAcceptanceTotal"] =this.calculateTaxForProductTaxesData(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["member"].constant):((this.allFormData[type]["member"].coverage.defaultRange/25000)*this.allFormData[type]["member"].constant),product.taxesDataJSON??[]).total

//         memberObj["pendingApprovalTax"] = this.calculateTaxForProductTaxesData(memberPrice*this.allFormData[type]["member"].constant,product.taxesDataJSON??[]).tax
//         memberObj["pendingApprovalTotal"] = this.calculateTaxForProductTaxesData(memberPrice*this.allFormData[type]["member"].constant,product.taxesDataJSON??[]).total

//         obj["member"]= memberObj
//         obj["spouse"]= {}

//         if(this.allFormData[type]['spouse']){
//           spouseObj={
//             "planStartDate":this.userForm.value.planEnrollmentDate
//     ? this._CustomDatePipe.transform(
//         this.userForm.value.planEnrollmentDate
//       )
//     : undefined,
//             "units":(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence/this.allFormData[type][category].coverage.minimumCoverage),
//             "standardUnits":this.allFormData[type]["spouse"].coverage.minimumCoverage,
//             "coverage":"MEMBER & SPOUSE",
//             "guaranteedAcceptance":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <=
//     this.allFormData[type]["spouse"].coverage.defaultRange?true:false, //defalutRange
//     "pendingMedicalQuestionaire":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence >
//     this.allFormData[type]["spouse"].coverage.defaultRange?true:false,
//     "guaranteedAcceptanceAmount":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["spouse"].coverage.defaultRange?((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["spouse"].constant):((this.allFormData[type]["spouse"].coverage.defaultRange/25000)*this.allFormData[type]["spouse"].constant),
//     "pendingApprovalAmount":adjustWith2DecimalsForUI(spousePrice*this.allFormData[type][category].constant )||0,
//             "smoker":this.allFormData[type]["spouse"].isSmoker=="yes"?true:false,
//             "age":this.allFormData[type]["spouse"].coverage.age,
//             "gender":this.userForm.value.spousegender?.toLowerCase(),
//             "province":"ON",
//             "provinceId":12,
//             "amount":this.allFormData[type]["spouse"].premium,
//             "tax":adjustWith2DecimalsForUI(this.productobj.tax),
//             "taxRate":"", //not mandatory,
//             "taxName":"",
//             "coverageValue":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence,
//             "premium":this.allFormData[type]["spouse"].constant,
//             "total":adjustWith2DecimalsForUI(this.productobj.total),
//             "coverageUnitPrice":this.allFormData[type]["spouse"].coverage.coverageUnitPrice,
//             "defaultRange":this.allFormData[type]["spouse"].coverage.defaultRange,
//             "maximumCoverageWithEvidence":this.allFormData[type]["spouse"].coverage.maximumCoverageWithEvidence,
//             "maximumCoverageWithoutEvidence":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence,
//             "minimumCoverage":this.allFormData[type]["spouse"].coverage.minimumCoverage,
//               }






//           obj["member"]= memberObj
//           obj["spouse"]= spouseObj

//           spouseObj["guaranteedAcceptanceTax"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["spouse"].coverage.defaultRange?((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["spouse"].constant):((this.allFormData[type]["spouse"].coverage.defaultRange/25000)*this.allFormData[type]["spouse"].constant),product.taxesDataJSON??[]).tax
//           spouseObj["guaranteedAcceptanceTotal"] =this.calculateTaxForProductTaxesData(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["spouse"].coverage.defaultRange?((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["spouse"].constant):((this.allFormData[type]["spouse"].coverage.defaultRange/25000)*this.allFormData[type]["spouse"].constant),product.taxesDataJSON??[]).total

//           spouseObj["pendingApprovalTax"] = this.calculateTaxForProductTaxesData(spousePrice*this.allFormData[type]["spouse"].constant,product.taxesDataJSON??[]).tax
//           spouseObj["pendingApprovalTotal"] = this.calculateTaxForProductTaxesData(spousePrice*this.allFormData[type]["spouse"].constant,product.taxesDataJSON??[]).total
//             }




//       }
//       else{

//         memberObj={
//           "planStartDate":this.userForm.value.planEnrollmentDate
//       ? this._CustomDatePipe.transform(
//           this.userForm.value.planEnrollmentDate
//         )
//       : undefined,
//           "units":(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence/this.allFormData[type][category].coverage.minimumCoverage),
//           "standardUnits":this.allFormData[type]["member"].coverage.minimumCoverage,
//           "coverage":"MEMBER",
//           "guaranteedAcceptance":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <=
//           this.allFormData[type]["member"].coverage.defaultRange?true:false, //defalutRange
//           "pendingMedicalQuestionaire":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence >
//           this.allFormData[type]["member"].coverage.defaultRange?true:false,
//           "guaranteedAcceptanceAmount":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["member"].constant):((this.allFormData[type]["member"].coverage.defaultRange/25000)*this.allFormData[type]["member"].constant),
//           "pendingApprovalAmount":adjustWith2DecimalsForUI(memberPrice*this.allFormData[type][category].constant )||0,
//           "smoker":this.allFormData[type]["member"].isSmoker=="yes"?true:false,
//           "age":this.allFormData[type]["member"].coverage.age,
//           "gender":this.userForm.value.gender?.toLowerCase(),
//           "province":"ON",
//           "provinceId":12,
//           "amount":this.allFormData[type]["member"].premium,
//           "tax":adjustWith2DecimalsForUI(this.productobj.tax),
//           "taxRate":"", //not mandatory,
//           "taxName":"",
//           "coverageValue":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence,
//           "premium":this.allFormData[type]["member"].constant,
//           "total":adjustWith2DecimalsForUI(this.productobj.total),
//           "coverageUnitPrice":this.allFormData[type]["member"].coverage.coverageUnitPrice,
//       "defaultRange":this.allFormData[type]["member"].coverage.defaultRange,
//       "maximumCoverageWithEvidence":this.allFormData[type]["member"].coverage.maximumCoverageWithEvidence,
//       "maximumCoverageWithoutEvidence":this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence,
//       "minimumCoverage":this.allFormData[type]["member"].coverage.minimumCoverage,
//             }
//             spouseObj={
//               "planStartDate":this.userForm.value.planEnrollmentDate
//       ? this._CustomDatePipe.transform(
//           this.userForm.value.planEnrollmentDate
//         )
//       : undefined,
//               "units":(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence/this.allFormData[type][category].coverage.minimumCoverage),
//               "standardUnits":this.allFormData[type]["spouse"].coverage.minimumCoverage,
//               "coverage":"MEMBER & SPOUSE",
//               "guaranteedAcceptance":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <=
//       this.allFormData[type]["spouse"].coverage.defaultRange?true:false, //defalutRange
//       "pendingMedicalQuestionaire":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence >
//       this.allFormData[type]["spouse"].coverage.defaultRange?true:false,
//       "guaranteedAcceptanceAmount":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["spouse"].coverage.defaultRange?((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["spouse"].constant):((this.allFormData[type]["spouse"].coverage.defaultRange/25000)*this.allFormData[type][category].constant),
//       "pendingApprovalAmount":adjustWith2DecimalsForUI(spousePrice*this.allFormData[type][category].constant )||0,
//       "smoker":this.allFormData[type]["spouse"].isSmoker=="yes"?true:false,
//               "age":this.allFormData[type]["spouse"].coverage.age,
//               "gender":this.userForm.value.spousegender?.toLowerCase(),
//               "province":"ON",
//               "provinceId":12,
//               "amount":this.allFormData[type]["spouse"].premium,
//               "tax":adjustWith2DecimalsForUI(this.productobj.tax),
//               "taxRate":"", //not mandatory,
//               "taxName":"",
//               "coverageValue":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence,
//               "premium":this.allFormData[type]["spouse"].constant,
//               "total":adjustWith2DecimalsForUI(this.productobj.total),
//               "coverageUnitPrice":this.allFormData[type]["spouse"].coverage.coverageUnitPrice,
//       "defaultRange":this.allFormData[type]["spouse"].coverage.defaultRange,
//       "maximumCoverageWithEvidence":this.allFormData[type]["spouse"].coverage.maximumCoverageWithEvidence,
//       "maximumCoverageWithoutEvidence":this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence,
//       "minimumCoverage":this.allFormData[type]["spouse"].coverage.minimumCoverage,
//                 }

//       obj["member"]= memberObj
//       obj["spouse"]= spouseObj

//       // let price = this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence > this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence-this.allFormData[type]["member"].coverage.defaultRange)/25000):0*this.allFormData[type]["member"].constant

//       //   console.log(price)
//       //   console.log(this.calculateTaxForProductTaxesData(price,product.taxesDataJSON??[]))
//       memberObj["guaranteedAcceptanceTax"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["member"].constant):((this.allFormData[type]["member"].coverage.defaultRange/25000)*this.allFormData[type]["member"].constant),product.taxesDataJSON??[]).tax
//       memberObj["guaranteedAcceptanceTotal"] =this.calculateTaxForProductTaxesData(this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["member"].coverage.defaultRange?((this.allFormData[type]["member"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["member"].constant):((this.allFormData[type]["member"].coverage.defaultRange/25000)*this.allFormData[type]["member"].constant),product.taxesDataJSON??[]).total
//       spouseObj["guaranteedAcceptanceTax"] = this.calculateTaxForProductTaxesData(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["spouse"].coverage.defaultRange?((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["spouse"].constant):((this.allFormData[type]["spouse"].coverage.defaultRange/25000)*this.allFormData[type]["spouse"].constant),product.taxesDataJSON??[]).tax
//       spouseObj["guaranteedAcceptanceTotal"] =this.calculateTaxForProductTaxesData(this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence <=this.allFormData[type]["spouse"].coverage.defaultRange?((this.allFormData[type]["spouse"].coverage.maximumCoverageWithoutEvidence/25000)*this.allFormData[type]["spouse"].constant):((this.allFormData[type]["spouse"].coverage.defaultRange/25000)*this.allFormData[type]["spouse"].constant),product.taxesDataJSON??[]).total

//       memberObj["pendingApprovalTax"] = this.calculateTaxForProductTaxesData(memberPrice*this.allFormData[type]["member"].constant,product.taxesDataJSON??[]).tax
//       memberObj["pendingApprovalTotal"] = this.calculateTaxForProductTaxesData(memberPrice*this.allFormData[type]["member"].constant,product.taxesDataJSON??[]).total

//       spouseObj["pendingApprovalTax"] = this.calculateTaxForProductTaxesData(spousePrice*this.allFormData[type]["member"].constant,product.taxesDataJSON??[]).tax
//       spouseObj["pendingApprovalTotal"] = this.calculateTaxForProductTaxesData(spousePrice*this.allFormData[type]["member"].constant,product.taxesDataJSON??[]).total

//       }


//       console.log(memberObj["pendingApprovalTax"])
//       let MSguranteeAcceptanceAmount = adjustWith2DecimalsForUI(memberObj["guaranteedAcceptanceAmount"]+(spouseObj["guaranteedAcceptanceAmount"]??0))
//       let MSguranteeAcceptancetTAX = adjustWith2DecimalsForUI(memberObj["guaranteedAcceptanceTax"]+(spouseObj["guaranteedAcceptanceTax"]??0))
//       let MSguranteeAcceptancetTotal = adjustWith2DecimalsForUI(memberObj["guaranteedAcceptanceTotal"]+(spouseObj["guaranteedAcceptanceTotal"]??0))

//       let MSpendingApprovalAmount = adjustWith2DecimalsForUI(memberObj["pendingApprovalAmount"]+(spouseObj["pendingApprovalAmount"]??0))
//       let MSpendingApprovalTAX = adjustWith2DecimalsForUI(memberObj["pendingApprovalTax"]+(spouseObj["pendingApprovalTax"]??0))
//       let MSpendingApprovalTotal = adjustWith2DecimalsForUI(memberObj["pendingApprovalTotal"]+(spouseObj["pendingApprovalTotal"]??0))

//       let obj1: any = {
//         packageId: plan.packageId,
//         packageName: pckage.name,

//         planproductname: this.productobj.name,

//         groupid: plan.planLevel,
//         groupName: group.name,
//         id: plan.id,
//         name: plan.name,
//         planLevel: plan.planLevel ? plan.planLevel : group.id,
//         planLevelParent: group.parentId,
//         fusebillPlanID: plan.fusebillId,
//         planFrequencyID: plan.frqMonthly,
//         isBundle: plan.isBundle,
//         coverage: lifeCoverage,
//         planCoverage: product.planCoverage,
//         bundledProducts: [],
//         products: [],
//         version:plan.version,
//         guaranteedAcceptanceDetails:{
//           "amount":MSguranteeAcceptanceAmount,
//           "tax":MSguranteeAcceptancetTAX,
//           "total":MSguranteeAcceptancetTotal,
//           "products": [{
//             id: product.id,
//             productId: product.productId,
//             name: product.name,
//             planProductId: product.planProductId,
//             price: adjustWith2DecimalsForUI(MSguranteeAcceptanceAmount),
//             tax_details:
//               product.bundledTaxes && product.bundledTaxes.length > 0
//                 ? product.bundledTaxes
//                 : product.taxesDataJSON,
//             calculatedTax: this.calculateTaxForProductTaxesData(MSguranteeAcceptanceAmount,product.taxesDataJSON??[]),
//             bundledProducts: product.bundledProducts,
//             planCoverage: lifeCoverage,
//             planLevel: product.planlevel ? product.planlevel.id : '',
//             planLevelParent: product.planlevel ? product.planlevel.parentId : '',
//             description: product.description,
//             coverage: lifeCoverage,
//             version:product.version,
//             total :this._decimalPipe
//             .transform(
//               this.calculateTaxForProductTaxesData(MSguranteeAcceptanceAmount,product.taxesDataJSON??[]) ? this.calculateTaxForProductTaxesData(MSguranteeAcceptanceAmount,product.taxesDataJSON??[]).total : 0,
//               '1.2-2'
//             )
//             ?.replace(/,/g, ''),
//             tax : this._decimalPipe
//             .transform(
//               this.calculateTaxForProductTaxesData(MSguranteeAcceptanceAmount,product.taxesDataJSON??[]) ? this.calculateTaxForProductTaxesData(MSguranteeAcceptanceAmount,product.taxesDataJSON??[]).tax : 0,
//               '1.2-2'
//             )
//             ?.replace(/,/g, '')
//           }]


//         },
//         pendingApprovalDetails:{
//           "amount":MSpendingApprovalAmount,
//           "tax":this._decimalPipe
//           .transform(
//             this.calculateTaxForProductTaxesData(MSpendingApprovalAmount,product.taxesDataJSON??[]) ? this.calculateTaxForProductTaxesData(MSpendingApprovalAmount,product.taxesDataJSON??[]).tax : 0,
//             '1.2-2'
//           )
//           ?.replace(/,/g, ''),
//           "total":this._decimalPipe
//           .transform(
//             this.calculateTaxForProductTaxesData(MSpendingApprovalAmount,product.taxesDataJSON??[]) ? this.calculateTaxForProductTaxesData(MSpendingApprovalAmount,product.taxesDataJSON??[]).total : 0,
//             '1.2-2'
//           )
//           ?.replace(/,/g, ''),
//           "products": [{
//             id: product.id,
//             productId: product.productId,
//             name: product.name,
//             planProductId: product.planProductId,
//             price: adjustWith2DecimalsForUI(MSpendingApprovalAmount),
//             tax_details:
//               product.bundledTaxes && product.bundledTaxes.length > 0
//                 ? product.bundledTaxes
//                 : product.taxesDataJSON,
//             calculatedTax: this.calculateTaxForProductTaxesData(MSpendingApprovalAmount,product.taxesDataJSON??[]),
//             bundledProducts: product.bundledProducts,
//             planCoverage: lifeCoverage,
//             planLevel: product.planlevel ? product.planlevel.id : '',
//             planLevelParent: product.planlevel ? product.planlevel.parentId : '',
//             description: product.description,
//             coverage: lifeCoverage,
//             version:product.version,
//             total :this._decimalPipe
//             .transform(
//               this.calculateTaxForProductTaxesData(MSpendingApprovalAmount,product.taxesDataJSON??[]) ? this.calculateTaxForProductTaxesData(MSpendingApprovalAmount,product.taxesDataJSON??[]).total : 0,
//               '1.2-2'
//             )
//             ?.replace(/,/g, ''),
//             tax : this._decimalPipe
//             .transform(
//               this.calculateTaxForProductTaxesData(MSpendingApprovalAmount,product.taxesDataJSON??[]) ? this.calculateTaxForProductTaxesData(MSpendingApprovalAmount,product.taxesDataJSON??[]).tax : 0,
//               '1.2-2'
//             )
//             ?.replace(/,/g, '')
//           }]
//         },
//         selectedDetails:{
//           "amount": adjustWith2DecimalsForUI(this.allFormData[type].total),
//           "total":adjustWith2DecimalsForUI(this.calculateTaxForProductTaxesData(this.allFormData[type].total,product.taxesDataJSON??[]) ? this.calculateTaxForProductTaxesData(this.allFormData[type].total,product.taxesDataJSON??[]).total : 0),

//           "tax" :adjustWith2DecimalsForUI(this.calculateTaxForProductTaxesData(this.allFormData[type].total,product.taxesDataJSON??[]) ? this.calculateTaxForProductTaxesData(this.allFormData[type].total,product.taxesDataJSON??[]).tax : 0),
//           "products":[this.productobj]
//         }


//       };

//       obj["guaranteedAcceptanceDetails"]=obj1.guaranteedAcceptanceDetails
//       obj["pendingApprovalDetails"]=obj1.pendingApprovalDetails
//       obj["selectedDetails"]=obj1.selectedDetails


//       obj1.products.push(this.productobj);
//       this.addtoplansummaryLife(obj, obj1);
//     }, 100);
//     for (let i = this.minValue; i <= this.allFormData[type][category]?.coverage?.maximumCoverageWithEvidence; i += 100000) {
//       this.rangeValues.push(i);
//     }
//   }
  public calculateTaxForProductTaxesData(price: number, taxesData: any) {
    // this.console.log(taxesData)
    // [{"tax_name":"PST","tax_code":"0002","plan_product_id":177927,"tax_description":"PST on Health Insurance","business_tax_number":"709505879TR0001","tax_rate":0.09,"tax_sku":"pstonhealthinsurance"}]
    let calculatedTax: any = {}
    let tax: any = 0;
    let planProductIds: any = []
    let planProductInfos: any = []
    if (taxesData && taxesData != undefined && taxesData != null && taxesData != "null") {
      for (const taxData of taxesData) {
        tax += adjustWith2DecimalsForUI(price * taxData.tax_rate);
        planProductIds.push(taxData.plan_product_id);
        let planProductInfo: any = {};
        // planProductInfo.name = taxData.tax_description + " " + (taxData.business_tax_number ?? "");
        planProductInfo.name = taxData.tax_name;
        planProductInfo.description = taxData.tax_description;
        planProductInfo.price = adjustWith2DecimalsForUI(price * taxData.tax_rate);
        planProductInfo.planProductUniqueId = taxData.plan_product_id;
        planProductInfo.isIncluded = true;
        planProductInfo.taxRate = taxData.tax_rate;
        planProductInfo.taxRatePercentage = adjustWith2DecimalsForUI((taxData.tax_rate) * 100) + "%";
        planProductInfos.push(planProductInfo);
      }
    }

    let total = adjustWith2DecimalsForUI(price + tax)
    calculatedTax.price = price;
    calculatedTax.tax = tax;
    calculatedTax.total = total;

    calculatedTax.planProductIds = planProductIds;
    calculatedTax.planProductInfos = planProductInfos;

    return calculatedTax;

  }

  public transformData() {
    let transformedData: any = [];
    let id = 12;

    Object.keys(this.allFormData).forEach(key => {
      const plan = this.allFormData[key];
      if (plan.total > 0) {
        const hasMember: boolean = plan.hasOwnProperty('member');

        const hasSpouse = plan.hasOwnProperty('spouse');
        const hasChildren = plan.hasOwnProperty('dependent');

        const transformedObject: any = {
          name: key.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '),
          id: id++,
          havingSpouse: hasSpouse,
          havingChildren: hasChildren,
          total: plan.total,
        }

        transformedObject.member = {}

        if (hasMember && plan.member.premium > 0) {
          transformedObject.member = {
            isSmoker: plan.member.isSmoker,
            total: plan.member.premium,
            units: plan.member.coverage,
            // placeOfBirth: plan.member.PlaceOfBirth,
            premium: plan.member.constant
          }
        }

        transformedObject.spouse = {}

        if (hasSpouse && plan.spouse.premium > 0) {
          transformedObject.spouse = {
            isSmoker: plan.spouse.isSmoker,
            total: plan.spouse.premium,
            units: plan.spouse.coverage,
            // placeOfBirth: plan.spouse.PlaceOfBirth,
            premium: plan.spouse.constant

          };
        }

        transformedObject.dependent = []

        if (hasChildren && plan.dependent.premium > 0) {
          const dep = this.signUpData.childrenDetails.map((d: any) => {
            return {
              childFirstName: d.childFirstName,
              childLastName: d.childLastName,
              //  total: plan.dependent.premium/this.signUpData.childrenDetails.length,
              total: plan.dependent.premium,
              units: plan.dependent.coverage,
              premium: plan.dependent.constant,
              // placeOfBirth:
              //   plan.dependent[`${d.childFirstName}-${d.childLastName}`],
            };
          });

          transformedObject.dependent = dep;
          transformedObject.dependent.allDependentPremium = {
            total: plan.dependent.premium,
            units: plan.dependent.coverage,
            premium: plan.dependent.constant
          }

        }

        transformedData.push(transformedObject);
      }
    });

    return transformedData;
  }

  // public onCheckboxChange(
  //   e:any,
  //   allow_multiple:any,
  //   disallowedPlanLevels:any,
  //   options:any,
  //   pname:any,
  //   groupname:any,
  //   plancoverage:any,
  //   planprice:any,
  //   gst:any,
  //   hst:any,
  //   pst:any,
  //   event:any,
  //   event1:any,
  //   packageindex:any,
  //   groupindex:any,
  //   planindex:any,
  //   plan:any,
  //   product:any,
  //   pckage:any,
  //   group:any,
  // ) {
  //   // let multiple = allow_multiple ? Boolean(allow_multiple.data[0]) : false;

  //   let multiple = allow_multiple==true?true: false;


  //   if (disallowedPlanLevels != null) {
  //     let disallowed_plans = disallowedPlanLevels.split(",");
  //     const dom1: HTMLElement = this.elementRef.nativeElement;
  //     const disallowed_elements = dom1.querySelectorAll(
  //       ".plansscreen input[type='checkbox']"
  //     );

  //     // disallowed_elements.forEach((elem: any) => {

  //     for (let i = 0; i < disallowed_elements.length; i++) {
  //       let elem: any = disallowed_elements[i];
  //       // elementcv.checked=false

  //       let plandetails = elem.attributes.data.value;

  //       let plandetailsobj = plandetails.split("##");

  //       //this.consoleMessage("main", plandetailsobj);

  //       if (e.target.checked) {
  //         if (!elem.disabled) {
  //           if (plandetailsobj[12] == null || plandetailsobj[12] == "") {
  //             if (disallowed_plans.indexOf(plandetailsobj[13]) >= 0) {
  //               //  elem.checked=false
  //               elem.disabled = true;

  //               this.disabledelement =
  //                 "Already included in GroupBenefitz All-In";
  //             }
  //           } else {
  //             if (disallowed_plans.indexOf(plandetailsobj[20]) >= 0) {
  //               //  elem.checked=false
  //               elem.disabled = true;
  //               this.disabledelement =
  //                 "Already included in GroupBenefitz All-In";
  //             }
  //           }
  //         }
  //         if (elem.checked) {


  //           if (plandetailsobj[12] == null || plandetailsobj[12] == "") {
  //             if (disallowed_plans.indexOf(plandetailsobj[13]) >= 0) {
  //               elem.checked = false;
  //               this.unselectplan(elem, options, plan, product, pckage, group,planindex);
  //             }
  //           } else {

  //             if (disallowed_plans.indexOf(plandetailsobj[12]) >= 0) {
  //               elem.checked = false;
  //               this.unselectplan(elem, options, plan, product, pckage, group,planindex);
  //               // elem.disabled =true
  //             }
  //           }
  //         }
  //       } else {
  //         if (elem.disabled) {
  //           if (plandetailsobj[12] == null || plandetailsobj[12] == "") {
  //             if (disallowed_plans.indexOf(plandetailsobj[13]) >= 0) {
  //               //  elem.checked=false
  //               elem.disabled = false;
  //             }
  //           } else {
  //             if (disallowed_plans.indexOf(plandetailsobj[12]) >= 0) {
  //               //  elem.checked=false
  //               elem.disabled = false;
  //             }
  //           }
  //         }
  //         if (elem.checked) {
  //           if (plandetailsobj[12] == null || plandetailsobj[12] == "") {
  //             if (disallowed_plans.indexOf(plandetailsobj[13]) >= 0) {
  //               elem.checked = false;
  //               // elem.disabled =true
  //             }
  //           } else {
  //             if (disallowed_plans.indexOf(plandetailsobj[12]) >= 0) {
  //               elem.checked = false;
  //               // elem.disabled =true
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }

  //   // //////this.consoleMessage(e)
  //   let classname = e.target.className;
  //   classname = classname.split(" ").join(".");

  //   // //////this.consoleMessage(classname)
  //   const dom: HTMLElement = this.elementRef.nativeElement;
  //   const elements = dom.querySelectorAll("." + classname);

  //   // //////this.consoleMessage(elements);

  //   let element: any;
  //   let elementcv: any;

  //   if (!multiple) {
  //     if (e.target.checked) {
  //       for (let i = 0; i < elements.length; i++) {
  //         let elem = elements[i];
  //         element = elem;

  //         if (element.checked) {
  //           this.unselectplan(elem, options, plan, product, pckage, group,planindex);
  //           element.checked = false;
  //         }
  //       }

  //       e.checked = true;
  //       e.target.checked = true;
  //     } else {

  //     }
  //   } else {
  //     //no checking for other checkboxes you can selet multiple
  //   }
  //   setTimeout(() => {
  //     if (e.target.checked) {
  //       this.selectplan(e.target, options, plan, product, pckage, group,planindex);
  //     } else {
  //       this.unselectplan(e.target, options, plan, product, pckage, group,planindex);
  //     }
  //   }, 10);
  // }
  public onCheckboxChange(
    e: any,
    allow_multiple: any,
    disallowedPlanLevels: any,
    options: any,
    pname: any,
    groupname: any,
    plancoverage: any,
    planprice: any,
    gst: any,
    hst: any,
    pst: any,
    event: any,
    event1: any,
    packageindex: any,
    groupindex: any,
    planindex: any,
    plan: any,
    product: any,
    pckage: any,
    group: any
  ) {
    // let multiple = allow_multiple ? Boolean(allow_multiple.data[0]) : false;
    let multiple = allow_multiple == true ? true : false;


    if (e.target.checked) {
      //unselect all checkboxes with same id(fix for single.couple and family multiple seelction)



      const dom: HTMLElement = this.elementRef.nativeElement;

      const samePlanIdElements = dom.querySelectorAll('#' + e.target.id);
      console.log(samePlanIdElements)

      for (let i = 0; i < samePlanIdElements.length; i++) {
        let elem = samePlanIdElements[i];
        let element: any = elem;
        console.log(elem)


        if (element.checked) {
          element.checked = false;
          this.unselectplan(elem, options, plan, product, pckage, group, planindex);

        }
      }
      e.target.checked = true

    } else {

    }

    /* if (disallowedPlanLevels != null) {
      let disallowed_plans = disallowedPlanLevels.split(",");
      const dom1: HTMLElement = this.elementRef.nativeElement;
      const disallowed_elements = dom1.querySelectorAll(
        ".plansscreen input[type='checkbox']"
      );

      // disallowed_elements.forEach((elem: any) => {

      for (let i = 0; i < disallowed_elements.length; i++) {
        let elem: any = disallowed_elements[i];
        // elementcv.checked=false

        let plandetails = elem.attributes.data.value;

        let plandetailsobj = plandetails.split("##");

        //this.consoleMessage("main", plandetailsobj);

        console.log(plandetailsobj[21])
        let isBundle = plandetailsobj[21] == "true" ? true : false
        if (e.target.checked) {
          if (!elem.disabled) {

            if (plandetailsobj[12] == null || plandetailsobj[12] == '') {
              if (isBundle) {
                console.log("111")
                if (disallowed_plans.indexOf(plandetailsobj[13]) >= 0) {
                  console.log("112")
                  //  elem.checked=false
                  elem.disabled = true;
                  this.disabledelement =
                    'Already included in GroupBenefitz All-In';
                }
              } else {
                if (disallowed_plans.indexOf(plandetailsobj[12]) >= 0) {
                  console.log("113")
                  //  elem.checked=false
                  elem.disabled = true;
                  this.disabledelement =
                    'Already included in GroupBenefitz All-In';
                }
              }

            } else {

              if (isBundle) {
                if (disallowed_plans.indexOf(plandetailsobj[12]) >= 0) {

                  console.log("1")

                  elem.disabled = true;

                  this.disabledelement =
                    'Already included in GroupBenefitz All-In';
                }
              } else {
                if (disallowed_plans.indexOf(plandetailsobj[13]) >= 0) {
                  console.log("2")
                  elem.disabled = true;

                  this.disabledelement =
                    'Already included in GroupBenefitz All-In';
                }
              }

            }
          }
          if (elem.checked) {

            if (plandetailsobj[12] == null || plandetailsobj[12] == '') {
              if (isBundle) {
                console.log("3")
                if (disallowed_plans.indexOf(plandetailsobj[13]) >= 0) {
                  elem.checked = false;
                  this.unselectplan(elem, options, plan, product, pckage, group, planindex);
                }

              }
              else {
                if (disallowed_plans.indexOf(plandetailsobj[12]) >= 0) {
                  elem.checked = false;
                  console.log("4")
                  this.unselectplan(elem, options, plan, product, pckage, group, planindex);
                }
              }
            } else {
              if (isBundle) {
                if (disallowed_plans.indexOf(plandetailsobj[12]) >= 0) {
                  elem.checked = false;
                  console.log("5")
                  this.unselectplan(elem, options, plan, product, pckage, group, planindex);
                  // elem.disabled =true
                }
              }
              else {
                if (disallowed_plans.indexOf(plandetailsobj[13]) >= 0) {
                  elem.checked = false;
                  console.log("6")
                  this.unselectplan(elem, options, plan, product, pckage, group, planindex);
                  // elem.disabled =true
                }
              }

            }
          }
        }
        else {
          if (elem.disabled) {
            if (plandetailsobj[12] == null || plandetailsobj[12] == '') {
              if (isBundle) {
                console.log("7")
                if (disallowed_plans.indexOf(plandetailsobj[13]) >= 0) {
                  //  elem.checked=false
                  elem.disabled = false;
                }
              } else {
                console.log("8")
                if (disallowed_plans.indexOf(plandetailsobj[12]) >= 0) {
                  //  elem.checked=false
                  elem.disabled = false;
                }
              }
            }
            if (isBundle) {
              if (disallowed_plans.indexOf(plandetailsobj[12]) >= 0) {

                elem.disabled = false;
                console.log("9")
                this.disabledelement =
                  'Already included in GroupBenefitz All-In';
              }
            } else {
              if (disallowed_plans.indexOf(plandetailsobj[13]) >= 0) {
                console.log("10")
                elem.disabled = false;

                this.disabledelement =
                  'Already included in GroupBenefitz All-In';
              }
            }

            // else {
            //   if (disallowed_plans.indexOf(plandetailsobj[12]) >= 0) {
            //     //  elem.checked=false
            //     elem.disabled = false;
            //   }
            // }
          }
          // if (elem.checked) {
          //   if (plandetailsobj[12] == null || plandetailsobj[12] == '') {
          //     if (disallowed_plans.indexOf(plandetailsobj[13]) >= 0) {
          //       elem.checked = false;
          //       // elem.disabled =true
          //     }
          //   } else {
          //     if (disallowed_plans.indexOf(plandetailsobj[12]) >= 0) {
          //       elem.checked = false;
          //       // elem.disabled =true
          //     }
          //   }
          // }
          if (elem.checked) {

            if (plandetailsobj[12] == null || plandetailsobj[12] == '') {
              if (isBundle) {
                console.log("11")
                if (disallowed_plans.indexOf(plandetailsobj[13]) >= 0) {
                  elem.checked = false;
                  this.unselectplan(elem, options, plan, product, pckage, group, planindex);
                }

              }
              else {
                if (disallowed_plans.indexOf(plandetailsobj[12]) >= 0) {
                  elem.checked = false;
                  console.log("12")
                  this.unselectplan(elem, options, plan, product, pckage, group, planindex);
                }
              }
            } else {
              if (isBundle) {
                if (disallowed_plans.indexOf(plandetailsobj[12]) >= 0) {
                  elem.checked = false;
                  console.log("13")
                  this.unselectplan(elem, options, plan, product, pckage, group, planindex);
                  // elem.disabled =true
                }
              }
              else {
                if (disallowed_plans.indexOf(plandetailsobj[13]) >= 0) {
                  elem.checked = false;
                  console.log("14")
                  this.unselectplan(elem, options, plan, product, pckage, group, planindex);
                  // elem.disabled =true
                }
              }

            }
          }
        }
        // if (e.target.checked) {
        //   if (!elem.disabled) {
        //     if (plandetailsobj[12] == null || plandetailsobj[12] == "") {
        //       if(isBundle){
        //       if (disallowed_plans.indexOf(plandetailsobj[13]) >= 0) {
        //         //  elem.checked=false
        //         elem.disabled = true;

        //         this.disabledelement =
        //           "Already included in GroupBenefitz All-In";
        //       }
        //     }
        //     } else {
        //       if (disallowed_plans.indexOf(plandetailsobj[20]) >= 0) {
        //         //  elem.checked=false
        //         elem.disabled = true;
        //         this.disabledelement =
        //           "Already included in GroupBenefitz All-In";
        //       }
        //     }
        //   }
        //   if (elem.checked) {
        //     ////this.consoleMessage(disallowed_plans)
        //     ////this.consoleMessage(plandetailsobj)

        //     if (plandetailsobj[12] == null || plandetailsobj[12] == "") {
        //       if(isBundle){
        //       if (disallowed_plans.indexOf(plandetailsobj[13]) >= 0) {
        //         elem.checked = false;
        //         this.unselectplan(elem, options, plan, product, pckage, group,planindex);
        //       }
        //     }
        //     } else {
        //       ////this.consoleMessage(disallowed_plans)
        //       ////this.consoleMessage(plandetailsobj)
        //       if (disallowed_plans.indexOf(plandetailsobj[20]) >= 0) {
        //         elem.checked = false;
        //         this.unselectplan(elem, options, plan, product, pckage, group,planindex);
        //         // elem.disabled =true
        //       }
        //     }
        //   }
        // } else {
        //   if (elem.disabled) {
        //     if (plandetailsobj[12] == null || plandetailsobj[12] == "") {
        //       if (disallowed_plans.indexOf(plandetailsobj[13]) >= 0) {
        //         //  elem.checked=false
        //         elem.disabled = false;
        //       }
        //     } else {
        //       if (disallowed_plans.indexOf(plandetailsobj[12]) >= 0) {
        //         //  elem.checked=false
        //         elem.disabled = false;
        //       }
        //     }
        //   }
        //   if (elem.checked) {
        //     if (plandetailsobj[12] == null || plandetailsobj[12] == "") {
        //       if (disallowed_plans.indexOf(plandetailsobj[13]) >= 0) {
        //         elem.checked = false;
        //         // elem.disabled =true
        //       }
        //     } else {
        //       if (disallowed_plans.indexOf(plandetailsobj[12]) >= 0) {
        //         elem.checked = false;
        //         // elem.disabled =true
        //       }
        //     }
        //   }
        // }
      }
    } */

      console.log(disallowedPlanLevels)
    if (disallowedPlanLevels != null) {
      let disallowed_plans = disallowedPlanLevels.split(",");
      const dom1: HTMLElement = this.elementRef.nativeElement;
      const disallowed_elements = dom1.querySelectorAll(
        ".plansscreen input[type='checkbox']"
      );

      // disallowed_elements.forEach((elem: any) => {

      for (let i = 0; i < disallowed_elements.length; i++) {
        let elem: any = disallowed_elements[i];
        // elementcv.checked=false

        let plandetails = elem.attributes.data.value;

        let plandetailsobj = plandetails.split("##");

        //this.consoleMessage("main", plandetailsobj);
        let isBundle = plandetailsobj[21] == "true" ? true : false
        if (e.target.checked) {
          if (!elem.disabled) {

            if (plandetailsobj[12] == null || plandetailsobj[12] == '') {
              if (isBundle) {
                if (disallowed_plans.indexOf(plandetailsobj[13]) >= 0) {
                  //  elem.checked=false
                  elem.disabled = true;
                  this.disabledelement =
                    'Already included in GroupBenefitz All-In';
                }
              } else {
                if (disallowed_plans.indexOf(plandetailsobj[20]) >= 0) {
                  //  elem.checked=false
                  elem.disabled = true;
                  this.disabledelement =
                    'Already included in GroupBenefitz All-In';
                }
              }

            } else {

              if (isBundle) {
                if (disallowed_plans.indexOf(plandetailsobj[12]) >= 0) {

                  elem.disabled = true;

                  this.disabledelement =
                    'Already included in GroupBenefitz All-In';
                }
              } else {
                if (disallowed_plans.indexOf(plandetailsobj[20]) >= 0) {

                  elem.disabled = true;

                  this.disabledelement =
                    'Already included in GroupBenefitz All-In';
                }
              }

            }
          }
          if (elem.checked) {

            if (plandetailsobj[12] == null || plandetailsobj[12] == '') {
              if (isBundle) {
                if (disallowed_plans.indexOf(plandetailsobj[13]) >= 0) {
                  elem.checked = false;
                  this.unselectplan(elem, options, plan, product, pckage, group, planindex);
                }

              }
              else {
                if (disallowed_plans.indexOf(plandetailsobj[20]) >= 0) {
                  elem.checked = false;
                  this.unselectplan(elem, options, plan, product, pckage, group, planindex);
                }
              }
            } else {
              if (isBundle) {
                if (disallowed_plans.indexOf(plandetailsobj[12]) >= 0) {
                  elem.checked = false;
                  this.unselectplan(elem, options, plan, product, pckage, group, planindex);
                  // elem.disabled =true
                }
              }
              else {
                if (disallowed_plans.indexOf(plandetailsobj[20]) >= 0) {
                  elem.checked = false;
                  this.unselectplan(elem, options, plan, product, pckage, group, planindex);
                  // elem.disabled =true
                }
              }

            }
          }
        }
        else {
          if (elem.disabled) {
            if (plandetailsobj[12] == null || plandetailsobj[12] == '') {
              if (isBundle) {
                if (disallowed_plans.indexOf(plandetailsobj[13]) >= 0) {
                  //  elem.checked=false
                  elem.disabled = false;
                }
              } else {
                if (disallowed_plans.indexOf(plandetailsobj[20]) >= 0) {
                  //  elem.checked=false
                  elem.disabled = false;
                }
              }
            }
            if (isBundle) {
              if (disallowed_plans.indexOf(plandetailsobj[12]) >= 0) {

                elem.disabled = false;

                this.disabledelement =
                  'Already included in GroupBenefitz All-In';
              }
            } else {
              if (disallowed_plans.indexOf(plandetailsobj[20]) >= 0) {

                elem.disabled = false;

                this.disabledelement =
                  'Already included in GroupBenefitz All-In';
              }
            }

            // else {
            //   if (disallowed_plans.indexOf(plandetailsobj[12]) >= 0) {
            //     //  elem.checked=false
            //     elem.disabled = false;
            //   }
            // }
          }


          if (elem.checked) {

            if (plandetailsobj[12] == null || plandetailsobj[12] == '') {
              if (isBundle) {
                if (disallowed_plans.indexOf(plandetailsobj[13]) >= 0) {
                  elem.checked = false;
                  this.unselectplan(elem, options, plan, product, pckage, group, planindex);
                }

              }
              else {
                if (disallowed_plans.indexOf(plandetailsobj[20]) >= 0) {
                  elem.checked = false;
                  this.unselectplan(elem, options, plan, product, pckage, group, planindex);
                }
              }
            } else {
              if (isBundle) {
                if (disallowed_plans.indexOf(plandetailsobj[12]) >= 0) {
                  elem.checked = false;
                  this.unselectplan(elem, options, plan, product, pckage, group, planindex);
                  // elem.disabled =true
                }
              }
              else {
                if (disallowed_plans.indexOf(plandetailsobj[20]) >= 0) {
                  elem.checked = false;
                  this.unselectplan(elem, options, plan, product, pckage, group, planindex);
                  // elem.disabled =true
                }
              }

            }
          }
        }


      }
    }





    //////this.consoleMessage(e)
    // console.log(`newPlan${plan.id}`)
    let sameplanclassname = `newPlan${plan.id}`
    // console.log(e.target.className)/////this.consoleMessage(e)
    let classname = e.target.className.replace(sameplanclassname, '');
    // console.log(classname)
    classname = e.target.className.split(' ').join('.');
    // classname = classname.split(' ').join('.');

    // if (classname[0] != '.') {
    //   classname = `.${classname}`

    // }

    // if (classname[classname.length - 1] == '.') {
    //   classname = classname.substring(0, classname.length - 1);
    // }

    console.log(classname)
    console.log(sameplanclassname)
    // //////this.consoleMessage(classname)
    const dom: HTMLElement = this.elementRef.nativeElement;
    // const elements = dom.querySelectorAll('.' + classname);
    const elements = dom.querySelectorAll(classname);

    const samePlanElements = dom.querySelectorAll('.' + sameplanclassname);
    let element: any;
    let elementcv: any;

    if (!multiple) {
      if (e.target.checked) {
        for (let i = 0; i < elements.length; i++) {
          let elem = elements[i];
          element = elem;

          if (element.checked) {
            this.unselectplan(elem, options, plan, product, pckage, group, planindex);
            element.checked = false;
          }
        }

        e.checked = true;
        e.target.checked = true;
      } else {

      }
    } else {

      console.log("elesehere")
      if (e.target.checked) {


        // for (let i = 0; i < samePlanElements.length; i++) {
        //   let elem = samePlanElements[i];
        //   element = elem;

        //   if (element.checked) {

        //     this.unselectplan(elem, options, plan, product, pckage, group,planindex);
        //     element.checked = false;
        //   }
        // }

        // e.checked = true;
        // e.target.checked = true;
      } else {
      }
      // alert("!")
      //no checking for other checkboxes you can selet multiple
    }
    setTimeout(() => {
      if (e.target.checked) {

        let removedisabledcompanypaidproductid: any = []
        let removedisabledcoveredpaidproductid: any = []
        let removedisabledemployedpaidproductid: any = []
        if (
          group.categorization.coveredByCompany == 1
          // ||
          // group.categorization.paidByEmployee == 1
        ) {

          pckage.groups.forEach((element: any) => {
            if (element.categorization.paidByCompany == 1) {
              element.plans.forEach((plan: any) => {
                let pl = plan
                Object.keys(plan.productAddonss).forEach((key) => {
                  plan.productAddonss[key].forEach((product: any) => {
                    if (pl.isBundle) {
                      removedisabledcompanypaidproductid.push({
                        id: pl.id,
                      });
                    }
                    else {
                      removedisabledcompanypaidproductid.push({
                        // id: product.id,

                        id: product.planLevel,

                      });
                    }

                  });
                });
              });
            }
          });
        } else {
        }

        if (
          group.categorization.paidByCompany == 1
          // ||
          // group.categorization.paidByEmployee == 1
        ) {

          pckage.groups.forEach((element: any) => {
            if (element.categorization.coveredByCompany == 1) {
              element.plans.forEach((plan: any) => {
                let pl = plan
                Object.keys(plan.productAddonss).forEach((key) => {
                  plan.productAddonss[key].forEach((product: any) => {
                    if (pl.isBundle) {
                      removedisabledcoveredpaidproductid.push({
                        id: pl.id,
                      });
                    }
                    else {
                      removedisabledcoveredpaidproductid.push({
                        // id: product.id,
                        id: product.planLevel,
                      });
                    }

                  });
                });
              });
            }
          });
        } else {
        }

        if (
          group.categorization.paidByEmployee == 1
          // ||
          // group.categorization.paidByEmployee == 1
        ) {

          pckage.groups.forEach((element: any) => {
            if (element.categorization.paidByCompany == 1) {
              element.plans.forEach((plan: any) => {
                let pl = plan
                Object.keys(plan.productAddonss).forEach((key) => {
                  plan.productAddonss[key].forEach((product: any) => {
                    if (pl.isBundle) {
                      removedisabledemployedpaidproductid.push({
                        id: pl.id,
                      });
                    }
                    else {
                      removedisabledemployedpaidproductid.push({
                        // id: product.id,
                        id: product.planLevel,
                      });
                    }

                  });
                });
              });
            }
          });
        } else {
        }

        console.log(removedisabledcompanypaidproductid)
        console.log(removedisabledcoveredpaidproductid)
        console.log(removedisabledemployedpaidproductid)
        removedisabledcompanypaidproductid.forEach((element: any, index: any) => {
          setTimeout(() => {
            const dom: HTMLElement = this.elementRef.nativeElement;
            const plan: any = dom.querySelectorAll("#plancheck" + element.id);
            console.log(plan)
            //Rakesh changes here company to upgrade

            plan.forEach((element: any) => {
              element.disabled = false;
              element.checked = false
            });  //remove this plan from sum
            // plan.click()

            let selectplans =
              JSON.parse(sessionStorage.getItem("plansummarymain") || '[]') || "";

            selectplans.forEach((elem: any, index: any) => {
              if (elem.isBundle) {
                if (elem.id == element.id) {
                  selectplans.splice(index, 1)
                }
              } else {


                if (elem.id == elem.products[0].planLevel) {
                  selectplans.splice(index, 1)
                }
                // if(elem.id==elem.products[0].id){
                //   selectplans.splice(index,1)
                // }
              }


            })

            sessionStorage.setItem(
              "plansummarymain",
              JSON.stringify(selectplans)
            );


            sessionStorage.setItem("plansummary", JSON.stringify(selectplans));

            this.planCoverafeFunction();

          }, 100);
        });
        removedisabledcoveredpaidproductid.forEach((element: any, index: any) => {
          setTimeout(() => {
            const dom: HTMLElement = this.elementRef.nativeElement;
            const plan: any = dom.querySelectorAll("#plancheck" + element.id);
            console.log(plan)
            //Rakesh changes here company to upgrade
            plan.disabled = false;



            plan.forEach((element: any) => {

              console.log(element)
              element.checked = false
            });


            //remove this plan from sum
            // plan.click()

            let selectplans =
              JSON.parse(sessionStorage.getItem("plansummarymain") || '[]') || "";

            selectplans.forEach((elem: any, index: any) => {
              if (elem.isBundle) {
                if (elem.id == element.id) {
                  selectplans.splice(index, 1)
                }
              } else {
                if (elem.id == elem.products[0].planLevel) {
                  selectplans.splice(index, 1)
                }
              }


            })

            sessionStorage.setItem(
              "plansummarymain",
              JSON.stringify(selectplans)
            );


            sessionStorage.setItem("plansummary", JSON.stringify(selectplans));

            this.planCoverafeFunction();
          }, 100);
        });


        this.selectplan(e.target, options, plan, product, pckage, group, planindex);
      } else {


        this.unselectplan(e.target, options, plan, product, pckage, group, planindex);
      }
    }, 10);
  }
  public onCheckboxChangemodified(
    e: any,
    allow_multiple: any,
    disallowedPlanLevels: any,
    options: any,
    pname: any,
    groupname: any,
    plancoverage: any,
    planprice: any,
    gst: any,
    hst: any,
    pst: any,
    event: any,
    event1: any,
    packageindex: any,
    groupindex: any,
    planindex: any,
    plan: any,
    product: any,
    pckage: any,
    group: any,
  ) {
    let multiple = allow_multiple ? Boolean(allow_multiple.data[0]) : false;

    if (disallowedPlanLevels != null) {
      let disallowed_plans = disallowedPlanLevels.split(",");
      const dom1: HTMLElement = this.elementRef.nativeElement;
      const disallowed_elements = dom1.querySelectorAll(
        ".plansscreen input[type='checkbox']"
      );

      // disallowed_elements.forEach((elem: any) => {

      for (let i = 0; i < disallowed_elements.length; i++) {
        let elem: any = disallowed_elements[i];
        // elementcv.checked=false

        let plandetails = elem.attributes.data.value;

        let plandetailsobj = plandetails.split("##");

        //this.consoleMessage("main", plandetailsobj);

        if (e.target.checked) {
          if (!elem.disabled) {
            if (plandetailsobj[12] == null || plandetailsobj[12] == "") {
              if (disallowed_plans.indexOf(plandetailsobj[13]) >= 0) {
                //  elem.checked=false
                elem.disabled = true;

                this.disabledelement =
                  "Already included in GroupBenefitz All-In";
              }
            } else {
              if (disallowed_plans.indexOf(plandetailsobj[12]) >= 0) {
                //  elem.checked=false
                elem.disabled = true;
                this.disabledelement =
                  "Already included in GroupBenefitz All-In";
              }
            }
          }
          if (elem.checked) {
            ////this.consoleMessage(disallowed_plans)
            ////this.consoleMessage(plandetailsobj)

            if (plandetailsobj[12] == null || plandetailsobj[12] == "") {
              if (disallowed_plans.indexOf(plandetailsobj[13]) >= 0) {
                elem.checked = false;
                this.unselectplan(elem, options, plan, product, pckage, group, planindex);
              }
            } else {
              ////this.consoleMessage(disallowed_plans)
              ////this.consoleMessage(plandetailsobj)
              if (disallowed_plans.indexOf(plandetailsobj[12]) >= 0) {
                elem.checked = false;
                this.unselectplan(elem, options, plan, product, pckage, group, planindex);
                // elem.disabled =true
              }
            }
          }
        } else {
          if (elem.disabled) {
            if (plandetailsobj[12] == null || plandetailsobj[12] == "") {
              if (disallowed_plans.indexOf(plandetailsobj[13]) >= 0) {
                //  elem.checked=false
                elem.disabled = false;
              }
            } else {
              if (disallowed_plans.indexOf(plandetailsobj[12]) >= 0) {
                //  elem.checked=false
                elem.disabled = false;
              }
            }
          }
          if (elem.checked) {
            if (plandetailsobj[12] == null || plandetailsobj[12] == "") {
              if (disallowed_plans.indexOf(plandetailsobj[13]) >= 0) {
                elem.checked = false;
                // elem.disabled =true
              }
            } else {
              if (disallowed_plans.indexOf(plandetailsobj[12]) >= 0) {
                elem.checked = false;
                // elem.disabled =true
              }
            }
          }
        }
      }
    }

    // //////this.consoleMessage(e)
    let classname = e.target.className;
    classname = classname.split(" ").join(".");

    // //////this.consoleMessage(classname)
    const dom: HTMLElement = this.elementRef.nativeElement;
    const elements = dom.querySelectorAll("." + classname);

    // //////this.consoleMessage(elements);

    let element: any;
    let elementcv: any;

    if (!multiple) {
      if (e.target.checked) {
        for (let i = 0; i < elements.length; i++) {
          let elem = elements[i];
          element = elem;

          if (element.checked) {
            this.unselectplan(elem, options, plan, product, pckage, group, planindex);
            element.checked = false;
          }
        }

        e.checked = true;
        e.target.checked = true;
      } else {
      }
    } else {
      //no checking for other checkboxes you can selet multiple
    }
    setTimeout(() => {
      if (e.target.checked) {
        this.selectplan(e.target, options, plan, product, pckage, group, planindex);
      } else {
        this.unselectplan(e.target, options, plan, product, pckage, group, planindex);
      }
    }, 10);
  }

  public allowmultipleplans() { }

  public disallowedPlans() { }
  public selectplan(
    elementcv: any,
    options: any,
    plan: any,
    product: any,
    pckage: any,
    group: any,
    planProductIndex: any
  ) {
    // ////this.consoleMessage(elementcv)
    //this.consoleMessage(plan);
    //this.consoleMessage(product)
    //this.consoleMessage(pckage)
    //this.consoleMessage(group)

    // alert("1")



    let plandetails = elementcv.attributes.data.value;

    let plandetailsobj = plandetails.split("##");
    if (options && options.length > 0) {
      let obj = {
        isBundle: plan.isBundle,
        enrollmentDate: this.userForm.value.planEnrollmentDate
          ? this._CustomDatePipe.transform(this.userForm.value.planEnrollmentDate)
          : undefined,
        // "name":plandetailsobj[1]+"-"+plandetailsobj[2],
        name: plandetailsobj[14],
        details: plandetailsobj[0],
        packagename: plandetailsobj[0],
        groupName: plandetailsobj[1],
        // amount: parseFloat(plandetailsobj[3]),
        amount: parseFloat(product.calculatedTax.price),
        planCoverage: plandetailsobj[2],
        // planPrice: parseFloat(plandetailsobj[3]),
        planPrice: parseFloat(product.calculatedTax.price),
        // amountUI: "$" + this._decimalPipe.transform(plandetailsobj[3], "1.2-2"),
        amountUI: "$" + parseFloat(product.calculatedTax.price),
        gst:
          plandetailsobj[4] == null ||
            plandetailsobj[4] == "" ||
            plandetailsobj[4] == undefined ||
            plandetailsobj[4] == "null"
            ? 0
            : parseFloat(plandetailsobj[4]),
        hst:
          plandetailsobj[5] == null ||
            plandetailsobj[5] == "" ||
            plandetailsobj[5] == undefined ||
            plandetailsobj[5] == "null"
            ? 0
            : parseFloat(plandetailsobj[5]),
        pst:
          plandetailsobj[6] == null ||
            plandetailsobj[6] == "" ||
            plandetailsobj[6] == undefined ||
            plandetailsobj[6] == "null"
            ? 0
            : parseFloat(plandetailsobj[6]),
        qst:
          plandetailsobj[17] == null ||
            plandetailsobj[17] == "" ||
            plandetailsobj[17] == undefined ||
            plandetailsobj[17] == "null"
            ? 0
            : parseFloat(plandetailsobj[17]),
        gstCheck:
          plandetailsobj[4] == null ||
            plandetailsobj[4] == "" ||
            plandetailsobj[4] == undefined ||
            plandetailsobj[4] == "null"
            ? false
            : true,
        hstCheck:
          plandetailsobj[5] == null ||
            plandetailsobj[5] == "" ||
            plandetailsobj[5] == undefined ||
            plandetailsobj[5] == "null"
            ? false
            : true,
        pstCheck:
          plandetailsobj[6] == null ||
            plandetailsobj[6] == "" ||
            plandetailsobj[6] == undefined ||
            plandetailsobj[6] == "null"
            ? false
            : true,
        qstCheck:
          plandetailsobj[17] == null ||
            plandetailsobj[17] == "" ||
            plandetailsobj[17] == undefined ||
            plandetailsobj[17] == "null"
            ? false
            : true,
        id: parseFloat(plandetailsobj[7]),
        fusebillPlanID:
          plandetailsobj[8] == null ||
            plandetailsobj[8] == "" ||
            plandetailsobj[8] == undefined ||
            plandetailsobj[8] == "null"
            ? 0
            : parseFloat(plandetailsobj[8]),
        planFrequencyID:
          plandetailsobj[9] == null ||
            plandetailsobj[9] == "" ||
            plandetailsobj[9] == undefined ||
            plandetailsobj[9] == "null"
            ? 0
            : parseFloat(plandetailsobj[9]),
        optIn: plandetailsobj[10] == "true" ? true : false,
        planname: plandetailsobj[14],
        planLevel: parseInt(plandetailsobj[15]),
        packageId: parseInt(plandetailsobj[16]),
        options: [],
        paidByCompany1: plandetailsobj[19],
        coveredByCompany1: plandetailsobj[20],
        paidByEmployee1: plandetailsobj[21],
        planLevel_parentId: plandetailsobj[12],
        disallowedPlanLevels: plandetailsobj[11],
        version: plan.version,
        description: plan.description,
        insuranceType: plan.insuranceType,
      };
      if (plandetailsobj[11] != null || plandetailsobj[11] != "null") {
        if (plandetailsobj[11].includes(plandetailsobj[12])) {
        }
      }
      let coverage = product.planCoverage;

      coverage = ["SINGLE", "COUPLE", "FAMILY"].includes(coverage)
        ? coverage
        : null;
      ////this.consoleMessage(obj)
      this.productobj = {
        id: product.id,
        productId: product.productId,
        name: product.name,

        planProductId: product.planProductId,
        price: adjustWith2DecimalsForUI(product.price1 || product.price),
        tax: adjustWith2DecimalsForUI(
          product.calculatedTax ? product.calculatedTax.tax : 0
        ),
        total: adjustWith2DecimalsForUI(
          product.calculatedTax ? product.calculatedTax.total : 0
        ),
        //  price: parseFloat(this._decimalPipe.transform(product.price1 || product.price, "1.2-2")) ,
        //  tax: parseFloat(this._decimalPipe.transform(product.calculatedTax ? product.calculatedTax.tax : 0, "1.2-2")),
        //  total:parseFloat(this._decimalPipe.transform(product.calculatedTax ? product.calculatedTax.total : 0, "1.2-2")),
        tax_details:
          product.bundledTaxes && product.bundledTaxes.length > 0
            ? product.bundledTaxes
            : product.taxesDataJSON,
        calculatedTax: product.calculatedTax,
        bundledProducts: product.bundledProducts,
        planCoverage: product.planCoverage,
        planLevel: product.planlevel
          ? product.planlevel.id
          : plan.planLevel
            ? plan.planLevel
            : group.id,
        planLevelParent: product.planlevel
          ? product.planlevel.parentId
          : group.parentId,
        description: product.description,
        coverage: coverage,
        version: product.version
      };
      let obj1: any = {
        packageId: plan.packageId,
        packageName: pckage.name,

        planproductname: this.productobj.name,

        groupid: plan.planLevel,
        groupName: group.name,
        id: plan.id,
        name: plan.name,
        planLevel: plan.planLevel ? plan.planLevel : group.id,
        planLevelParent: group.parentId,
        fusebillPlanID: plan.fusebillId,
        planFrequencyID: plan.frqMonthly,
        isBundle: plan.isBundle,
        coverage: coverage,
        planCoverage: product.planCoverage,
        bundledProducts: [],
        products: [],
        upgradeCalculation: plan.products && plan.products[planProductIndex] ? plan.products[planProductIndex].upgradeCalculation : plan.upgradeCalculation,
        paidByCompany: group.categorization.paidByCompany,
        coveredByCompany: group.categorization.coveredByCompany,
        paidByEmployee: group.categorization.paidByEmployee,
      };

      obj1.products.push(this.productobj);

      //this.consoleMessage(obj1);
      this.planobjdata = obj1;
      this.elementcvValues = elementcv

      // this.addtoplansummary(obj, obj1, elementcv);
      this.openplanoptions(elementcv, options, plan, product);
    }
    else {
      let obj = {
        isBundle: plan.isBundle,
        enrollmentDate: this.userForm.value.planEnrollmentDate
          ? this._CustomDatePipe.transform(this.userForm.value.planEnrollmentDate)
          : undefined,
        // "name":plandetailsobj[1]+"-"+plandetailsobj[2],
        name: plandetailsobj[14],
        details: plandetailsobj[0],
        packagename: plandetailsobj[0],
        groupName: plandetailsobj[1],
        // amount: parseFloat(plandetailsobj[3]),
        amount: parseFloat(product.calculatedTax.price),
        planCoverage: plandetailsobj[2],
        // planPrice: parseFloat(plandetailsobj[3]),
        planPrice: parseFloat(product.calculatedTax.price),
        // amountUI: "$" + this._decimalPipe.transform(plandetailsobj[3], "1.2-2"),
        amountUI: "$" + parseFloat(product.calculatedTax.price),
        gst:
          plandetailsobj[4] == null ||
            plandetailsobj[4] == "" ||
            plandetailsobj[4] == undefined ||
            plandetailsobj[4] == "null"
            ? 0
            : parseFloat(plandetailsobj[4]),
        hst:
          plandetailsobj[5] == null ||
            plandetailsobj[5] == "" ||
            plandetailsobj[5] == undefined ||
            plandetailsobj[5] == "null"
            ? 0
            : parseFloat(plandetailsobj[5]),
        pst:
          plandetailsobj[6] == null ||
            plandetailsobj[6] == "" ||
            plandetailsobj[6] == undefined ||
            plandetailsobj[6] == "null"
            ? 0
            : parseFloat(plandetailsobj[6]),
        qst:
          plandetailsobj[17] == null ||
            plandetailsobj[17] == "" ||
            plandetailsobj[17] == undefined ||
            plandetailsobj[17] == "null"
            ? 0
            : parseFloat(plandetailsobj[17]),
        gstCheck:
          plandetailsobj[4] == null ||
            plandetailsobj[4] == "" ||
            plandetailsobj[4] == undefined ||
            plandetailsobj[4] == "null"
            ? false
            : true,
        hstCheck:
          plandetailsobj[5] == null ||
            plandetailsobj[5] == "" ||
            plandetailsobj[5] == undefined ||
            plandetailsobj[5] == "null"
            ? false
            : true,
        pstCheck:
          plandetailsobj[6] == null ||
            plandetailsobj[6] == "" ||
            plandetailsobj[6] == undefined ||
            plandetailsobj[6] == "null"
            ? false
            : true,
        qstCheck:
          plandetailsobj[17] == null ||
            plandetailsobj[17] == "" ||
            plandetailsobj[17] == undefined ||
            plandetailsobj[17] == "null"
            ? false
            : true,
        id: parseFloat(plandetailsobj[7]),
        fusebillPlanID:
          plandetailsobj[8] == null ||
            plandetailsobj[8] == "" ||
            plandetailsobj[8] == undefined ||
            plandetailsobj[8] == "null"
            ? 0
            : parseFloat(plandetailsobj[8]),
        planFrequencyID:
          plandetailsobj[9] == null ||
            plandetailsobj[9] == "" ||
            plandetailsobj[9] == undefined ||
            plandetailsobj[9] == "null"
            ? 0
            : parseFloat(plandetailsobj[9]),
        optIn: plandetailsobj[10] == "true" ? true : false,
        planname: plandetailsobj[14],
        planLevel: parseInt(plandetailsobj[15]),
        packageId: parseInt(plandetailsobj[16]),
        options: [],
        paidByCompany1: plandetailsobj[19],
        coveredByCompany1: plandetailsobj[20],
        paidByEmployee1: plandetailsobj[21],
        planLevel_parentId: plandetailsobj[12],
        disallowedPlanLevels: plandetailsobj[11],
        version: plan.version,
        description: plan.description,
        insuranceType: plan.insuranceType,
      };
      if (plandetailsobj[11] != null || plandetailsobj[11] != "null") {
        if (plandetailsobj[11].includes(plandetailsobj[12])) {
        }
      }

      let coverage = product.planCoverage;

      coverage = ["SINGLE", "COUPLE", "FAMILY"].includes(coverage)
        ? coverage
        : null;
      ////this.consoleMessage(obj)
      this.productobj = {
        id: product.id,
        productId: product.productId,
        name: product.name,

        planProductId: product.planProductId,
        //  price: product.price1 || product.price,
        //  tax: product.calculatedTax ? product.calculatedTax.tax : 0,
        //  total: product.calculatedTax ? product.calculatedTax.total : 0,

        price: adjustWith2DecimalsForUI(product.price1 || product.price),
        tax: adjustWith2DecimalsForUI(
          product.calculatedTax ? product.calculatedTax.tax : 0
        ),
        total: adjustWith2DecimalsForUI(
          product.calculatedTax ? product.calculatedTax.total : 0
        ),
        //  price: parseFloat(this._decimalPipe.transform(product.price1 || product.price, "1.2-2")) ,
        //  tax: parseFloat(this._decimalPipe.transform(product.calculatedTax ? product.calculatedTax.tax : 0, "1.2-2")),
        //  total:parseFloat(this._decimalPipe.transform(product.calculatedTax ? product.calculatedTax.total : 0, "1.2-2")),

        tax_details:
          product.bundledTaxes && product.bundledTaxes.length > 0
            ? product.bundledTaxes
            : product.taxesDataJSON,
        calculatedTax: product.calculatedTax,
        bundledProducts: product.bundledProducts,
        planCoverage: product.planCoverage,
        planLevel: product.planlevel
          ? product.planlevel.id
          : plan.planLevel
            ? plan.planLevel
            : group.id,
        planLevelParent: product.planlevel
          ? product.planlevel.parentId
          : group.parentId,
        description: product.description,
        coverage: coverage,
        version: product.version
      };
      let obj1: any = {
        packageId: plan.packageId,
        packageName: pckage.name,

        planproductname: this.productobj.name,

        groupid: plan.planLevel,
        groupName: group.name,
        id: plan.id,
        name: plan.name,
        planLevel: plan.planLevel ? plan.planLevel : group.id,
        planLevelParent: group.parentId,
        fusebillPlanID: plan.fusebillId,
        planFrequencyID: plan.frqMonthly,
        isBundle: plan.isBundle,
        coverage: coverage,
        planCoverage: product.planCoverage,
        bundledProducts: [],
        products: [],
        upgradeCalculation: plan.products && plan.products[planProductIndex] ? plan.products[planProductIndex].upgradeCalculation : plan.upgradeCalculation,
        paidByCompany: group.categorization.paidByCompany,
        coveredByCompany: group.categorization.coveredByCompany,
        paidByEmployee: group.categorization.paidByEmployee,
      };

      //this.consoleMessage(group)
      obj1.products.push(this.productobj);
      //this.consoleMessage(obj1);

      this.addtoplansummary(obj, obj1, elementcv);
    }
  }

  public unselectplan(
    elementcv: any,
    options: any,
    plan: any,
    product: any,
    pckage: any,
    group: any,
    planProductIndex: any,
  ) {

    console.log(plan)

    let plandetails = elementcv.attributes.data.value;

    let plandetailsobj = plandetails.split("##");
    let obj = {
      isBundle: plan.isBundle,
      enrollmentDate: this.userForm.value.planEnrollmentDate
        ? this._CustomDatePipe.transform(this.userForm.value.planEnrollmentDate)
        : undefined,
      // "name":plandetailsobj[1]+"-"+plandetailsobj[2],
      name: plandetailsobj[14],
      details: plandetailsobj[0],
      packagename: plandetailsobj[0],
      groupName: plandetailsobj[1],
      amount: parseFloat(plandetailsobj[3].replace(/\,/g, "")),
      planCoverage: plandetailsobj[2],
      planPrice: parseFloat(plandetailsobj[3].replace(/\,/g, "")),
      amountUI: "$" + parseFloat(plandetailsobj[3].replace(/\,/g, "")),
      gst:
        plandetailsobj[4] == null ||
          plandetailsobj[4] == "" ||
          plandetailsobj[4] == undefined ||
          plandetailsobj[4] == "null"
          ? 0
          : parseFloat(plandetailsobj[4]),
      hst:
        plandetailsobj[5] == null ||
          plandetailsobj[5] == "" ||
          plandetailsobj[5] == undefined ||
          plandetailsobj[5] == "null"
          ? 0
          : parseFloat(plandetailsobj[5]),
      pst:
        plandetailsobj[6] == null ||
          plandetailsobj[6] == "" ||
          plandetailsobj[6] == undefined ||
          plandetailsobj[6] == "null"
          ? 0
          : parseFloat(plandetailsobj[6]),
      qst:
        plandetailsobj[17] == null ||
          plandetailsobj[17] == "" ||
          plandetailsobj[17] == undefined ||
          plandetailsobj[17] == "null"
          ? 0
          : parseFloat(plandetailsobj[17]),
      gstCheck:
        plandetailsobj[4] == null ||
          plandetailsobj[4] == "" ||
          plandetailsobj[4] == undefined ||
          plandetailsobj[4] == "null"
          ? false
          : true,
      hstCheck:
        plandetailsobj[5] == null ||
          plandetailsobj[5] == "" ||
          plandetailsobj[5] == undefined ||
          plandetailsobj[5] == "null"
          ? false
          : true,
      pstCheck:
        plandetailsobj[6] == null ||
          plandetailsobj[6] == "" ||
          plandetailsobj[6] == undefined ||
          plandetailsobj[6] == "null"
          ? false
          : true,
      qstCheck:
        plandetailsobj[17] == null ||
          plandetailsobj[17] == "" ||
          plandetailsobj[17] == undefined ||
          plandetailsobj[17] == "null"
          ? false
          : true,
      id: parseFloat(plandetailsobj[7]),
      fusebillPlanID:
        plandetailsobj[8] == null ||
          plandetailsobj[8] == "" ||
          plandetailsobj[8] == undefined ||
          plandetailsobj[8] == "null"
          ? 0
          : parseFloat(plandetailsobj[8]),
      planFrequencyID:
        plandetailsobj[9] == null ||
          plandetailsobj[9] == "" ||
          plandetailsobj[9] == undefined ||
          plandetailsobj[9] == "null"
          ? 0
          : parseFloat(plandetailsobj[9]),
      optIn: plandetailsobj[10] == "true" ? true : false,
      planname: plandetailsobj[14],
      planLevel: parseInt(plandetailsobj[15]),
      packageId: parseInt(plandetailsobj[16]),
      options: [],
      paidByCompany1: plandetailsobj[19],
      coveredByCompany1: plandetailsobj[20],
      paidByEmployee1: plandetailsobj[21],
      planLevel_parentId: plandetailsobj[12],
      disallowedPlanLevels: plandetailsobj[11],
    };
    if (plandetailsobj[11] != null || plandetailsobj[11] != "null") {
      if (plandetailsobj[11].includes(plandetailsobj[12])) {
      }
    }

    this.removeplansummary(obj, group, pckage, elementcv);
  }

  public addtoplansummary(obj: any, obj1: any, e: any) {
    let planSummary = JSON.parse(sessionStorage.getItem("plansummary") || "[]");

    //this.consoleMessage("obj1", obj1);
    console.log("obj", obj);
    setTimeout(() => {
      const dom: HTMLElement = this.elementRef.nativeElement;
      const plan = dom.querySelectorAll(".plan-description-span.single"+".plancheck"+obj.id);
      const plan2= dom.querySelectorAll(".plan-description-span.couple"+".plancheck"+obj.id);
      const plan3 = dom.querySelectorAll(".plan-description-span.family"+".plancheck"+obj.id);
      console.log(plan)

      if (obj.coverage === "SINGLE") {
        if(plan){
        plan.forEach((elem: any) => {
          (elem as HTMLElement).style.display = 'block';
        });
      }
      if(plan2){
        plan2.forEach((elem: any) => {
          (elem as HTMLElement).style.display = 'none';
        });
      }
      if(plan3){
        plan3.forEach((elem: any) => {
          (elem as HTMLElement).style.display = 'none';
        });
      }
      } else if (obj.coverage === "COUPLE") {
        if(plan){
        plan.forEach((elem: any) => {
          (elem as HTMLElement).style.display = 'none';
        });
      }
      if(plan2){
        plan2.forEach((elem: any) => {
          (elem as HTMLElement).style.display = 'block';
        });
      }
      if(plan3){
        plan3.forEach((elem: any) => {
          (elem as HTMLElement).style.display = 'none';
        });
      }
      } else if (obj.coverage === "FAMILY") {
        if(plan){
        plan.forEach((elem: any) => {
          (elem as HTMLElement).style.display = 'none';
        });
      }
      if(plan2){
        plan2.forEach((elem: any) => {
          (elem as HTMLElement).style.display = 'none';
        });
      }
      if(plan3){
        plan3.forEach((elem: any) => {
          (elem as HTMLElement).style.display = 'block';
        });
      }
      } else {
        // Default case - hide all plans if coverage doesn't match expected
      }



    }, 2000);

    this.addtoslectplans(obj.id, planSummary.length);
    planSummary.push(obj);

    let gstprice = 0;
    let hstprice = 0;
    let pstprice = 0;
    let qstprice = 0;

    if (obj.gst > 0) {
      gstprice = obj.planPrice * obj.gst;

      obj['gstPrice'] = gstprice
        ? this._decimalPipe.transform(gstprice, '1.2-2')
        : 0;
      obj['gstPrice'] = parseFloat(obj['gstPrice']);
    } else {
      obj["gstPrice"] = 0;
    }

    if (obj.hst > 0) {
      hstprice = obj.planPrice * obj.hst;

      obj['hstPrice'] = hstprice
        ? this._decimalPipe.transform(hstprice, '1.2-2')
        : 0;
      obj['hstPrice'] = parseFloat(obj['hstPrice']);
    } else {
      obj["hstPrice"] = 0;
    }

    if (obj.pst > 0) {
      pstprice = obj.planPrice * obj.pst;


      obj['pstPrice'] = pstprice
        ? this._decimalPipe.transform(pstprice, '1.2-2')
        : 0;
      obj['pstPrice'] = parseFloat(obj['pstPrice']);
    } else {
      obj["pstPrice"] = 0;
    }

    if (obj.qst > 0) {
      qstprice = obj.planPrice * obj.qst;

      obj['qstPrice'] = qstprice
        ? this._decimalPipe.transform(qstprice, '1.2-2')
        : 0;
      obj['qstPrice'] = parseFloat(obj['qstPrice']);
    } else {
      obj["qstPrice"] = 0;
    }

    obj["taxUI"] = "";
    if (obj["gstCheck"]) {
      obj["taxUI"] += "<span>";
      if (obj["gstPrice"] == 0) {
        obj["taxUI"] += "-";
      } else {
        obj["taxUI"] +=
          "$" +
          this._decimalPipe.transform(obj["gstPrice"], "1.2-2") +
          "&nbsp;(GST)";
      }
      obj["taxUI"] += "</span>";
    }

    if (obj["pstCheck"]) {
      obj["taxUI"] += "<span>";
      if (obj["pstPrice"] == 0) {
        obj["taxUI"] += "-";
      } else {
        obj["taxUI"] +=
          "$" +
          this._decimalPipe.transform(obj["pstPrice"], "1.2-2") +
          "&nbsp;(PST)";
      }
      obj["taxUI"] += "</span>";
    }

    if (obj["qstCheck"]) {
      obj["taxUI"] += "<span>";
      if (obj["qstPrice"] == 0) {
        obj["taxUI"] += "-";
      } else {
        obj["taxUI"] +=
          "$" +
          this._decimalPipe.transform(obj["qstPrice"], "1.2-2") +
          "&nbsp;(QST)";
      }
      obj["taxUI"] += "</span>";
    }

    if (obj["hstCheck"]) {
      obj["taxUI"] += "<span> ";
      if (obj["hstPrice"] == 0) {
        obj["taxUI"] += "-";
      } else {
        obj["taxUI"] +=
          "$" +
          this._decimalPipe.transform(obj["hstPrice"], "1.2-2") +
          "&nbsp;(HST)";
      }
      obj["taxUI"] += "</span>";
    }

    if (
      !obj["hstCheck"] &&
      !obj["gstCheck"] &&
      !obj["pstCheck"] &&
      !obj["qstCheck"]
    ) {
      obj["taxUI"] += "<span>-";

      obj["taxUI"] += "</span>";
    }

    // ////this.consoleMessage(object["taxUI"])

    // obj["tax"] = parseFloat(
    //   this._decimalPipe.transform(gstprice + hstprice + pstprice + qstprice, "1.2-2")
    // );
    // obj["tax"] = parseFloat(
    //   this._decimalPipe.transform(obj1.products[0].calculatedTax.tax, "1.2-2")
    // );
    obj['tax'] = obj1.products[0]
      ? this._decimalPipe.transform(obj1.products[0].calculatedTax.tax, "1.2-2") : 0;
    obj['tax'] = parseFloat(obj['tax']);
    // obj["products"] =obj1

    obj["coverage"] = obj1.coverage;
    obj["planCoverage"] = obj1.planCoverage;
    obj["planLevelParent"] = obj1.planLevelParent;
    obj["planproductname"] = obj1.planproductname;

    obj["products"] = obj1.products;
    obj["upgradeCalculation"] = obj1.upgradeCalculation;
    obj["paidByCompany"] = obj1.paidByCompany;
    obj["coveredByCompany"] = obj1.coveredByCompany;
    obj["paidByEmployee"] = obj1.paidByEmployee;



    let pricecal = obj1.products[0].calculatedTax.total;
    obj["totalPrice"] = pricecal;

    obj["totalUI"] = "$" + pricecal;

    (obj["total"] = pricecal),
      (obj["skuTotalPrice"] = parseFloat(
        obj1.products.reduce((acc: any, calculatedTax: any) => {
          return acc + calculatedTax.total;
        }, 0)
      ));

    let updatedSum = this.addtosum(obj.skuTotalPrice);
    this.planssummarymain = [];
    this.planssummaryopt = [];

    planSummary.forEach((element: any) => {
      if (element.packagename != "Opt-in") {
        this.planssummarymain.push(element);
      } else {
        this.planssummaryopt.push(element);
      }
    });

    this.plansskumain.push(obj1);

    sessionStorage.setItem("plansskumain", JSON.stringify(this.plansskumain));

    sessionStorage.setItem(
      "plansummarymain",
      JSON.stringify(this.planssummarymain)
    );
    sessionStorage.setItem(
      "plansummaryopt",
      JSON.stringify(this.planssummaryopt)
    );

    sessionStorage.setItem("plansummary", JSON.stringify(planSummary));

    // setTimeout(() => {
    this.planCoverafeFunction();

    // }, 3000);

    if (planSummary.length > 0) {
      this.plansnexttab = false;
    } else {
      this.plansnexttab = true;
    }

    if (updatedSum > 0) {
      this.cartcheckvalue = false;
    } else {
      this.cartcheckvalue = true;
    }
    setTimeout(() => {
      this.planssummarymainLife = JSON.parse(
        sessionStorage.getItem('planssummarymainLife') || '[]'
      );

      this.planssummarymain = JSON.parse(
        sessionStorage.getItem('plansummarymain') || '[]'
      );
      var total = 0;
      for (let i = 0; i < this.planssummarymainLife.length; i++) {
        total += parseFloat(this.planssummarymainLife[i].skuTotalPrice);
      }
      for (let i = 0; i < this.planssummarymain.length; i++) {
        total += parseFloat(this.planssummarymain[i].skuTotalPrice);
      }

      //this.consoleMessage(total);

      this.planAmount = parseFloat(total.toString());
      sessionStorage.setItem("totalAmount", this.planAmount);
    }, 100);

    setTimeout(() => {
      let selectplans =
        JSON.parse(sessionStorage.getItem("plansummarymain") || '[]') || "";



      selectplans.forEach((element: any) => {
        if (element.paidByCompany == 1) {
          const dom: HTMLElement = this.elementRef.nativeElement;
          const plan: any = dom.querySelector("#plancheck" + element.id);

          const samePlanIdElements = dom.querySelectorAll('#' + e.id);
          console.log(samePlanIdElements)

          for (let i = 0; i < samePlanIdElements.length; i++) {
            let elem = samePlanIdElements[i];
            let element: any = elem;
            // console.log(elem)
            // console.log(elem.className.includes('cp_plan'))
            if (elem.className.includes('cp_plan')) {
              element.disabled = false;
              if (element.checked) {
                element.disabled = true;

              }
            }
          }
          if (plan) {
            // plan.disabled = true;
            // if(plan.isBundle){
            //   plan.disabled = false;
            // }else{
            //   plan.disabled = false;
            // }

          }
        }
      });
    }, 1000);
  }
  public addtoplansummaryLife(obj: any, obj1: any) {
    let plansummaryLife = JSON.parse(sessionStorage.getItem("plansummaryLife") || "[]");

    //this.consoleMessage("obj1", obj1);
    //this.consoleMessage("obj", obj);

    this.addtoslectplansLife(obj.id, plansummaryLife.length);
    plansummaryLife.push(obj);

    let gstprice = 0;
    let hstprice = 0;
    let pstprice = 0;
    let qstprice = 0;

    if (obj.gst > 0) {
      gstprice = obj.planPrice * obj.gst;

      obj['gstPrice'] = gstprice
        ? this._decimalPipe.transform(gstprice, '1.2-2')
        : 0;
      obj['gstPrice'] = parseFloat(obj['gstPrice']);
    } else {
      obj["gstPrice"] = 0;
    }

    if (obj.hst > 0) {
      hstprice = obj.planPrice * obj.hst;

      obj['hstPrice'] = hstprice
        ? this._decimalPipe.transform(hstprice, '1.2-2')
        : 0;
      obj['hstPrice'] = parseFloat(obj['hstPrice']);
    } else {
      obj["hstPrice"] = 0;
    }

    if (obj.pst > 0) {
      pstprice = obj.planPrice * obj.pst;


      obj['pstPrice'] = pstprice
        ? this._decimalPipe.transform(pstprice, '1.2-2')
        : 0;
      obj['pstPrice'] = parseFloat(obj['pstPrice']);
    } else {
      obj["pstPrice"] = 0;
    }

    if (obj.qst > 0) {
      qstprice = obj.planPrice * obj.qst;

      obj['qstPrice'] = qstprice
        ? this._decimalPipe.transform(qstprice, '1.2-2')
        : 0;
      obj['qstPrice'] = parseFloat(obj['qstPrice']);
    } else {
      obj["qstPrice"] = 0;
    }

    obj["taxUI"] = "";
    if (obj["gstCheck"]) {
      obj["taxUI"] += "<span>";
      if (obj["gstPrice"] == 0) {
        obj["taxUI"] += "-";
      } else {
        obj["taxUI"] +=
          "$" +
          this._decimalPipe.transform(obj["gstPrice"], "1.2-2") +
          "&nbsp;(GST)";
      }
      obj["taxUI"] += "</span>";
    }

    if (obj["pstCheck"]) {
      obj["taxUI"] += "<span>";
      if (obj["pstPrice"] == 0) {
        obj["taxUI"] += "-";
      } else {
        obj["taxUI"] +=
          "$" +
          this._decimalPipe.transform(obj["pstPrice"], "1.2-2") +
          "&nbsp;(PST)";
      }
      obj["taxUI"] += "</span>";
    }

    if (obj["qstCheck"]) {
      obj["taxUI"] += "<span>";
      if (obj["qstPrice"] == 0) {
        obj["taxUI"] += "-";
      } else {
        obj["taxUI"] +=
          "$" +
          this._decimalPipe.transform(obj["qstPrice"], "1.2-2") +
          "&nbsp;(QST)";
      }
      obj["taxUI"] += "</span>";
    }

    if (obj["hstCheck"]) {
      obj["taxUI"] += "<span> ";
      if (obj["hstPrice"] == 0) {
        obj["taxUI"] += "-";
      } else {
        obj["taxUI"] +=
          "$" +
          this._decimalPipe.transform(obj["hstPrice"], "1.2-2") +
          "&nbsp;(HST)";
      }
      obj["taxUI"] += "</span>";
    }

    if (
      !obj["hstCheck"] &&
      !obj["gstCheck"] &&
      !obj["pstCheck"] &&
      !obj["qstCheck"]
    ) {
      obj["taxUI"] += "<span>-";

      obj["taxUI"] += "</span>";
    }

    // ////this.consoleMessage(object["taxUI"])

    // obj["tax"] = parseFloat(
    //   this._decimalPipe.transform(gstprice + hstprice + pstprice + qstprice, "1.2-2")
    // );
    // obj["tax"] = parseFloat(
    //   this._decimalPipe.transform(obj1.products[0].calculatedTax.tax, "1.2-2")
    // );
    obj['tax'] = obj1.products[0]
      ? this._decimalPipe.transform(obj1.products[0].calculatedTax.tax, "1.2-2") : 0;
    obj['tax'] = parseFloat(obj['tax']);
    // obj["products"] =obj1

    obj["coverage"] = obj1.coverage;
    obj["planCoverage"] = obj1.planCoverage;
    obj["planLevelParent"] = obj1.planLevelParent;
    obj["planproductname"] = obj1.planproductname;

    obj["products"] = obj1.products;
    // obj["upgradeCalculation"] = obj1.upgradeCalculation;
    // obj["paidByCompany"] = obj1.paidByCompany;
    // obj["coveredByCompany"] = obj1.coveredByCompany;
    // obj["paidByEmployee"] = obj1.paidByEmployee;

    //this.consoleMessage(obj.planPrice)
    //this.consoleMessage(gstprice)
    //this.consoleMessage(hstprice)
    //this.consoleMessage(pstprice)

    //this.consoleMessage(qstprice)
    // let pricecal = obj.planPrice + gstprice + hstprice + pstprice + qstprice

    let pricecal = obj1.products[0].calculatedTax.total;
    obj["totalPrice"] = pricecal;

    obj["totalUI"] = "$" + pricecal;

    (obj["total"] = pricecal),
      (obj["skuTotalPrice"] = parseFloat(
        obj1.products.reduce((acc: any, calculatedTax: any) => {
          return acc + calculatedTax.total;
        }, 0)
      ));

    let updatedSum = this.addtosum(obj.skuTotalPrice);
    this.planssummarymainLife = [];
    this.planssummaryopt = [];

    plansummaryLife.forEach((element: any) => {
      if (element.packagename != "Opt-in") {
        this.planssummarymainLife.push(element);
      } else {
        this.planssummaryopt.push(element);
      }
    });

    this.plansskumain.push(obj1);

    sessionStorage.setItem("plansskumain", JSON.stringify(this.plansskumain));

    sessionStorage.setItem(
      "planssummarymainLife",
      JSON.stringify(this.planssummarymainLife)
    );
    sessionStorage.setItem(
      "plansummaryopt",
      JSON.stringify(this.planssummaryopt)
    );

    sessionStorage.setItem("plansummaryLife", JSON.stringify(plansummaryLife));

    // setTimeout(() => {
    this.planCoverafeFunction();

    // }, 3000);

    if (plansummaryLife.length > 0) {
      this.plansnexttab = false;
    } else {
      this.plansnexttab = true;
    }

    if (updatedSum > 0) {
      this.cartcheckvalue = false;
    } else {
      this.cartcheckvalue = true;
    }
    setTimeout(() => {
      this.planssummarymainLife = JSON.parse(
        sessionStorage.getItem('planssummarymainLife') || '[]'
      );

      this.planssummarymain = JSON.parse(
        sessionStorage.getItem('plansummarymain') || '[]'
      );
      var total = 0;
      for (let i = 0; i < this.planssummarymainLife.length; i++) {
        total += parseFloat(this.planssummarymainLife[i].skuTotalPrice);
      }
      for (let i = 0; i < this.planssummarymain.length; i++) {
        total += parseFloat(this.planssummarymain[i].skuTotalPrice);
      }
      //this.consoleMessage(total);

      this.planAmount = parseFloat(total.toString());
      sessionStorage.setItem("totalAmount", this.planAmount);
    }, 100);

    setTimeout(() => {
      let selectplans =
        JSON.parse(sessionStorage.getItem("plansummarymain") || '[]') || "";

      selectplans.forEach((element: any) => {
        if (element.paidByCompany == 1) {
          const dom: HTMLElement = this.elementRef.nativeElement;
          const plan: any = dom.querySelector("#plancheck" + element.id);

          if (plan) {
            plan.disabled = true;
          }
        }
      });
    }, 1000);
  }

  public unselectplanLife(elementcv: any) {

    // this.planCoverageName = ''
    // this.insuranceplanid =''
    // console.log(elementcv)

    let plandetails = elementcv.target.attributes.data.value;

    let plandetailsobj = plandetails.split('##');
    let obj = {
      // enrollmentDate: moment(
      //   this.userForm.get("planenrollmentdate").value,
      //   "MM-DD-YYYY"
      // ).format("YYYY-MM-DD"),
      enrollmentDate: this.userForm.value.planEnrollmentDate
        ? this._CustomDatePipe.transform(this.userForm.value.planEnrollmentDate)
        : undefined,
      // "name":plandetailsobj[1]+"-"+plandetailsobj[2],
      name: plandetailsobj[14],
      details: plandetailsobj[0],
      packagename: plandetailsobj[0],
      groupName: plandetailsobj[1],
      amount: parseFloat(plandetailsobj[3]),
      planCoverage: plandetailsobj[2],
      planPrice: parseFloat(plandetailsobj[3]),
      amountUI: '$' + this._decimalPipe.transform(plandetailsobj[3], '1.2-2'),
      gst:
        plandetailsobj[4] == null ||
          plandetailsobj[4] == '' ||
          plandetailsobj[4] == undefined ||
          plandetailsobj[4] == 'null'
          ? 0
          : parseFloat(plandetailsobj[4]),
      hst:
        plandetailsobj[5] == null ||
          plandetailsobj[5] == '' ||
          plandetailsobj[5] == undefined ||
          plandetailsobj[5] == 'null'
          ? 0
          : parseFloat(plandetailsobj[5]),
      pst:
        plandetailsobj[6] == null ||
          plandetailsobj[6] == '' ||
          plandetailsobj[6] == undefined ||
          plandetailsobj[6] == 'null'
          ? 0
          : parseFloat(plandetailsobj[6]),
      qst:
        plandetailsobj[17] == null ||
          plandetailsobj[17] == '' ||
          plandetailsobj[17] == undefined ||
          plandetailsobj[17] == 'null'
          ? 0
          : parseFloat(plandetailsobj[17]),
      gstCheck:
        plandetailsobj[4] == null ||
          plandetailsobj[4] == '' ||
          plandetailsobj[4] == undefined ||
          plandetailsobj[4] == 'null'
          ? false
          : true,
      hstCheck:
        plandetailsobj[5] == null ||
          plandetailsobj[5] == '' ||
          plandetailsobj[5] == undefined ||
          plandetailsobj[5] == 'null'
          ? false
          : true,
      pstCheck:
        plandetailsobj[6] == null ||
          plandetailsobj[6] == '' ||
          plandetailsobj[6] == undefined ||
          plandetailsobj[6] == 'null'
          ? false
          : true,
      qstCheck:
        plandetailsobj[17] == null ||
          plandetailsobj[17] == '' ||
          plandetailsobj[17] == undefined ||
          plandetailsobj[17] == 'null'
          ? false
          : true,
      id: parseFloat(plandetailsobj[7]),
      fusebillPlanID:
        plandetailsobj[8] == null ||
          plandetailsobj[8] == '' ||
          plandetailsobj[8] == undefined ||
          plandetailsobj[8] == 'null'
          ? 0
          : parseFloat(plandetailsobj[8]),
      planFrequencyID:
        plandetailsobj[9] == null ||
          plandetailsobj[9] == '' ||
          plandetailsobj[9] == undefined ||
          plandetailsobj[9] == 'null'
          ? 0
          : parseFloat(plandetailsobj[9]),
      optIn: plandetailsobj[10] == 'true' ? true : false,
      planname: plandetailsobj[14],
      planLevel: parseInt(plandetailsobj[15]),
      packageId: parseInt(plandetailsobj[16]),
      options: [],
      //  "disallowedPlanLevels":plandetailsobj[11]
    };
    if (plandetailsobj[11] != null || plandetailsobj[11] != 'null') {
      if (plandetailsobj[11].includes(plandetailsobj[12])) {
      }
    }

    this.removeplansummaryLife(obj);
  }

  public removeplansummaryLife(obj: any) {
    //this.consoleMessage(obj)

    let plansummaryLife = JSON.parse(sessionStorage.getItem("plansummaryLife") || '[]');


    if (plansummaryLife) {

      let index: any = this.getslectedplansLife(obj.id);
      ////this.consoleMessage(index)

      if (index > -1) {
        // console.log(index);
        plansummaryLife.splice(index, 1);
        ////this.consoleMessage("afterremove")
        ////this.consoleMessage(planSummary)
      } else {
        return;
      }

      let selectedPlans = JSON.parse(
        sessionStorage.getItem('selectedPlans') || '[]'
      );
      var newselectedplans: any = {};
      for (var i = 0; i < plansummaryLife.length; i++) {
        newselectedplans[plansummaryLife[i].id] = i;
      }

      sessionStorage.setItem('selectedPlansLife', JSON.stringify(newselectedplans));
      // this.removeslectplans(obj.id,planSummary.length)

      let gstprice = 0;
      let hstprice = 0;
      let pstprice = 0;
      let qstprice = 0;

      if (obj.gst > 0) {
        gstprice = obj.planPrice * obj.gst;


        obj['gstPrice'] = gstprice
          ? this._decimalPipe.transform(gstprice, '1.2-2')
          : 0;
        obj['gstPrice'] = parseFloat(obj['gstPrice']);
      } else {
        obj["gstPrice"] = 0;
      }

      if (obj.hst > 0) {
        hstprice = obj.planPrice * obj.hst;




        obj['hstPrice'] = hstprice
          ? this._decimalPipe.transform(hstprice, '1.2-2')
          : 0;
        obj['hstPrice'] = parseFloat(obj['hstPrice']);

      } else {
        obj["hstPrice"] = 0;
      }

      if (obj.pst > 0) {
        pstprice = obj.planPrice * obj.pst;


        obj['pstPrice'] = pstprice
          ? this._decimalPipe.transform(pstprice, '1.2-2')
          : 0;
        obj['pstPrice'] = parseFloat(obj['pstPrice']);




      } else {
        obj["pstPrice"] = 0;
      }
      if (obj.qst > 0) {
        qstprice = obj.planPrice * obj.qst;

        obj['qstPrice'] = qstprice
          ? this._decimalPipe.transform(qstprice, '1.2-2')
          : 0;
        obj['qstPrice'] = parseFloat(obj['qstPrice']);
      } else {
        obj["qstPrice"] = 0;
      }

      obj["taxUI"] = "";
      if (obj["gstCheck"]) {
        obj["taxUI"] += "<span>";
        if (obj["gstPrice"] == 0) {
          obj["taxUI"] += "-";
        } else {
          obj["taxUI"] +=
            "$" +
            this._decimalPipe.transform(obj["gstPrice"], "1.2-2") +
            "&nbsp;(GST)";
        }
        obj["taxUI"] += "</span>";
      }

      if (obj["pstCheck"]) {
        obj["taxUI"] += "<span>";
        if (obj["pstPrice"] == 0) {
          obj["taxUI"] += "-";
        } else {
          obj["taxUI"] +=
            "$" +
            this._decimalPipe.transform(obj["pstPrice"], "1.2-2") +
            "&nbsp;(PST)";
        }
        obj["taxUI"] += "</span>";
      }

      if (obj["qstCheck"]) {
        obj["taxUI"] += "<span>";
        if (obj["qstPrice"] == 0) {
          obj["taxUI"] += "-";
        } else {
          obj["taxUI"] +=
            "$" +
            this._decimalPipe.transform(obj["qstPrice"], "1.2-2") +
            "&nbsp;(QST)";
        }
        obj["taxUI"] += "</span>";
      }

      if (obj["hstCheck"]) {
        obj["taxUI"] += "<span> ";
        if (obj["hstPrice"] == 0) {
          obj["taxUI"] += "-";
        } else {
          obj["taxUI"] +=
            "$" +
            this._decimalPipe.transform(obj["hstPrice"], "1.2-2") +
            "&nbsp;(HST)";
        }
        obj["taxUI"] += "</span>";
      }

      if (
        !obj["hstCheck"] &&
        !obj["gstCheck"] &&
        !obj["pstCheck"] &&
        !obj["qstCheck"]
      ) {
        obj["taxUI"] += "<span>-";

        obj["taxUI"] += "</span>";
      }

      // ////this.consoleMessage(object["taxUI"])



      obj['tax'] = gstprice + hstprice + pstprice + qstprice
        ? this._decimalPipe.transform(gstprice + hstprice + pstprice + qstprice, '1.2-2')
        : 0;
      obj['tax'] = parseFloat(obj['tax']);
      (obj["totalPrice"] =
        obj.planPrice + gstprice + hstprice + pstprice + qstprice),
        (obj["totalUI"] =
          "$" +
          parseFloat(
            obj.planPrice + gstprice + hstprice + pstprice + qstprice
          ));

      obj["total"] = parseFloat(
        obj.planPrice + gstprice + hstprice + pstprice + qstprice
      );

      let updatedSum = this.removetosum(obj.totalPrice);
      this.planssummarymainLife = [];
      this.planssummaryopt = [];

      plansummaryLife.forEach((element: any) => {
        if (element.packagename != "Opt-in") {
          this.planssummarymainLife.push(element);
        } else {
          this.planssummaryopt.push(element);
        }
      });

      sessionStorage.setItem(
        "plansummarymainLife",
        JSON.stringify(this.planssummarymainLife)
      );
      sessionStorage.setItem(
        "plansummaryopt",
        JSON.stringify(this.planssummaryopt)
      );

      sessionStorage.setItem("plansummaryLife", JSON.stringify(plansummaryLife));
      // if (plansummaryLife.length > 0) {
      //   this.plansnexttab = false;
      // } else {
      //   this.plansnexttab = true;
      // }



      if (updatedSum > 0) {
        this.cartcheckvalue = false;
      } else {
        this.cartcheckvalue = true;
      }

      setTimeout(() => {
        this.planCoverafeFunction();
        let selectplans =
          JSON.parse(sessionStorage.getItem("plansummarymainLife") || '[]') || "";

        //this.consoleMessage(pckage);
        //this.consoleMessage(group);

        // if (
        //   group.categorization.coveredByCompany == 1 ||
        //   group.categorization.paidByEmployee == 1
        // ) {
        //   pckage.groups.forEach((element:any) => {
        //     if (element.categorization.paidByCompany == 1) {
        //       element.plans.forEach((plan:any) => {
        //         Object.keys(plan.productAddonss).forEach((key) => {
        //           plan.productAddonss[key].forEach((product:any) => {
        //             this.adddisabledcompanypaidproductid.push({
        //               id: product.id,
        //               packgeid: plan.packageId,
        //             });
        //             //this.consoleMessage(this.adddisabledcompanypaidproductid);
        //           });
        //         });
        //       });
        //     }
        //   });

        //   //this.consoleMessage(this.adddisabledcompanypaidproductid);
        //   //here apply logic

        //   this.adddisabledcompanypaidproductid.forEach((element:any, index:any) => {
        //     const dom: HTMLElement = this.elementRef.nativeElement;
        //     const plan: any = dom.querySelector("#plancheck" + element.id);

        //     //this.consoleMessage(selectplans);

        //     // //this.consoleMessage(selectplans.products)

        //     if (
        //       selectplans.some(function (el:any) {
        //         return el.packageId == element.packgeid;
        //       })
        //     ) {
        //       selectplans.forEach((products:any) => {
        //         // //this.consoleMessage(products.packageId)
        //         // //this.consoleMessage(element.packgeid)
        //         if (products.packageId == element.packgeid) {
        //           // //this.consoleMessage("CheckHere")
        //           products.products.forEach((prod:any) => {
        //             // //this.consoleMessage(prod.id)
        //             // //this.consoleMessage(element.id)
        //             if (prod.id == element.id) {
        //             } else {
        //               // $("#plancheck" + element.id).click()
        //             }
        //           });
        //         } else {
        //           // //this.consoleMessage("CheckHere1")
        //           // $("#plancheck" + element.id).click()
        //           // $("#plancheck" + element.id).prop('disabled',true)
        //           // plan.disabled=true
        //         }
        //       });
        //     } else {
        //       // this.renderer.selectRootElement("#plancheck"+ element.id).click()
        //       // this.renderer.selectRootElement("#plancheck"+ element.id).prop("disabled", true);
        //       // $("#plancheck" + element.id).click(); //rakesh
        //       // $("#plancheck" + element.id).prop("disabled", true); //rakesh

        //       this.renderer.selectRootElement("#plancheck"+ element.id).click()
        //       const dom: HTMLElement = this.elementRef.nativeElement;
        //       const plan: any = dom.querySelector("#plancheck" + element.id);

        //       if (plan) {
        //         plan.disabled = true;
        //       }
        //     }
        //   });
        // }

        // setTimeout(() => {
        //   let selectplans =
        //     JSON.parse(sessionStorage.getItem("plansummarymain") || '[]') || "";

        //   selectplans.forEach((products:any) => {
        //     if (products.paidByCompany == 1) {
        //       products.products.forEach((element:any) => {
        //         const dom: HTMLElement = this.elementRef.nativeElement;
        //         const plan: any = dom.querySelector("#plancheck" + element.id);

        //         //this.consoleMessage(plan);

        //         if (plan) {
        //           plan.disabled = true;
        //         }
        //       });
        //     }
        //   });
        // }, 1000);




      }, 100);
    }
  }

  //   public getindexofplan(obj:any) {
  //     console.log(obj)
  //     let plansproductarray = JSON.parse(sessionStorage.getItem("plansummary")||'[]');

  //     for (let i = 0; i < plansproductarray.length; i++) {
  //       if (obj.isBundle == false) {
  //         if (plansproductarray[i].id == obj.id) {

  // if (plansproductarray[i].paidByCompany == 1) {
  //   plansproductarray[i].products.forEach((product:any, index:any) => {
  //     const dom: HTMLElement = this.elementRef.nativeElement;
  //     const plan: any = dom.querySelector("#plancheck" + product.id);

  //     if (plan) {
  //       // plan.disabled = false;
  //     }
  //   })

  // }
  //           return i;
  //         }
  //       } else {
  //         if (plansproductarray[i].packagename == obj.packagename) {
  //           if (plansproductarray[i].paidByCompany == 1) {
  //             plansproductarray[i].products.forEach((product:any, index:any) => {
  //               const dom: HTMLElement = this.elementRef.nativeElement;
  //               const plan: any = dom.querySelector("#plancheck" + product.id);

  //               if (plan) {
  //                 plan.disabled = false;
  //               }
  //             })

  //           }
  //           return i;
  //         }
  //       }
  //     }
  //   }

  public getindexofplan(obj: any) {

    console.log(obj)
    let plansproductarray = JSON.parse(sessionStorage.getItem("plansummary") || '[]');

    for (let i = 0; i < plansproductarray.length; i++) {
      if (obj.isBundle == false) {
        if (plansproductarray[i].id == obj.id) {

          if (plansproductarray[i].paidByCompany == 1) {
            plansproductarray[i].products.forEach((product: any, index: any) => {
              const dom: HTMLElement = this.elementRef.nativeElement;
              const plan: any = dom.querySelector("#plancheck" + product.id);

              if (plan) {
                // plan.disabled = false;
              }
            })

          }
          return i;
        }
      } else {
        if (plansproductarray[i].id == obj.id) {
          if (plansproductarray[i].paidByCompany == 1) {
            plansproductarray[i].products.forEach((product: any, index: any) => {
              const dom: HTMLElement = this.elementRef.nativeElement;
              const plan: any = dom.querySelector("#plancheck" + product.id);

              if (plan) {
                plan.disabled = false;
              }
            })

          }
          return i;
        }
      }
    }
  }
  toggleItem2(item: any): void {
    item.expanded = !item.expanded;
  }


  /*  public removeplansummary12(obj: any, group: any, pckage: any) {
     //this.consoleMessage(obj)

     let planSummary = JSON.parse(sessionStorage.getItem("plansummary") || '[]');



     if (planSummary) {
       ////this.consoleMessage("removeinplanid"+obj.id)

       // let index = this.getslectedplans(obj.id);
       // ////this.consoleMessage(index)

       // if (index > -1) {
       //   planSummary.splice(index, 1);

       // } else {
       //   return;
       // }
       let index: any = this.getindexofplan(obj);

       console.log(index)
       if (index > -1) {
         planSummary.splice(index, 1);
       } else {
         return;
       }

       let selectedPlans = JSON.parse(sessionStorage.getItem("selectedPlans") || '{}');
       var newselectedplans: any = {};
       for (var i = 0; i < planSummary.length; i++) {
         newselectedplans[planSummary[i].id] = i;
       }

       ////this.consoleMessage("newselectedPlans")
       ////this.consoleMessage(newselectedplans)
       sessionStorage.setItem("selectedPlans", JSON.stringify(newselectedplans));
       // this.removeslectplans(obj.id,planSummary.length)

       let gstprice = 0;
       let hstprice = 0;
       let pstprice = 0;
       let qstprice = 0;

       if (obj.gst > 0) {
         gstprice = obj.planPrice * obj.gst;


         obj['gstPrice'] = gstprice
           ? this._decimalPipe.transform(gstprice, '1.2-2')
           : 0;
         obj['gstPrice'] = parseFloat(obj['gstPrice']);
       } else {
         obj["gstPrice"] = 0;
       }

       if (obj.hst > 0) {
         hstprice = obj.planPrice * obj.hst;




         obj['hstPrice'] = hstprice
           ? this._decimalPipe.transform(hstprice, '1.2-2')
           : 0;
         obj['hstPrice'] = parseFloat(obj['hstPrice']);

       } else {
         obj["hstPrice"] = 0;
       }

       if (obj.pst > 0) {
         pstprice = obj.planPrice * obj.pst;


         obj['pstPrice'] = pstprice
           ? this._decimalPipe.transform(pstprice, '1.2-2')
           : 0;
         obj['pstPrice'] = parseFloat(obj['pstPrice']);




       } else {
         obj["pstPrice"] = 0;
       }
       if (obj.qst > 0) {
         qstprice = obj.planPrice * obj.qst;

         obj['qstPrice'] = qstprice
           ? this._decimalPipe.transform(qstprice, '1.2-2')
           : 0;
         obj['qstPrice'] = parseFloat(obj['qstPrice']);
       } else {
         obj["qstPrice"] = 0;
       }

       obj["taxUI"] = "";
       if (obj["gstCheck"]) {
         obj["taxUI"] += "<span>";
         if (obj["gstPrice"] == 0) {
           obj["taxUI"] += "-";
         } else {
           obj["taxUI"] +=
             "$" +
             this._decimalPipe.transform(obj["gstPrice"], "1.2-2") +
             "&nbsp;(GST)";
         }
         obj["taxUI"] += "</span>";
       }

       if (obj["pstCheck"]) {
         obj["taxUI"] += "<span>";
         if (obj["pstPrice"] == 0) {
           obj["taxUI"] += "-";
         } else {
           obj["taxUI"] +=
             "$" +
             this._decimalPipe.transform(obj["pstPrice"], "1.2-2") +
             "&nbsp;(PST)";
         }
         obj["taxUI"] += "</span>";
       }

       if (obj["qstCheck"]) {
         obj["taxUI"] += "<span>";
         if (obj["qstPrice"] == 0) {
           obj["taxUI"] += "-";
         } else {
           obj["taxUI"] +=
             "$" +
             this._decimalPipe.transform(obj["qstPrice"], "1.2-2") +
             "&nbsp;(QST)";
         }
         obj["taxUI"] += "</span>";
       }

       if (obj["hstCheck"]) {
         obj["taxUI"] += "<span> ";
         if (obj["hstPrice"] == 0) {
           obj["taxUI"] += "-";
         } else {
           obj["taxUI"] +=
             "$" +
             this._decimalPipe.transform(obj["hstPrice"], "1.2-2") +
             "&nbsp;(HST)";
         }
         obj["taxUI"] += "</span>";
       }

       if (
         !obj["hstCheck"] &&
         !obj["gstCheck"] &&
         !obj["pstCheck"] &&
         !obj["qstCheck"]
       ) {
         obj["taxUI"] += "<span>-";

         obj["taxUI"] += "</span>";
       }

       // ////this.consoleMessage(object["taxUI"])



       obj['tax'] = gstprice + hstprice + pstprice + qstprice
         ? this._decimalPipe.transform(gstprice + hstprice + pstprice + qstprice, '1.2-2')
         : 0;
       obj['tax'] = parseFloat(obj['tax']);
       (obj["totalPrice"] =
         obj.planPrice + gstprice + hstprice + pstprice + qstprice),
         (obj["totalUI"] =
           "$" +
           parseFloat(
             obj.planPrice + gstprice + hstprice + pstprice + qstprice
           ));

       obj["total"] = parseFloat(
         obj.planPrice + gstprice + hstprice + pstprice + qstprice
       );

       let updatedSum = this.removetosum(obj.totalPrice);
       this.planssummarymain = [];
       this.planssummaryopt = [];

       planSummary.forEach((element: any) => {
         if (element.packagename != "Opt-in") {
           this.planssummarymain.push(element);
         } else {
           this.planssummaryopt.push(element);
         }
       });

       sessionStorage.setItem(
         "plansummarymain",
         JSON.stringify(this.planssummarymain)
       );
       sessionStorage.setItem(
         "plansummaryopt",
         JSON.stringify(this.planssummaryopt)
       );
       console.log(JSON.stringify(planSummary))
       sessionStorage.setItem("plansummary", JSON.stringify(planSummary));
       if (planSummary.length > 0) {
         this.plansnexttab = false;
       } else {
         this.plansnexttab = true;
       }
       this.planCoverafeFunction();


       if (updatedSum > 0) {
         this.cartcheckvalue = false;
       } else {
         this.cartcheckvalue = true;
       }

       setTimeout(() => {
         let selectplans =
           JSON.parse(sessionStorage.getItem("plansummarymain") || '[]') || "";

         //this.consoleMessage(pckage);
         //this.consoleMessage(group);

         if (
           group.categorization.coveredByCompany == 1 ||
           group.categorization.paidByEmployee == 1
         ) {
           pckage.groups.forEach((element: any) => {
             if (element.categorization.paidByCompany == 1) {
               element.plans.forEach((plan: any) => {
                 Object.keys(plan.productAddonss).forEach((key) => {
                   plan.productAddonss[key].forEach((product: any) => {
                     this.adddisabledcompanypaidproductid.push({
                       id: product.id,
                       packgeid: plan.packageId,
                     });
                     //this.consoleMessage(this.adddisabledcompanypaidproductid);
                   });
                 });
               });
             }
           });

           //this.consoleMessage(this.adddisabledcompanypaidproductid);
           //here apply logic

           this.adddisabledcompanypaidproductid.forEach((element: any, index: any) => {
             const dom: HTMLElement = this.elementRef.nativeElement;
             const plan: any = dom.querySelector("#plancheck" + element.id);

             //this.consoleMessage(selectplans);

             // //this.consoleMessage(selectplans.products)

             if (
               selectplans.some(function (el: any) {
                 return el.packageId == element.packgeid;
               })
             ) {
               selectplans.forEach((products: any) => {
                 // //this.consoleMessage(products.packageId)
                 // //this.consoleMessage(element.packgeid)
                 if (products.packageId == element.packgeid) {
                   // //this.consoleMessage("CheckHere")
                   products.products.forEach((prod: any) => {
                     // //this.consoleMessage(prod.id)
                     // //this.consoleMessage(element.id)
                     if (prod.id == element.id) {
                     } else {
                       // $("#plancheck" + element.id).click()
                     }
                   });
                 } else {
                   // //this.consoleMessage("CheckHere1")



                   // $("#plancheck" + element.id).click()
                   // $("#plancheck" + element.id).prop('disabled',true)
                   // plan.disabled=true
                 }
               });
             } else {

               // const dom: HTMLElement = this.elementRef.nativeElement;
               // const plan: any = dom.querySelector("#plancheck" + element.id);

               //  const planCheck = this.renderer.selectRootElement("#plancheck"+ element.id, true);
               //     this.renderer.setProperty(planCheck, 'click', true);
               //     this.renderer.setProperty(planCheck, 'disabled', true);
               // this.renderer.selectRootElement("#plancheck"+ element.id).click()
               // this.renderer.selectRootElement("#plancheck"+ element.id).prop("disabled", true);
               // $("#plancheck" + element.id).click(); //rakesh
               // $("#plancheck" + element.id).prop("disabled", true); //rakesh

               this.renderer.selectRootElement("#plancheck" + element.id).click()
               const dom: HTMLElement = this.elementRef.nativeElement;
               const plan: any = dom.querySelector("#plancheck" + element.id);
               console.log(plan)

               if (plan) {
                 plan.disabled = true;
               }
             }
           });
         }

         setTimeout(() => {
           let selectplans =
             JSON.parse(sessionStorage.getItem("plansummarymain") || '[]') || "";

           selectplans.forEach((products: any) => {
             if (products.paidByCompany == 1) {
               products.products.forEach((element: any) => {
                 const dom: HTMLElement = this.elementRef.nativeElement;
                 const plan: any = dom.querySelector("#plancheck" + element.id);

                 //this.consoleMessage(plan);

                 if (plan) {
                   plan.disabled = true;
                 }
               });
             }
           });
         }, 1000);




       }, 100);
     }
   } */

  public removeplansummary(obj: any, group: any, pckage: any, e: any) {


    console.log(obj)
    // setTimeout(() => {
    //   const dom: HTMLElement = this.elementRef.nativeElement;
    //   const plan = dom.querySelectorAll(".plan-description-span.single"+".plancheck"+obj.id);
    //   const plan2= dom.querySelectorAll(".plan-description-span.couple"+".plancheck"+obj.id);
    //   const plan3 = dom.querySelectorAll(".plan-description-span.family"+".plancheck"+obj.id);

    //   if(obj.coverage=="SINGLE"){
    //     plan.forEach((elem:any) => {
    //       (elem as HTMLElement).style.display = 'block';
    //     });
    //   }else{
    //     plan2.forEach((elem:any) => {
    //       (elem as HTMLElement).style.display = 'none';
    //     });
    //     plan3.forEach((elem:any) => {
    //       (elem as HTMLElement).style.display = 'none';
    //     });
    //   }
    //   if(obj.coverage=="COUPLE"){
    //     plan.forEach((elem:any) => {
    //       (elem as HTMLElement).style.display = 'none';
    //     });
    //   }else{
    //     plan2.forEach((elem:any) => {
    //       (elem as HTMLElement).style.display = 'block';
    //     });
    //     plan3.forEach((elem:any) => {
    //       (elem as HTMLElement).style.display = 'none';
    //     });
    //   }
    //   if(obj.coverage=="FAMILY"){
    //     plan.forEach((elem:any) => {
    //       (elem as HTMLElement).style.display = 'none';
    //     });
    //   }else{
    //     plan2.forEach((elem:any) => {
    //       (elem as HTMLElement).style.display = 'none';
    //     });
    //     plan3.forEach((elem:any) => {
    //       (elem as HTMLElement).style.display = 'block';
    //     });
    //   }


    // }, 2000);

    let planSummary = JSON.parse(sessionStorage.getItem("plansummary") || '[]');



    if (planSummary) {

      let index: any = this.getindexofplan(obj);
      if (index > -1) {
        planSummary.splice(index, 1);
      } else {
        return;
      }

      let selectedPlans = JSON.parse(sessionStorage.getItem("selectedPlans") || '{}');
      var newselectedplans: any = {};
      for (var i = 0; i < planSummary.length; i++) {
        newselectedplans[planSummary[i].id] = i;
      }

      ////this.consoleMessage("newselectedPlans")
      ////this.consoleMessage(newselectedplans)
      sessionStorage.setItem("selectedPlans", JSON.stringify(newselectedplans));
      // this.removeslectplans(obj.id,planSummary.length)

      let gstprice = 0;
      let hstprice = 0;
      let pstprice = 0;
      let qstprice = 0;

      if (obj.gst > 0) {
        gstprice = obj.planPrice * obj.gst;


        obj['gstPrice'] = gstprice
          ? this._decimalPipe.transform(gstprice, '1.2-2')
          : 0;
        obj['gstPrice'] = parseFloat(obj['gstPrice']);
      } else {
        obj["gstPrice"] = 0;
      }

      if (obj.hst > 0) {
        hstprice = obj.planPrice * obj.hst;




        obj['hstPrice'] = hstprice
          ? this._decimalPipe.transform(hstprice, '1.2-2')
          : 0;
        obj['hstPrice'] = parseFloat(obj['hstPrice']);

      } else {
        obj["hstPrice"] = 0;
      }

      if (obj.pst > 0) {
        pstprice = obj.planPrice * obj.pst;


        obj['pstPrice'] = pstprice
          ? this._decimalPipe.transform(pstprice, '1.2-2')
          : 0;
        obj['pstPrice'] = parseFloat(obj['pstPrice']);




      } else {
        obj["pstPrice"] = 0;
      }
      if (obj.qst > 0) {
        qstprice = obj.planPrice * obj.qst;

        obj['qstPrice'] = qstprice
          ? this._decimalPipe.transform(qstprice, '1.2-2')
          : 0;
        obj['qstPrice'] = parseFloat(obj['qstPrice']);
      } else {
        obj["qstPrice"] = 0;
      }

      obj["taxUI"] = "";
      if (obj["gstCheck"]) {
        obj["taxUI"] += "<span>";
        if (obj["gstPrice"] == 0) {
          obj["taxUI"] += "-";
        } else {
          obj["taxUI"] +=
            "$" +
            this._decimalPipe.transform(obj["gstPrice"], "1.2-2") +
            "&nbsp;(GST)";
        }
        obj["taxUI"] += "</span>";
      }

      if (obj["pstCheck"]) {
        obj["taxUI"] += "<span>";
        if (obj["pstPrice"] == 0) {
          obj["taxUI"] += "-";
        } else {
          obj["taxUI"] +=
            "$" +
            this._decimalPipe.transform(obj["pstPrice"], "1.2-2") +
            "&nbsp;(PST)";
        }
        obj["taxUI"] += "</span>";
      }

      if (obj["qstCheck"]) {
        obj["taxUI"] += "<span>";
        if (obj["qstPrice"] == 0) {
          obj["taxUI"] += "-";
        } else {
          obj["taxUI"] +=
            "$" +
            this._decimalPipe.transform(obj["qstPrice"], "1.2-2") +
            "&nbsp;(QST)";
        }
        obj["taxUI"] += "</span>";
      }

      if (obj["hstCheck"]) {
        obj["taxUI"] += "<span> ";
        if (obj["hstPrice"] == 0) {
          obj["taxUI"] += "-";
        } else {
          obj["taxUI"] +=
            "$" +
            this._decimalPipe.transform(obj["hstPrice"], "1.2-2") +
            "&nbsp;(HST)";
        }
        obj["taxUI"] += "</span>";
      }

      if (
        !obj["hstCheck"] &&
        !obj["gstCheck"] &&
        !obj["pstCheck"] &&
        !obj["qstCheck"]
      ) {
        obj["taxUI"] += "<span>-";

        obj["taxUI"] += "</span>";
      }

      // ////this.consoleMessage(object["taxUI"])



      obj['tax'] = gstprice + hstprice + pstprice + qstprice
        ? this._decimalPipe.transform(gstprice + hstprice + pstprice + qstprice, '1.2-2')
        : 0;
      obj['tax'] = parseFloat(obj['tax']);
      (obj["totalPrice"] =
        obj.planPrice + gstprice + hstprice + pstprice + qstprice),
        (obj["totalUI"] =
          "$" +
          parseFloat(
            obj.planPrice + gstprice + hstprice + pstprice + qstprice
          ));

      obj["total"] = parseFloat(
        obj.planPrice + gstprice + hstprice + pstprice + qstprice
      );

      let updatedSum = this.removetosum(obj.totalPrice);
      this.planssummarymain = [];
      this.planssummaryopt = [];

      planSummary.forEach((element: any) => {
        if (element.packagename != "Opt-in") {
          this.planssummarymain.push(element);
        } else {
          this.planssummaryopt.push(element);
        }
      });

      sessionStorage.setItem(
        "plansummarymain",
        JSON.stringify(this.planssummarymain)
      );
      sessionStorage.setItem(
        "plansummaryopt",
        JSON.stringify(this.planssummaryopt)
      );

      sessionStorage.setItem("plansummary", JSON.stringify(planSummary));
      if (planSummary.length > 0) {
        this.plansnexttab = false;
      } else {
        this.plansnexttab = true;
      }
      this.planCoverafeFunction();


      if (updatedSum > 0) {
        this.cartcheckvalue = false;
      } else {
        this.cartcheckvalue = true;
      }

      setTimeout(() => {
        let selectplans =
          JSON.parse(sessionStorage.getItem("plansummarymain") || '[]') || "";

        //this.consoleMessage(pckage);
        //this.consoleMessage(group);

        let adddisabledcompanypaidproductid: any = []

        if (
          group.categorization.coveredByCompany == 1
          ||
          group.categorization.paidByEmployee == 1
        ) {
          pckage.groups.forEach((element: any) => {
            if (element.categorization.paidByCompany == 1) {
              element.plans.forEach((plan: any) => {
                let pl = plan
                Object.keys(plan.productAddonss).forEach((key) => {
                  plan.productAddonss[key].forEach((product: any) => {
                    console.log(product)
                    if (pl.isBundle) {
                      adddisabledcompanypaidproductid.push({
                        id: pl.id,
                        packgeid: plan.packageId,
                      });
                    } else {
                      adddisabledcompanypaidproductid.push({
                        // id: product.id,
                        id: product.planLevel,
                        packgeid: plan.packageId,
                      });
                    }

                    //this.consoleMessage(this.adddisabledcompanypaidproductid);
                  });
                });
              });
            }
          });

          console.log(adddisabledcompanypaidproductid);
          //here apply logic



          adddisabledcompanypaidproductid.forEach((element: any, index: any) => {
            const dom: HTMLElement = this.elementRef.nativeElement;
            const plan: any = dom.querySelector("#plancheck" + element.id);
            const planCheck1 = this.renderer.selectRootElement("#plancheck" + element.id, true);

            if (
              selectplans.some(function (el: any) {
                return el.packageId == element.packgeid;
              })
            ) {
              selectplans.forEach((products: any) => {

                if (products.packageId == element.packgeid) {

                  products.products.forEach((prod: any) => {

                    if (prod.id == element.id) {
                    } else {
                      // $("#plancheck" + element.id).click()
                    }
                  });
                } else {
                  // //this.consoleMessage("CheckHere1")

                }
              });
            } else {


              const dom: HTMLElement = this.elementRef.nativeElement;
              const plan: any = dom.querySelectorAll("#plancheck" + element.id);
              console.log(plan)
              // plan[0].click()

              plan.forEach((element: any) => {
                if (element.className.includes('cp_plan')) {
                  element.click()
                }
              });



              //   plan.forEach((element:any) => {
              //     element.click()
              // });

              const planCheck1 = this.renderer.selectRootElement("#plancheck" + element.id, true);

              // this.renderer.setProperty(planCheck1, 'disabled', false);

            }
          });
        }

        setTimeout(() => {
          let selectplans =
            JSON.parse(sessionStorage.getItem("plansummarymain") || '[]') || "";

          selectplans.forEach((products: any) => {
            if (products.paidByCompany == 1) {
              products.products.forEach((element: any) => {
                const dom: HTMLElement = this.elementRef.nativeElement;
                const plan: any = dom.querySelector("#plancheck" + element.id);

                //this.consoleMessage(plan);

                if (plan) {

                }


                const samePlanIdElements = dom.querySelectorAll('#' + e.id);
                console.log(samePlanIdElements)

                for (let i = 0; i < samePlanIdElements.length; i++) {
                  let elem = samePlanIdElements[i];
                  let element: any = elem;
                  if (elem.className.includes('cp_plan')) {
                    element.disabled = false;
                    if (element.checked) {
                      element.disabled = true;

                    }
                  }

                }
              });
            }
          });
        }, 1000);
      }, 100);
    }
  }

  public addtoslectplans(planid: number, plansumamryindex: number) {
    ////this.consoleMessage("beforeaddtoplans")
    ////this.consoleMessage(planid)
    ////this.consoleMessage(plansumamryindex)
    let selectedPlans = JSON.parse(
      sessionStorage.getItem("selectedPlans") || "{}"
    );
    ////this.consoleMessage(selectedPlans)
    selectedPlans[planid] = plansumamryindex;
    ////this.consoleMessage("afteraddtoplans")
    ////this.consoleMessage(selectedPlans)
    sessionStorage.setItem("selectedPlans", JSON.stringify(selectedPlans));
  }
  public addtoslectplansLife(planid: number, plansumamryindex: number) {
    ////this.consoleMessage("beforeaddtoplans")
    ////this.consoleMessage(planid)
    ////this.consoleMessage(plansumamryindex)
    let selectedPlans = JSON.parse(
      sessionStorage.getItem("selectedPlansLife") || "{}"
    );
    ////this.consoleMessage(selectedPlans)
    selectedPlans[planid] = plansumamryindex;
    ////this.consoleMessage("afteraddtoplans")
    ////this.consoleMessage(selectedPlans)
    sessionStorage.setItem("selectedPlansLife", JSON.stringify(selectedPlans));
  }
  public removeslectplans(planid: number, plansumamryindex: number) {
    ////this.consoleMessage("beforeremoveplans")
    let selectedPlans = JSON.parse(sessionStorage.getItem("selectedPlans") || '{}');

    ////this.consoleMessage(selectedPlans)
    delete selectedPlans[planid];

    for (const planid in selectedPlans) {
      ////this.consoleMessage(planid)
      ////this.consoleMessage(selectedPlans[planid])

      if (selectedPlans[planid] != 0) {
        selectedPlans[planid] = selectedPlans[planid] - 1;
        if (selectedPlans[planid]) {
        }
      }
    }
    ////this.consoleMessage("adjustedselectedPlans")
    ////this.consoleMessage(selectedPlans)
    sessionStorage.setItem("selectedPlans", JSON.stringify(selectedPlans));
  }

  public getslectedplans(planid: number) {
    ////this.consoleMessage(planid)
    let selectedPlans = JSON.parse(
      sessionStorage.getItem("selectedPlans") || "{}"
    );
    ////this.consoleMessage(selectedPlans)
    return selectedPlans[planid];
  }
  public getslectedplansLife(planid: number) {
    ////this.consoleMessage(planid)
    let selectedPlans = JSON.parse(
      sessionStorage.getItem("selectedPlansLife") || "{}"
    );
    ////this.consoleMessage(selectedPlans)
    return selectedPlans[planid];
  }
  public addtosum(amount: number) {
    //this.consoleMessage("addtosum" + amount);

    // amount = Math.round((amount + Number.EPSILON) * 100) / 100;

    let selectedPlansAmount = parseFloat(
      sessionStorage.getItem("totalAmount") || "0.00"
    );

    selectedPlansAmount = selectedPlansAmount + amount;

    //this.consoleMessage("selectedPlansAmount" + selectedPlansAmount);
    let totalAmount: any = this._decimalPipe.transform(selectedPlansAmount, "1.2-2")?.replace(/,/g, "")
    sessionStorage.setItem(
      "totalAmount",
      totalAmount
    );

    sessionStorage.setItem(
      "totalAmountUI",
      "$" +
      this._decimalPipe.transform(selectedPlansAmount, "1.2-2")?.replace(/,/g, "")
    );

    // this.planAmount = selectedPlansAmount

    this.planAmount = this._decimalPipe
      .transform(selectedPlansAmount, "1.2-2")
      ?.replace(/,/g, "");

    //this.consoleMessage("palnamopunt" + this.planAmount);
    return selectedPlansAmount;
    // //////this.consoleMessage(this.planAmount )
  }

  public removetosum(amount: number) {

    let selectedPlansAmount: any = parseFloat(sessionStorage.getItem("totalAmount") || '0')
    // selectedPlansAmount =selectedPlansAmount.replace(/,/g, "");
    selectedPlansAmount = selectedPlansAmount - amount;
    let finaltotalAmount: any = this._decimalPipe.transform(selectedPlansAmount, "1.2-2")?.replace(/,/g, "")
    sessionStorage.setItem(
      "totalAmount", finaltotalAmount)
    sessionStorage.setItem(
      "totalAmountUI",
      "$" +
      this._decimalPipe
        .transform(selectedPlansAmount, "1.2-2")
        ?.replace(/,/g, "")
    );

    // this.planAmount = selectedPlansAmount
    this.planAmount = this._decimalPipe
      .transform(selectedPlansAmount, "1.2-2")
      ?.replace(/,/g, "");
    return selectedPlansAmount;
  }

  public openplanoptions(elementcv: any, options: any, plan: any, product: any) {
    let plandetails = elementcv.attributes.data.value;

    let plandetailsobj = plandetails.split("##");

    let modifiedOptions: any = [];
    let optionumber: any = [];
    // let optionumber = $("#optionumber").val();  //rakesh
    // jQuery("#showplanoptions-modal").modal("hide");

    for (let i = 1; i <= optionumber; i++) {
      // $(".optionselectionmethod-" + i + ":checked").prop("checked", false);
    }

    // this.plandetailsobjvalue = plandetailsobj;

    this.plandetailsobjvalue = plandetails;

    options.forEach((element: any) => {
      element.planOptionsValues.forEach((options: any) => {
        options.planOptionName = element.name;
        options.json = JSON.stringify(options);
      });

      modifiedOptions.push(element);
    });

    this.optionstitle = modifiedOptions;
    //this.consoleMessage("isexeccp:" + plandetailsobj[19]);
    this.isExecutiveCP = plandetailsobj[19]
      ? parseInt(plandetailsobj[19]) == 1
        ? true
        : false
      : false;
      this.planOptionsModel =true
    // alert("startrhetre")
    // jQuery("#showplanoptions-modal").modal("show");
  }

  public closeplanoptions() { }

  public chooseplan() {
    this.isDisabledplan = true;
  }



  public planCoverafeFunction() {
    this.paidEmployeeShare = 0;
    this.coveredEmployeeShare = 0;

    //this.consoleMessage("plancoverage");

    let planDetailsArray = JSON.parse(
      sessionStorage.getItem("planDetailsArray") || "[]"
    );
    //this.consoleMessage(planDetailsArray);
    // var package_plans = this.convertpackagesToPlans(planDetailsArray);
    // var package_plans = this.convertpackagesToPlans2(this.packagesInBlocks);
    var package_plans = this.convertpackagesToPlans2(this.basepackages);

    //this.consoleMessage(package_plans);
    let planSummaryMain = JSON.parse(sessionStorage.getItem("plansummary") || "[]");
    let planSummaryLife = JSON.parse(sessionStorage.getItem("plansummaryLife") || "[]");
    // let iafgplansCheck = JSON.parse(
    //   sessionStorage.getItem('plansummaryLife') || '[]'
    // );

  this.iafgplansCheck = JSON.parse(
      sessionStorage.getItem('plansummaryLife') || '[]'
    );
    console.log(planSummaryMain)

    // let planSummary;
    // if(planSummaryMain.length>0){
    //  let planSummary = planSummaryMain.concat(planSummaryLife)
    // }else{
    //    planSummary = planSummaryLife
    // }
    let planSummary = planSummaryMain.concat(planSummaryLife)

    console.log(planSummary)
    //this.consoleMessage(planSummary);
    var all_selected_plans = [];
    var payrollCCplans = [];
    var directpayCCplans = [];
    var employeeDealingPlans = [];
    var companyDealingPlans = [];
    var alloptinplans = [];
    var company_selected_plans = [];
    var employee_selected_plans = [];
    var company_paid_selected_plans = [];
    var company_covered_selected_plans = [];

    var selected_cc_plans = planSummary.filter(function (plan: any) {
      return plan.coveredByCompany == 1 || plan.categorization?.coveredByCompany == true;;
    });

    var selected_cp_plans = planSummary.filter(function (plan: any) {
      return plan.paidByCompany == 1 || plan.categorization?.paidByCompany == true;;
    });

    var selected_ep_plans = planSummary.filter(function (plan: any) {
      return plan.paidByEmployee == 1 || plan.categorization?.paidByEmployee == true;
    });

    // var selected_cc_plans = planSummary.forEach(element => {

    //   element.groups.forEach(group => {
    //     return group.categorization.coveredByCompany == 1;

    //   });

    // });

    // var selected_cp_plans = planSummary.forEach(element => {

    //   element.groups.forEach(group => {
    //     return group.categorization.paidByCompany == 1;

    //   });

    // });
    // var selected_ep_plans = planSummary.forEach(element => {

    //   element.groups.forEach(group => {
    //     return group.categorization.paidByEmployee == 1;

    //   });

    // });

    //this.consoleMessage(selected_cc_plans);
    //this.consoleMessage(selected_cp_plans);
    //this.consoleMessage(selected_ep_plans);

    console.log(selected_ep_plans)

    for (var cp = 0; cp < selected_cp_plans.length; cp++) {
      var current_cp_plan: any = selected_cp_plans[cp];

      // current_cp_plan.amount = parseFloat(
      //   this._decimalPipe.transform(current_cp_plan.amount, "1.2-2")
      // );
      current_cp_plan.amount = adjustWith2DecimalsForUI(current_cp_plan.amount)

      // //this.consoleMessage("rakesh",current_cp_plan.amount)
      current_cp_plan["amounts"] = {
        company: current_cp_plan.amount,
        employee: 0,
        total: current_cp_plan.amount,
      };
      current_cp_plan["categorization"] = {
        paidByCompany: true,
        coveredByCompany: false,
        paidByEmployee: false,
      };

      current_cp_plan["type"] = this.lang["100_Company_Paid"];

      current_cp_plan["companyCoveredBasePlan"] = {
        planId: 0, //0
        planName: "NA", //NA
        planLevelId: 0, //0
        amount: 0, //0
        tax: 0,
        total: 0,
      };
      current_cp_plan["employeeAmount"] = current_cp_plan["amounts"].employee;
      current_cp_plan["employeeAmountUI"] =
        "$" + current_cp_plan["amounts"].employee;
      current_cp_plan["companyAmount"] = current_cp_plan["amounts"].company;
      current_cp_plan["companyAmountUI"] =
        "$" + current_cp_plan["amounts"].company;
      current_cp_plan["planLevelName"] = current_cp_plan.groupName || ""; //Here

      if (
        current_cp_plan.id == 429 ||
        current_cp_plan.packageId == 8 ||
        current_cp_plan.planLevel == 16
      ) {
        alloptinplans.push(current_cp_plan);
      } else {
        all_selected_plans.push(current_cp_plan);
        company_selected_plans.push(current_cp_plan);
        company_paid_selected_plans.push(current_cp_plan);
      }
    }

    for (var cc = 0; cc < selected_cc_plans.length; cc++) {
      var current_cc_plan: any = selected_cc_plans[cc];

      current_cc_plan["amounts"] = {
        company: current_cc_plan.upgradeCalculation.baseplanPrice,
        employee: current_cc_plan.upgradeCalculation.deltaplanPrice,
        total: current_cc_plan.upgradeCalculation.combinedplanPrice,
      };

      current_cc_plan["companyCoveredBasePlan"] =
        current_cc_plan.upgradeCalculation.basePlanDetails;

      current_cc_plan["companyCoveredBasePlan"].companyTax =
        current_cc_plan.upgradeCalculation.baseplanTax;
      current_cc_plan["companyCoveredBasePlan"].companyTaxPercentage =
        current_cc_plan.upgradeCalculation.companyTaxPercentage;

      current_cc_plan["companyCoveredBasePlan"].employeeTax = 0;
      current_cc_plan["companyCoveredBasePlan"].employeeTaxPercentage =
        current_cc_plan.upgradeCalculation.employeeTaxPercentage;
      current_cc_plan["companyCoveredBasePlan"].employeeTaxEffect = 0;
      current_cc_plan["companyCoveredBasePlan"].employeeTaxPercentageEffect =
        "0%";
      current_cc_plan["companyCoveredBasePlan"].specialTax =
        current_cc_plan["companyCoveredBasePlan"].companyTax +
        current_cc_plan["companyCoveredBasePlan"].employeeTaxEffect;
      current_cc_plan["companyCoveredBasePlan"].tax2 =
        current_cc_plan["companyCoveredBasePlan"].companyTax;

      //this.consoleMessage(current_cc_plan);
      ///
      //deleta plan things
      current_cc_plan["employeeDeductions"] = {
        amount: current_cc_plan.upgradeCalculation.deltaplanPrice, //0
        amountUI: `$${current_cc_plan.upgradeCalculation.deltaplanPrice}`, //0
        tax: `${current_cc_plan.upgradeCalculation.deltaplanTax}`,
        total: `${current_cc_plan.upgradeCalculation.deltaplanTotal}`,
        taxUI: `$${current_cc_plan.upgradeCalculation.deltaplanTax}`,
        totalUI: `$${current_cc_plan.upgradeCalculation.deltaplanTotal}`,
      };

      // current_cc_plan["employeeDeductions"].amountUI =
      //   "$" + current_cc_plan["employeeDeductions"].amount;
      // current_cc_plan["employeeDeductions"].taxUI = current_cc_plan[
      //   "companyCoveredBasePlan"
      // ].taxUI.replace(
      //   current_cc_plan["companyCoveredBasePlan"].tax,
      //   current_cc_plan["employeeDeductions"].tax
      // );
      // current_cc_plan["employeeDeductions"].totalUI =
      //   "$" + current_cc_plan["employeeDeductions"].total;

      current_cc_plan["categorization"] = {
        paidByCompany: false,
        coveredByCompany: true,
        paidByEmployee: false,
      };

      current_cc_plan["type"] = current_cc_plan.upgradeCalculation.type;

      current_cc_plan["planGrandTotal"] = {
        amount: current_cc_plan.upgradeCalculation.combinedplanPrice, //0
        amountUI: `$${current_cc_plan.upgradeCalculation.combinedplanPrice}`, //0
        tax: current_cc_plan.upgradeCalculation.combinedplanTax,
        total: current_cc_plan.upgradeCalculation.combinedplanTotal,
        taxUI: `$${current_cc_plan.upgradeCalculation.combinedplanTax}`,
        totalUI: `$${current_cc_plan.upgradeCalculation.combinedplanTotal}`,
      };
      // planid,planname,planlevelid,planlevename,amount
      current_cc_plan.amount = adjustWith2DecimalsForUI(current_cc_plan["planGrandTotal"].amount);
      current_cc_plan.amountUI = adjustWith2DecimalsForUI(current_cc_plan["planGrandTotal"].amount);
      current_cc_plan.tax = current_cc_plan["planGrandTotal"].tax;
      current_cc_plan.taxUI = current_cc_plan["planGrandTotal"].taxUI;
      current_cc_plan.total = current_cc_plan["planGrandTotal"].total;
      current_cc_plan.totalUI = current_cc_plan["planGrandTotal"].totalUI;
      current_cc_plan.skuTotalPrice = current_cc_plan["planGrandTotal"].total;
      current_cc_plan.totalPrice = current_cc_plan["planGrandTotal"].total;

      //this.consoleMessage(current_cc_plan);

      current_cc_plan["companyShareTotal"] =
        current_cc_plan["companyCoveredBasePlan"].total;
      current_cc_plan["employeeShareTotal"] =
        current_cc_plan["employeeDeductions"].total;

      current_cc_plan["companySharePlanAmountTotal"] =
        current_cc_plan["companyCoveredBasePlan"].amount;
      current_cc_plan["employeeSharePlanAmountTotal"] =
        current_cc_plan["employeeDeductions"].amount;

      current_cc_plan["companyShareTaxTotal"] =
        current_cc_plan["companyCoveredBasePlan"].tax;
      current_cc_plan["employeeShareTaxTotal"] =
        current_cc_plan["employeeDeductions"].tax;

      current_cc_plan["employeeAmount"] = current_cc_plan["amounts"].employee;
      current_cc_plan["employeeAmountUI"] =
        "$" + current_cc_plan["amounts"].employee;
      current_cc_plan["companyAmount"] = current_cc_plan["amounts"].company;
      current_cc_plan["companyAmountUI"] =
        "$" + current_cc_plan["amounts"].company;
      //this.consoleMessage(current_cc_plan["amounts"].employee);
      //this.consoleMessage(current_cc_plan["amounts"].company);
      //this.consoleMessage(current_cc_plan["amounts"].total);
      this.coveredEmployeeShare += parseFloat(
        current_cc_plan["amounts"].employee
      );

      // this.coveredEmployeeShare = parseFloat(
      //   this._decimalPipe.transform(this.coveredEmployeeShare, "1.2-2" ||)
      // );
      this.coveredEmployeeShare = parseFloat(this.coveredEmployeeShare)
      all_selected_plans.push(current_cc_plan);
      company_selected_plans.push(current_cc_plan);
      company_covered_selected_plans.push(current_cc_plan);

      if (current_cc_plan.upgradeCalculation.payroll) {
        payrollCCplans.push(current_cc_plan);
      } else {
        directpayCCplans.push(current_cc_plan);
      }
      //this.consoleMessage("payrollCCplans",payrollCCplans)
      //this.consoleMessage("directpayCCplans",directpayCCplans)
    }

    for (var ep = 0; ep < selected_ep_plans.length; ep++) {
      var current_ep_plan: any = selected_ep_plans[ep];

      //this.consoleMessage(current_ep_plan)

      current_ep_plan.amount = adjustWith2DecimalsForUI(current_ep_plan.products[0].price);

      current_ep_plan["amounts"] = {
        company: 0,
        employee: current_ep_plan.total, //current_ep_plan.amount,(+tax)
        total: current_ep_plan.total, //current_ep_plan.amount,(+tax)
      };

      current_ep_plan["categorization"] = {
        paidByCompany: false,
        coveredByCompany: false,
        paidByEmployee: true,
      };

      current_ep_plan["type"] = this.lang["Voluntary_Opt_In"];

      current_ep_plan["companyCoveredBasePlan"] = {
        planId: 0, //0
        planName: "NA", //NA
        planLevelId: 0, //0
        amount: 0, //0
        tax: 0,
        total: 0,
      };
      current_ep_plan["employeeAmount"] = current_ep_plan["amounts"].employee;
      current_ep_plan["employeeAmountUI"] =
        "$" + current_ep_plan["amounts"].employee;
      current_ep_plan["companyAmount"] = current_ep_plan["amounts"].company;
      current_ep_plan["companyAmountUI"] =
        "$" + current_ep_plan["amounts"].company;
      //  current_cp_plan["planLevelName"] = current_ep_plan.groupName || ""

      //this.consoleMessage(current_ep_plan)

      console.log(current_ep_plan)

      //this.consoleMessage(current_ep_plan["amounts"].employee);
      //this.consoleMessage(this.paidEmployeeShare);
      // this.paidEmployeeShare = +current_ep_plan["amounts"].employee;
      this.paidEmployeeShare += parseFloat(current_ep_plan["amounts"].total);
      //this.consoleMessage(this.paidEmployeeShare);
      // this.paidEmployeeShare = parseFloat(
      //   this._decimalPipe.transform(this.paidEmployeeShare, "1.2-2")
      // );

      //this.consoleMessage(this.paidEmployeeShare);
      all_selected_plans.push(current_ep_plan);
      employee_selected_plans.push(current_ep_plan);
    }

    employeeDealingPlans = directpayCCplans.concat(employee_selected_plans);

    console.log(employeeDealingPlans)
    companyDealingPlans = company_paid_selected_plans.concat(payrollCCplans);
    console.log(this.iafgplansCheck)
    // employeeDealingPlans = employeeDealingPlans.concat(this.iafgplansCheck)  //hererakesh
    console.log(employeeDealingPlans)

    //this.consoleMessage("employeeDealingPlans",employeeDealingPlans)
    //this.consoleMessage("companyDealingPlans",companyDealingPlans)

    this.alloptinplans = alloptinplans;

    //this.consoleMessage(this.coveredEmployeeShare);
    //this.consoleMessage(this.paidEmployeeShare);

    sessionStorage.setItem("employeeshare", this.paidEmployeeShare);
    //this.consoleMessage(this._decimalPipe.transform(this.coveredEmployeeShare, "1.2-2"));
    //this.consoleMessage(this._decimalPipe.transform(this.paidEmployeeShare, "1.2-2"));

    var totalIAFG = 0;
    for (let i = 0; i < this.iafgplansCheck.length; i++) {
      totalIAFG += parseFloat(this.iafgplansCheck[i].skuTotalPrice);
    }



    this.totalEmployeeShare =
      this.coveredEmployeeShare + this.paidEmployeeShare;
    // this.cartDisplayAmount = this.paidEmployeeShare + totalIAFG;
    this.cartDisplayAmount = this.paidEmployeeShare;
    // this.totalEmployeeShare = parseFloat(
    //   this._decimalPipe.transform(this.totalEmployeeShare, "1.2-2")
    // );
    this.totalEmployeeShare = parseFloat(this.totalEmployeeShare)
    this.cartDisplayAmount = this._decimalPipe.transform(
      this.cartDisplayAmount,
      "1.2-2"
    );

    //this.cartDisplayAmount = floatWith2Decimals(this.cartDisplayAmount);

    //this.consoleMessage(this.totalEmployeeShare);

    /* if (employee_selected_plans.length > 0) {
      this.showPaymenthMethod = true;
      this.showPaymenthMethod1 = false;
      this.creditcardform = true;
      sessionStorage.setItem("paymentMethod", "CC");
      this.bankpaymentmethod = "CC";

    } else {


      this.showPaymenthMethod = false;
      this.showPaymenthMethod1 = true;
      this.creditcardform = false;
      sessionStorage.setItem("paymentMethod", "NONE");
    }*/

    console.log(this.iafgplansCheck)
    console.log(this.iafgplansCheck.length)
    if (employeeDealingPlans.length > 0 || this.iafgplansCheck.length > 0) {
      this.showPaymenthMethod = true;
      this.showPaymenthMethod1 = false;
      this.creditcardform = true;
      sessionStorage.setItem("paymentMethod", "CC");
      this.bankpaymentmethod = "CC";
      this.voluntryplansCheck = true
      this.paymentForm.get('paymentRadioCheck')?.setValue("CC");
    } else {
      this.showPaymenthMethod = false;
      this.showPaymenthMethod1 = true;
      this.creditcardform = false;
      this.voluntryplansCheck = false
      // this.bankpay =false;  //rakesh
      this.paymentForm.get('paymentRadioCheck')?.setValue('');
      sessionStorage.setItem("paymentMethod", "NONE");
    }

    this.directpayCCplansarr = directpayCCplans;
    this.payrollCCplansarr = payrollCCplans;
    this.companyDealingPlans = companyDealingPlans;
    this.employeeDealingPlansarr = employeeDealingPlans;

    console.log(this.employeeDealingPlansarr)


    this.payrollCCplansarr = this.payrollCCplansarr.sort(function (a: any, b: any) {
      return a.packageId - b.packageId;
    });
    this.companyDealingPlans = this.companyDealingPlans.sort(function (a: any, b: any) {
      return a.packageId - b.packageId;
    });
    this.companyDealingPlans = this.companyDealingPlans.sort(function (a: any, b: any) {
      return a.packageId - b.packageId;
    });

    if (this.payrollCCplansarr.length > 0) {
      this.upgradeplansCheck = true
    }
    //this.consoleMessage(this.companyDealingPlans)
    //this.consoleMessage(this.payrollCCplansarr)
    sessionStorage.setItem(
      "allSelectedPlans",
      JSON.stringify(all_selected_plans)
    );
    sessionStorage.setItem(
      "allSelectedOptPlans",
      JSON.stringify(alloptinplans)
    );
    //this.consoleMessage(all_selected_plans);
    //this.consoleMessage(company_selected_plans);

    //this.consoleMessage(company_paid_selected_plans);
    //this.consoleMessage(company_covered_selected_plans);
    //this.consoleMessage(employee_selected_plans);

    //company paid plans

    this.companySelectedPlans = company_paid_selected_plans;
    this.companyCoveredPlans = company_covered_selected_plans;
    // this.EmployeeSelectedPlans = employee_selected_plans.concat(this.iafgplansCheck);
    this.EmployeeSelectedPlans = employee_selected_plans
    this.allSelectedPlans = all_selected_plans;

    this.allSelectedPlans = this.allSelectedPlans.sort(function (a: any, b: any) {
      return a.packageId - b.packageId;
    });

    this.allPlansAmountTotal = sumBy(this.allSelectedPlans, "amount");
    //     this.allPlansAmountTotal =  this._decimalPipe.transform(
    //   this.allPlansAmountTotal,
    //   "1.2-2"
    // );
    this.allPlansTotal = sumBy(this.allSelectedPlans, "total");
    // this.allPlansTotal =  this._decimalPipe.transform(
    //   this.allPlansTotal,
    //   "1.2-2"
    // );
    this.allPlansTotaltax = sumBy(this.allSelectedPlans, "tax");
    // this.allPlansTotaltax =  this._decimalPipe.transform(
    //   this.allPlansTotaltax,
    //   "1.2-2"
    // );

    this.allCPPlansAmountTotal = sumBy(this.companySelectedPlans, "amount");
    this.allCPTaxTotal = sumBy(this.companySelectedPlans, "tax");
    this.companyPaidTotal = sumBy(this.companySelectedPlans, "total");
    //this.consoleMessage(this.companyPaidTotal);
    // this.companyPaidTotal =  this._decimalPipe.transform(
    //   this.companyPaidTotal,
    //   "1.2-2"
    // );
    //this.consoleMessage(this.companyPaidTotal);
    // for(var i=0;i<this.companySelectedPlans.length;i++){
    //   this.companyPaidTotal += this.companySelectedPlans[i].total

    //   this.companyPaidTotal = parseFloat(this._decimalPipe.transform(this.companySelectedPlans[i].total, "1.2-2"))

    // }
    // for(var i=0;i<this.companyCoveredPlans.length;i++){
    //   this.companyCoveredTotal += this.companyCoveredPlans[i].total
    //   this.companyCoveredTotal = parseFloat(this._decimalPipe.transform(this.companyCoveredTotal, "1.2-2"))

    // }
    this.allCCPlansAmountTotal = sumBy(this.companyCoveredPlans, "amount");
    this.allCCTaxTotal = sumBy(this.companyCoveredPlans, "tax");
    this.companyCoveredTotal = sumBy(this.companyCoveredPlans, "total");
    //this.consoleMessage(this.allCCPlansAmountTotal);
    //this.consoleMessage(this.allCCTaxTotal);
    //this.consoleMessage(this.companyCoveredTotal);
    this.companyCoveredCompanyShareTotal = sumBy(
      this.companyCoveredPlans,
      "companyShareTotal"
    );
    this.companyCoveredCompanySharePlanAmountTotal = sumBy(
      this.companyCoveredPlans,
      "companySharePlanAmountTotal"
    );
    this.companyCoveredCompanyShareTaxTotal = sumBy(
      this.companyCoveredPlans,
      "companyShareTaxTotal"
    );
    this.companyCoveredEmployeeShareTotal = sumBy(
      this.companyCoveredPlans,
      "employeeShareTotal"
    );
    this.companyCoveredEmployeeSharePlanAmountTotal = sumBy(
      this.companyCoveredPlans,
      "employeeSharePlanAmountTotal"
    );
    this.companyCoveredEmployeeShareTaxTotal = sumBy(
      this.companyCoveredPlans,
      "employeeShareTaxTotal"
    );

    this.payrollCCPlansAmountTotal = sumBy(payrollCCplans, "amount");
    this.payrollCCPlantaxTotal = sumBy(payrollCCplans, "tax");
    this.payrollCCPlangrandTotal = sumBy(payrollCCplans, "total");

    this.DirectCCpayPlanamountTotal = sumBy(directpayCCplans, "amount");
    this.DirectCCpayPlantaxTotal = sumBy(directpayCCplans, "tax");
    this.DirectCCpayPlangrandTotal = sumBy(directpayCCplans, "total");

    //The below includes Employee-voluntary-plans and company-covered-split with direct-pay-plans
    this.empDirectpayPlanamountTotal = sumBy(employeeDealingPlans, "amount");
    this.empDirectpayPlantaxTotal = sumBy(employeeDealingPlans, "tax");
    this.empDirectpayPlangrandTotal = sumBy(employeeDealingPlans, "total");

    //Company paid and company covered split with payroll
    this.companydealingplansTotal = sumBy(companyDealingPlans, "amount");
    this.companydealingplanstaxTotal = sumBy(companyDealingPlans, "tax");
    this.companydealingplansgrandTotal = sumBy(companyDealingPlans, "total");

    // for(var i=0;i<this.EmployeeSelectedPlans.length;i++){
    //   this.companyCoveredTotal += this.EmployeeSelectedPlans[i].total
    //   this.companyCoveredTotal = parseFloat(this._decimalPipe.transform(this.EmployeeSelectedPlans[i].total, "1.2-2"))

    // }
    //cp+cc(company)
    this.effectiveCompanyPlansAmountTotal =
      parseFloat(this.allCPPlansAmountTotal) +
      parseFloat(this.companyCoveredCompanySharePlanAmountTotal);
    this.effectiveCompanyPlansAmountTotal = this._decimalPipe.transform(
      this.effectiveCompanyPlansAmountTotal,
      "1.2-2"
    );
    this.effectiveCompanyTaxTotal =
      parseFloat(this.allCPTaxTotal) +
      parseFloat(this.companyCoveredCompanyShareTaxTotal);
    this.effectiveCompanyTaxTotal = this._decimalPipe.transform(
      this.effectiveCompanyTaxTotal,
      "1.2-2"
    );
    this.effectiveCompanyTotalContibution =
      parseFloat(this.companyPaidTotal) +
      parseFloat(this.companyCoveredCompanyShareTotal);
    this.effectiveCompanyTotalContibution = this._decimalPipe.transform(
      this.effectiveCompanyTotalContibution,
      "1.2-2"
    );


    this.allEPPlansAmountTotal = sumBy(this.EmployeeSelectedPlans, "amount");
    this.allEPTaxTotal = sumBy(this.EmployeeSelectedPlans, "tax");
    this.paidEmployeedTotal = sumBy(this.EmployeeSelectedPlans, "total");
    //this.consoleMessage(this.paidEmployeedTotal);
    // this.paidEmployeedTotal = this._decimalPipe.transform(
    //   this.paidEmployeedTotal,
    //   "1.2-2"
    // );
    // //this.consoleMessage(this.companyPaidTotal)
    // //this.consoleMessage(this.companyCoveredTotal)
    // //this.consoleMessage(this.paidEmployeedTotal)



    this.planssummarymainLife = JSON.parse(
      sessionStorage.getItem('plansummaryLife') || '[]'
    );

    console.log(this.planssummarymainLife)
    let total: any = 0;
    for (let i = 0; i < this.planssummarymainLife.length; i++) {
      total += parseFloat(this.planssummarymainLife[i].skuTotalPrice);
    }

    console.log(total)
    this.effectiveEmployeeTotalContibution =
      parseFloat(this.companyCoveredEmployeeShareTotal) +
      parseFloat(this.paidEmployeedTotal);
    this.effectiveEmployeeTotalContibution = adjustWith2DecimalsForUI(
      this.effectiveEmployeeTotalContibution
    );

    console.log(this.effectiveEmployeeTotalContibution)
    this.effectiveCompanyTotalContibution =
      parseFloat(this.companyCoveredCompanyShareTotal) +
      parseFloat(this.companyPaidTotal);

    this.effectiveCompanyTotalContibution = this._decimalPipe.transform(
      this.effectiveCompanyTotalContibution,
      "1.2-2"
    );

    //this.consoleMessage(this.companyCoveredPlans)
    //payrollDeduction setting

    let payrollDeduction = sessionStorage.getItem("payrollDeduction")
      ? sessionStorage.getItem("payrollDeduction")
      : "EMPLOYEE_CHOICE";

    if (
      this.companyCoveredPlans.length > 0 &&
      payrollDeduction == "EMPLOYEE_CHOICE"
    ) {
      // $("payrolldeduction-modal").show()
      // jQuery("#payrolldeduction-modal").modal("show");
    }
  }
  public calculateTaxforPlan(plan: any) {
    let plandetails;
    //this.consoleMessage(plan)
    //this.consoleMessage($(`#plancheck${plan.id}`))
    for (const cpPlan of plan) {
      //this.consoleMessage(plan)
      for (const product1 in cpPlan.productAddonss) {
        //this.consoleMessage(cpPlan.productAddonss[product1][0].id)
        // const dom: HTMLElement = this.elementRef.nativeElement;
        // const plan: any = dom.querySelector("#plancheck" + cpPlan.productAddonss[product1][0].id);
        // plandetails = $(
        //   "#plancheck" + cpPlan.productAddonss[product1][0].id
        // ).attr("data"); //rakesh


        plandetails = this.renderer.selectRootElement("#plancheck" + cpPlan.productAddonss[product1][0].id).attr("data");

        //this.consoleMessage(plandetails)
        let plandetailsobj = plandetails.split("##");

        let obj: any = {
          enrollmentDate: this.userForm.value.planEnrollmentDate
            ? this._CustomDatePipe.transform(this.userForm.value.planEnrollmentDate)
            : undefined,
          // "name":plandetailsobj[1]+"-"+plandetailsobj[2],
          name: plandetailsobj[14],
          details: plandetailsobj[0],
          packagename: plandetailsobj[0],
          groupName: plandetailsobj[1],
          amount: parseFloat(plandetailsobj[3].replace(/\,/g, "")),
          planCoverage: plandetailsobj[2],
          planPrice: parseFloat(plandetailsobj[3].replace(/\,/g, "")),
          amountUI: "$" + parseFloat(plandetailsobj[3].replace(/\,/g, "")),
          gst:
            plandetailsobj[4] == null ||
              plandetailsobj[4] == "" ||
              plandetailsobj[4] == undefined ||
              plandetailsobj[4] == "null"
              ? 0
              : parseFloat(plandetailsobj[4]),
          hst:
            plandetailsobj[5] == null ||
              plandetailsobj[5] == "" ||
              plandetailsobj[5] == undefined ||
              plandetailsobj[5] == "null"
              ? 0
              : parseFloat(plandetailsobj[5]),
          pst:
            plandetailsobj[6] == null ||
              plandetailsobj[6] == "" ||
              plandetailsobj[6] == undefined ||
              plandetailsobj[6] == "null"
              ? 0
              : parseFloat(plandetailsobj[6]),
          qst:
            plandetailsobj[17] == null ||
              plandetailsobj[17] == "" ||
              plandetailsobj[17] == undefined ||
              plandetailsobj[17] == "null"
              ? 0
              : parseFloat(plandetailsobj[17]),
          gstCheck:
            plandetailsobj[4] == null ||
              plandetailsobj[4] == "" ||
              plandetailsobj[4] == undefined ||
              plandetailsobj[4] == "null"
              ? false
              : true,
          hstCheck:
            plandetailsobj[5] == null ||
              plandetailsobj[5] == "" ||
              plandetailsobj[5] == undefined ||
              plandetailsobj[5] == "null"
              ? false
              : true,
          pstCheck:
            plandetailsobj[6] == null ||
              plandetailsobj[6] == "" ||
              plandetailsobj[6] == undefined ||
              plandetailsobj[6] == "null"
              ? false
              : true,
          qstCheck:
            plandetailsobj[17] == null ||
              plandetailsobj[17] == "" ||
              plandetailsobj[17] == undefined ||
              plandetailsobj[17] == "null"
              ? false
              : true,
          id: parseFloat(plandetailsobj[7]),
          fusebillPlanID:
            plandetailsobj[8] == null ||
              plandetailsobj[8] == "" ||
              plandetailsobj[8] == undefined ||
              plandetailsobj[8] == "null"
              ? 0
              : parseFloat(plandetailsobj[8]),
          planFrequencyID:
            plandetailsobj[9] == null ||
              plandetailsobj[9] == "" ||
              plandetailsobj[9] == undefined ||
              plandetailsobj[9] == "null"
              ? 0
              : parseFloat(plandetailsobj[9]),
          optIn: plandetailsobj[10] == "true" ? true : false,
          planname: plandetailsobj[14],
          planLevel: parseInt(plandetailsobj[15]),
          packageId: parseInt(plandetailsobj[16]),
          options: [],
          paidByCompany1: plandetailsobj[19],
          coveredByCompany1: plandetailsobj[20],
          paidByEmployee1: plandetailsobj[21],
          planLevel_parentId: plandetailsobj[12],
          disallowedPlanLevels: plandetailsobj[11],
        };

        ////this.consoleMessage(obj)
        let gstprice = 0;
        let hstprice = 0;
        let pstprice = 0;
        let qstprice = 0;

        if (obj.gst > 0) {
          gstprice = obj.planPrice * obj.gst;
          obj['gstPrice'] = gstprice
            ? this._decimalPipe.transform(gstprice, '1.2-2')
            : 0;
          obj['gstPrice'] = parseFloat(obj['gstPrice']);

        } else {
          obj["gstPrice"] = 0;
        }

        if (obj.hst > 0) {
          hstprice = obj.planPrice * obj.hst;

          obj["hstPrice"] = parseFloat(
            this._decimalPipe.transform(hstprice, "1.2-2") ?? '0'
          );
        } else {
          obj["hstPrice"] = 0;
        }

        if (obj.pst > 0) {
          pstprice = obj.planPrice * obj.pst;
          obj["pstPrice"] = parseFloat(
            this._decimalPipe.transform(pstprice, "1.2-2") ?? '0'
          );
        } else {
          obj["pstPrice"] = 0;
        }

        if (obj.qst > 0) {
          qstprice = obj.planPrice * obj.qst;
          obj["qstPrice"] = parseFloat(
            this._decimalPipe.transform(qstprice, "1.2-2") ?? '0'
          );
        } else {
          obj["qstPrice"] = 0;
        }

        obj["taxUI"] = "";
        if (obj["gstCheck"]) {
          obj["taxUI"] += "<span>";
          if (obj["gstPrice"] == 0) {
            obj["taxUI"] += "-";
          } else {
            obj["taxUI"] +=
              "$" +
              this._decimalPipe.transform(obj["gstPrice"], "1.2-2") +
              "&nbsp;(GST)";
          }
          obj["taxUI"] += "</span>";
        }

        if (obj["pstCheck"]) {
          obj["taxUI"] += "<span>";
          if (obj["pstPrice"] == 0) {
            obj["taxUI"] += "-";
          } else {
            obj["taxUI"] +=
              "$" +
              this._decimalPipe.transform(obj["pstPrice"], "1.2-2") +
              "&nbsp;(PST)";
          }
          obj["taxUI"] += "</span>";
        }

        if (obj["qstCheck"]) {
          obj["taxUI"] += "<span>";
          if (obj["qstPrice"] == 0) {
            obj["taxUI"] += "-";
          } else {
            obj["taxUI"] +=
              "$" +
              this._decimalPipe.transform(obj["qstPrice"], "1.2-2") +
              "&nbsp;(QST)";
          }
          obj["taxUI"] += "</span>";
        }

        if (obj["hstCheck"]) {
          obj["taxUI"] += "<span> ";
          if (obj["hstPrice"] == 0) {
            obj["taxUI"] += "-";
          } else {
            obj["taxUI"] +=
              "$" +
              this._decimalPipe.transform(obj["hstPrice"], "1.2-2") +
              "&nbsp;(HST)";
          }
          obj["taxUI"] += "</span>";
        }

        if (
          !obj["hstCheck"] &&
          !obj["gstCheck"] &&
          !obj["pstCheck"] &&
          !obj["qstCheck"]
        ) {
          obj["taxUI"] += "<span>-";

          obj["taxUI"] += "</span>";
        }

        // ////this.consoleMessage(object["taxUI"])

        obj["tax"] = parseFloat(
          this._decimalPipe.transform(
            gstprice + hstprice + pstprice + qstprice,
            "1.2-2"
          ) ?? '0'
        );

        //this.consoleMessage(obj.planPrice);
        //this.consoleMessage(gstprice);
        //this.consoleMessage(hstprice);
        //this.consoleMessage(pstprice);

        //this.consoleMessage(qstprice);
        let pricecal =
          obj.planPrice + gstprice + hstprice + pstprice + qstprice;
        obj["totalPrice"] = pricecal;

        obj["totalUI"] = "$" + pricecal;

        obj["total"] = pricecal;
        //this.consoleMessage(obj)
        return obj;
      }
    }
  }

  gotoPersonalInfoPage(): void {
    // console.log(this.previousTab);
    this.activeTab--;
    this.effectiveEmployeeTotalContibution = 0.00
  }

  gotoPlansPage(): void {
    this.activeTab--;
    this._viewportScroller.scrollToPosition([0, 0]);


    let data: any = JSON.parse(sessionStorage.getItem("plansummary") || '[]');
    let obj = {};

    if (data) {

      data.forEach((element: any, index: any) => {
        obj = {
          planid: element.id,
        };

        setTimeout(() => {

          const dom: HTMLElement = this.elementRef.nativeElement;
          const plan: any = dom.querySelector("#plancheck" + element.id);


          if (element.options && element.options.length > 0) {

            if (element.isBundle) {
              // const planCheck = this.renderer.selectRootElement("#plancheck" + element.id, true);


              // this.renderer.setProperty(planCheck, 'checked', true);
              const plan: any = dom.querySelector("#plancheck" + element.id + ".coverage" + element.coverage);
              this.renderer.setProperty(plan, 'checked', true);

              if (element.paidByCompany == 1) {
                const dom: HTMLElement = this.elementRef.nativeElement;
                // const plan: any = dom.querySelector("#plancheck" + element.id);




                if (element.coverage) {
                  const plan: any = dom.querySelector("#plancheck" + element.id + ".coverage" + element.coverage);
                  if (plan) {
                    plan.disabled = true;
                  }
                }
                else {
                  const plan = this.renderer.selectRootElement("#plancheck" + element.id, true);
                  if (plan) {
                    plan.disabled = true;
                  }
                }
              }


            } else {
              element.products.forEach((products: any, index: any) => {
                // const planCheck = this.renderer.selectRootElement("#plancheck" + products.planLevel, true);


                // this.renderer.setProperty(planCheck, 'checked', true);
                if (element.coverage) {
                  const plan: any = dom.querySelector("#plancheck" + products.planLevel + ".coverage" + element.coverage);


                  this.renderer.setProperty(plan, 'checked', true);
                }
                else {
                  const planCheck = this.renderer.selectRootElement("#plancheck" + products.planLevel, true);
                  this.renderer.setProperty(planCheck, 'checked', true);

                }

                if (element.paidByCompany == 1) {
                  const dom: HTMLElement = this.elementRef.nativeElement;
                  // const plan: any = dom.querySelector("#plancheck" + products.planLevel);


                  if (element.coverage) {
                    const plan: any = dom.querySelector("#plancheck" + products.planLevel + ".coverage" + element.coverage);
                    if (plan) {
                      plan.disabled = true;
                    }
                  }
                  else {
                    const planCheck = this.renderer.selectRootElement("#plancheck" + products.planLevel, true);
                    if (plan) {
                      plan.disabled = true;
                    }
                  }
                }


              })
            }

          }

          else {

            if (element.isBundle) {
              const plan: any = dom.querySelector("#plancheck" + element.id + ".coverage" + element.coverage);


              this.renderer.setProperty(plan, 'checked', true);
              // const planCheck = this.renderer.selectRootElement("#plancheck" + element.id, true);

              // // console.log(planCheck)
              // this.renderer.setProperty(planCheck, 'checked', true);

              if (element.paidByCompany == 1) {
                const dom: HTMLElement = this.elementRef.nativeElement;
                if (element.coverage) {
                  const plan: any = dom.querySelector("#plancheck" + element.id + ".coverage" + element.coverage);
                  if (plan) {
                    plan.disabled = true;
                  }
                }
                else {
                  const plan = this.renderer.selectRootElement("#plancheck" + element.id, true);
                  if (plan) {
                    plan.disabled = true;
                  }
                }
              }


            }
            else {
              element.products.forEach((products: any, index: any) => {
                // $("#plancheck" + products.id).click();
                // const planCheck = this.renderer.selectRootElement("#plancheck" + products.planLevel, true);
                //   console.log(planCheck)
                // this.renderer.setProperty(planCheck, 'checked', true);
                if (element.coverage) {
                  const plan: any = dom.querySelector("#plancheck" + products.planLevel + ".coverage" + element.coverage);


                  this.renderer.setProperty(plan, 'checked', true);
                }
                else {
                  const planCheck = this.renderer.selectRootElement("#plancheck" + products.planLevel, true);
                  this.renderer.setProperty(planCheck, 'checked', true);

                }
                if (element.paidByCompany == 1) {
                  const dom: HTMLElement = this.elementRef.nativeElement;
                  if (element.coverage) {
                    const plan: any = dom.querySelector("#plancheck" + products.planLevel + ".coverage" + element.coverage);
                    if (plan) {
                      plan.disabled = true;
                    }
                  }
                  else {
                    const plan = this.renderer.selectRootElement("#plancheck" + products.planLevel, true);
                    if (plan) {
                      plan.disabled = true;
                    }
                  }
                }
              })
            }
          }

          // $("#plancheck" + element.id).prop("checked", true);
          // plan.checked =true
        }, 100);

        // this.plancheckarray.push(element.id)
      });

    }
    let dataLife: any = JSON.parse(sessionStorage.getItem("plansummaryLife") || '[]');

    if (dataLife) {

      dataLife.forEach((element: any, index: any) => {

        setTimeout(() => {

          const dom: HTMLElement = this.elementRef.nativeElement;
          // const plan: any = dom.querySelector("#planCheckLife" + element.planCoverage+"-"+coverage);



          element.products.forEach((products: any, index: any) => {
            // $("#plancheck" + products.id).click();
            if (element.coverage == 'MEMBER') {
              element.coverage = 'MEMBER'
              const planCheck = this.renderer.selectRootElement("#planCheckLife" + element.planCoverage + "-" + element.coverage, true);

              this.renderer.setProperty(planCheck, 'checked', true);
            } else {
              element.coverage = 'SPOUSE'
              const planCheck = this.renderer.selectRootElement("#planCheckLife" + element.planCoverage + "-" + element.coverage, true);

              this.renderer.setProperty(planCheck, 'checked', true);
              const planCheck1 = this.renderer.selectRootElement("#planCheckLife" + element.planCoverage + "-" + 'MEMBER', true);

              this.renderer.setProperty(planCheck1, 'checked', true);
            }

          })


          // $("#plancheck" + element.id).prop("checked", true);
          // plan.checked =true
        }, 100);

        // this.plancheckarray.push(element.id)
      });

    }
  }
  // paymentpage(): void{
  //   if (this.activeTab < this.tabs.length - 1) {
  //     this.previousTab = this.activeTab;
  //     this.activeTab++;
  //   }
  // }

  public paymentpage(tabId: number) {
    this.bankpayForm.reset()
    // this.clearPadSignature();
    // this.clearCreditCardSignature();
    // this.clearPadPlansConfirmation();

    console.log(this.paymentForm.get('paymentRadioCheck')?.value)


    let benficiryData = JSON.parse(sessionStorage.getItem('addbenificaryData') || '[]');
    let inputData = JSON.parse(sessionStorage.getItem('Data') || '[]');
    let Data = JSON.parse(sessionStorage.getItem('plansummarymain') || '[]');
    let iafgData = JSON.parse(sessionStorage.getItem('plansummaryLife') || '[]');
    let phrPlanSelected = Data.filter((p: any) => p.packageId == 10);
    let healthPlanSelected = Data.filter((p: any) => p.packageId == 1);
    let iafgDataSelectedPlan = iafgData.filter((p: any) => p.packageId == 13);
    let iafgTermplanCheck = iafgData.filter((p: any) => p.planLevel == 44);
    let benficiaryList = JSON.parse(sessionStorage.getItem('addbenificaryData') || '[]')
    this.paymentemail = inputData.email || this.userForm.get('email')?.value
    this.paymentFirstName = this.capitalize(inputData.firstName) || this.userForm.get('firstName')?.value;
    this.paymentLastName = this.capitalize(inputData.lastName) || this.userForm.get('lastName')?.value
    let iafgTermplanSpouseCheck = iafgData.filter((p: any) => p.coverage == 'MEMBER & SPOUSE');





    if (iafgTermplanCheck && iafgTermplanCheck.length > 0) {

      if (this.addbenificaryData.length > 0) {
        this.benficiary = false;

        const validateBeneficiaries = this.addbenificaryData.reduce((acc: any, obj: any) => {
          const compositeKey = `${obj.customerType}_${obj.type}`;
          if (!acc[compositeKey]) {
            acc[compositeKey] = 0;
          }
          acc[compositeKey] += parseFloat(obj.percentage);
          return acc;
        }, {});

        let beneficiaryError = "";
        for (const key in validateBeneficiaries) {
          if (validateBeneficiaries.hasOwnProperty(key)) {
            const [customerType, type] = key.split("_");
            console.log(`The customerType ${customerType} and type ${type} with perc: ${validateBeneficiaries[key]}`);

            if (validateBeneficiaries[key] != 100) {
              beneficiaryError += `CustomerType ${customerType} and type ${type} has percentage issue. \n`
            }
          }
        }




        if (beneficiaryError != "") {
          this.benficiaryCount = true
          this.benficiaryCountMessage = this.lang.Distributed_percentages_must_equal_100_and_not_more
          return false;
        }

        else {
          this.benficiary = false
          if (iafgTermplanSpouseCheck.length > 0) {

            if (sessionStorage.getItem("Spousesignature") || "") {
              // this.gotosumamrypageLogic()
            } else {
              this.showSpouseSignature = true
              this.signatureNeeded = this.signaturePad.isEmpty();


            }
          }
        }


      }


      else {
        this.benficiary = true;
        if (this.addbenificaryData.length > 0) {

        } else {
          this.showBenificiaryForm = true
        }


        return false;

      }

    } else {
      this.addbenificaryData =[]
      // this.gotosumamrypageLogic()
    }
    if (inputData.provincial_health_coverage == false) {

      if (phrPlanSelected.length > 0) {
        if (healthPlanSelected.length > 0) {
          if (iafgDataSelectedPlan.length > 0) {

            if (healthPlanSelected.length > 0) {

              if (iafgTermplanCheck.length > 0) {

                var total = 0;
                for (let i = 0; i < benficiryData.length; i++) {
                  total += parseFloat(benficiryData[i].percentage);
                }

                if (!this.beneficiaryError) {
                  this.benficiary = false
                  this.showbenificaryTable = false;
                  this.gotosumamrypageLogic()
                } else {
                  this.benficiary = true
                  this.showbenificaryTable = true;
                  this.showaddmorebenficiary = true;

                }

              }
              else {
                this.gotosumamrypageLogic()
              }



            }
            else {
              this.ShowModelPopup = true
              this.ShowModelPopupMessage = this.lang.Please_select_a_Health_Dental_Insurance_Plan_along_with_a_Life_or_Critical_Insurance_Plan;
              this.ShowModelPopupTitle = this.lang.Info
            }
          }
          else {
            this.gotosumamrypageLogic()
          }
        }
        else {
          this.ShowModelPopup = true
          this.ShowModelPopupMessage = this.lang.Please_select_a_Provincial_Health_Replacement_Plan_along_with_a_Health_Dental_Insurance_Plan;
          this.ShowModelPopupTitle = this.lang.Info
        }

      }


      else {
        if (healthPlanSelected.length > 0) {
          this.ShowModelPopup = true
          this.ShowModelPopupMessage = this.lang.Please_select_a_Provincial_Health_Replacement_Plan;
          this.ShowModelPopupTitle = this.lang.Info
        }
        else {
          this.ShowModelPopup = true
          this.ShowModelPopupMessage = this.lang.Please_select_a_Provincial_Health_Replacement_Plan_along_with_a_Health_Dental_Insurance_Plan;
          this.ShowModelPopupTitle = this.lang.Info
        }


      }
    }
    else {

      if (iafgDataSelectedPlan.length > 0) {

        if (healthPlanSelected.length > 0) {

          if (iafgTermplanCheck.length > 0) {

            var total = 0;
            for (let i = 0; i < benficiryData.length; i++) {
              total += parseFloat(benficiryData[i].percentage);
            }

            if (!this.beneficiaryError) {
              this.benficiary = false
              this.showbenificaryTable = false;


              this.gotosumamrypageLogic()
            } else {
              this.benficiary = true
              this.showbenificaryTable = true;
              this.showaddmorebenficiary = true;

            }

          }
          else {

            this.gotosumamrypageLogic()
          }



        }
        else {
          // this.ShowModelPopup = true
          // this.ShowModelPopupMessage = this.lang.Please_select_a_Health_Dental_Insurance_Plan_along_with_a_Life_or_Critical_Insurance_Plan;
          // this.ShowModelPopupTitle = this.lang.Info
          this.gotosumamrypageLogic()
        }
      }
      else {
        this.gotosumamrypageLogic()
      }

    }
  }

  public closeConfirmModel(){
    this.ConfirmModel = false
  }

  public gotosumamrypageLogic() {

    let iafgData = JSON.parse(sessionStorage.getItem('plansummaryLife') || '[]');

    let iafgTermplanCheck = iafgData.filter((p: any) => p.planLevel == 45);
    let iafgTermLifeplanCheck = iafgData.filter((p: any) => p.planLevel == 44);

    if(iafgData.length>1){
      this.ConfirmModel =true
    }


    if(iafgTermplanCheck.length>0){
      this.criticalplancheck =true
    }
    else{
      this.criticalplancheck =false
    }

    if(iafgTermLifeplanCheck.length>0){
      this.termLifeplancheck =true
    }
    else{
      this.termLifeplancheck =false
    }

    let inputData = JSON.parse(sessionStorage.getItem('Data') || '[]');
    let Data = JSON.parse(sessionStorage.getItem('plansummarymain') || '[]');
    this.previousTab = this.activeTab;
    this.activeTab++;
    this.visitedTabs.add(this.previousTab);
    setTimeout(() => {
      sessionStorage.setItem('pageName', 'Enrollment Summary');
    }, 10);
    this.planssummarymain = JSON.parse(
      sessionStorage.getItem('plansummarymain') || '[]'
    );

    this.planssummaryopt = JSON.parse(
      sessionStorage.getItem('plansummaryopt') || '[]'
    );
    let array: any = [];

    this.planSummaryMainResult =
      array.sort((a: any, b: any) => a.id - b.id) || '';

    this.planssummarymain =
      this.planssummarymain.sort(
        (a: any, b: any) => a.packageId - b.packageId
      ) || '';
    this.finalenrollemntsummary = this.planssummarymain;

    const combinedArray: any = [];
    const groupedByParent: any = {};
    //  this.paymentForm.get('FirstName')

    for (const obj of this.planSummaryMainResult) {
      //this.consoleMessage(obj)

      const name = obj.packageName;

      const products = obj.products;
      if (!groupedByParent[name]) {
        groupedByParent[name] = [];
      }
      groupedByParent[name] = groupedByParent[name].concat(products);
    }
    // Push grouped child arrays into combinedArray

    for (const name in groupedByParent) {
      combinedArray.push({ name, products: groupedByParent[name] });
    }

    this.planSummaryMainResult = combinedArray;

    //this.consoleMessage(this.planSummaryMainResult)

    // let inputData = JSON.parse(sessionStorage.getItem("Data"));
    // let Data = JSON.parse(sessionStorage.getItem("plansummarymain"))

    let plansData = {
      customerName: inputData.firstName + '' + inputData.lastName,
      contactNum: inputData.phone_number,
      dob: inputData.date_of_birth,
      email: inputData.email,
      process: 'planSelection',
      request: { plans: Data },
    };

    //this.consoleMessage(plansData)
    // this._CorporateService.savePlan(plansData).subscribe((result: any) => {
    // })

  }

  public paymentpage12(tabId: number) {

    // this.clearPadSignature();
    // this.clearCreditCardSignature();
    // this.clearPadPlansConfirmation();

    console.log(this.paymentForm.get('paymentRadioCheck')?.value)

    let inputData = JSON.parse(sessionStorage.getItem('Data') || '[]');
    let Data = JSON.parse(sessionStorage.getItem('plansummarymain') || '[]');

    this.paymentemail = inputData.email || this.userForm.get('email')?.value

    this.paymentemail = inputData.email || this.userForm.get('email')?.value
    this.paymentFirstName = this.capitalize(inputData.firstName) || this.userForm.get('firstName')?.value;
    this.paymentLastName = this.capitalize(inputData.lastName) || this.userForm.get('lastName')?.value



    if (inputData.provincial_health_coverage == false) {
      let phrPlanSelected = Data.filter((p: any) => p.packageId == 10);
      let healthPlanSelected = Data.filter((p: any) => p.packageId == 1);

      if (phrPlanSelected.length > 0) {
        if (this.activeTab < this.tabs.length - 1) {
          this.previousTab = this.activeTab;
          this.activeTab++;
          this.visitedTabs.add(this.previousTab);
        }
        this.router.events.subscribe((event) => {
          if (event instanceof NavigationEnd) {
            this.scrollContainer.nativeElement.scrollTo(0, 0);
          }
        });
        this.imagedisplay = false;
        setTimeout(() => {
          sessionStorage.setItem('pageName', 'Enrollment Summary');
        }, 10);
        this.planssummarymain = JSON.parse(
          sessionStorage.getItem('plansummarymain') || '[]'
        );

        this.planssummaryopt = JSON.parse(
          sessionStorage.getItem('plansummaryopt') || '[]'
        );
        let array: any = [];

        this.planSummaryMainResult =
          array.sort((a: any, b: any) => a.id - b.id) || '';

        this.planssummarymain =
          this.planssummarymain.sort(
            (a: any, b: any) => a.packageId - b.packageId
          ) || '';
        this.finalenrollemntsummary = this.planssummarymain;

        const combinedArray: any = [];
        const groupedByParent: any = {};
        //  this.paymentForm.get('FirstName')

        for (const obj of this.planSummaryMainResult) {
          //this.consoleMessage(obj)

          const name = obj.packageName;

          const products = obj.products;
          if (!groupedByParent[name]) {
            groupedByParent[name] = [];
          }
          groupedByParent[name] = groupedByParent[name].concat(products);
        }
        // Push grouped child arrays into combinedArray

        for (const name in groupedByParent) {
          combinedArray.push({ name, products: groupedByParent[name] });
        }

        this.planSummaryMainResult = combinedArray;

        let plansData = {
          customerName: inputData.firstName + '' + inputData.lastName,
          contactNum: inputData.phone_number,
          dob: inputData.date_of_birth,
          email: inputData.email,
          process: 'planSelection',
          request: { plans: Data },
        };

        //this.consoleMessage(plansData)
        // this.loginService.savePlan(plansData).subscribe((result: any) => {
        //   //this.consoleMessage(result)
        // })
        // $("#planSelectionInfo").css("display", "block");
      } else {
        if (healthPlanSelected.length > 0) {

          this.ShowModelPopup = true
          this.ShowModelPopupMessage = this.lang.Please_select_a_Provincial_Health_Replacement_Plan_along_with_a_Health_Dental_Insurance_Plan;
          this.ShowModelPopupTitle = this.lang.Info
        }
        else {
          this.ShowModelPopup = true
          this.ShowModelPopupMessage = this.lang.Please_select_a_Provincial_Health_Replacement_Plan;
          this.ShowModelPopupTitle = this.lang.Info
        }
        // else {
        //   this.imagedisplay =false
        //   setTimeout(() => {
        //     // this.staticTabs.tabs[tabId].active = true;
        //     sessionStorage.setItem('pageName', 'Enrollment Summary');
        //   }, 10);
        //   this.planssummarymain = JSON.parse(
        //     sessionStorage.getItem('plansummarymain') || '[]'
        //   );

        //   this.planssummaryopt = JSON.parse(
        //     sessionStorage.getItem('plansummaryopt') || '[]'
        //   );
        //   let array: any = [];

        //   // for(let i=0;i<this.planssummarymain.length;i++){

        //   //   array.push(this.planssummarymain[i].products)

        //   // }

        //   this.planSummaryMainResult =
        //     array.sort((a: any, b: any) => a.id - b.id) || '';

        //   this.planssummarymain =
        //     this.planssummarymain.sort(
        //       (a: any, b: any) => a.packageId - b.packageId
        //     ) || '';
        //   this.finalenrollemntsummary = this.planssummarymain;

        //   const combinedArray: any = [];
        //   const groupedByParent: any = {};
        //   //  this.paymentForm.get('FirstName')

        //   for (const obj of this.planSummaryMainResult) {
        //     //this.consoleMessage(obj)

        //     const name = obj.packageName;

        //     const products = obj.products;
        //     if (!groupedByParent[name]) {
        //       groupedByParent[name] = [];
        //     }
        //     groupedByParent[name] = groupedByParent[name].concat(products);
        //   }
        //   // Push grouped child arrays into combinedArray

        //   for (const name in groupedByParent) {
        //     combinedArray.push({ name, products: groupedByParent[name] });
        //   }

        //   this.planSummaryMainResult = combinedArray;

        //   //this.consoleMessage(this.planSummaryMainResult)

        //   // let inputData = JSON.parse(sessionStorage.getItem("Data"));
        //   // let Data = JSON.parse(sessionStorage.getItem("plansummarymain"))

        //   let plansData = {
        //     customerName: inputData.firstName + '' + inputData.lastName,
        //     contactNum: inputData.phone_number,
        //     dob: inputData.date_of_birth,
        //     email: inputData.email,
        //     process: 'planSelection',
        //     request: { plans: Data },
        //   };

        //   //this.consoleMessage(plansData)
        //   // this._CorporateService.savePlan(plansData).subscribe((result: any) => {
        //   //   //this.consoleMessage(result)
        //   // })
        // }
      }
    }

    else {
      this.previousTab = this.activeTab;
      this.activeTab++;
      this.visitedTabs.add(this.previousTab);
      setTimeout(() => {
        sessionStorage.setItem('pageName', 'Enrollment Summary');
      }, 10);
      this.planssummarymain = JSON.parse(
        sessionStorage.getItem('plansummarymain') || '[]'
      );

      this.planssummaryopt = JSON.parse(
        sessionStorage.getItem('plansummaryopt') || '[]'
      );
      let array: any = [];

      this.planSummaryMainResult =
        array.sort((a: any, b: any) => a.id - b.id) || '';

      this.planssummarymain =
        this.planssummarymain.sort(
          (a: any, b: any) => a.packageId - b.packageId
        ) || '';
      this.finalenrollemntsummary = this.planssummarymain;

      const combinedArray: any = [];
      const groupedByParent: any = {};
      //  this.paymentForm.get('FirstName')

      for (const obj of this.planSummaryMainResult) {
        //this.consoleMessage(obj)

        const name = obj.packageName;

        const products = obj.products;
        if (!groupedByParent[name]) {
          groupedByParent[name] = [];
        }
        groupedByParent[name] = groupedByParent[name].concat(products);
      }
      // Push grouped child arrays into combinedArray

      for (const name in groupedByParent) {
        combinedArray.push({ name, products: groupedByParent[name] });
      }

      this.planSummaryMainResult = combinedArray;

      //this.consoleMessage(this.planSummaryMainResult)

      // let inputData = JSON.parse(sessionStorage.getItem("Data"));
      // let Data = JSON.parse(sessionStorage.getItem("plansummarymain"))

      let plansData = {
        customerName: inputData.firstName + '' + inputData.lastName,
        contactNum: inputData.phone_number,
        dob: inputData.date_of_birth,
        email: inputData.email,
        process: 'planSelection',
        request: { plans: Data },
      };

      //this.consoleMessage(plansData)
      // this._CorporateService.savePlan(plansData).subscribe((result: any) => {
      // })
    }
  }
  public checkbankdetails(event: any) {
    // this.bankdetailsverify =  true
    if (event.target.value == 'yes') {
    } else {
      this.bankpayForm.reset();
      this.imagedisplay = false;
      sessionStorage.removeItem('fileurl');
      sessionStorage.removeItem('filename');
      // this.bankpayForm.get('bankcode').value
    }

    this.verifybankdetails = false;
  }
  public verifybank() {
    // alert()

    // this.isVisible =0

    this.banknameres = '';

    let inputData = {
      bankCode: this.bankpayForm.controls['banknumber'].value,
      branchCode: this.bankpayForm.controls['transitnumber'].value,
    };

    this._CorporateService.getbanknames(inputData).subscribe(
      (result: any) => {
        if (result.status == '200') {
          // this.bankdetailsverify =false
          this.verifybutton = false;
          this.bankverifyDetails = result.data;
          this.banknameres =
            result.data.bank.name +
            '\n' +
            result.data.address.split(',').join('\n');
          // this.invalidbankdetails = false;
        } else if (result.status == '202') {
          // this.toastrService.warning(result.message, '');
          this.bankerrormsg = result.message;
          // this.invalidbankdetails = true;
          this.verifybankdetails = false;
        } else {
          // this.toastrService.warning(result.message, '');
          this.bankerrormsg = result.message;
          // this.invalidbankdetails = true;
          this.verifybankdetails = false;
        }
      },
      (error) => {
        alert('Please check the provided bank details');
        // this.toastrService.warning(
        //   "Please check the provided bank details",
        //   ""
        // );
      }
    );
  }
  public touchedmsg() {
    // this.invalidbankdetails = false;
    this.bankerrormsg = ''
    if (
      this.bankpayForm.controls['banknumber'].value?.length == 3 &&
      this.bankpayForm.controls['transitnumber'].value?.length == 5 &&
      (this.bankpayForm.controls['accountnumber'].value?.length ?? 0) >= 5
    ) {
      this.verifybankdetails = true;
      this.verifybutton = true;
    } else {
      this.verifybankdetails = false;
      this.verifybutton = false;
    }
  }
  public voidcheckupload(event: any) {
    this.imagedisplay = false
    sessionStorage.removeItem('fileurl');
    sessionStorage.removeItem('filename');
    sessionStorage.removeItem('signature');
    var fileExtension = '.' + event.target.files[0].name.split('.').pop();

    // event.target.files[0].name ="void_cheque_"+Date.now()+fileExtension

    // "void_cheque_"+Date.now()+fileExt
    ////this.consoleMessage(event)
    this.isFileUploaded = true;
    // alert(event.target.files[0].name.replace(event.target.files[0].name,"void.pdf"))
    this.bankfile = event.target.files[0];
    this.Shared.setMessage(this.bankfile);
    // this.bankfile.name = (this.bankfile.name,"void_cheque_"+Date.now()+fileExtension)
    // alert(fileExtension.toLowerCase())
    var allowedMimes = [
      'image/jpeg',
      'image/pjpeg',
      'image/png',
      'application/pdf',
    ];

    let error = false;

    let message = '';

    if (allowedMimes.includes(this.bankfile.type)) {
      if (this.bankfile.size <= 1000 * 1024) {
        //300KB

        error = false;
      } else {
        error = true;
        // this.ShowModelPopup =true
        this.ShowModelPopupMessage = this.lang.File_size_is_too_large_maximum_file_size_is_1MB;
        // this.ShowModelPopupTitle =this.lang.Info
      }
    } else {
      error = true;
      // this.ShowModelPopup =true
      this.ShowModelPopupMessage = this.lang.Invalid_file_type_Only_jpg_png_image_pdf_files_are_allowed;
      // this.ShowModelPopupTitle =this.lang.Info
    }

    if (error) {
      // this.showDeleteModal = true;
      // this.modalMessage = message;

      this.ShowModelPopup = true
      this.ShowModelPopupMessage = this.ShowModelPopupMessage
      this.ShowModelPopupTitle = this.lang.Info
      this.bankpayForm.get('voidcheck')?.reset();
      this.isFileUploaded = true;
    } else {
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);

      //this.consoleMessage(reader)

      if (event.target.files[0].type == 'application/pdf') {
        // alert("1")
        this.pdfview = true;
        this.normalview = false;
      } else {
        this.pdfview = false;
        this.normalview = true;
      }

      reader.onload = (_event) => {
        this.imagedisplay = true
        this.imagedisplay = reader.result;
      };

      //     if(this.imagedisplay ==false){
      //       Swal.fire({
      //         title: this.lang.Info,
      //         html: "Invalid file uploaded, please try again",

      //         showCancelButton: false,
      //         confirmButtonText: "Ok",
      //       }).then((result) => {
      //         if (result.value) {

      // this.bankpayForm.get('voidcheck').reset();
      // this.isFileUploaded =true
      //         } else {
      //         }
      //       });
      //     }
    }

    // sessionStorage.setItem("files", event.target.files[0]);
    // localStorage.setItem("files", JSON.stringify(event.target.files[0]));

    // sessionStorage.setItem("voidCheque",this.bankfile)
  }

  public homeaddresscheck(event: any) {
    if (event.target.checked == true) {
      sessionStorage.setItem('homeAddress', 'true');

      // this.aptcheck = this.userForm.get("apt_suite")?.value;
      this.bankpayForm
        .get('bankstreetaddress')
        ?.setValue(this.userForm.controls['street_address_line1'].value);
      this.bankpayForm
        .get('bankstreetaddressline2')
        ?.setValue(this.userForm.controls['street_address_line2'].value);
      this.bankpayForm
        .get('bankcity')
        ?.setValue(this.userForm.controls['city'].value);
      this.bankpayForm
        .get('bankprovince')
        ?.setValue(this.userForm.controls['province'].value);
      this.bankpayForm
        .get('bankpostalcode')
        ?.setValue(this.userForm.controls['postal_code'].value);
    } else {
      sessionStorage.setItem('homeAddress', 'false');
      // this.bankpayForm?.setValue({'bankstreetaddress':'','bankstreetaddressline2':'','bankcity':'','bankprovince':'','bankpostalcode':''})
      this.bankpayForm.get('bankstreetaddress')?.setValue('');
      this.bankpayForm.get('bankstreetaddressline2')?.setValue('');
      this.bankpayForm.get('bankcity')?.setValue('');
      this.bankpayForm.get('bankprovince')?.setValue('');
      this.bankpayForm.get('bankpostalcode')?.setValue('');
    }
  }
  public homeaddresscheckCC(event: any) {
    if (event.target.checked == true) {
      sessionStorage.setItem('homeAddress', 'true');

      // this.aptcheck = this.userForm.get("apt_suite")?.value;
      this.paymentForm
        .get('streetaddress')
        ?.setValue(this.userForm.controls['street_address_line1'].value);
      this.paymentForm
        .get('streetaddressline2')
        ?.setValue(this.userForm.controls['street_address_line2'].value);
      this.paymentForm
        .get('city')
        ?.setValue(this.userForm.controls['city'].value);
      this.paymentForm
        .get('province')
        ?.setValue(this.userForm.controls['province'].value);
      this.paymentForm
        .get('postalcode')
        ?.setValue(this.userForm.controls['postal_code'].value);
    } else {
      sessionStorage.setItem('homeAddress', 'false');
      // this.bankpayForm?.setValue({'bankstreetaddress':'','bankstreetaddressline2':'','bankcity':'','bankprovince':'','bankpostalcode':''})
      this.paymentForm.get('streetaddress')?.setValue('');
      this.paymentForm.get('streetaddressline2')?.setValue('');
      this.paymentForm.get('city')?.setValue('');
      this.paymentForm.get('province')?.setValue('');
      this.paymentForm.get('postalcode')?.setValue('');
    }
  }

  public showmodelsign() {
    // console.log("tets1")
    this.generatePADaggrement = ""

    let inputData = {
      "url": sessionStorage.getItem("fileurl"),
      "filename": sessionStorage.getItem("filename"),
      "signature": sessionStorage.getItem("signature")
    }



    this._CorporateService.padsignature(inputData).subscribe((result: any) => {
      if (result.status == "200") {
        // jQuery("#PADpafaggrement1-modal").modal("show");
        this.padAggrementFinalModel = true
        this.generatePADaggrement = result.data.url
        this.signaturestatus = false
        this.agreestatus = true
        sessionStorage.setItem("failed", "")

      } else {
        this.failedarray.push("updatePAD")
        //this.consoleMessage(this.failedarray)
        sessionStorage.setItem("failed", JSON.stringify(this.failedarray))
        // Swal.fire({
        //   title: this.lang.Error,
        //   text: result.message ? result.message : result.error,
        //   icon: "warning",
        //   width: "30%",
        //   showCancelButton: false,
        //   confirmButtonText: "Ok",
        // }).then((result) => {
        //   if (result.value) {
        //   } else {
        //     // this.router.navigate(['pages/signup-form']);
        //   }
        // });
      }

    })



  }
  public showpadaggrement() {
    if (
      sessionStorage.getItem('fileurl') &&
      sessionStorage.getItem('filename') &&
      sessionStorage.getItem('signature')
    ) {
      this.showmodelsign()

      // console.log("sdasds")
    }
    else {
      // console.log("sdasds2")
      let Data = JSON.parse(sessionStorage.getItem('Data') || '{}');
      this.agreestatus = false
      this.signaturestatus = true
      // alert(this.bankdetailsverify)

      if (this.bankpayForm.valid) {
        let addressobject = {
          address1:
            this.bankpayForm.get('bankstreetaddress')?.value ||
            Data.street_address_line1 ||
            '',
          address2:
            this.bankpayForm.get('bankstreetaddressline2')?.value ||
            Data.street_address_line2 ||
            '',
          apt: this.bankpayForm.get('bankaptcheck')?.value || Data.apt || '',
          city: this.bankpayForm.get('bankcity')?.value || Data.city || '',
          province:
            this.bankpayForm.get('bankprovince')?.value || Data.province || '',
          postalCode:
            this.bankpayForm.get('bankpostalcode')?.value ||
            Data.postal_code ||
            '',
          phone:Data.phone_number,
          email: Data.email,
        };

        let bankAddress = this.bankverifyDetails.address;

        let bankAdressArr = bankAddress.split(',');

        let bankCity = bankAdressArr[bankAdressArr.length - 2].replace(' ', '');

        let provincePostal = bankAdressArr[bankAdressArr.length - 1];

        let provincePostalArr = provincePostal.split(' ');

        let bankProvince = provincePostalArr[provincePostalArr.length - 3];

        let bankPostalCode =
          provincePostalArr[provincePostalArr.length - 2] +
          ' ' +
          provincePostalArr[provincePostalArr.length - 1];

        let bankobject = {
          name: this.bankverifyDetails.bank.name,
          address: this.bankverifyDetails.bank.address.split(',')[0],
          city: bankAdressArr[bankAdressArr.length - 2].replace(' ', ''),
          province: provincePostalArr[provincePostalArr.length - 3],
          postalCode:
            provincePostalArr[provincePostalArr.length - 2] +
            ' ' +
            provincePostalArr[provincePostalArr.length - 1],
        };

        let inputData1 = {
          bankCode: this.bankpayForm.get('banknumber')?.value,
          branchCode: this.bankpayForm.get('transitnumber')?.value,
          accountNumber: this.bankpayForm.get('accountnumber')?.value,
        };

        // ////this.consoleMessage(this.bankfile)
        // this.bankfile[0].name ="test.pdf"
        var fileExtension = '.' + this.bankfile.name.split('.').pop();

        // event.target.files[0].name ="void_cheque_"+Date.now()+fileExtension
        var encBank = btoa(JSON.stringify(inputData1));
        var formData: any = new FormData();
        formData.append('firstName', Data.firstName);
        formData.append('lastName', Data.lastName);
        formData.append('address', JSON.stringify(addressobject));
        formData.append('bank', JSON.stringify(bankobject));
        formData.append(
          'files',
          this.bankfile,
          'void_cheque_' + Date.now() + fileExtension.toLowerCase()
        );
        // formData.append("files", this.bankfile.name,"void.pdf");
        formData.append('session', encBank) || '';
        formData.append('timestamp', new Date().getTime());

        // ////this.consoleMessage(formData)
        // ////this.consoleMessage(this.bankfile)

        this._CorporateService
          .padaddgrementdetails(formData)
          .subscribe((result: any) => {
            if (result.status == '200') {
              this.padAggrementModel = true
              this.generatePADaggrement = result.data.url

              // jQuery("#PADpafaggrement-modal").modal("show");

              sessionStorage.setItem('fileurl', result.data.url);
              sessionStorage.setItem('filename', result.data.filename);
              // this.showPADModelDiaslog =true
              // this.header = "PAD Agreement"
              // this.pdfData =result.data.url
            } else {



              this.ShowModelPopup = true
              this.ShowModelPopupMessage = result.message
                ? result.message
                : result.error
              this.ShowModelPopupTitle = this.lang.Info
            }
          });
      } else {
        this.bankpayForm.markAllAsTouched();
        // this.bankpayForm.get("banknumber")?.markAsTouched();
        // this.bankpayForm.get("transitnumber")?.markAsTouched();
        // this.bankpayForm.get("accountnumber")?.markAsTouched();
        // this.bankpayForm.get("bankname")?.markAsTouched();
        // this.bankpayForm.get("voidcheck")?.markAsTouched();
        // this.bankpayForm.get("checkbankdetails")?.markAsTouched();
        // this.bankpayForm.get("bankaptcheck")?.markAsTouched();
        // this.bankpayForm.get("bankstreetaddress")?.markAsTouched();
        // this.bankpayForm.get("bankcity")?.markAsTouched();
        // this.bankpayForm.get("bankprovince")?.markAsTouched();
        // this.bankpayForm.get("bankpostalcode")?.markAsTouched();

        // if (this.signaturecheck == true) {
        //   this.signaturemessage = true;
        // }
      }
    }

    // this.createCutomerPADagreement(data)
  }

  public agreepadconditions() {
    this.acceptaggrepad = false
    this.padaggrementerror = ""
    this.padAggrementFinalModel = false
    this.signaturestatus = false
    this.agreestatus = false
    this.rejectPADModel = false
    this.closePADModel = true
    this.showagree = false
    // jQuery("#PADpafaggrement1-modal").modal("hide");

    // this.acceptaggrepad = false
    // this.padaggrementerror =""
    // this.showagree =false
    // this.showReject =false
    // this.showClose =true
  }
  public closePADAdggrementModel() {
    this.padAggrementModel = false
    this.padAggrementFinalModel = false
  }

  public rejectPADpafaggrement() {
    this.acceptaggrepad = true
    // this.padaggrementerror = "Please check the PAD aggrement"

    this.padAggrementModel = false
    this.padAggrementFinalModel = false

  }
  public rejectPADpafaggrement1() {
    this.acceptaggrepad = true
    // this.padaggrementerror = "Please check the PAD aggrement"

    this.padAggrementModel = false
    this.padAggrementFinalModel = false

  }
  public signaturestartmodel() {
    // jQuery("#drawsignaturemodel-modal").modal("show");
    this.signModelForBankPay = true
    // this.clearPadSignature();
    this.generatePADaggrement = ""
    // this.acceptaggrepad ==true
  }
  memberinfogenderCheck(event: any) {
    if (event.target.value == 'true') {
      this.iafggenderForm
        .get('gender')
        ?.setValidators([Validators.required]);
    }
    else {
      this.iafggenderForm.get('gender')?.reset();
      this.iafggenderForm.get('gender')?.clearValidators();
    }
  }
  hideIAFGgenderfrom() {
    this.iafginfo = false
  }
  public saveIAFGsignature() {
    // this.padaggrementerror =""
    this.signatureNeeded = this.signaturePad.isEmpty();
    if (this.signatureNeeded == false) {
      const base64Data = this.signaturePad.toDataURL();
      // console.log(base64Data);
      sessionStorage.setItem("Spousesignature", base64Data);
      this.showSpouseSignature = false
    }
    else {
      sessionStorage.setItem("Spousesignature", "");
      this.showIAFGerrorMessage = true
    }


  }
  public savePadsignature() {
    this.acceptaggrepad = false
    // this.padaggrementerror =""
    // this.signatureNeeded = this.signaturePad.isEmpty();
    // console.log(this.signatureNeeded)
    // console.log(this.signaturePad)
    // console.log(this.signaturePad.isEmpty())
    if (this.signaturePad2.isEmpty() == false) {
      const base64Data = this.signaturePad2.toDataURL();
      // console.log(base64Data);
      sessionStorage.setItem("signature", base64Data);
    }
    else {
      sessionStorage.setItem("signature", "");
      this.showpaderrorMessage = true
    }
    setTimeout(() => {
      if (sessionStorage.getItem("signature")) {
        // if(sessionStorage.getItem("signature") && sessionStorage.getItem("signature")?.length>0){
        this.signModelForBankPay = false

        setTimeout(() => {
          this.padAggrementModel = false
          this.showmodelsign();

          // console.log("test")
        }, 10);
      }
      else {
        // this.signaturemessagepad = true;
        // this.signaturePad.clear();

        // console.log("tetshere")
      }
    }, 100);

  }
  // <!-- [class.text-green-700]="activeTab === i"
  // [class.animate-pluse]="activeTab === i"
  // [class.bg-green-200]="activeTab === i"
  public creditcardpaysubmit(value: any, value1: any) {



    this.signatureNeeded = this.signaturePad1.isEmpty();
    if (this.signaturePad1.isEmpty() == false) {

      const base64Data = this.signaturePad1.toDataURL();
      console.log(base64Data);
      sessionStorage.setItem("signature", base64Data);

    }
    else {
      sessionStorage.setItem("signature", "");
      this.signaturemessagecc = true

    }
    let inputData = JSON.parse(sessionStorage.getItem("Data") || '{}');


    inputData.signature = sessionStorage.getItem("signature") || "";

    let provincelist = JSON.parse(sessionStorage.getItem("provincelist") || "");
    let iafgData = JSON.parse(sessionStorage.getItem('plansummaryLife') || '[]');

    let iafgTermplanCheck = iafgData.filter((p: any) => p.planLevel == 44);



    if (iafgTermplanCheck.length > 0) {

      if (this.addbenificaryData.length > 0) {
        this.benficiary = false;

        const validateBeneficiaries = this.addbenificaryData.reduce((acc: any, obj: any) => {
          const compositeKey = `${obj.customerType}_${obj.type}`;
          if (!acc[compositeKey]) {
            acc[compositeKey] = 0;
          }
          acc[compositeKey] += parseFloat(obj.percentage);
          return acc;
        }, {});

        let beneficiaryError = "";
        for (const key in validateBeneficiaries) {
          if (validateBeneficiaries.hasOwnProperty(key)) {
            const [customerType, type] = key.split("_");
            console.log(`The customerType ${customerType} and type ${type} with perc: ${validateBeneficiaries[key]}`);

            if (validateBeneficiaries[key] != 100) {
              beneficiaryError += `CustomerType ${customerType} and type ${type} has percentage issue. \n`
            }
          }
        }




        if (beneficiaryError != "") {
          this.benficiaryCount = true
          this.benficiaryCountMessage = this.lang.Distributed_percentages_must_equal_100_and_not_more
          return false;
        }

        else {
          this.benficiary = false

          if (this.addbenificaryData && this.addbenificaryData.length > 0) {
            this.addbenificaryData =
              this.addbenificaryData
                .map((v: any) => ({ ...v, contactDetails: {contingentBeneficiary:{

                  firstName:v.contingentFirstName,

                  lastName:v.contingentLastName,

                  }},trusteeDetails: {
                  firstName:v.trusteeFirstName || "",
                  lastName:v.trusteeLastName || "",
                  }
                }))
            inputData.havingBeneficiaries = true
          }
          else {
            this.benficiary = true;

            return false;
          }
          inputData.beneficiariesDetails = this.addbenificaryData

        }


      }


      else {
        this.benficiary = true;
        if (this.addbenificaryData.length > 0) {

        } else {
          this.showBenificiaryForm = true
        }


        return false;

      }

    } else {
      // this.gotosumamrypageLogic()
    }

    if (provincelist.includes(inputData.province_id)) {
    } else {
      if (this.formConfigResult.assign_default_form == true) {

        inputData.formId = this.formConfigResult.default_form.id;
        inputData.brokershipTransfered = true;
        inputData.originalFormId = this.formConfigResult.form.id;
      } else {
      }
    }
    if(sessionStorage.getItem("Spousesignature")){
      inputData.spouseSignature = sessionStorage.getItem("Spousesignature")
    }

    if (sessionStorage.getItem("fusebillCustomerAddressId")) {
      inputData.fusebillCustomerAddressId =
        sessionStorage.getItem("fusebillCustomerAddressId") || null;
    }
    if (
      sessionStorage.getItem("fusebillCustomerId")
    ) {
      inputData.fusebillCustomerId =
        sessionStorage.getItem("fusebillCustomerId") || null;
    }
    if (
      sessionStorage.getItem("fusebillCustomerBillingAddressId")
    ) {
      inputData.fusebillCustomerBillingAddressId =
        sessionStorage.getItem("fusebillCustomerBillingAddressId") || null;
    }
    if (
      sessionStorage.getItem("customerId")
    ) {
      inputData.customerId = sessionStorage.getItem("customerId") || null;
      // inputData.billing_address.customer_id= parseInt(sessionStorage.getItem("customerId")) || null
    }
    inputData.billing_sameas_homeAddress =
      sessionStorage.getItem("homeAddress") == "true" ? true : false;

    if (sessionStorage.getItem("homeAddress") == "true") {
      inputData.billing_address = {
        // "customer_id": parseInt(sessionStorage.getItem("customerId")) || null,
        // "fusebill_customer_id": parseInt(sessionStorage.getItem("fusebillCustomerId")) || null,
        line1: inputData.street_address_line1 || "",
        line2: inputData.street_address_line2 || "",
        city: inputData.city || "",
        state: inputData.province || "",
        state_id: parseInt(this.provineList?.id), // this.fusebillid = parseInt(element.fusebillId);
        fusebillStateId: parseInt(this.provineList?.fusebillId),
        fusebillCountryId: 124,
        country: "Canada",
        country_id: 1,
        // ocountry_id: 1,
        postal_zip: inputData.postal_code || "",
        apt: inputData.apt || "",
      };
    } else {
      inputData.billing_address = {
        // "customer_id": parseInt(sessionStorage.getItem("customerId")) || null,
        // "fusebill_customer_id": parseInt(sessionStorage.getItem("fusebillCustomerId")) || null,
        line1: this.paymentForm.get("streetaddress")?.value || "",
        line2: this.paymentForm.get("streetaddressline2")?.value || "",
        city: this.paymentForm.get("city")?.value || "",
        state: this.paymentForm.get("province")?.value || "",
        state_id: parseInt(this.provineListcreditPayment?.id), // this.fusebillid = parseInt(element.fusebillId);
        fusebillStateId: parseInt(this.provineListcreditPayment?.fusebillId),
        fusebillCountryId: 124,
        country: "Canada",
        country_id: 1,
        // ocountry_id: 1,
        postal_zip: this.paymentForm.get("postalcode")?.value || "",
        apt: this.paymentForm.get("aptcheck")?.value || "",
      };
    }
    inputData.officeAddress = {
      // "customer_id": parseInt(sessionStorage.getItem("customerId")) || null,
      // "fusebill_customer_id": parseInt(sessionStorage.getItem("fusebillCustomerId")) || null,
      // line1:  "",
      // line2:  "",
      // city:  "",
      province: sessionStorage.getItem("corporateStateName"),
      state: sessionStorage.getItem("corporateStateName"),
      state_id: sessionStorage.getItem("corporateStateId"),
      stateId: sessionStorage.getItem("corporateStateId"),
      // this.fusebillid = parseInt(element.fusebillId);
      // fusebillStateId: '',
      // fusebillCountryId: '',
      country: "Canada",
      country_id: 1,
      countryId: 1,
      // // ocountry_id: 1,
      // postal_zip:  "",
      // apt: "",
    };
    if (
      sessionStorage.getItem("customerId") &&
      sessionStorage.getItem("fusebillCustomerId")
    ) {
      inputData.billing_address.customer_id =
        parseInt(sessionStorage.getItem("customerId") || '0') || null;
      inputData.billing_address.fusebill_customer_id =
        parseInt(sessionStorage.getItem("fusebillCustomerId") || '0') || null;
    }

    // ////this.consoleMessage(inputData);

    if (this.paymentForm.invalid) {
      const invalid = [];
      this.paymentForm.markAllAsTouched();
      const controls: any = this.paymentForm.controls;
      for (let name in controls) {
        //this.consoleMessage(name)
        if (controls[name].invalid) {

          if (name == "CardNumber") {
            name = this.lang.Enter_Card_Number;
          }
          if (name == "ExpirationMonth") {
            name = this.lang.Select_Expiry_Month;
          }
          if (name == "ExporationYear") {
            name = this.lang.Select_Expiry_Year;
          }
          if (name == "cvv") {
            name = this.lang.Enter_CVV
          }
          if (name == "streetaddress") {
            name = this.lang.Enter_Street_Address;
          }
          if (name == "city") {
            name = this.lang.Enter_City;
          }
          if (name == "province") {
            name = this.lang.Select_Province;
          }
          if (name == "postalcode") {
            name = this.lang.Enter_Postal_Code;

          }
          if (name == "recaptchaReactive") {
            name = this.lang.Select_Recaptcha;
          }


          invalid.push(name);
        }

      }
      this.missingInformation = true
      this.missingInfo = invalid;
      //this.consoleMessage(invalid)
      return invalid;
    }
    if (this.signaturePad1.isEmpty() == true) {
      const invalid = [];
      invalid.push("Draw the Signature");
      this.missingInformation = false

      this.missingInfo = invalid;
      //this.consoleMessage(invalid)
      return invalid;
    }
    if (
      this.paymentForm.valid && this.signaturePad1.isEmpty() == false && this.companyplansCheck == false && this.upgradeplansCheck == false && this.voluntryplansCheck == false
    ) {


      this._CorporateService.login1(inputData).subscribe(
        (result: any) => {

          if (result.status == "200") {
            sessionStorage.removeItem("paymentfail");

            // ////this.consoleMessage(result.data.province);
            // sessionStorage.setItem("stateid",result.data.province.id)
            if (result.data.customer.fusebill) {
              sessionStorage.setItem(
                "fusebillCustomerId",
                result.data.customer.fusebill.customerId
              );
              sessionStorage.setItem(
                "publicApiKey",
                result.data.customer.fusebill.publicApiKey
              );
              if (result.data.customer.fusebill.customerAddressId) {
                sessionStorage.setItem(
                  "fusebillCustomerAddressId",
                  result.data.customer.fusebill.customerAddressId
                );
              }
              if (result.data.customer.fusebill.customerBillingAddressId) {
                sessionStorage.setItem(
                  "fusebillCustomerBillingAddressId",
                  result.data.customer.fusebill.customerBillingAddressId
                );
              }
            }
            sessionStorage.setItem("userKey", result.data.userKey);
            sessionStorage.setItem("customerId", result.data.customer.id);
            // sessionStorage.setItem("publicApiKey",result.data.customer.fusebill.publicApiKey)
            sessionStorage.setItem("normalcustomerid", result.data.customer.id);
            sessionStorage.setItem(
              "enrollmentdate",
              inputData.planEnrollmentDate
            );
            this.customerid = result.data.customer.fusebill.customerId;
            this.publicapikey = result.data.customer.fusebill.publicApiKey;
            setTimeout(() => {
              value1.submit();
              // }
            }, 100);
          }

          else if (result.status == "206") {

            this.ShowModelPopup = true
            this.ShowModelPopupMessage = result.message
            this.ShowModelPopupTitle = this.lang.Info





          }
          else {

            if (result.error || result.errors) {


              this.ShowModelPopup = true
              this.ShowModelPopupMessage = result.error ? result.error[0].value : result.errors[0].value,
                this.ShowModelPopupTitle = this.lang.Info

            }

            this.ShowModelPopup = true
            this.ShowModelPopupMessage = result.message
            this.ShowModelPopupTitle = this.lang.Info
          }

        },
        (error) => {
          if ([0, 500, 501, 502, 503].includes(error.status)) {
            this.ShowModelPopup = true
            this.ShowModelPopupMessage = this.lang.Sorry_the_server_is_currently_undergoing_maintenance_Please_try_again_later_We_apologize_for_any_inconvenience;
              this.ShowModelPopupTitle = this.lang.Info

          }
          else {
            this.ShowModelPopup = true
            this.ShowModelPopupMessage = error.error.message ? error.error.message : error.error.error,
              this.ShowModelPopupTitle = this.lang.Info

          }
        }
      );
    } else {
      // this.paymentForm.markAsAllTouched();

      if (this.signaturecheckcc == true) {
        this.signaturemessagecc = true;
      }



    }
  }
  public bankpayformsubmit(value: any) {
    let inputData = JSON.parse(sessionStorage.getItem("Data") || '{}');




    inputData.signature = sessionStorage.getItem("signature") || "";
    let iafgData = JSON.parse(sessionStorage.getItem('plansummaryLife') || '[]');

    let iafgTermplanCheck = iafgData.filter((p: any) => p.planLevel == 44);

    if (iafgTermplanCheck.length > 0) {

      if (this.addbenificaryData.length > 0) {
        this.benficiary = false;

        const validateBeneficiaries = this.addbenificaryData.reduce((acc: any, obj: any) => {
          const compositeKey = `${obj.customerType}_${obj.type}`;
          if (!acc[compositeKey]) {
            acc[compositeKey] = 0;
          }
          acc[compositeKey] += parseFloat(obj.percentage);
          return acc;
        }, {});

        let beneficiaryError = "";
        for (const key in validateBeneficiaries) {
          if (validateBeneficiaries.hasOwnProperty(key)) {
            const [customerType, type] = key.split("_");
            console.log(`The customerType ${customerType} and type ${type} with perc: ${validateBeneficiaries[key]}`);

            if (validateBeneficiaries[key] != 100) {
              beneficiaryError += `CustomerType ${customerType} and type ${type} has percentage issue. \n`
            }
          }
        }




        if (beneficiaryError != "") {
          this.benficiaryCount = true
          this.benficiaryCountMessage = this.lang.Distributed_percentages_must_equal_100_and_not_more
          return false;
        }

        else {
          this.benficiary = false

          if (this.addbenificaryData && this.addbenificaryData.length > 0) {
            this.addbenificaryData =
              this.addbenificaryData
                .map((v: any) => ({ ...v, contactDetails: {contingentBeneficiary:{

                  firstName:v.contingentFirstName,

                  lastName:v.contingentLastName,

                  }},trusteeDetails: {
                  firstName:v.trusteeFirstName || "",
                  lastName:v.trusteeLastName || "",
                  }
                }))
            inputData.havingBeneficiaries = true
          }
          else {
            this.benficiary = true;

            return false;
          }
          inputData.beneficiariesDetails = this.addbenificaryData

        }


      }


      else {
        this.benficiary = true;
        if (this.addbenificaryData.length > 0) {

        } else {
          this.showBenificiaryForm = true
        }


        return false;

      }

    } else {
      // this.gotosumamrypageLogic()
    }


    let provincelist = JSON.parse(sessionStorage.getItem("provincelist") || "");

    if (provincelist.includes(inputData.province_id)) {
    } else {
      if (this.formConfigResult.assign_default_form == true) {
        // alert("1");
        inputData.formId = this.formConfigResult.default_form.id;
        inputData.brokershipTransfered = true;
        inputData.originalFormId = this.formConfigResult.form.id;
      } else {
      }
    }

    if (
      sessionStorage.getItem("fusebillCustomerAddressId")
    ) {
      inputData.fusebillCustomerAddressId =
        sessionStorage.getItem("fusebillCustomerAddressId") || null;
    }
    if (
      sessionStorage.getItem("fusebillCustomerId")
    ) {
      inputData.fusebillCustomerId =
        sessionStorage.getItem("fusebillCustomerId") || null;
    }
    if (
      sessionStorage.getItem("fusebillCustomerBillingAddressId")
    ) {
      inputData.fusebillCustomerBillingAddressId =
        sessionStorage.getItem("fusebillCustomerBillingAddressId") || null;
    }
    if (
      sessionStorage.getItem("customerId")
    ) {
      inputData.customerId = sessionStorage.getItem("customerId") || null;
    }

    if(sessionStorage.getItem("Spousesignature")){
      inputData.spouseSignature = sessionStorage.getItem("Spousesignature")
    }
    inputData.billing_sameas_homeAddress =
      sessionStorage.getItem("homeAddress") == "true" ? true : false;

    if (sessionStorage.getItem("homeAddress") == "true") {
      inputData.billing_address = {
        // "customer_id": parseInt(sessionStorage.getItem("customerId")) || null,
        // "fusebill_customer_id": parseInt(sessionStorage.getItem("fusebillCustomerId")) || null,
        line1: inputData.street_address_line1,
        line2: inputData.street_address_line2,
        city: inputData.city,
        state: inputData.province,
        state_id: parseInt(this.provineList?.id), // this.fusebillid = parseInt(element.fusebillId);
        fusebillStateId: parseInt(this.provineList?.fusebillId),
        fusebillCountryId: 124,
        country: "Canada",
        country_id: 1,
        // ocountry_id: 1,
        postal_zip: inputData.postalcode,
        apt: inputData.apt || "",
      };
    } else {

      //this.consoleMessage(this.paymentForm)
      inputData.billing_address = {
        line1: this.bankpayForm.get("bankstreetaddress")?.value,
        line2: this.bankpayForm.get("bankstreetaddressline2")?.value,
        city: this.bankpayForm.get("bankcity")?.value,
        state: this.bankpayForm.get("bankprovince")?.value,
        state_id: parseInt(this.provineListPayment?.id), // this.fusebillid = parseInt(element.fusebillId);
        fusebillStateId: parseInt(this.provineListPayment?.fusebillId),
        fusebillCountryId: 124,
        country: "Canada",
        country_id: 1,
        // ocountry_id: 1,
        postal_zip: this.bankpayForm.get("bankpostalcode")?.value,
        apt: this.bankpayForm.get("bankaptcheck")?.value || "",
      };
    }

    inputData.officeAddress = {
      // "customer_id": parseInt(sessionStorage.getItem("customerId")) || null,
      // "fusebill_customer_id": parseInt(sessionStorage.getItem("fusebillCustomerId")) || null,
      // line1:  "",
      // line2:  "",
      // city:  "",
      province: sessionStorage.getItem("corporateStateName"),
      state: sessionStorage.getItem("corporateStateName"),
      state_id: sessionStorage.getItem("corporateStateId"),
      stateId: sessionStorage.getItem("corporateStateId"),
      // this.fusebillid = parseInt(element.fusebillId);
      // fusebillStateId: '',
      // fusebillCountryId: '',
      country: "Canada",
      country_id: 1,
      countryId: 1,
      // // ocountry_id: 1,
      // postal_zip:  "",
      // apt: "",
    };
    if (
      sessionStorage.getItem("customerId") &&
      sessionStorage.getItem("fusebillCustomerId")
    ) {
      inputData.billing_address.customer_id =
        parseInt(sessionStorage.getItem("customerId") || "0") || null;
      inputData.billing_address.fusebill_customer_id =
        parseInt(sessionStorage.getItem("fusebillCustomerId") || "0") || null;


    }
    if (this.acceptaggrepad == true) {
      this.padaggrementerror = "Please check the PAD agreement"
    }
    else {
      this.padaggrementerror = ""
    }
    if (this.bankpayForm.invalid) {
      // this.bankpayForm.markAsTouched();
      this.bankpayForm.markAllAsTouched();
      const invalid = [];
      const controls: any = this.bankpayForm.controls;
      for (let name in controls) {
        //this.consoleMessage(name)
        if (controls[name].invalid) {

          if (name == "banknumber") {
            name = this.lang.Enter_Bank_Number;
          }
          if (name == "transitnumber") {
            name = this.lang.Enter_Transit_Number;
          }
          if (name == "accountnumber") {
            name = this.lang.Enter_Account_Number;
          }
          if (name == "bankname") {
            name = this.lang.Verify_the_bank_details;
          }

          if (name == "voidcheck") {
            name = this.lang.Uplaod_VoidCheck;
          }
          if (name == "bankstreetaddress") {
            name = this.lang.Enter_Street_Address;
          }
          if (name == "bankcity") {
            name = this.lang.Enter_City;
          }
          if (name == "bankprovince") {
            name = this.lang.Select_Province;
          }
          if(name=="bankpostalcode"){
            name = this.lang.Enter_Postal_Code;

          }
          if (name == "recaptchaReactive") {
            name = this.lang.Select_Recaptcha;
          }


          invalid.push(name);
        }

      }
      // jQuery("#missingInfo-modal").modal("show");
      this.missingInformation = true
      this.missingInfo = invalid;
      //this.consoleMessage(invalid)
      return invalid;
    }
    if (this.acceptaggrepad == true) {
      const invalid = [];
      invalid.push("Accept the PAD");
      // jQuery("#missingInfo-modal").modal("show");
      this.missingInformation = true
      this.missingInfo = invalid;
      //this.consoleMessage(invalid)
      return invalid;
    }
    // && this.acceptaggrepad == false
    if (this.bankpayForm.valid && this.cartcheckvalue == false && this.companyplansCheck == false && this.upgradeplansCheck == false && this.voluntryplansCheck == false) {
      let enrollmentData = {
        "customerName": inputData.firstName + "" + inputData.lastName,
        "contactNum": inputData.phone_number,
        "dob": inputData.date_of_birth,
        "email": inputData.email,
        "process": "enrollmentSumamry",
        "request": {
          "paymentMethod": "PAD", "signature": sessionStorage.getItem("signature") || "", "bankCode": this.bankpayForm.get("banknumber")?.value,
          "branchCode": this.bankpayForm.get("transitnumber")?.value, "voidcheque": this.imagedisplay
        }
      }

      // this._CorporateService.savePlan(enrollmentData).subscribe((result: any) => {
      //   //this.consoleMessage(result)
      // })
      this._CorporateService.login1(inputData).subscribe(
        (result: any) => {

          if (result.status == "200") {
            sessionStorage.removeItem("paymentfail");
            // this.planssummary = JSON.parse(
            //   sessionStorage.getItem("plansummary")
            // );
            // let obj = {};

            // this.planstab = 0;
            // this.cartamount = true;
            // ////this.consoleMessage(result.data.province);
            if (result.data.customer.fusebill) {
              sessionStorage.setItem(
                "fusebillCustomerId",
                result.data.customer.fusebill.customerId
              );
              sessionStorage.setItem(
                "publicApiKey",
                result.data.customer.fusebill.publicApiKey
              );
              if (result.data.customer.fusebill.customerAddressId) {
                sessionStorage.setItem(
                  "fusebillCustomerAddressId",
                  result.data.customer.fusebill.customerAddressId
                );
              }
              if (result.data.customer.fusebill.customerBillingAddressId) {
                sessionStorage.setItem(
                  "fusebillCustomerBillingAddressId",
                  result.data.customer.fusebill.customerBillingAddressId
                );
              }
            }
            sessionStorage.setItem("userKey", result.data.userKey);
            sessionStorage.setItem("customerId", result.data.customer.id);
            // sessionStorage.setItem("publicApiKey",result.data.customer.fusebill.publicApiKey)
            sessionStorage.setItem("normalcustomerid", result.data.customer.id);
            sessionStorage.setItem(
              "enrollmentdate",
              inputData.planEnrollmentDate
            );
            this.customerid = result.data.customer.fusebill.customerId;
            this.publicapikey = result.data.customer.fusebill.publicApiKey;

            setTimeout(() => {
              // if(this.validateaddresscheck==true){
              //   this.validateaddress(this.paymentForm.value,"1")

              // }
              // else{
              this.bankformsubmitdetails();
              // }
            }, 100);
          }
          else if (result.status == "206") {
            this.ShowModelPopup = true
            this.ShowModelPopupMessage = result.message
            this.ShowModelPopupTitle = this.lang.Info


          }

          else {
            if (result.error || result.errors) {

              this.ShowModelPopup = true
              this.ShowModelPopupMessage = result.error ? result.error[0].value : result.errors[0].value,
                this.ShowModelPopupTitle = this.lang.Info


            }
            else {

              this.ShowModelPopup = true
              this.ShowModelPopupMessage = result.data.message
              this.ShowModelPopupTitle = this.lang.Info

            }



          }

        },
        (error) => {
          if ([0, 500, 501, 502, 503].includes(error.status)) {

            this.ShowModelPopup = true
            this.ShowModelPopupMessage = this.lang.Sorry_the_server_is_currently_undergoing_maintenance_Please_try_again_later_We_apologize_for_any_inconvenience;
              this.ShowModelPopupTitle = this.lang.Info


          }
          else {

            this.ShowModelPopup = true
            this.ShowModelPopupMessage = error.error.message ? error.error.message : error.error.error,
              this.ShowModelPopupTitle = this.lang.Info


          }
        }
      );
    } else {
      this.bankpayForm.markAllAsTouched();
      // this.bankpayForm.get("banknumber").markAsTouched();
      // this.bankpayForm.get("transitnumber").markAsTouched();
      // this.bankpayForm.get("accountnumber").markAsTouched();
      // this.bankpayForm.get("voidcheck").markAsTouched();
      // this.bankpayForm.get("checkbankdetails").markAsTouched();
      // this.bankpayForm.get("bankaptcheck").markAsTouched();
      // this.bankpayForm.get("bankstreetaddress").markAsTouched();
      // this.bankpayForm.get("bankcity").markAsTouched();
      // this.bankpayForm.get("bankprovince").markAsTouched();
      // this.bankpayForm.get("bankpostalcode").markAsTouched();

      if (this.signaturecheck == true) {
        this.signaturemessage = true;
      }
      // this.bankpayForm.get("recaptchaReactive").markAsTouched();
      if (this.cartcheckvalue == true) {
        this.ShowModelPopup = true
        this.ShowModelPopupMessage = 'Please check the Plan Selection Page'
        this.ShowModelPopupTitle = this.lang.Info



      }
      // this.bankscrollToFirstInvalidControl();
    }
  }
  public registartionform(value: any) {


    this.signatureNeeded = this.signaturePad.isEmpty();
    if (this.signaturePad.isEmpty() == false) {

      const base64Data = this.signaturePad.toDataURL();
      // console.log(base64Data);
      sessionStorage.setItem("signature", base64Data);

    }
    else {
      sessionStorage.setItem("signature", "");
      this.signaturemessagecc = true

    }
    let inputData = JSON.parse(sessionStorage.getItem("Data") || '{}');

    sessionStorage.setItem("paymentMethod", "NONE");

    inputData.employeeKey = sessionStorage.getItem("employeelink");

    inputData.signature = sessionStorage.getItem("signature") || "";
    inputData.planEnrollmentDate =
      sessionStorage.getItem("enrollmentdate") ||
      inputData.planEnrollmentDate ||
      "";

    let provincelist = JSON.parse(sessionStorage.getItem("provincelist") || '');

    if (provincelist.includes(inputData.province_id)) {
    } else {
      if (this.formConfigResult.assign_default_form == true) {
        inputData.formId = this.formConfigResult.default_form.id;
        inputData.brokershipTransfered = true;
        inputData.originalFormId = this.formConfigResult.form.id;
      } else {
      }
    }

    if (
      sessionStorage.getItem("fusebillCustomerAddressId")
    ) {
      inputData.fusebillCustomerAddressId =
        sessionStorage.getItem("fusebillCustomerAddressId") || null;
    }
    if (
      sessionStorage.getItem("fusebillCustomerId")
    ) {
      inputData.fusebillCustomerId =
        sessionStorage.getItem("fusebillCustomerId") || null;
    }
    if (
      sessionStorage.getItem("fusebillCustomerBillingAddressId")
    ) {
      inputData.fusebillCustomerBillingAddressId =
        sessionStorage.getItem("fusebillCustomerBillingAddressId") || null;
    }
    if (
      sessionStorage.getItem("customerId")
    ) {
      inputData.customerId = sessionStorage.getItem("customerId") || null;
      // inputData.billing_address.customer_id= parseInt(sessionStorage.getItem("customerId")) || null
    }
    inputData.billing_sameas_homeAddress =
      sessionStorage.getItem("homeAddress") == "true" ? true : false;

    if (this.employeeDealingPlansarr.length > 0) {
      if (sessionStorage.getItem("homeAddress") == "true") {
        inputData.billing_address = {
          // "customer_id": parseInt(sessionStorage.getItem("customerId")) || null,
          // "fusebill_customer_id": parseInt(sessionStorage.getItem("fusebillCustomerId")) || null,
          line1: inputData.street_address_line1 || "",
          line2: inputData.street_address_line2 || "",
          city: inputData.city || "",
          state: inputData.province || "",
          state_id: this.provineListcreditPayment?.id,
          fusebillStateId: parseInt(this.provineListcreditPayment?.fusebillId),
          fusebillCountryId: 124,

          country: "Canada",
          country_id: 1,
          // ocountry_id: 1,
          postal_zip: inputData.postal_code || "",
          apt: inputData.apt || "",
        };
      }
      else {
        inputData.billing_address = {
          // "customer_id": parseInt(sessionStorage.getItem("customerId")) || null,
          // "fusebill_customer_id": parseInt(sessionStorage.getItem("fusebillCustomerId")) || null,
          line1: this.paymentForm.get("streetaddress")?.value || "",
          line2: this.paymentForm.get("streetaddressline2")?.value || "",
          city: this.paymentForm.get("city")?.value || "",
          state: this.paymentForm.get("province")?.value || "",
          state_id: this.provineList?.fusebillid, // this.fusebillid = parseInt(element.fusebillId);
          // ostate_id:this.state_id
          country: "Canada",
          country_id: 124,
          // ocountry_id: 1,
          postal_zip: this.paymentForm.get("postalcode")?.value || "",
          apt: this.paymentForm.get("aptcheck")?.value || "",
        };
      }
    } else {
      inputData.billing_sameas_homeAddress = true;
      inputData.billing_address = {};
    }

    inputData.officeAddress = {
      // "customer_id": parseInt(sessionStorage.getItem("customerId")) || null,
      // "fusebill_customer_id": parseInt(sessionStorage.getItem("fusebillCustomerId")) || null,
      // line1:  "",
      // line2:  "",
      // city:  "",
      province: sessionStorage.getItem("corporateStateName"),
      state: sessionStorage.getItem("corporateStateName"),
      state_id: sessionStorage.getItem("corporateStateId"),
      stateId: sessionStorage.getItem("corporateStateId"),
      // this.fusebillid = parseInt(element.fusebillId);
      // fusebillStateId: '',
      // fusebillCountryId: '',
      country: "Canada",
      country_id: 1,
      countryId: 1,
      // // ocountry_id: 1,
      // postal_zip:  "",
      // apt: "",
    };
    if (
      sessionStorage.getItem("customerId") &&
      sessionStorage.getItem("fusebillCustomerId")
    ) {
      inputData.billing_address.customer_id =
        parseInt(sessionStorage.getItem("customerId") || '0') || null;
      inputData.billing_address.fusebill_customer_id =
        parseInt(sessionStorage.getItem("fusebillCustomerId") || '0') || null;
    }

    // ////this.consoleMessage(inputData);

    if (!this.showPaymenthMethod) {
      this.signaturecheckcc = false;
      this.cartcheckvalue = false;
      this.signaturemessagecc = false;
    } else {
    }

    if (this.signaturePad.isEmpty() == true) {
      const invalid = [];
      invalid.push("Draw the Signature");
      // jQuery("#missingInfo-modal").modal("show");
      this.missingInformation = true

      this.missingInfo = invalid;
      //this.consoleMessage(invalid)
      return invalid;
    }

    if (this.signaturePad.isEmpty() == false && this.companyplansCheck == false && this.upgradeplansCheck == false) {
      this._CorporateService.login1(inputData).subscribe(
        (result: any) => {
          if (result.status == "200") {
            sessionStorage.removeItem("paymentfail");
            // this.planssummary = JSON.parse(
            //   sessionStorage.getItem("plansummary")
            // );
            let obj = {};


            // ////this.consoleMessage(result.data.province);
            // sessionStorage.setItem("stateid",result.data.province.id)
            if (result.data.customer.fusebill) {
              sessionStorage.setItem(
                "fusebillCustomerId",
                result.data.customer.fusebill.customerId
              );
              sessionStorage.setItem(
                "publicApiKey",
                result.data.customer.fusebill.publicApiKey
              );
              if (result.data.customer.fusebill.customerAddressId) {
                sessionStorage.setItem(
                  "fusebillCustomerAddressId",
                  result.data.customer.fusebill.customerAddressId
                );
              }
              if (result.data.customer.fusebill.customerBillingAddressId) {
                sessionStorage.setItem(
                  "fusebillCustomerBillingAddressId",
                  result.data.customer.fusebill.customerBillingAddressId
                );
              }
            }
            sessionStorage.setItem("customerId", result.data.customer.id);
            // sessionStorage.setItem("publicApiKey",result.data.customer.fusebill.publicApiKey)
            sessionStorage.setItem("normalcustomerid", result.data.customer.id);
            sessionStorage.setItem(
              "enrollmentdate",
              inputData.planEnrollmentDate
            );
            sessionStorage.setItem("userKey", result.data.userKey);
            this.customerid = result.data.customer.fusebill.customerId;
            this.publicapikey = result.data.customer.fusebill.publicApiKey;
            setTimeout(() => {
              this.router.navigate(["payment/corporate"]);
            }, 100);
          } else {
            if (result.status == "206") {
              // var str =
              //   "2 Street Bay\n" + "3A Mansion Park\n" + "580-1000 Park Drive";
              // Swal.fire({
              //   title:
              //     "Please provide an address using any of the accepted formats below,",
              //   html: "<pre>" + str + "</pre>",
              //   showCancelButton: false,
              //   confirmButtonText: "Ok",
              //   width: "30%",
              //   customClass: {
              //     header: 'swal-header-orange',
              //     popup: 'custom-swal-dialog',
              //     confirmButton: 'custom-ok-button-class',
              //   }
              // }).then((result) => {
              //   if (result.value) {
              //   } else {
              //     // this.router.navigate(['pages/signup-form']);
              //   }
              // });

              this.ShowModelPopup = true
              this.ShowModelPopupTitle = this.lang.Info
              this.ShowModelPopupMessage = result.message


            }

            else {
              if (result.error || result.errors) {

                this.ShowModelPopup = true
                this.ShowModelPopupTitle = this.lang.Error
                this.ShowModelPopupMessage = result.error
                  ? result.error[0].value
                  : result.errors[0].value


              }
              else {
                this.ShowModelPopup = true
                this.ShowModelPopupTitle = this.lang.Error
                this.ShowModelPopupMessage = result.mesage
              }
            }
          }
        },
        (error) => {
          if ([0, 500, 501, 502, 503].includes(error.status)) {

            this.ShowModelPopup = true
            this.ShowModelPopupTitle = this.lang.Info
            this.ShowModelPopupMessage = this.lang.Sorry_the_server_is_currently_undergoing_maintenance_Please_try_again_later_We_apologize_for_any_inconvenience;


          }
          else {
            this.ShowModelPopup = true
            this.ShowModelPopupTitle = this.lang.Info
            this.ShowModelPopupMessage = error.error.message ? error.error.message : error.error.error


          }
        }
      );
    } else {
      this.paymentForm.markAllAsTouched();

      // if (this.signaturecheckcc == true) {
      //   this.signaturemessagecc = true;
      // }
      // if (this.signaturemessagePlansConfirmation == true) {
      //   this.signaturemessagePlansConfirmation = true;
      // }
      if (this.cartcheckvalue == true) {
        this.ShowModelPopup = true
        this.ShowModelPopupTitle = this.lang.Info
        this.ShowModelPopupMessage = "Please check the Plan Selection Page"


      }
      // this.bankscrollToFirstInvalidControl();
    }
  }
  public bankformsubmitdetails() {
    var formData: any = new FormData();

    let personalInfo = JSON.parse(sessionStorage.getItem("Data") || '{}');

    let bankinputData = {
      bankCode: this.bankpayForm.get("banknumber")?.value,
      branchCode: this.bankpayForm.get("transitnumber")?.value,
      bankName: this.bankpayForm.get("bankname")?.value,
      accountNumber: this.bankpayForm.get("accountnumber")?.value,
    };


    ////this.consoleMessage(sessionStorage.getItem("totalAmount"))
    // ////this.consoleMessage(this._decimalPipe.transform(sessionStorage.getItem("totalAmount"),"1.2-2"))
    // ////this.consoleMessage(this._decimalPipe.transform(parseInt(sessionStorage.getItem("totalAmount")),"1.2-2"))
    // ////this.consoleMessage(this._decimalPipe.transform(parseFloat(sessionStorage.getItem("totalAmount")),"1.2-2"))
    let inputData = {
      customerId: parseInt(sessionStorage.getItem("normalcustomerid") || '0'),
      bankCode: this.bankpayForm.get("banknumber")?.value,
      branchCode: this.bankpayForm.get("transitnumber")?.value,
      accountNumber: this.bankpayForm.get("accountnumber")?.value,

      amount: this._decimalPipe.transform(sessionStorage.getItem("totalAmount"), "1.2-2")?.replace(/,/g, ""),
      totalAmount: this._decimalPipe.transform(sessionStorage.getItem("totalAmount"), "1.2-2")?.replace(/,/g, ""),

      totalAmountUI: sessionStorage.getItem("totalAmountUI"),
      enrollmentDate: sessionStorage.getItem("enrollmentdate"),
      customerName: personalInfo.firstName + " " + personalInfo.lastName,
    };

    ////this.consoleMessage(inputData)

    var encBank = btoa(JSON.stringify(inputData));
    // formData.append("session", encBank);
    // formData.append("timestamp",new Date().getTime());
    // formData.append("files", this.bankfile);

    // ////this.consoleMessage(this.bankfile)

    sessionStorage.setItem("session", encBank);
    let link = sessionStorage.getItem("formlink")

    this.router.navigate(["/payment/corporate"]);

    return false;

  }
  getTabClass(index: any): object {

    //     if(this.visitedTabs.has(index) && this.activeTab !== index){
    //       return 'bg-green-700'
    // }
    //     if(this.visitedTabs.has(index) && this.activeTab !== index){
    //       return 'text-white'
    //     }
    //     if(this.activeTab <index){
    //       return 'bg-gray-700'
    //     }
    //     if(this.activeTab === index){
    //       return 'border-b-2 border-green-700'
    //     }


    return {
      'text-white': this.visitedTabs.has(index) && this.activeTab !== index,
      'border-b-2 border-green-700': this.activeTab === index,

      'bg-green-700': this.visitedTabs.has(index) && this.activeTab !== index,


    };
    // 'bg-gray-200': this.activeTab <index,
    // return {
    //   'bg-green-300 text-green': this.activeTab === index,
    //   'bg-gray-200 text-black': this.activeTab !== index,
    //   'border-b-2 border-green-700': this.activeTab === index,
    //   'hover:bg-green-300': this.activeTab !== index,
    //   'bg-green-700': this.visitedTabs.has(index) && this.activeTab !== index && this.activeTab,  // Custom style for visited tabs

    // };
    // if (index === this.activeTab) {
    //   return 'text-green-700 animate-pulse bg-green-200'; // Active tab
    // } else if (index === this.previousTab) {
    //   return 'bg-green-700 text-white'; // Previous tab
    // } else {
    //   return 'bg-gray-300'; // Other tabs
    // }
  }

  // getTabClass(index: number) {
  //   return this.activeTab === index ? 'bg-blue-500 text-black' : 'bg-green-700 text-white';
  // }
  numbersOnly(event: any) {
    const key = event.key || String.fromCharCode(event.keyCode);
    // Check if the key is an alphabetical character (A-Z or a-z) or a space
    if (/(^[0-9 \-]+$)/.test(key)) {
      return true;
    } else {
      return false;
    }
  }
  public checkDateOfBirthMinor(event: any) {


    const birthDate = new Date(event.target.value);
    const today = new Date();
    const age = today.getFullYear() - birthDate.getFullYear();





    if (event.type === 'input') {
      if (event.target.value == '') {
        this.benficiaryForm.controls['dob'].reset();
        this.benficiaryForm.controls['dob'].markAsTouched();
      }
    } else {
      if (event.target.value == 'Invalid date') {
        this.benficiaryForm.controls['dob'].reset();
        this.benficiaryForm.controls['dob'].markAsTouched();
      }
      if (event.target.value == '') {
        this.benficiaryForm.controls['dob'].reset();
        this.benficiaryForm.controls['dob'].markAsTouched();
      }
    }
  }
  public checkDateOfBirth(event: any) {

    if (event.type === 'input') {
      if (event.target.value == '') {
        this.userForm.controls['date_of_birth'].reset();
        this.userForm.controls['date_of_birth'].markAsTouched();
      }
    } else {
      if (event.target.value == 'Invalid date') {
        this.userForm.controls['date_of_birth'].reset();
        this.userForm.controls['date_of_birth'].markAsTouched();
      }
      if (event.target.value == '') {
        this.userForm.controls['date_of_birth'].reset();
        this.userForm.controls['date_of_birth'].markAsTouched();
      }
    }
  }
  public checkPlanEnrollmentDate(event: any) {

    if (event.type === 'input') {
      if (event.target.value == '') {
        this.userForm.controls['planEnrollmentDate'].reset();
        this.userForm.controls['planEnrollmentDate'].markAsTouched();
      }
    } else {
      if (event.target.value == 'Invalid date') {
        this.userForm.controls['planEnrollmentDate'].reset();
        this.userForm.controls['planEnrollmentDate'].markAsTouched();
      }
      if (event.target.value == '') {
        this.userForm.controls['planEnrollmentDate'].reset();
        this.userForm.controls['planEnrollmentDate'].markAsTouched();
      }
    }
  }
  public checkDateOfHire(event: any) {


    if (event.type === 'input') {
      if (event.target.value == '') {
        this.userForm.controls['date_of_hiring'].reset();
        this.userForm.controls['date_of_hiring'].markAsTouched();
      }
    }
    else {
      if (event.target.value == 'Invalid date') {
        this.userForm.controls['date_of_hiring'].reset();
        this.userForm.controls['date_of_hiring'].markAsTouched();
      }
      if (event.target.value == '') {
        this.userForm.controls['date_of_hiring'].reset();
        this.userForm.controls['date_of_hiring'].markAsTouched();
      }
    }

  }
  public checkDateOfArrival(event: any) {

    if (event.type === 'input') {
      if (event.target.value == '') {
        this.userForm.controls['dateOfArrival'].reset();
        this.userForm.controls['dateOfArrival'].markAsTouched();
      }
    }
    else {
      if (event.target.value == 'Invalid date') {
        this.userForm.controls['dateOfArrival'].reset();
        this.userForm.controls['dateOfArrival'].markAsTouched();
      }
      if (event.target.value == '') {
        this.userForm.controls['dateOfArrival'].reset();
        this.userForm.controls['dateOfArrival'].markAsTouched();
      }
    }
  }

  public checkSpouseDateofBirth(event: any) {
    if (event.type === 'input') {
      if (event.target.value == '') {
        this.userForm.controls['spousedateofbirth'].reset();
        this.userForm.controls['spousedateofbirth'].markAsTouched();
      }
    } else {
      if (event.target.value == 'Invalid date') {
        this.userForm.controls['spousedateofbirth'].reset();
        this.userForm.controls['spousedateofbirth'].markAsTouched();
      }
      if (event.target.value == '') {
        this.userForm.controls['spousedateofbirth'].reset();
        this.userForm.controls['spousedateofbirth'].markAsTouched();
      }
    }
  }
  public checkDateOfADependentArrival(event: any) {

    if (event.type === 'input') {
      if (event.target.value == '') {
        this.userForm.controls['dependentdateOfArrival'].reset();
        this.userForm.controls['dependentdateOfArrival'].markAsTouched();
      }
    } else {
      if (event.target.value == 'Invalid date') {
        this.userForm.controls['dependentdateOfArrival'].reset();
        this.userForm.controls['dependentdateOfArrival'].markAsTouched();
      }
      if (event.target.value == '') {
        this.userForm.controls['dependentdateOfArrival'].reset();
        this.userForm.controls['dependentdateOfArrival'].markAsTouched();
      }
    }
  }

  // public checkDateOfgraducation(event:any){
  //   if(event.target.value=="Invalid date"){
  //     this.childFormGroup.get('childInfoGraduationday').reset();
  //     this.childFormGroup.get('childInfoGraduationday').markAsTouched();
  //   }
  // }
  toggleItem1(item: any): void {
    item.expanded = !item.expanded;
  }
  getFeatureClasses(feature: any): string {

    const featureName = feature.feature.name.replace(/\s+/g, '-').toLowerCase(); // e.g., "professional-services"

    // Regex to extract the class name from feature.description if it's HTML
    const descriptionClassMatch = feature.description.match(/class="([^"]+)"/);
    const descriptionClass = descriptionClassMatch ? descriptionClassMatch[1] : '';

    // Combine feature name and description class
    return `${descriptionClass}`;



  }
  public autoSelectCompanyPaidPlans() {

    var result = this.companypaidproductid.reduce((unique: any, o: any) => {
      if (!unique.some((obj: { name: any; }) => obj.name === o.name)) {
        unique.push(o);
      }
      return unique;
    }, []);
    console.log(result);

    // this.companypaidproductid.forEach((element, index) => {

    result.forEach((element: any, index: any) => {


      setTimeout(() => {
        const dom: HTMLElement = this.elementRef.nativeElement;
        const plan: any = dom.querySelector("#plancheck" + element.id);

        // console.log(plan)

        this.renderer.selectRootElement("#plancheck" + element.id).click()






        //Rakesh Remove


        // $("#plancheck" + element.id).click();




      }, 100);

    });


    setTimeout(() => {
      let selectplans =
        JSON.parse(sessionStorage.getItem("plansummarymain") || '[]') || "";

      //this.consoleMessage(selectplans)

      console.log(selectplans)
      selectplans.forEach((element: any) => {
        if (element.paidByCompany == 1) {
          element.products.forEach((product: any, index: any) => {
            const dom: HTMLElement = this.elementRef.nativeElement;
            const plan: any = dom.querySelector("#plancheck" + product.id);

            if (plan) {
              plan.disabled = true;
            }
          })

        }
      });
    }, 2000);
    this.mainarray.forEach((element: any) => {
      element.groups.forEach((group: any) => {
        group.plans.forEach((plan: any) => {
          if(plan.productAddonss){
          Object.keys(plan.productAddonss).forEach((key) => {
            // plan.productAddonss[key].forEach((product) => {
            const keys = ['planCoverage'];
            const filteredData = plan.productAddonss[key].filter((value: any, index: any, self: any) =>
              self.findIndex((v: { [x: string]: any; }) => keys.every(k => v[k] === value[k])) === index
            );
            plan.productAddonss[key] = filteredData
            // });
          });
        }
        });

      });
    });
    //this.consoleMessage(this.mainarray)

    this.mainarray.forEach((element: { groups: any[]; }) => {
      element.groups.forEach((group: { categorization: { paidByCompany: number; }; plans: any[]; }) => {
        // //this.consoleMessage(element.groups)
        if (element.groups.length == 1) {
          // //this.consoleMessage(group)
          if (group.categorization.paidByCompany == 1) {
            group.plans.forEach((plan: { productAddonss: { [x: string]: any[]; }; }) => {
              Object.keys(plan.productAddonss).forEach((key) => {
                plan.productAddonss[key].forEach((product: { id: any; }) => {
                  this.disabledcompanypaidproductid.push({ id: product.id });
                  const dom: HTMLElement = this.elementRef.nativeElement;

                  const plan: any = dom.querySelector("#plancheck" + product.id);

                  if (plan) {
                    plan.checked = false;
                  }
                  // $("#plancheck" + product.id).prop("checked", false);
                });
              });
            });
          }
        }
      });
    });


    this.disabledcompanypaidproductid.forEach((element: { id: string; }, index: any) => {
      setTimeout(() => {
        const dom: HTMLElement = this.elementRef.nativeElement;
        const plan: any = dom.querySelector("#plancheck" + element.id);

        // plan.disabled=true
      }, 100);
    });
    // if (packagesInBlocks && packagesInBlocks.paidByCompany) {
    //   for (const cpPackage of packagesInBlocks.paidByCompany) {
    //     for (const cpGroup of cpPackage.groupsCP) {
    //       for (const cpPlan of cpGroup.plans) {

    //         for(const product1 in cpPlan.productAddonss){
    //           //this.consoleMessage(product1)
    //           //this.consoleMessage(cpPlan.productAddonss[product1])
    //           $("#plancheck"+cpPlan.productAddonss[product1][0].id).click();

    //         }

    //       }
    //     }
    //   }
    // }
    // this.toastrService.success("Company paid plans are all selected", "");

  }
  cobminedcommonfunction(cpPackages: any, ccPackages: any, epPackages: any) {

    // console.log(cpPackages)
    let commonPackages: any = {};
    for (let cpPackage of cpPackages) {
      let pckg = cpPackage;
      pckg.groups = pckg.groupsCP;

      if (commonPackages && commonPackages[pckg.id]) {
        commonPackages[pckg.id].groups.push(pckg.groupsCP);
      } else {
        commonPackages[pckg.id] = pckg;
      }
    }
    //this.consoleMessage(commonPackages)

    for (let ccPackage of ccPackages) {
      let pckg = ccPackage;
      //this.consoleMessage(pckg)
      pckg.groups = pckg.groupsCC;

      //this.consoleMessage(pckg.groups)
      //this.consoleMessage(pckg.groupsCC)

      if (commonPackages && commonPackages[pckg.id]) {
        //this.consoleMessage(commonPackages[pckg.id])
        commonPackages[pckg.id].groups = commonPackages[pckg.id].groups.concat(
          pckg.groupsCC
        );
      } else {
        commonPackages[pckg.id] = pckg;
      }
    }

    for (let epPackage of epPackages) {
      let pckg = epPackage;
      pckg.groups = pckg.groupsEP;

      if (commonPackages && commonPackages[pckg.id]) {
        commonPackages[pckg.id].groups = commonPackages[pckg.id].groups.concat(
          pckg.groupsEP
        );
      } else {
        commonPackages[pckg.id] = pckg;
      }
    }

    return commonPackages;


  }
  public convertpackagesToPlans2(packages: { paidByCompany: any[]; }) {
    //this.consoleMessage("pacakges");
    //all_plans
    var plans: any[] = [];
    var cp_plans: any[] = [];
    var cc_plans: any[] = [];
    var ep_plans: any[] = [];

    packages?.paidByCompany.forEach((pack: { groupsCP: any[]; name: any; }, index: any) => {
      pack.groupsCP.forEach((group: { plans: any[]; parentId: any; name: any; id: any; categorization: { paidByCompany: any; coveredByCompany: any; paidByEmployee: any; }; }, index1: any) => {
        group.plans.forEach((plan: { [x: string]: any; }, index2: any) => {
          if (plan) {
            plan["planLevel_parentId"] = group.parentId;
            plan["packageName"] = pack.name;
            plan["groupName"] = group.name;
            plan["groupId"] = group.id;
            plan["groupparentId"] = group.parentId;
            plans.push(plan);

            if (group.categorization.paidByCompany) {
              cp_plans.push(plan);
            }
            if (group.categorization.coveredByCompany) {
              cc_plans.push(plan);
            }
            if (group.categorization.paidByEmployee) {
              ep_plans.push(plan);
            }
          } else {
            //this.consoleMessage("else");
          }
        });
      });
    });



    return [plans, cp_plans, cc_plans, ep_plans];
  }
  calculateBasePlanDeltasforCCplans(ccplanspackages: string | any[], baseplans: any[], compnayplans: any[]) {
    let modifiedCCplanPackages = [];
    for (let i = 0; i < ccplanspackages?.length; i++) {
      let modifiedCCPackage = ccplanspackages[i];
      //this.consoleMessage(ccplanspackages[i].name)
      //this.consoleMessage(ccplanspackages[i].id)
      let CCplansgroups = ccplanspackages[i].groupsCC;

      modifiedCCPackage.groupsCC = [];
      for (let CCplangroup of CCplansgroups) {
        let modifiedCCgroup = CCplangroup;
        //this.consoleMessage(CCplangroup.id)
        //this.consoleMessage(CCplangroup.parentId)
        //this.consoleMessage(CCplangroup.name)
        let CCplans = CCplangroup.plans;

        modifiedCCgroup.plans = [];
        for (let CCplan of CCplans) {
          let modifiedCCplan = CCplan;
          //this.consoleMessage(CCplan.id);
          //this.consoleMessage(CCplan.isBundle);
          //this.consoleMessage(CCplan.name);

          CCplan.planLevel_parentId = CCplangroup.parentId;

          let payroll = true;
          let EmployeeChoice = 0;

          if (
            this.CORP_PAYROLL_NOT_MANDATORY_PACKAGES.includes(CCplan.packageId)
          ) {
            if (EmployeeChoice == 2) {
              payroll = false;
            }
          } else {
            if (
              this.CORP_PAYROLL_NOT_MANDATORY_PLANLEVELS.includes(
                CCplan.planLevel
              )
            ) {
              if (EmployeeChoice == 2) {
                payroll = false;
              }
            }
          }
          let selectedCCplanProductarray = CCplan.productAddonss[CCplan.name];

          if (CCplan.isBundle) {
            //this.consoleMessage("BundlePlan")

            //this.consoleMessage(baseplans)
            var filtered_base_plans = baseplans.filter(function (plan: { packageId: any; planLevel_parentId: any; planLevel: number; }) {
              //this.consoleMessage(plan)
              //cp_plans

              //this.consoleMessage(CCplan.packageId,plan.packageId)
              //this.consoleMessage(CCplan.planLevel_parentId,plan.planLevel_parentId)
              //this.consoleMessage(CCplan.planLevel,plan.planLevel)


              return (
                CCplan.packageId == plan.packageId &&
                CCplan.planLevel_parentId == plan.planLevel_parentId &&
                CCplan.planLevel >= plan.planLevel
              );
            });

            var filtered_compnay_plans = compnayplans.filter(function (plan: { packageId: any; planLevel_parentId: any; planLevel: number; }) {
              //this.consoleMessage(plan)
              //cp_plans

              //this.consoleMessage(CCplan.packageId,plan.packageId)
              //this.consoleMessage(CCplan.planLevel_parentId,plan.planLevel_parentId)
              //this.consoleMessage(CCplan.planLevel,plan.planLevel)

              return (
                CCplan.packageId == plan.packageId &&
                CCplan.planLevel_parentId == plan.planLevel_parentId &&
                CCplan.planLevel >= plan.planLevel
              );
            });

            //this.consoleMessage(filtered_base_plans);

            //this.consoleMessage(filtered_compnay_plans)
            let selectedBasePlan;
            let selectedCompnayPlan;
            let DeltaPlan;
            let selectedBaseplanProduct: any;
            let selectedBaseplanProductsArray = [];
            let selectedCompanyplanProduct: any;
            let selectedCompanyplanProductsArray = [];

            if (filtered_base_plans.length == 0) {
            } else if (filtered_base_plans.length == 1) {
              selectedBasePlan = filtered_base_plans[0];
              selectedCompnayPlan = filtered_compnay_plans[0];
            } else {
              // //this.consoleMessage(filtered_base_plans.length);
              // alert("morethan1")

              var filtered_base_plans2 = filtered_base_plans.filter(function (plan: { id: any; }) {
                //this.consoleMessage(plan)
                //cp_plans

                // //this.consoleMessage(CCplan.packageId,plan.packageId)
                // //this.consoleMessage(CCplan.planLevel_parentId,plan.planLevel_parentId)
                // //this.consoleMessage(CCplan.planLevel,plan.planLevel)


                return (
                  CCplan.id == plan.id
                );
              });
              var filtered_compnay_plans2 = filtered_compnay_plans.filter(function (plan: { id: any; }) {
                //this.consoleMessage(plan)
                //cp_plans

                // //this.consoleMessage(CCplan.packageId,plan.packageId)
                // //this.consoleMessage(CCplan.planLevel_parentId,plan.planLevel_parentId)
                // //this.consoleMessage(CCplan.planLevel,plan.planLevel)

                return (
                  CCplan.id == plan.id
                );
              });
              selectedBasePlan = filtered_base_plans2[0];
              selectedCompnayPlan = filtered_compnay_plans2[0];
            }

            if (selectedBasePlan) {
              // //this.consoleMessage(selectedBasePlan)
              // selectedBaseplanProduct = selectedBasePlan.productAddonss[selectedBasePlan.name][0]
              selectedBaseplanProductsArray = selectedBasePlan.productAddonss[selectedBasePlan.name];
              let selectedBaseplanProductsArray2 = [];
              //  //this.consoleMessage(CCplan.packageId)
              if (CCplan.packageId == 5 || CCplan.packageId == 10) {
                selectedBaseplanProductsArray2 = selectedBaseplanProductsArray
                for (let i = 0; i < selectedCCplanProductarray.length - selectedBaseplanProductsArray.length; i++) {
                  selectedBaseplanProductsArray2.push(selectedBaseplanProductsArray[selectedBaseplanProductsArray.length - 1]);
                }
                // //this.consoleMessage(selectedBaseplanProductsArray)
                selectedBaseplanProductsArray = selectedBaseplanProductsArray2
                // //this.consoleMessage(selectedBaseplanProductsArray)
              }
              else {
                let productCoverageFound = false;
                for (let i = 0; i < selectedCCplanProductarray.length; i++) {
                  for (let j = 0; j < selectedBaseplanProductsArray.length; j++) {

                    if (selectedCCplanProductarray[i].planCoverage == selectedBaseplanProductsArray[j].planCoverage) {
                      productCoverageFound = true
                      selectedBaseplanProductsArray2.push(selectedBaseplanProductsArray[j])
                      break;
                    }


                  }
                  if (!productCoverageFound) {



                    selectedBasePlan = {};
                    selectedBaseplanProduct = {};
                    selectedBaseplanProduct.calculatedTax = {};
                    selectedBaseplanProduct.calculatedTax.price = 0;
                    selectedBaseplanProduct.calculatedTax.tax = 0;
                    selectedBaseplanProduct.calculatedTax.total = 0;
                    selectedBaseplanProduct.name = "NA";
                    selectedBaseplanProduct.planCoverage = selectedCCplanProductarray[i].planCoverage;
                    selectedBaseplanProductsArray2.push(selectedBaseplanProduct);



                  }

                }
                //this.consoleMessage(selectedBaseplanProductsArray)
                selectedBaseplanProductsArray = selectedBaseplanProductsArray2
                //this.consoleMessage(selectedBaseplanProductsArray)
              }
            }
            else {
              selectedBasePlan = {};
              selectedBaseplanProduct = {};
              selectedBaseplanProduct.calculatedTax = {};
              selectedBaseplanProduct.calculatedTax.price = 0;
              selectedBaseplanProduct.calculatedTax.tax = 0;
              selectedBaseplanProduct.calculatedTax.total = 0;
              selectedBaseplanProduct.name = "NA";

              for (let i = 0; i < selectedCCplanProductarray.length; i++) {
                selectedBaseplanProductsArray.push(selectedBaseplanProduct);
              }
            }

            if (selectedCompnayPlan) {
              // selectedCompanyplanProduct = selectedCompnayPlan.productAddonss[selectedCompnayPlan.name][0]
              selectedCompanyplanProductsArray =
                selectedCompnayPlan.productAddonss[selectedCompnayPlan.name];
              // //this.consoleMessage(selectedCompanyplanProductsArray)
              // //this.consoleMessage(selectedCompnayPlan.name)

              let selectedCompanyplanProductsArray2 = [];

              if (CCplan.packageId == 5 || CCplan.packageId == 10) {
                selectedCompanyplanProductsArray2 = selectedCompanyplanProductsArray
                for (let i = 0; i < selectedCCplanProductarray.length - selectedCompanyplanProductsArray.length; i++) {
                  selectedCompanyplanProductsArray2.push(selectedCompanyplanProductsArray[selectedCompanyplanProductsArray.length - 1]);
                }
                //this.consoleMessage(selectedCompanyplanProductsArray)
                selectedCompanyplanProductsArray = selectedCompanyplanProductsArray2
                //this.consoleMessage(selectedCompanyplanProductsArray)

              }
              else {
                let productCoverageFound = false;
                for (let i = 0; i < selectedCCplanProductarray.length; i++) {
                  for (let j = 0; j < selectedCompanyplanProductsArray.length; j++) {

                    if (selectedCCplanProductarray[i].planCoverage == selectedCompanyplanProductsArray[j].planCoverage) {
                      productCoverageFound = true
                      selectedCompanyplanProductsArray2.push(selectedCompanyplanProductsArray[j])
                      break;
                    }


                  }
                  if (!productCoverageFound) {


                    selectedCompanyplanProduct = {};
                    selectedCompanyplanProduct.calculatedTax = {};
                    selectedCompanyplanProduct.calculatedTax.planProductInfos = [];
                    selectedCompanyplanProduct.name = "NA";
                    selectedCompanyplanProduct.planCoverage = selectedCCplanProductarray[i].planCoverage;
                    selectedCompanyplanProductsArray2.push(selectedCompanyplanProduct);



                  }

                }
                selectedCompanyplanProductsArray = selectedCompanyplanProductsArray2
              }


            } else {
              selectedCompanyplanProduct = {};
              selectedCompanyplanProduct.calculatedTax = {};
              selectedCompanyplanProduct.calculatedTax.planProductInfos = [];
              for (let i = 0; i < selectedCCplanProductarray.length; i++) {
                selectedCompanyplanProductsArray.push(
                  selectedCompanyplanProduct
                );
              }
              //this.consoleMessage(selectedCompanyplanProductsArray)
            }


            //this.consoleMessage(selectedCompanyplanProductsArray)
            //this.consoleMessage(selectedBaseplanProduct)
            //this.consoleMessage(selectedBaseplanProduct.calculatedTax.price)
            //this.consoleMessage(selectedBaseplanProduct.calculatedTax.tax)
            //this.consoleMessage(selectedBaseplanProduct.calculatedTax.total)
            // let selectedCCplanProduct = CCplan.productAddonss[CCplan.name][0]

            // //this.consoleMessage(selectedCCplanProduct.calculatedTax.price)
            // //this.consoleMessage(selectedCCplanProduct.calculatedTax.tax)
            // //this.consoleMessage(selectedCCplanProduct.calculatedTax.total)

            // //this.consoleMessage("Delta")
            // //this.consoleMessage(selectedCCplanProduct.calculatedTax.price-selectedBaseplanProduct.calculatedTax.price)
            // //this.consoleMessage("EmployeeTax")
            // //this.consoleMessage(selectedCCplanProduct.calculatedTax.planProductInfos.length>0?selectedCCplanProduct.calculatedTax.planProductInfos[0].taxRate:0)
            // //this.consoleMessage(selectedCCplanProduct.calculatedTax.planProductInfos.length>0?selectedCCplanProduct.calculatedTax.planProductInfos[0].taxRatePercentage:0)

            //this.consoleMessage("CompanyTax")
            //this.consoleMessage(selectedCompanyplanProduct.calculatedTax.planProductInfos.length>0?selectedCompanyplanProduct.calculatedTax.planProductInfos[0].taxRate:0)
            //this.consoleMessage(selectedCompanyplanProduct.calculatedTax.planProductInfos.length>0?selectedCompanyplanProduct.calculatedTax.planProductInfos[0].taxRatePercentage:0)

            let upgradeText;
            let upgradepaymentText;
            if (payroll) {
              upgradeText = "Employee upgraded using payroll deduction";
              upgradepaymentText = "using payroll deduction";
              //this.consoleMessage("One subscription to parent/corp with base(company tax)+Delta(employee tax)")
            } else {
              upgradeText = "Employee upgraded using Direct payment";
              upgradepaymentText = "using Direct payment";
            }

            modifiedCCplan.products = []

            for (let i = 0; i < selectedCCplanProductarray.length; i++) {
              let selectedBaseplanProduct = selectedBaseplanProductsArray[i];
              let selectedCompanyplanProduct =
                selectedCompanyplanProductsArray[i];
              let selectedCCplanProduct = selectedCCplanProductarray[i];
              let companyTax =
                selectedCompanyplanProduct.calculatedTax.planProductInfos
                  .length > 0
                  ? selectedCompanyplanProduct.calculatedTax.planProductInfos[0]
                    .taxRate
                  : 0;
              let companyTaxPercentage =
                selectedCompanyplanProduct.calculatedTax.planProductInfos
                  .length > 0
                  ? selectedCompanyplanProduct.calculatedTax.planProductInfos[0]
                    .taxRatePercentage
                  : 0;

              let baseplanPrice = adjustWith2DecimalsForUI(
                selectedBaseplanProduct.calculatedTax.price
              );
              let baseplanTaxorg = adjustWith2DecimalsForUI(
                selectedBaseplanProduct.calculatedTax.tax
              );
              let baseplanTax = adjustWith2DecimalsForUI(
                baseplanPrice * companyTax
              );
              let baseplanTotalorg = adjustWith2DecimalsForUI(
                selectedBaseplanProduct.calculatedTax.total
              );
              let baseplanTotal = adjustWith2DecimalsForUI(
                baseplanPrice + baseplanTax
              );
              let employeeTax =
                selectedCCplanProduct?.calculatedTax?.planProductInfos.length > 0
                  ? selectedCCplanProduct.calculatedTax.planProductInfos[0]
                    .taxRate
                  : 0;
              let employeeTaxPercentage =
                selectedCCplanProduct?.calculatedTax?.planProductInfos.length > 0
                  ? selectedCCplanProduct.calculatedTax.planProductInfos[0]
                    .taxRatePercentage
                  : 0;
              let deltaplanPrice = adjustWith2DecimalsForUI(
                selectedCCplanProduct.calculatedTax.price -
                selectedBaseplanProduct.calculatedTax.price
              );

              if (selectedBaseplanProduct.name == 'NA') {
                //this.consoleMessage("No Base plan/product for this upgrade plans/product")
                companyTax = employeeTax
                companyTaxPercentage = employeeTaxPercentage
              }
              let deltaplanTax = adjustWith2DecimalsForUI(
                deltaplanPrice *
                (this.deltaCompanyTax ? companyTax : employeeTax)
              );
              let deltaplanTotal = adjustWith2DecimalsForUI(
                deltaplanPrice + deltaplanTax
              );
              let combinedplanPrice = adjustWith2DecimalsForUI(
                baseplanPrice + deltaplanPrice
              );
              let combinedplanbasePrice = baseplanPrice;
              let combinedplanDeltaprice = deltaplanPrice;
              let combinedplanTax = adjustWith2DecimalsForUI(
                baseplanTax + deltaplanTax
              );
              let combinedplanCompanyTax = baseplanTax;
              let combinedplanEmployeeTax =
                adjustWith2DecimalsForUI(deltaplanTax);
              let combinedplanCompanyTaxPercentage = companyTaxPercentage;
              let combinedplanemployeeTaxPercentage = this.deltaCompanyTax
                ? companyTaxPercentage
                : employeeTaxPercentage;
              let combinedplanemployeeTaxPercentageorg = employeeTaxPercentage;

              let combinedplanTotal = adjustWith2DecimalsForUI(
                baseplanTotal + deltaplanTotal
              );

              let scenario = `Employee Upgrade from ${selectedBasePlan.name} to ${CCplan.name}`;
              let planName = `${CCplan.name} (${upgradeText})`;
              let compnayShareStatement = `${CCplan.name} Contribution`;
              let employeeShareStatement = `${CCplan.name} upgrade cost`;
              let planDescription = `${scenario} On a total of ${combinedplanTotal}, company share ${baseplanTotal} and Employee- pays ${upgradepaymentText} ${deltaplanTotal} `;
              let planDescription2 = `${scenario} On a total of ${combinedplanTotal}, company share ${baseplanPrice}(with tax:${companyTaxPercentage}) and Employee- pays ${upgradepaymentText} ${deltaplanPrice}(with tax:${employeeTaxPercentage}) `;
              let type;

              if (payroll) {
                type = "Split with payroll deduction";
              } else {
                type = "Split with direct pay";
              }
              let basePlanDetails = {
                planId: selectedBasePlan.id, //0
                planName: selectedBasePlan.name, //NA
                planLevelId: selectedBasePlan.planLevel, //0
                amount: baseplanPrice, //0
                amountUI: `$${baseplanPrice}`, //0
                tax: baseplanTax,
                total: baseplanTotal,
                taxUI: `$${baseplanTax}`, //companyTaxpercentage
                totalUI: `$${baseplanTotal}`,
                planLevelName: selectedBasePlan.groupName || "", //if it is Bundle
                planFrequencyID: selectedBasePlan.frqMonthly || "",
                planFusebillID: selectedBasePlan.fusebillId || "",
                products: [selectedBaseplanProduct],
                planLevel_parentId: selectedBasePlan.planLevel_parentId,
                packageName: selectedBasePlan.packageName,
                packageId: selectedBasePlan.packageId,
              };

              let obj = {
                companyTax,
                companyTaxPercentage,
                baseplanPrice,
                baseplanTaxorg,
                baseplanTax,
                baseplanTotalorg,
                baseplanTotal,
                employeeTax,
                employeeTaxPercentage,
                deltaplanPrice,
                deltaplanTax,
                deltaplanTotal,
                combinedplanPrice,
                combinedplanbasePrice,
                combinedplanDeltaprice,
                combinedplanTax,
                combinedplanCompanyTax,
                combinedplanEmployeeTax,
                combinedplanCompanyTaxPercentage,
                combinedplanemployeeTaxPercentage,
                combinedplanTotal,
                payroll,
                scenario,
                planName,
                compnayShareStatement,
                employeeShareStatement,
                planDescription,
                planDescription2,
                type,
                basePlanDetails,
                combinedplanemployeeTaxPercentageorg,
              };

              //this.consoleMessage(obj)
              let ccProduct: any = {}
              ccProduct.upgradeCalculation = obj;
              modifiedCCplan.products.push(ccProduct)
              modifiedCCplan.upgradeCalculation = obj;
            }

          }
          else {
            //this.consoleMessage("Not a BundlePlan")

            //this.consoleMessage(baseplans)
            var filtered_base_plans = baseplans.filter(function (plan: { packageId: any; planLevel_parentId: any; planLevel: number; }) {
              //this.consoleMessage(plan)
              //cp_plans

              //this.consoleMessage(CCplan.packageId,plan.packageId)
              //this.consoleMessage(CCplan.planLevel_parentId,plan.planLevel_parentId)
              //this.consoleMessage(CCplan.planLevel,plan.planLevel)

              return (
                CCplan.packageId == plan.packageId &&
                CCplan.planLevel_parentId == plan.planLevel_parentId &&
                CCplan.planLevel >= plan.planLevel
              );
            });

            var filtered_compnay_plans = compnayplans.filter(function (plan: { packageId: any; planLevel_parentId: any; planLevel: number; }) {
              //this.consoleMessage(plan)
              //cp_plans

              //this.consoleMessage(CCplan.packageId,plan.packageId)
              //this.consoleMessage(CCplan.planLevel_parentId,plan.planLevel_parentId)
              //this.consoleMessage(CCplan.planLevel,plan.planLevel)

              return (
                CCplan.packageId == plan.packageId &&
                CCplan.planLevel_parentId == plan.planLevel_parentId &&
                CCplan.planLevel >= plan.planLevel
              );
            });

            //this.consoleMessage(filtered_base_plans)

            //this.consoleMessage(filtered_compnay_plans)
            let selectedBasePlan;
            let selectedCompnayPlan;
            let DeltaPlan;
            if (filtered_base_plans.length == 0) {
            } else if (filtered_base_plans.length == 1) {
              selectedBasePlan = filtered_base_plans[0];
              selectedCompnayPlan = filtered_compnay_plans[0];
            } else {
              //this.consoleMessage(filtered_base_plans.length)
            }

            let selectedbaseplanproduct: any;
            let selectedBaseplanProduct: any;

            if (selectedBasePlan) {
              for (let planname in selectedBasePlan.productAddonss) {
                //this.consoleMessage(planname)
                //this.consoleMessage(selectedBasePlan.productAddonss[planname])
                let products = selectedBasePlan.productAddonss[planname];

                if (products.length == 0) {
                } else if (products.length == 1) {
                  selectedbaseplanproduct = products[0];
                  // selectedCompnayPlan= filtered_compnay_plans[0]
                } else {
                  //this.consoleMessage(products.length)
                }
              }
              //this.consoleMessage(selectedbaseplanproduct)
              selectedBaseplanProduct = selectedbaseplanproduct;

              //this.consoleMessage(selectedBaseplanProduct.calculatedTax.price)
              //this.consoleMessage(selectedBaseplanProduct.calculatedTax.tax)
              //this.consoleMessage(selectedBaseplanProduct.calculatedTax.total)
            } else {
              selectedBasePlan = {};
              selectedBaseplanProduct = {};
              selectedBaseplanProduct.calculatedTax = {};
              selectedBaseplanProduct.calculatedTax.price = 0;
              selectedBaseplanProduct.calculatedTax.tax = 0;
              selectedBaseplanProduct.calculatedTax.total = 0;
              selectedBaseplanProduct.name = "NA";
            }

            let CCplanProduct;

            for (let planname in CCplan.productAddonss) {
              let products = CCplan.productAddonss[planname];

              if (products.length == 0) {
              } else if (products.length == 1) {
                CCplanProduct = products[0];
                // selectedCompnayPlan= filtered_compnay_plans[0]
              } else {
                //this.consoleMessage(products.length)
              }
            }
            let selectedCCplanProduct = CCplanProduct;

            // //this.consoleMessage(selectedCCplanProduct.calculatedTax.price)
            // //this.consoleMessage(selectedCCplanProduct.calculatedTax.tax)
            // //this.consoleMessage(selectedCCplanProduct.calculatedTax.total)

            // //this.consoleMessage("Delta")
            // //this.consoleMessage(selectedCCplanProduct.calculatedTax.price-selectedBaseplanProduct.calculatedTax.price)
            // //this.consoleMessage("EmployeeTax")
            // //this.consoleMessage(selectedCCplanProduct.calculatedTax.planProductInfos.length>0?selectedCCplanProduct.calculatedTax.planProductInfos[0].taxRate:0)
            // //this.consoleMessage(selectedCCplanProduct.calculatedTax.planProductInfos.length>0?selectedCCplanProduct.calculatedTax.planProductInfos[0].taxRatePercentage:0)

            let selectedcompanyplanproduct: any;
            let selectedCompanyplanProduct: any;

            if (selectedCompnayPlan) {
              for (let planname in selectedCompnayPlan.productAddonss) {
                //this.consoleMessage(planname)
                //this.consoleMessage(selectedCompnayPlan.productAddonss[planname])
                let products = selectedCompnayPlan.productAddonss[planname];

                if (products.length == 0) {
                } else if (products.length == 1) {
                  selectedcompanyplanproduct = products[0];
                  // selectedCompnayPlan= filtered_compnay_plans[0]
                } else {
                  //this.consoleMessage(products.length)
                }
              }
              selectedCompanyplanProduct = selectedcompanyplanproduct;

              //this.consoleMessage("CompanyTax")
              //this.consoleMessage(selectedCompanyplanProduct.calculatedTax.planProductInfos.length>0?selectedCompanyplanProduct.calculatedTax.planProductInfos[0].taxRate:0)
              //this.consoleMessage(selectedCompanyplanProduct.calculatedTax.planProductInfos.length>0?selectedCompanyplanProduct.calculatedTax.planProductInfos[0].taxRatePercentage:0)
            } else {
              selectedCompanyplanProduct = {};
              selectedCompanyplanProduct.calculatedTax = {};
              selectedCompanyplanProduct.calculatedTax.planProductInfos = [];
            }

            let upgradeText;
            let upgradepaymentText;
            if (payroll) {
              upgradeText = "Employee upgraded using payroll deduction";
              upgradepaymentText = "using payroll deduction";
              //this.consoleMessage("One subscription to parent/corp with base(company tax)+Delta(employee tax)")
            } else {
              upgradeText = "Employee upgraded using Direct payment";
              upgradepaymentText = "using Direct payment";
            }
            let companyTax =
              selectedCompanyplanProduct.calculatedTax.planProductInfos.length >
                0
                ? selectedCompanyplanProduct.calculatedTax.planProductInfos[0]
                  .taxRate
                : 0;
            let companyTaxPercentage =
              selectedCompanyplanProduct.calculatedTax.planProductInfos.length >
                0
                ? selectedCompanyplanProduct.calculatedTax.planProductInfos[0]
                  .taxRatePercentage
                : 0;
            let baseplanPrice = adjustWith2DecimalsForUI(
              selectedBaseplanProduct.calculatedTax.price
            );
            let baseplanTaxorg = adjustWith2DecimalsForUI(
              selectedBaseplanProduct.calculatedTax.tax
            );
            let baseplanTax = adjustWith2DecimalsForUI(
              baseplanPrice * companyTax
            );
            let baseplanTotalorg = adjustWith2DecimalsForUI(
              selectedBaseplanProduct.calculatedTax.total
            );
            let baseplanTotal = adjustWith2DecimalsForUI(
              baseplanPrice + baseplanTax
            );
            let employeeTax =
              selectedCCplanProduct.calculatedTax.planProductInfos.length > 0
                ? selectedCCplanProduct.calculatedTax.planProductInfos[0]
                  .taxRate
                : 0;
            let employeeTaxPercentage =
              selectedCCplanProduct.calculatedTax.planProductInfos.length > 0
                ? selectedCCplanProduct.calculatedTax.planProductInfos[0]
                  .taxRatePercentage
                : 0;

            let deltaplanPrice = adjustWith2DecimalsForUI(
              selectedCCplanProduct.calculatedTax.price -
              selectedBaseplanProduct.calculatedTax.price
            );

            //this.consoleMessage(selectedCCplanProduct.calculatedTax.price)
            //this.consoleMessage(selectedBaseplanProduct.calculatedTax.price)
            //this.consoleMessage(selectedCCplanProduct.calculatedTax.price -selectedBaseplanProduct.calculatedTax.price)
            if (selectedBaseplanProduct.name == 'NA') {
              //this.consoleMessage("No Base plan/product for this upgrade plans/product")
              companyTax = employeeTax
              companyTaxPercentage = employeeTaxPercentage
            }
            let deltaplanTax = adjustWith2DecimalsForUI(
              deltaplanPrice * (this.deltaCompanyTax ? companyTax : employeeTax)
            );
            // let deltaplanTax = deltaplanPrice*(this.deltaCompanyTax?companyTax:employeeTax)

            let deltaplanTotal = adjustWith2DecimalsForUI(
              deltaplanPrice + deltaplanTax
            );

            let combinedplanPrice = baseplanPrice + deltaplanPrice;
            let combinedplanbasePrice = baseplanPrice;
            let combinedplanDeltaprice = deltaplanPrice;
            let combinedplanTax = adjustWith2DecimalsForUI(
              baseplanTax + deltaplanTax
            );
            let combinedplanCompanyTax = baseplanTax;
            let combinedplanEmployeeTax =
              adjustWith2DecimalsForUI(deltaplanTax);
            let combinedplanCompanyTaxPercentage = companyTaxPercentage;
            let combinedplanemployeeTaxPercentage = this.deltaCompanyTax
              ? companyTaxPercentage
              : employeeTaxPercentage;
            let combinedplanemployeeTaxPercentageorg = employeeTaxPercentage;
            let combinedplanTotal = adjustWith2DecimalsForUI(
              baseplanTotal + deltaplanTotal
            );

            let scenario = `Employee Upgrade from ${selectedBaseplanProduct.name} to ${selectedCCplanProduct.name}`;
            let planName = `${CCplan.name}(${selectedCCplanProduct.name}) (${upgradeText})`;
            let compnayShareStatement = `${CCplan.name}(${selectedCCplanProduct.name}) Contribution`;
            let employeeShareStatement = `${CCplan.name}(${selectedCCplanProduct.name}) upgrade cost`;
            let planDescription = `${scenario} On a total of ${combinedplanTotal}, company share ${baseplanTotal} and Employee- pays ${upgradepaymentText} ${deltaplanTotal} `;
            let planDescription2 = `${scenario} On a total of ${combinedplanTotal}, company share ${baseplanPrice}(with tax:${companyTaxPercentage}) and Employee- pays ${upgradepaymentText} ${deltaplanPrice}(with tax:${employeeTaxPercentage}) `;
            let type;

            if (payroll) {
              type = "Split with payroll deduction";
            } else {
              type = "Split with direct pay";
            }
            let basePlanDetails = {
              planId: selectedBasePlan.id || 0, //0
              planName: selectedBasePlan.name || "NA", //NA
              planLevelId: selectedBasePlan.planLevel || 0, //0
              amount: baseplanPrice || 0, //0
              amountUI: `$${baseplanPrice}` || 0, //0
              tax: baseplanTax,
              total: baseplanTotal,
              taxUI: `$${baseplanTax}`, //companyTaxpercentage
              totalUI: `$${baseplanTotal}`,
              planLevelName: selectedBasePlan.groupName || "", //if it is Bundle
              planFrequencyID: selectedBasePlan.frqMonthly || "",
              planFusebillID: selectedBasePlan.fusebillId || "",
              products: [selectedBaseplanProduct],
              planLevel_parentId: selectedBasePlan.planLevel_parentId || 0,
              packageName: selectedBasePlan.packageName || "",
              packageId: selectedBasePlan.packageId || 0,
            };
            let obj = {
              companyTax,
              companyTaxPercentage,
              baseplanPrice,
              baseplanTaxorg,
              baseplanTax,
              baseplanTotalorg,
              baseplanTotal,
              employeeTax,
              employeeTaxPercentage,
              deltaplanPrice,
              deltaplanTax,
              deltaplanTotal,
              combinedplanPrice,
              combinedplanbasePrice,
              combinedplanDeltaprice,
              combinedplanTax,
              combinedplanCompanyTax,
              combinedplanEmployeeTax,
              combinedplanCompanyTaxPercentage,
              combinedplanemployeeTaxPercentage,
              combinedplanTotal,
              payroll,
              scenario,
              planName,
              compnayShareStatement,
              employeeShareStatement,
              planDescription,
              planDescription2,
              type,
              basePlanDetails,
              combinedplanemployeeTaxPercentageorg,
            };

            //this.consoleMessage(obj)
            modifiedCCplan.upgradeCalculation = obj;
          }
          modifiedCCgroup.plans.push(modifiedCCplan);
        }
        modifiedCCPackage.groupsCC.push(modifiedCCgroup);
      }

      modifiedCCplanPackages.push(modifiedCCPackage);
    }

    this.modifiedBlocksCoveredByCompany = modifiedCCplanPackages;

    //this.consoleMessage(this.modifiedBlocksCoveredByCompany)
  }

  public capitalize(str: String) {
    if (str) {
      // return str.charAt(0).toUpperCase() + str.slice(1);
      const words = str.split(' ');

      let finalres: any = words.filter((v) => v != '');
      return finalres
        .map((word: any) => {
          return word[0].toUpperCase() + word.substring(1);
        })
        .join(' ');
    }
  }
  public closemissingInfoModel() {
    this.missingInformation = false
  }
  public closeoptionsselection() {
    // console.log(this.optionstitle)
    this.planOptionsModel =false
    let plandetailsobj = this.optionPlanDetailsObj.nativeElement.value.split("##");

    // //this.consoleMessage(plandetailsobj[19])
    // //this.consoleMessage(plandetailsobj[20])
    console.log(plandetailsobj)

    // $("#plancheck" + plandetailsobj[19]).prop("checked", false);
   const planCheck = this.renderer.selectRootElement("#plancheck"+ plandetailsobj[7]);
      // this.renderer.setProperty(planCheck, 'checked', false);
      planCheck.click()


    let optionumber =this.optionNumberInput.nativeElement.value;

    this.planOptionsModel =false
    for (let i = 1; i <= optionumber; i++) {
      // $(".optionselectionmethod-" + i + ":checked").prop("checked", false);
      const radioButton = this.renderer.selectRootElement(".optionselectionmethod-" + i +":checked", true);
      this.renderer.setProperty(radioButton, 'checked', false);

    }
  }
  public planoptionselection(event:any, optionvalue:any, optionid:any, optionvalueid:any,index:any,className:any) {

    let obj={
      id:optionid,
    }

    this.planoptionsValues.push(obj)

    const radioButton = this.renderer.selectRootElement(".optionselectionmethod-" + optionid);
    this.renderer.setProperty(radioButton, 'checked', false);



      const elements = document.querySelectorAll(`.${className}`+ optionid);
    elements.forEach((element: Element) => {
      this.renderer.setProperty(element, 'checked', false);
    });



   const radioButtonID = this.renderer.selectRootElement("#planselectionvalue-" + optionvalueid + "-" + optionid,true);
    this.renderer.setProperty(radioButtonID, 'checked', true);


    this.optionmessage = "";
  }
  public confirmoptionsselection() {
    // let optionumber = $("#optionumber").val();

    // let optionumber: any = this.optionstitle
    // //this.consoleMessage($("#plandetailsobj").val())

    // let plandetailsobj = this.plandetailsobjvalue.split("##");



    // let array = [];

    // let optionarray = [];


    // let checkvalue = true;

    // for (let i = 1; i <= optionumber; i++) {
    //   checkvalue = checkvalue && this.planoptionsValues.val();
    // }

    // if (!checkvalue) {
    //   this.optionmessage = this.lang.Please_select_all_plan_options;
    //   return;
    // } else {
    //   this.optionmessage = "";
    // }

    // // jQuery("#showplanoptions-modal").modal("hide");

    // this.planOptionsModel = false


    // for (let i = 1; i <= optionumber; i++) {
    //   let optionobj: any = {};

    //   let selectedOptionValues = JSON.parse(this.planoptionsValues)

    //   // ////this.consoleMessage(selectedOptionValues)
    //   optionobj = selectedOptionValues;

    //   optionobj.name = selectedOptionValues.planOptionName;
    //   optionobj.value = selectedOptionValues.value;
    //   optionobj.planOptionsValueId = selectedOptionValues.planOptionsValueId;

    //   optionarray.push(optionobj);
    // }
    let optionumber:any =this.optionNumberInput.nativeElement.value;


    let plandetailsobj = this.optionPlanDetailsObj.nativeElement.value.split("##");



    let array = [];

    let optionarray = [];


    let checkvalue = true;



//     for (let i = 1; i <= optionumber; i++) {

//  checkvalue = checkvalue &&   this.renderer.selectRootElement(".optionselectionmethod-" + i +":checked").value;
//     }

for (let i = 1; i <= optionumber; i++) {
  // console.log(this.renderer.selectRootElement(".optionselectionmethod-" + i +":checked").value)
  // checkvalue = checkvalue &&   this.renderer.selectRootElement(".optionselectionmethod-" + i +":checked").value;
  const dom: HTMLElement = this.elementRef.nativeElement;
  const selector = `.optionselectionmethod-${i}:checked`;
  const element = dom.querySelector(selector);

  if (!element) {
    checkvalue = false; // Set to false if any element is not checked
    break; // Exit the loop early since we found an unchecked element
  }
}
    if (!checkvalue) {

      this.optionmessage = this.lang.Please_select_all_plan_options;
      return;
    } else {
      this.optionmessage = "";
    }

    // jQuery("#showplanoptions-modal").modal("hide");

this.planOptionsModel =false


    for (let i = 1; i <= optionumber; i++) {
      let optionobj: any = {};

      let selectedOptionValues = JSON.parse(
        this.renderer.selectRootElement(".optionselectionmethod-" + i +":checked").value
      );

      optionobj = selectedOptionValues;

      optionobj.name = selectedOptionValues.planOptionName;
      optionobj.value = selectedOptionValues.value;
      optionobj.planOptionsValueId = selectedOptionValues.planOptionsValueId;

      optionarray.push(optionobj);
    }
    // let plandetailsobj = $("#plandetailsobj").val().split(",");

    let obj = {
      isBundle:"true",
      // enrollmentDate: moment(
      //   this.userForm.get("planenrollmentdate").value,
      //   "MM-DD-YYYY"
      // ).format("YYYY-MM-DD"),
      enrollmentDate: this.userForm.value.planEnrollmentDate
        ? this._CustomDatePipe.transform(this.userForm.value.planEnrollmentDate)
        : undefined,
      // "name":plandetailsobj[1]+"-"+plandetailsobj[2],
      name: plandetailsobj[14],
      details: plandetailsobj[0],
      packagename: plandetailsobj[0],
      groupName: plandetailsobj[1],
      amount: parseFloat(plandetailsobj[3]),
      planCoverage: plandetailsobj[2],
      planPrice: parseFloat(plandetailsobj[3]),
      amountUI: "$" + this._decimalPipe.transform(plandetailsobj[3], "1.2-2"),
      gst:
        plandetailsobj[4] == null ||
          plandetailsobj[4] == "" ||
          plandetailsobj[4] == undefined ||
          plandetailsobj[4] == "null"
          ? 0
          : parseFloat(plandetailsobj[4]),
      hst:
        plandetailsobj[5] == null ||
          plandetailsobj[5] == "" ||
          plandetailsobj[5] == undefined ||
          plandetailsobj[5] == "null"
          ? 0
          : parseFloat(plandetailsobj[5]),
      pst:
        plandetailsobj[6] == null ||
          plandetailsobj[6] == "" ||
          plandetailsobj[6] == undefined ||
          plandetailsobj[6] == "null"
          ? 0
          : parseFloat(plandetailsobj[6]),
      qst:
        plandetailsobj[17] == null ||
          plandetailsobj[17] == "" ||
          plandetailsobj[17] == undefined ||
          plandetailsobj[17] == "null"
          ? 0
          : parseFloat(plandetailsobj[17]),
      gstCheck:
        plandetailsobj[4] == null ||
          plandetailsobj[4] == "" ||
          plandetailsobj[4] == undefined ||
          plandetailsobj[4] == "null"
          ? false
          : true,
      hstCheck:
        plandetailsobj[5] == null ||
          plandetailsobj[5] == "" ||
          plandetailsobj[5] == undefined ||
          plandetailsobj[5] == "null"
          ? false
          : true,
      pstCheck:
        plandetailsobj[6] == null ||
          plandetailsobj[6] == "" ||
          plandetailsobj[6] == undefined ||
          plandetailsobj[6] == "null"
          ? false
          : true,
      qstCheck:
        plandetailsobj[17] == null ||
          plandetailsobj[17] == "" ||
          plandetailsobj[17] == undefined ||
          plandetailsobj[17] == "null"
          ? false
          : true,
      id: parseFloat(plandetailsobj[7]),
      fusebillPlanID:
        plandetailsobj[8] == null ||
          plandetailsobj[8] == "" ||
          plandetailsobj[8] == undefined ||
          plandetailsobj[8] == "null"
          ? 0
          : parseFloat(plandetailsobj[8]),
      planFrequencyID:
        plandetailsobj[9] == null ||
          plandetailsobj[9] == "" ||
          plandetailsobj[9] == undefined ||
          plandetailsobj[9] == "null"
          ? 0
          : parseFloat(plandetailsobj[9]),
      optIn: plandetailsobj[10] == "true" ? true : false,
      planname: plandetailsobj[14],
      planLevel: parseInt(plandetailsobj[15]),
      packageId: parseInt(plandetailsobj[16]),
      options: optionarray || [],
      //  "disallowedPlanLevels":plandetailsobj[11]
    };
    if (plandetailsobj[11] != null || plandetailsobj[11] != "null") {
      if (plandetailsobj[11].includes(plandetailsobj[12])) {
        // elementcv.checked =false
      }
    }

    let obj1 = {}
    let e = {}
    //this.consoleMessage(obj)

    console.log(obj)
    console.log(this.planobjdata)
    this.addtoplansummary(obj, this.planobjdata, this.elementcvValues)




  }


  voluntryplansConfirmation(event: any) {

    const isChecked = (event.target as HTMLInputElement).checked;
    // console.log(isChecked)

    if (isChecked == true) {
      this.voluntryplansCheck = false
    }
    else {
      this.voluntryplansCheck = true
    }

  }

  upgradeplansConfirmation(event: any) {

    const isChecked = (event.target as HTMLInputElement).checked;
    // console.log(isChecked)

    if (isChecked == true) {
      this.upgradeplansCheck = false
    }
    else {
      this.upgradeplansCheck = true
    }

  }

  companyPlansConfirmation(event: any) {

    const isChecked = (event.target as HTMLInputElement).checked;
    // console.log(isChecked)

    if (isChecked == true) {
      this.companyplansCheck = false
    }
    else {
      this.companyplansCheck = true
    }

  }
  public showvoidcheckpreview() {
    this.imageDisplayShow = true

  }
  public closeImagePreview() {
    this.imageDisplayShow = false
  }
}

export function emailValidator(control: AbstractControl): any {
  var emailRegexp =
    /^(?!\\s)*[\sA-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[a-z]{2,3}(?!\\s)*$/i;

  // var emailRegexp =
  //   /^(?!\\s)[_A-Za-z0-9._%+-]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$/i;

  if (control.value && !emailRegexp.test(control.value)) {
    return { invalidEmail: true };
  }
}
export function childrenminimumAgeValidator(minimumAge: number) {
  return (control: { value: string | number | Date }) => {
    const birthDate = new Date(control.value);

    // //this.consoleMessage(birthDate)
    const today = new Date();
    const age = today.getFullYear() - birthDate.getFullYear();

    // //this.consoleMessage(age)
    // //this.consoleMessage(minimumAge)

    if (age == 55) {
      return { childrenminimumAge: false };
    }
    if (age > 21 || age < 0) {
      return { childrenminimumAge: true };
    }

    return null;
  };
}
export function validatehoursperweek(control: AbstractControl): any {
  if (control.value && control.value < 20) {
    return { mininvalidhours: true };
  } else if (control.value && control.value >= 81) {
    return { maxinvalidhours: true };
  }
}
export function hourselectioncondition(control: AbstractControl): any {
  if (control.value && control.value === 'false') {
    return { invalidhoursselection: true };
  }
}
export function creditcardvalidation(control: AbstractControl): any {
  if (control.value.slice(0, 2) == 34 || control.value.slice(0, 2) == 37) {
    var creditcardRegexp = /^[0-9_ ]{17,17}$/;
    if (control.value && !creditcardRegexp.test(control.value)) {
      return { invalidcreditcarddetails: true };
    }
  } else {
    var creditcardRegexp = /^[0-9_ ]{19,19}$/;
    if (control.value && !creditcardRegexp.test(control.value)) {
      return { invalidcreditcarddetails: true };
    }
  }
}
export function creditcardvalidationAmerica(control: AbstractControl): any { }
export function checkbanknumber(control: AbstractControl): any {
  if (control.value && control.value.length != 3) {
    return { checkbanknumbervalidator: true };
  }
}
export function checktransitnumber(control: AbstractControl): any {
  if (control.value && control.value.length != 5) {
    return { checktransitnumbervalidator: true };
  }
}

export function checkaccountnumber(control: AbstractControl): any {
  if (
    control.value &&
    (control.value.length < 5 || control.value.length > 12)
  ) {
    return { checkaccountnumbervalidator: true };
  }
}




